import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { BreakdownOpts, BreakdownOpt } from '../../types';
export interface DropdownProps {
  options: BreakdownOpts;
  placeholder: string;
  breakdown: string;
  label: string;
  value: BreakdownOpts;
  onSubBreakdownsChange: (
    breakdown: string,
    subBreakdowns: BreakdownOpts
  ) => void;
}

export default function BreakdownOptions({
  options,
  placeholder,
  breakdown,
  label,
  value,
  onSubBreakdownsChange,
}: DropdownProps) {
  return (
    <Autocomplete
      multiple
      id='checkboxes-dropdown-breakdown'
      key={label}
      options={options}
      size='small'
      filterSelectedOptions
      value={value}
      onChange={(_: React.ChangeEvent<{}>, values: BreakdownOpts) => onSubBreakdownsChange(breakdown, values)}
      getOptionLabel={(option: BreakdownOpt) => option.label}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          style={{ width: '300px' }}
        />
      )}
    />
  );
}



