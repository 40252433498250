import {
  AdludioTheme,
  Avatar,
  AvatarGroup,
  Box,
  Tooltip,
  makeStyles,
} from '@adludio/components';
import React, { useState } from 'react';

import { User } from '../generated/graphql';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  userAvatar: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const purpleTooltipStyles = makeStyles((theme: AdludioTheme) => ({
  tooltip: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

interface MyTeamProps {
  team: User[];
  teamInitials: string[];
  openModal: (open: boolean) => void;
}
const Avatars = ({ team, teamInitials, openModal }: MyTeamProps) => {
  const { userAvatar } = useStyles();
  const [isShown, setIsShown] = useState(false);
  const handleMouseEnter = () => {
    if (team.length < 8) {
      setIsShown(true);
    } else {
      openModal(true);
    }
  };
  const handleMouseLeave = () => {
    if (team.length < 8) {
      setIsShown(false);
    }
  };
  const { tooltip } = purpleTooltipStyles();
  return (
    <Box display={{ xs: 'none', md: 'block' }} py='1rem'>
      <AvatarGroup
        max={isShown ? 8 : 4}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {team.map((user: User, i: number) => (
          <Tooltip title={user.name!} key={user.name} className={tooltip}>
            <Avatar bgColour='red' className={userAvatar}>
              {teamInitials[i]}
            </Avatar>
          </Tooltip>
        ))}
      </AvatarGroup>
    </Box>
  );
};

export default Avatars;
