export const mockDSPs = [
  { label: 'TTD', value: 'TTD', key: 'TTD' },
  { label: 'DV360', value: 'DV360', key: 'DV360' },
  { label: 'XANDR', value: 'XANDR', key: 'XANDR' },
  // { label: 'Verizon', value: 'Verizon', key: 'Verizon' },
  // { label: 'Amobee', value: 'Amobee', key: 'Amobee' },
  // { label: 'Adform', value: 'Adform', key: 'Adform' },
  { label: 'Kidoz', value: 'Kidoz', key: 'Kidoz' },
  { label: 'Yahoo', value: 'Yahoo', key: 'Yahoo' },
];
