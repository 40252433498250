import React from 'react';

import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import { BreakdownReportValues } from '../../types';

import { MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbar from './CustomToolbar';
import tableColumnFilterOption from '../../reducers/muiTableOptions';
import { getMuiTheme } from '../../utils/Styles';

export interface NumericReportProps {
  report: BreakdownReportValues;
  tableRows:any[];
  campaignName: string;
  kpiCols: string[];
  category?: string[];
  titleText: string;
}

export interface filterProps {
  name?: string | undefined;
  label?: string | undefined;
  options?: {};
}

const NumericReport = ({
  report,
  tableRows,
  campaignName,
  kpiCols,
  category,
  titleText
}: NumericReportProps) => {

  const columns: any[] = [];


  // datatable column handler array
  if (category){
    columns.push(tableColumnFilterOption('category', category));
  }
  kpiCols.forEach((kpi) => {
    columns.push(tableColumnFilterOption(kpi));
  });

  const getCustomToolBar = () => {
    /*
     * this is name less because PDFDownloader accept breakdownReportValues
     * and overall report doesn't have a breakdown thus we just give it no name
     */


    return (
      <CustomToolbar
        report={report}
        campaignName={campaignName}
        kpiCols={kpiCols}
        isSite={false}
      />
    );
  };

  const options: MUIDataTableOptions = {
    customToolbar: getCustomToolBar,
    filter: true,
    print: false,
    selectableRows: 'none',
    tableBodyHeight: '100%',
    fixedHeader: true,
    search: false,
    elevation: 0,
    textLabels: {
      body: {
        noMatch: 'Sorry, no matching records found',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    download: false,
  };


  return (
    <div style={{ width: '100%' }}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={tableRows}
          columns={columns}
          options={options}
          title={titleText}
        />
      </MuiThemeProvider>
    </div>
  );
};

export default NumericReport;
