import * as React from 'react';

export function preventBriefExit(wasSaved: boolean, values: any) {
  const [preventExit, setPreventExit] = React.useState(false);
  const [hasChanged, setHasChanged] = React.useState(false);
  
  const alertUser = (e : any) => {
    e.preventDefault()
    e.returnValue = ''
  }
  const noAlert = (e : any) => {
  }
  
  React.useEffect(() => {
    setHasChanged(true);
  }, [values])

  React.useEffect(() => {
    setHasChanged(false);
  }, [wasSaved])

  React.useEffect(() => {
    if (hasChanged) {
      setPreventExit(true);
    }
    else {
      if (wasSaved) {
        setPreventExit(false); 
      }
      else setPreventExit(false);
    }
  }, [hasChanged, wasSaved])

  React.useEffect(() => {
    window.addEventListener('beforeunload', preventExit ? alertUser : noAlert)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  }, [preventExit])
}
