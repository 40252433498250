import './charts.css';

import * as React from 'react';

import { removeURLPrefix, truncateString } from '../../utils/stringFilter';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { Site } from '../../types';
import { formatAxis } from '../../utils/ChartAxis';

export interface DonutPlotProps {
  topN: number;
  kpi: string;
  siteList: Site[];
}
const DonutPlot = ({ topN, kpi, siteList }: DonutPlotProps) => {
  siteList.sort((prev, curr) => curr[kpi] - prev[kpi]);
  const allSitesImp = siteList.map((site) => site.impression);
  const subSiteList = siteList.slice(0, topN);
  subSiteList.sort((prev, curr) => curr['impression'] - prev['impression']);
  const series = subSiteList.map((site) => site.impression);
  const labels = subSiteList.map((site) => removeURLPrefix(site.site));

  // compute total impression first to know the proportion of top-N sites
  const totalImp = allSitesImp.reduce(function (a, b) {
    return a + b;
  }, 0);

  const options: ApexOptions = {
    series: series,
    theme: {
      monochrome: {
        enabled: true,
        color: '#4E55C3',
      },
    },
    labels: labels,
    chart: {
      width: 'max-content',
    },
    dataLabels: {
      enabled: true,
      formatter: (value: string | number | number[], opts: any): string => {
        /*
         * the bellow is formatter function template of ApexCharts
         * https://apexcharts.com/docs/options/datalabels/#formatter
         * formatter: function(value, { seriesIndex, dataPointIndex, w }) {
         * return w.config.series[seriesIndex].name + ":  " + value
         * }
         */
        const numerator = parseInt(opts.w.globals.series[opts.seriesIndex]);
        const percent = (numerator / totalImp) * 100;
        return `${percent.toFixed(2)}%`;
      },
    },
    tooltip: {
      y: {
        formatter: (value: number | string): string => {
          return formatAxis('Impression', value);
        },
      },
    },
    title: {
      text: `Impression proportion of top ${topN} most served sites by ${kpi}`,
      align: 'center',
      style: {
        fontSize: '12px',
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '10px',
      horizontalAlign: 'center',
      formatter: (seriesName: string): string => {
        return truncateString(seriesName);
      },
      width: 500,
    },
    responsive: [
      {
        breakpoint: 1280,
        options: {
          chart: {
            width: 550,
          },
          title: {
            text: `Impression share by ${kpi}`,
          },
          legend: {
            formatter: (seriesName: string): string => {
              return truncateString(seriesName, 20);
            },
          },
        },
      },
    ],
  };
  return (
    <div>
      <ReactApexChart
        options={options}
        series={options.series}
        type='pie'
        width={500}
        height={400}
      />
    </div>
  );
};
export default DonutPlot;
