/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint react/prop-types: 0 */
import * as React from 'react';
import { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Subtitle2,
  Button,
  Box,
  Divider,
  Caption,
  Tooltip,
} from '@adludio/components';
import { CampaignDocument, FileStatus } from '../../models/document';
import { Chip } from '@material-ui/core';
import { FileIcon, defaultStyles } from 'react-file-icon';
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import AcceptPlanModal from '../Modals/AcceptPlan';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { FileType } from '../../generated/graphql';
import { useCanApproveDocument } from './useCanApproveDocument';
import { useDownload } from '../../helpers/useDownload';

interface DocumentCardProps {
  document: CampaignDocument;
  handleApprove: (documentId: string, newStatus: FileStatus) => void;
  hideStatus?: boolean;
  hideName?: boolean;
  isManaged?: boolean | null | undefined;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    height: ({ isManaged }: { isManaged: boolean }) =>
      isManaged ? '16.5rem' : '100%',
    width: '100%',
    maxWidth: '18.5rem',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  chip: {
    height: '1.5rem',
  },
  awaitingChip: {
    borderColor: theme.palette.warning.dark,
    color: theme.palette.warning.dark,
    height: '1.5rem',
  },
  approvedChip: {
    height: '1.5rem',
    color: theme.palette.success.dark,
    borderColor: theme.palette.success.dark,
  },
  filenameText: {
    textOverflow: 'ellipsis',
    textAlign: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '10rem',
  },
  cardContent: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '2em important',
  },
  text: {
    height: ' -webkit-fill-available',
    flexBasis: '100%',
    justifyContent: ' flex-end',
  },
}));

export const DocumentCard: React.FC<DocumentCardProps> = ({
  document,
  handleApprove,
  hideStatus,
  hideName,
  isManaged,
}) => {
  const {
    root,
    chip,
    awaitingChip,
    approvedChip,
    filenameText,
    cardContent,
    text,
  } = styles({
    isManaged: !!isManaged,
  });

  const isAwaiting: boolean = document.fileStatus === 'AWAITING';
  const isApproved: boolean = document.fileStatus === 'ACCEPTED';
  const canApprove = useCanApproveDocument(document, isManaged || false);
  const [acceptPlanDialogOpen, setAcceptPlanDialogOpen] = useState(false);
  const { downloadDocument } = useDownload();
  const [hover, setHover] = useState<boolean>(false);

  /* some dates are not getting recognized as Dates but rather strings, probably TS things */
  const dateTime = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString();
  };
  const CardFileIcon = (): JSX.Element => {
    const fileExt = document.fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />;
  };

  const StatusChip = (): JSX.Element => {
    let BadgeChip: JSX.Element = (
      <Chip
        label='Archived'
        variant='outlined'
        color='secondary'
        clickable={false}
        className={chip}
      />
    );
    if (isApproved) {
      BadgeChip = (
        <Chip
          label='Approved'
          variant='outlined'
          color='secondary'
          clickable={false}
          className={approvedChip}
        />
      );
    }
    if (isAwaiting) {
      BadgeChip = (
        <Chip
          label='Awaiting approval'
          variant='outlined'
          color='secondary'
          clickable={false}
          className={awaitingChip}
        />
      );
    }
    return (
      <Box py='1rem' display='flex' justifyContent='center'>
        <> {BadgeChip}</>
      </Box>
    );
  };

  const getCardTitle = () => {
    switch (document.fileType) {
      case FileType.CampaignPlan:
        return isManaged ? 'Campaign Plan' : 'Spend Floor CPM';
      case FileType.SiteList:
        return 'Site List';
      case FileType.SpendFloorCpm:
        return 'Spend Floor CPM';
      default:
        return 'Storyboard';
    }
  };

  return (
    <>
      <Card className={root} elevation={2}>
        <CardContent className={cardContent}>
          <Grid
            container
            direction='column'
            wrap='nowrap'
            justifyContent='space-between'
            xs={12}
          >
            <Grid container item direction='column' justifyContent='center'>
              {/* expression to remove the extension from the file name */}
              <Subtitle2 align='center'>{getCardTitle()}</Subtitle2>
              <Box pt='0.5rem'>
                <Divider variant='fullWidth' />
              </Box>
            </Grid>

            <Grid
              item
              container
              direction='column'
              className={text}
              alignItems='center'
            >
              <Grid item alignContent='center'>
                {!hideStatus && <StatusChip />}
                {!hideName && (
                  <Box
                    pt={hideStatus ? '1rem' : '0rem'}
                    pb='1rem'
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  >
                    <Tooltip
                      title={document.fileName.replace(/\.[^/.]+$/, '')}
                      placement='bottom'
                      open={hover}
                    >
                      <div>
                        <Caption className={filenameText}>
                          {document.fileName.replace(/\.[^/.]+$/, '')}
                        </Caption>
                      </div>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
              <Grid
                item
                container
                direction='column'
                justifyContent='flex-end'
                alignItems='center'
              >
                <Grid item xs={3}>
                  <Box pl='0.5rem' pb='0.5rem'>
                    <CardFileIcon />
                  </Box>
                </Grid>
                <Grid item>
                  <Caption>{dateTime(document.uploadedAt?.toString())}</Caption>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction='column'>
              <Box pt='0.5rem' pb='1rem'>
                <Divider variant='fullWidth' />
              </Box>
              <Grid container item justify='space-between'>
                {isAwaiting && canApprove && (
                  <Grid container xs={6} justify='center'>
                    <Button
                      data-testId='approve-document'
                      variant='contained'
                      size='medium'
                      onClick={() => setAcceptPlanDialogOpen(true)}
                    >
                      <Box px='0.6rem'>Accept</Box>
                    </Button>
                  </Grid>
                )}
                <Grid container xs={canApprove ? 6 : 12} justify='center'>
                  <Button
                    variant={'outlined'}
                    size='medium'
                    color='primary'
                    onClick={() => downloadDocument(document)}
                  >
                    Download
                  </Button>
                </Grid>
                <AcceptPlanModal
                  download={() => downloadDocument(document)}
                  documentId={document.id}
                  isOpen={acceptPlanDialogOpen}
                  campaignId={document.campaignId.id}
                  isManaged={!!isManaged}
                  documentType={document.fileType}
                  onClose={() => {
                    setAcceptPlanDialogOpen(false);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
