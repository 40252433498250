import React from 'react';
import { Tooltip } from '@adludio/components';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { TooltipProps } from '@material-ui/core/Tooltip';

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: '#4E55C3',
  },
  tooltip: {
    backgroundColor: '#4E55C3',
    fontSize: '0.8rem',
  },
}));

const BootstrapTooltip = (props: TooltipProps) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};
export default BootstrapTooltip;
