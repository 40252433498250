import {
  Body1,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Body2,
  makeStyles,
} from '@adludio/components';
/* eslint react/prop-types: 0 */
import React, { forwardRef, useImperativeHandle } from 'react';

import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import CloseIcon from '@material-ui/icons/Close';
import InviteTeam from '../MyTeam/index';
import { MemberCard } from './MemberCard';
import PeopleIcon from '@material-ui/icons/People';
import BackIcon from '@material-ui/icons/ArrowBack';
import { User } from '../../generated/graphql';
import { useInviteToTeamMutation } from '../../generated/graphql';
import { useState } from 'react';

interface MyTeamProps {
  team: User[];
  campaignId: string;
  campaignName: string;
  campaignStatus: string;
  env: 'development' | 'staging' | 'production' | 'testing';
}

export type InviteStep = 'EmailSent' | 'NoCompanyEmailWarning' | 'Form';

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    position: 'absolute',
    right: '9%',
    top: 150,
    zIndex: 1500,
    width: '22rem',
    height: '16rem',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  elements: {
    width: '20rem',
  },
  closeIcon: {
    fontSize: '1.2rem',
    verticalAlign: 'bottom',
  },
  linkText: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconAlign: {
    display: 'flex',
    alignContent: 'center',
  },
  membersContainer: {
    width: '100%',
    maxHeight: '168px',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#e6e6e5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c5c4c4',
    },
  },
}));
export type InviteTeamWrapperHandle = {
  setModalOpen: (val: boolean) => void;
  setTeamOpen: (val: boolean) => void;
};
// eslint-disable-next-line react/display-name
export const InviteTeamWrapper = forwardRef<
  InviteTeamWrapperHandle,
  MyTeamProps
>(({ team, campaignId, campaignName, env, campaignStatus }, ref) => {
  const {
    root,
    title,
    elements,
    closeIcon,
    linkText,
    membersContainer,
    iconAlign,
  } = styles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamVisible, setTeamVisible] = useState(false);
  const [inviteState, setInviteState] = React.useState<InviteStep>('Form');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const toggleTeam = () => {
    setTeamVisible(!teamVisible);
  };
  const [invite] = useInviteToTeamMutation();
  useImperativeHandle(ref, () => ({
    setModalOpen: setIsModalOpen,
    setTeamOpen: setTeamVisible,
  }));
  return (
    <Grid container xs={12} justify='flex-end'>
      <Box pr='8.5%'>
        <Button
          data-testId={'invite-btn'}
          variant='contained'
          color='secondary'
          onClick={toggleModal}
        >
          Invite
        </Button>
      </Box>
      {isModalOpen ? (
        <Grid item xs className={root} direction='column'>
          <Card>
            <Grid container item xs direction='row'>
              <Grid xs={10} item>
                <Box p='0.5rem'>
                  {inviteState === 'NoCompanyEmailWarning' ? (
                    <IconButton onClick={() => setInviteState('Form')} label=''>
                      <BackIcon />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box p='0.5rem'>
                  <IconButton onClick={toggleModal} label=''>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Box pb='1rem'>
              <Grid
                container
                item
                xs={12}
                direction='column'
                justify='center'
                alignItems='center'
                spacing={2}
              >
                <Grid item xs={12} className={title}>
                  <Body1>Invite members to the campaign!</Body1>
                </Grid>
                <Box pb='1rem'>
                  <Grid item xs className={elements}>
                    <Divider />
                  </Grid>
                </Box>
                <Grid container item xs justify='flex-end'>
                  <Link className={linkText} onClick={toggleTeam}>
                    <Body2 aria-expanded={teamVisible}>
                      {teamVisible ? 'Close Team View' : 'View Campaign Team'}
                    </Body2>
                    &nbsp;
                    <Body2 className={iconAlign}>
                      {teamVisible ? (
                        <CloseIcon />
                      ) : (
                        <PeopleIcon className={closeIcon} />
                      )}
                    </Body2>
                  </Link>
                </Grid>
                {teamVisible && (
                  <Box className={membersContainer} pl='0.5rem' pt='1rem'>
                    <Grid container item xs justify='flex-start'>
                      {team.map((user: User, i: number) => (
                        <MemberCard
                          key={user.name}
                          userName={user.name!}
                          userEmail={user.email!}
                        />
                      ))}
                    </Grid>
                  </Box>
                )}
                <Grid container item xs justify='center' alignItems='center'>
                  <Box py='1rem' ml='0.5rem' width='100%'>
                    <InviteTeam
                      env={env}
                      invite={invite}
                      loading
                      campaignId={campaignId}
                      campaignName={campaignName}
                      campaignStatus={campaignStatus}
                      inviteState={inviteState}
                      setInviteState={setInviteState}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      ) : null}
    </Grid>
  );
});
