import React from 'react';
import { Grid, Box } from '@adludio/components';
import CompanyProfile from './CompanyProfile/index';

export default function CompanyProfilePage() {
  return (
    <Grid container direction='row' spacing={4}>
      <Grid container item direction='column' xs={12}>
        <Grid item>
          <Box pb='5%'>
            <CompanyProfile />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
