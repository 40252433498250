import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatAxis } from '../../utils/ChartAxis';
import { formatDateString } from '../../utils/date';
import getKpiName from '../../utils/setKpiCols';
import GetAppIcon from '@material-ui/icons/GetApp';
import getIcons from './chartIcons';
import { responsiveStyle } from '../../utils/Styles';
export interface DataProps {
  campaignName: string;
  breakdown: string;
  data: { name: string; data: number[] }[];
  categories: string[];
}
const SummaryBarPlot = ({
  campaignName,
  breakdown,
  data,
  categories,
}: DataProps) => {
  const downloadFilename = `${campaignName}_${formatDateString(new Date())}_${breakdown}_total`;
  const classes = responsiveStyle();
  const options: ApexOptions = {
    series: data,
    chart: {
      type: 'bar',
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: false,
          zoomout: false,
          pan: false,
          zoom: false,
          reset: false,
        },
        export: {
          png: {
            filename: downloadFilename,
          },
          svg: {
            filename: downloadFilename,
          },
          csv: {
            filename: downloadFilename,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#4E55C3', '#7DC3AA', '#F7C1A1', '#58A0DA', '#495373', '#CDC7E5'],
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    markers: {
      size: 4,
      colors: ['#58A0DA']
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: 'Rate(%)',
      },
      labels: {
        formatter: (val: number): string => formatAxis('ER', val),
      },
    },
    xaxis: {
      categories: categories,
      type: 'category',
      labels: {
        show: true,
        hideOverlappingLabels: true,
        formatter: (value: string): string => getKpiName(value)
      },
    },
    title: {
      text: `${breakdown} Overall Performance`,
      offsetX: 14,
      align: 'left'
    },
  };

  return (
    <div className={classes.chartsPadding}>
      <ReactApexChart
        options={options}
        series={options.series}
        type='bar'
        height={260}
      />
    </div>
  );
};

export default SummaryBarPlot;

