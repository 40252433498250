/* eslint-disable complexity */
import {
  AdludioTheme,
  Body2,
  Button,
  Card,
  Grid,
  H5,
  Link,
  TextInput,
  makeStyles,
  useSnackbar
} from '@adludio/components';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';
import { FormControl, FormHelperText } from '@material-ui/core';

import { AdludioError } from '.';
import React from 'react';
import { encodePayload } from '../../../util/payloadActions';
import { initialConfig } from '../../../index';
import { updateUser } from '../../../API';

type UpdatePassword = {
  currentPassword: string;
  newPassword: string;
  invalidPassword: boolean;
  incorrectPassword: boolean;
  resetState: () => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleErrors: (error: AdludioError) => void;
}

const useStyles = makeStyles<AdludioTheme>((theme) => ({
  container: {
    padding: '2rem 1.5rem',
    minHeight: '27.5rem'
  },
  editField: {
    maxWidth: '27rem',
    width: '100%'
  },
  helper: {
    display: 'inline',
    listStyleType: 'none',
    paddingInlineStart: '0px',
    '& li': {
      display: 'inline'
    }
  },
  helperError: {
    paddingInlineStart: '15px',
    color: theme.palette.error.main
  }

}));

const UpdatePassword = ({
  currentPassword,
  newPassword,
  invalidPassword,
  incorrectPassword,
  resetState,
  handlePasswordChange,
  handleErrors
}: UpdatePassword) => {
  const { auth: { user, authToken } } = React.useContext(AuthContext) as SuccessAuthContextProps;
  const { container, editField, helper, helperError } = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const checkPasswordLength = React.useMemo(() => (
    [currentPassword, newPassword].some(pw => pw.length < 1)),
  [currentPassword, newPassword]);

  function redirectToSSOFE() {
    const payload = encodePayload({ url: window.location.href, payload: {} });
    return window.location.replace(`${initialConfig.ssoFEURL}/forgot-password?payload=${payload}`);
  }

  function handleResetPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    if (invalidPassword){
      enqueueSnackbar('Invalid new password', { variant: 'error' });
      return;
    }
    updateUser(
      authToken,
      user.id,
      { currentPassword, password: newPassword }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.error) handleErrors(data.error);
        else {
          resetState();
          enqueueSnackbar('Change saved', { variant: 'success' });
        }
      })
      .catch(()=>{
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      });
  }

  const hasCapitalLetter = (pass:string)=>{
    return new RegExp( /[A-Z]+/m).test(pass);
  };
  const hasSpecialCharacter = (pass:string)=>{
    return new RegExp( /(?:[@$!%*#?&]+)/).test(pass);
  };
  const hasNumber = (pass:string)=>{
    return new RegExp(/\d/).test(pass);
  };

  return (
    <Card>
      <Grid
        container
        direction='column'
        justify='space-between'
        className={container}
        spacing={2}
      >
        <Grid item xs>
          <H5>Change Password</H5>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextInput
            error={incorrectPassword}
            helperText={incorrectPassword ? 'Wrong password' : ' '}
            onChange={handlePasswordChange}
            variant='outlined'
            required
            password
            className={editField}
            name='currentPassword'
            value={currentPassword}
            label='Current password'
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <FormControl
            className={editField}
          >
            <TextInput
              error={invalidPassword}
              onChange={handlePasswordChange}
              variant='outlined'
              password
              required
              name='newPassword'
              value={newPassword}
              label='New password'
            />
            <FormHelperText component='div'>
              <ul className={!invalidPassword ? helper : helperError} >
                {newPassword.length < 8 && (<li>Password should be at least 8 characters in length.&nbsp;</li>)}
                { !hasCapitalLetter(newPassword) && (<li>Must contain at least 1 uppercase characters.&nbsp;</li>)}
                { !hasSpecialCharacter(newPassword) && (<li>Must contain at least 1 special character (@$!%*#?&).&nbsp;</li>)}
                { !hasNumber(newPassword) && (<li>Must contain  at least 1 number.&nbsp;</li>)}
              </ul>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <Button onClick={handleResetPassword} size='large' variant='contained' disabled={checkPasswordLength}>
            Update
          </Button>
        </Grid>
        <Grid item>
          <Body2>Can&apos;t remember your current password?</Body2>
          <Link onClick={redirectToSSOFE}>
            <Body2>Reset your password by email</Body2>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export default UpdatePassword;
