import { ReportOverview } from '../types/Report';

export default function validate(summary:ReportOverview) {
  let validCols = ['date', 'curatorRevenue', 'impression'];
  if (summary.engagement > 1){
    validCols.push('engagement');
    validCols.push('er');
  }
  if (summary.click > 1){
    validCols.push('click');
    validCols.push('ctr');
    if (summary.engagement > 1){
      validCols.push('ectr');
    }
  }
  if (summary.videoEnd > 1){
    validCols.push('videoEnd');
    validCols.push('vtr');
  }
  if (summary.viewedImps > 1){
    validCols.push('viewedImps');
    // validCols.push('viewability');
  }

  return validCols;
}
