/* eslint-disable complexity */
import React from 'react';

import {
  AdludioTheme,
  Body1,
  Box,
  Grid,
  H2,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@adludio/components';

import { RouteComponentProps } from '@reach/router';
import backgroundSplodges from '../../shared/img/Arrow.png';
import Login from '../../Components/Login';
import { AdludioLogo } from '../../Components/AdludioLogo';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    overflowX: 'clip',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      width: '100%',
      minHeight: 'calc(100vh - 3rem)',
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: 'calc(100vh + 5em)',
    },
  },
  header1: {
    color: '#C2FCB4',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  header2: {
    color: '#76FA5A',
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  body: {
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  svg: {
    position: 'absolute',
    bottom: -1,
    left: -50,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const Landing = (_: RouteComponentProps) => {
  const { container, header1, header2, body, svg } = useStyles();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // This is here to get around the max complexity rule.
  const textAlignment = isDesktop ? 'left' : 'center';

  return (
    <Box pt={isDesktop ? '3rem' : '2rem'} className={container}>
      <Grid container xs={12} justify={isDesktop ? 'center' : 'center'}>
        <Grid xs={11}>
          <Box pb='2rem'>
            <AdludioLogo isLogin />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction={isDesktop ? 'row' : 'column-reverse'}
        justify='center'
        alignItems={isDesktop ? 'flex-start' : 'center'}
        spacing={2}
      >
        <Grid
          item
          container
          sm={8}
          md={7}
          lg={7}
          xl={6}
          spacing={2}
          justify={'flex-start'}
        >
          <Grid container xs={12} justify={isDesktop ? 'flex-start' : 'center'}>
            <Grid container md={10} lg={10} xl={10}>
              <Box pb={'2.5rem'} pt='0.5rem'>
                <H2 align={textAlignment} className={header1}>
                  The Premium Platform
                </H2>
                <H2 align={textAlignment} className={header2}>
                  To Win Attention
                </H2>
              </Box>
            </Grid>
            <Grid container xs={8}>
              <Body1 align={textAlignment} className={body}>
                All the mobile ad products you need, powered by AI, in one easy
                platform.
              </Body1>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={10}
          md={4}
          justify={isDesktop ? 'flex-end' : 'center'}
        >
          <Login />
        </Grid>
        <Grid item xs className={svg}>
          <img width='70%' src={backgroundSplodges} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
