import { Map, RecordOf, List } from 'immutable';

import makeScriptWrapper from './makeScriptWrapper';
import makeDataForDSP from './makeDataForDSP';

import * as s from './setters';
import { BioTagState, DisplayTagState, TagType } from './store/tagReducer';

export type DataMapType = Map<
  string,
  number | string | string[] | List<string>
>;
export type TagState = BioTagState | DisplayTagState | TagType;

export type TagRecord<T = TagState> = RecordOf<EmptyAble<T>>;
export type EmptyAble<T> = { [P in keyof T]: T[P] | '' };

export default function generateScript(tag: TagRecord): string {
  const scriptWrap = makeScriptWrapper(tag.environment);

  let data = makeDataForDSP(tag.dsp);
  if (!data) {
    return scriptWrap({}, List(['']), List(['']), '', List(['']));
  }
  data = s.setUnitTypeData(tag, data);
  data = s.setDimensions(tag, data);
  data = s.setRegion(tag, data);
  data = s.setThirdPartyEngagement(tag, data);
  data = s.setEnvironment(tag, data);
  data = s.setService(tag, data);
  if (tag.get('adludio_brief_ref'))
    data = data.set('adludio_brief_ref', tag.get('adludio_brief_ref'));

  return scriptWrap(
    data.toJS(),
    tag.impressionLevelJsUrls,
    tag.impressionLevelImagePixelUrls,
    tag.dsp,
    tag.thirdParty as List<string>
  );
}
