import React from 'react';
import { Grid, makeStyles, H5, H3, Button, Box, Caption, Body2, Link } from '@adludio/components';
import { navigate } from '@reach/router';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: 'auto',
  },
}));

const PrivacyPolicy = (_: { path?: string }) => {
  const { container } = useStyles();

  return (
    <Grid container item xs direction='column'>
      <Box p='2rem' pl='5rem'>
        <Grid container item xs={8} direction='column'>
          <H3>Privacy Policy</H3>
          <Box py='1rem'>
            <Caption>Privacy Policy update on 3rd September 2020</Caption>
          </Box>
          <Body2>
            Adludio Limited takes seriously the responsibility for respecting and caring for your
            privacy. This Privacy Policy lays out our protocols on data collection, protection and
            use. It will help you make informed decisions about your relationship with Adludio
            please read it carefully
          </Body2>
          <br />
          <H5>
            Introduction
          </H5>
          <br />
          <Body2>
            Adludio Limited (&#39;Adludio&#39;, &#39;we&#39;, &#39;us&#39; or &#39;our&#39;) provides a creative solution for
            digital branded content (including video and non-video), facilitating the development,
            serving and distribution of digital ads on mobile environments, and other digital
            devices and connected environments including computers.
            Adludio offers a creative as a service via our creative platform Adludio Direct
            (&#39;Platform&#39; or &#39;Adludio Direct&#39;), and a full stack advertising solution which includes the
            development, serving and distribution of advertising content online.
            The information we collect depends on what products or services you use and how you
            use them. This policy applies to:<br />
          </Body2>
          <Box p='1rem'>
            <Body2>
              ● &#39;Visitors&#39;: Visitors of the Adludio website, all associated sites by Adludio, its
              subsidiaries, and affiliates, including Adludio sites around the world, and our
              creative platform, Adludio Direct (collectively, the &#39;Site(s)&#39;), who view only
              publicly available content.<br />
              ● &#39;Platform Users&#39;: Users of our creative platform Adludio Direct, creative as a
              service, provided through our Sites.<br />
              ● &#39;End Users&#39;: End users of our digital advertisements created through and/or
              trafficked by Adludio products or services.<br />
            </Body2>
          </Box>
          <Body2>
            In using or accessing our Site or Adludio services (as defined in this Policy), you agree
            to our privacy policies outlined herein. Adludio will have the right to use your
            information as described in this Privacy Policy. If you do not agree to have your
            information used in any of the ways described in this Privacy Policy, you must
            discontinue use of the Adludio Site and any services provided by Adludio.<br />
            If you have any questions about these Terms of Use, you can contact us at
            <Link> privacy@adludio.com</Link>.<br />
            <br />
            This Privacy Policy will describe the data and information that Adludio may collect,
            process, use, disclose or retain, about you or your device (computer or mobile,
            including tablet and mobile phone) when you visit our Site, use our Services or when
            you encounter Distributed Content served by Adludio at any Participant Site.
            Participant Sites are operated and controlled by third parties, and the collection,
            processing, use, disclosure or retention of information by Participant Sites or other third
            parties working with such sites are governed by their privacy policies - Adludio&#39;
            Privacy Policy does not apply to such Participant Sites or third parties.<br />
            <br />
            <br />
            <H5>Summary</H5>
            <br />
            This Privacy policy covers the following key subjects:<br />
            <Box p='1rem'>
              <Body2>
                ● It explains how we use your information, as a Visitor, Platform User and End User.<br />
                ● It aims to explain clearly what information we collect with your permission, what
                we do with it and, and what we do not do with that information.<br />
                ● It holds us accountable for protecting your rights and privacy under this policy.
              </Body2>
            </Box>
            It also lays out in detail the ways in which you are consenting your information to be
            collected, processed and used including:<br />
            <Box p='1rem'>
            ● The use of cookies and other tracking technologies<br />
            ● The transfer of your information outside your country of residence.<br />
            ● The transfer of your non-personal information between trusted partners and
            third-party entities as described in this policy, in order to fulfil a service as
            agreed by you, Adludio and/or such entities.<br />
            ● The collection, processing, use, disclosure and retention of your information by
            all entities as described in this policy, including Adludio as Data Controller and
            Data Processor (as applicable).
            </Box>
            <br />
            <H5>Glossary of terms</H5><br />
            For purposes of this policy:
            <Box p='1rem'>
              <Body2>
                ● &#39;Personal Information&#39; for non-residents of the European Economic Area means
                any information that can be used to identify, contact, or precisely locate a
                person, including name, email, address, and telephone number.<br />
                ● &#39;Personal Information&#39; for residents of the European Economic Area means any
                information relating to an identified or identifiable natural person; an identifiable
                natural person is one who can be identified, directly or indirectly, in particular by
                reference to an identifier such as a name, an identification number, location
                data, an online identifier or to one or more factors specific to the physical,
                physiological, genetic, mental, economic, cultural, or social identity of that
                natural person. This includes IP address and advertising ID (IDFA/AAID).<br />
                ● &#39;Non-Personal Information&#39; means information not included in the definition of
                Personal Information, such as pages or ads visited, time spent on pages,
                browser and device type, and referring and exit pages. For non-residents of the
                European Economic Area, IP address and advertising IDs (IDFA/AAID) are
                Non-Personal Information.
              </Body2>
            </Box>
            A list of additional terms for reference:<br />
            <Box p='1rem'>
              <Body2>
                ● &#39;Information&#39; or &#39;Data&#39;: Used interchangeably, &#39;Information&#39; or &#39;Data&#39; refers
                to personal and nonpersonal data that is collected, processed, used, distributed
                or retained from Visitors, Platform Users and/or End Users, by Adludio, in order
                to fulfill the terms of use of our Site or to fulfill our service agreements, as
                described in this Privacy Policy.<br />
                ● &#39;Ad(s)&#39; -- a digital advertisement or &#39;adunit&#39;<br />
                ● &#39;Sensory ads&#39; -- Adludio&#39;s user-engagement ad product, Sensory Ads are
                served on the premise that a user must voluntarily and actively participate with
                an ad experience by fullfiling a digital &#39;gesture-based&#39; (using the computer
                cursor, mobile touch-screen or mobile gyroscope type command) interaction.
                This means that a user must view and opt-in by engaging in order to benefit
                from the ad experience, as opposed to the traditional industry model of
                view-only.<br />
                ● &#39;RTB&#39; -- Real-time bidding refers to the buying and selling of online ad
                impressions through real time auctions that occur in the time it takes a webpage
                to load.<br />
                ● &#39;Bidder&#39; -- Adludio is using Beeswax to buy inventory required for serving
                adunits. Beeswax is New York based company that offers the first
                &#39;Bidder-as-a-Service&#39; solution for programmatic RTB media buying.<br />
                ● &#39;HTTPS&#39; -- (HTTP Secure) is an adaptation of the Hypertext Transfer Protocol
                (HTTP) for secure communication over a computer network, and is widely used
                on the Internet.<br />
                ● &#39;Cookie&#39; -- An HTTP cookie is a small piece of data sent from a website and
                stored on the user&#39;s computer by the user&#39;s web browser while the user is
                browsing. &#39;Beacons, Pixels&#39; -- A Web beacon is an often-transparent graphic
                image, usually no larger than 1 pixel x 1 pixel, that is placed on a Web site or in
                an email that is used to monitor the behavior of the user visiting the Web site or
                sending the email. It is often used in combination with cookies.<br />
                ● &#39;AWS&#39; -- Amazon Web Services (AWS) is a subsidiary of Amazon.com that
                provides on-demand cloud computing platforms to individuals, companies and
                governments. &#39;Amazon VPC&#39; -- Amazon VPC lets you provision a logically
                isolated section of the Amazon Web Services (AWS) cloud where you can launch
                AWS resources in a virtual network defined by the client (Adludio).<br />
                ● &#39;Amazon S3&#39; -- Amazon Simple Storage Service is a web service offered by
                Amazon Web Services (AWS). Amazon S3 is extremely durable, highly available
                and secure object storage built to store and retrieve any amount of data on the
                Internet.<br />
                ● &#39;AWS Kinesis&#39; -- Amazon Kinesis Data Streams enables you to build custom
                applications that process or analyze streaming data for specialized needs.<br />
              </Body2>
            </Box>
            <br />
            1. What information we collect, how we do it and how we use it<br />
            In general, there are three categories of information we collect:<br />
            <Box p='1rem'>
              <Body2>
                ● Information that you opt to share so we can use it to provide additional features
                and improve your experience.<br />
                ● Information that we must have for you to use the Adludio Sites and Services.<br />
                ● Information collected from your interaction with Adludio Products or Services as
                an End User of an ad.<br />
              </Body2>
            </Box>
            <br />
            The kind of information we collect, and how we do it depends on which kind of user
            category you fall into.<br />
            <br />
            Contact information. When you request information about Adludio, via our Site&#39;s
            contact forms or through other means, register to use our Platform, or sign-up to our
            Platform or other services, you will be asked to provide certain Personal Information
            which may include your name, email, mailing address, phone number, job title, name of
            your organization.<br />
            <br />
            When you voluntarily share Personal Information with us, this information is used by
            Adludio in a manner consistent with the permissions communicated to us. We may use
            this information to:<br />
            <Box p='1rem'>
              <Body2>
            ● Respond to you regarding your specific query or request.<br />
            ● Communicate with you for marketing, research and promotional purposes.<br />
            ● Inform you of any training sessions or events.<br />
            ● Conduct communication relating to the service or project you signed-up for.<br />
            ● Inform you of product and service updates.<br />
              </Body2>
            </Box>
            You always have the option to opt-out of such communication by unsubscribing,
            changing your user/profile settings or emailing us. You can also request that we delete
            your information at any time by contacting us directly at <Link>info@adludio.com</Link> or
            <Link> privacy@adludio.com</Link>.<br />
            <br />
            We collect information from our Visitors via cookies or similar technologies, as laid out
            in our Cookies Policy.<br />
            <br />
            In order to ensure the best user experience on our Sites, we may collect certain Visitor
            and browsing information from visitors to our Sites (&#39;Visitor Analytics&#39;). This may
            include IP address, browser and device type and language, referring and exit pages
            and URLs, date and time, amount of time spend on pages, what sections of the Site
            Visitors visit, and other, similar aggregate user data concerning your use of the Sites.<br />
            <br />
            We may use this data to manage and improve our Site. In some areas of the world,
            certain Visitor Analytics may be Personal Information. Where this is the case, we
            comply with applicable laws regarding our use and processing of Personal Information.
            When you register or sign-up for our Platform or services, we may request you to
            provide payment or invoicing information that may include invoicing contact (name,
            email, mailing address, phone number, etc.), credit card details (number, expiration
            date, security code), billing address, unique invoice identifier number, activation code,
            and similar information (&#39;Billing Information&#39;).<br />
            Adludio uses this information for the purpose of invoicing and processing payments for
            services provided. We may use reliable third-party service providers to manage credit
            card processing, they are not permitted to store, retain, or use Billing Information
            except for the sole purpose of credit card processing. We may also use reliable
            third-party service providers to issue, manage and execute billing and payment events,
            they are not permitted to store, retain, or use Billing Information except for the sole
            purpose of executing the billing and payment service, and are required to comply with
            applicable laws regarding our use and processing of Personal Information.<br />
            <br />
            When you register or sign-up to our Platform, we request you to define a Platform User
            Profile that may include a username, contact email and user photo/avatar. This
            information will be visible to members of your project team to enable communication
            and execution of the project.<br />
            <br />
            You can edit and manage this information on your Platform account settings or by
            contacting us directly on <Link>info@adludio.com</Link> or <Link> directinfo@adludio.com</Link>.<br />
            <br />
            Adludio collects, processes, uses, discloses or retains information about End Users in
            the context of their exposure or interaction with an ad. We may also collect information
            on behalf of our customers which may be an brand/advertiser, publisher, advertising
            agency and/or their agents, in the context of fulfilling our service to them.<br />
            <br />
            Ad Serving Data includes standard digital advertising metrics as defined by industry
            bodies such as the Internet Advertising Bureau (IAB), including number of ads viewed,
            time spent viewing an ad, page views, video views, number of ads clicked, IP address,
            device ID, GPS coordinates, advertising identifiers (IDFA/AAID). It may also include
            Personal Information that you actively choose to share with us through text field forms,
            and other information about your interaction with the ads.<br />
            <br />
            End User information may be used for lawful advertising purposes to provide you with
            a more tailored online advertising experience. It may also be used by Adludio to
            improve the functionality of our services.<br />
            <br />
            In this context, Adludio may collect, process, uses, disclose or retain information about
            End Users in the following manners:<br />
            <br />
            Adludio serves as a &#39;Data Processor&#39; in the context of user targeting and media
            buying in a programmatic environment, whereas the Bidder makes available
            information that is covered by consent passed on by the Bidder or Bidder suppliers.<br />
            <br />
            Adludio may use Ad Serving Data to optimize the performance of the ad campaigns
            we are bidding for using a Real-Time Bidding Platform. For every relevant auction we
            receive a bid request. This request is sent over HTTPS and is processed by our servers
            inside protected AWS VPC. After we reply to the bid request, we delete the request
            data.<br />
            <br />
            When we buy inventory using a Bidder, we receive logs with details about auctions we
            won. This data is sent to us using secure channels using tools like AWS S3 and AWS
            Kinesis. This data contains End User IP addresses, locations, browser details, websites
            name, app name, and all bid-related details, and we may use it for campaign
            optimization and reporting purposes. You can read more about the Bidder Data we
            receive at https://docs.beeswax.com/docs/data-feeds.<br />
            <br />
            In addition to standard digital advertising metrics, Adludio also tracks &#39;user
            engagements&#39; that form the core offering of Sensory Ads.<br />
            <br />
            Every time an ad is loaded in the user browser or app environment, Adludio tracks a
            set of events triggered by the engagement (including engagement gesture, clicks,
            skips, etc.). We send this data with actinoid back to our servers over the HTTPS
            channel.<br />
            <br />
            Adludio may implement third-party performance tracking mechanism, such as
            click-through links and tracking pixels, per the request of our clients who receive and
            may share with us daily aggregated reports, used to monitor the performance of the
            campaigns.<br />
            <br />
            These mechanisms may store user IP address, store a cookie in the user browser and
            get information about the browser.<br />
            <br />
            Adludio doesn&#39;t stream any cookies in End User browsers in the context of Ad serving.
            However, the Bidder (used to buy ad inventory) and its supplies may store cookies in
            the End User browser to profile inventory, to fulfill targeting requirements. For this use,
            Adludio does not target individual (identifiable) users, we only focus on the
            environmental variables like type of device, domain name and geographical location of
            the user.<br />
            <br />
            Third party tools may also store cookies in the End User browser to help measure and
            monitor the ad campaign performance accurately. These cookies are governed by the
            third party and/or domain (publisher) terms.<br />
            <br />
            In order to fulfill our service proposition, Adludio aggregates raw Bidder Data and
            Adludio Engagement Data for monitoring, optimization and reporting purposes.
            Aggregation is usually executed in the form of daily sums and/or campaign lifecycle
            totals.<br />
            <br />
            Aggregated reports are used to track performance and delivery of campaigns and are
            shared with our clients (the advertiser) as part of our contractual obligations.<br />
            <br />
            Adludio&#39;s customers and/or partners (collectively &#39;Third Parties&#39;) may also use cookies
            to customize the End User online advertising experience, based on the preferences or
            interests known or inferred from the information collected.<br />
            <br />
            Our customers (the advertiser) may use cookies to serve appropriate ads to you and to
            help track results or measure effectiveness of those ads. Third Parties may also
            combine information collected in the context of delivering an ad to you with other
            information they have independently collected over time across different online
            environments, including syncing or connecting behavior across different websites,
            mobile apps or devices. These Third Parties collect and use information under their
            own privacy policies.<br />
            <br />
            <br />
            <H5>Storage of Ad Serving Data</H5>
            <br />
            Ad Serving Data is stored in AWS data centers in AWS North Virginia (USA) and the
            Republic of Ireland. There are two types of Ad Serving Data:<br />
            <Box p='1rem'>
              <Body2>
                ● Raw Data -- Information we collect via the Bidder (containing IP address,
                locations and details about the user browser) and tracking logs we store in
                encrypted S3 buckets with appropriate access control policies. We keep Raw
                Data for 90 days. This allows us to check and ensure that the relevant and
                subsequent aggregations are correct.<br />
                ● Aggregated Data -- Aggregated (non-identifiable) information is stored in
                secured and encrypted databases in the AWS data centers. At this stage we no
                longer store IP addresses, device IDs and location we have received from the
                Bidder. We may store aggregated data indefinitely or as long as normal business
                operations will require. If you have any concerns about or would like to opt-out
                from Ad Serving Data, you can contact Adludio at info@adludio.com for further
                information or contact the Bidder directly at <Link>privacy@beeswax.com</Link> per the
                Bidder Privacy Policy guidelines.<br />
                ● Opt-out: Adludio adheres to the DAA&#39;s Self-Regulatory Principles for Online
                Behavioral Advertising. To learn more about interest based or online behavioral
                advertising, and other advertising companies&#39; privacy practices and opt-out from
                each or any of them (including Adludio), you may also go directly to the DAA
                website at http://www.aboutads.info/choices/.<br />
              </Body2>
            </Box><br />
            <H5>Sharing and disclosure of Personal Information</H5><br />
            <Box p='1rem'>
              <Body2>
                ● Adludio will only share Personal Information as agreed by the User at the time of
                collection, or in the following events.<br />
                ● We may share Ad Serving Data per the terms described in the above section.<br />
                ● We may share your information with reliable Third-Party Service Providers that
                support us in fulfilling our service obligations.<br />
                ● We may also share information with third parties that help us operate and
                market our services, per the situations described in section 1. We ensure that
                such third parties are contractually obligated to protect your Personal
                Information, as required by law.<br />
                ● Compliance with the Law. Adludio may disclose your information to courts, law
                enforcement, or government authorities, or authorized third parties, if and to the
                extent we are required to do so by law or in a good-faith belief that such action
                is necessary or advisable in order to comply with any laws of any country or
                region which may assert jurisdiction) or in order to respond to a judicial or other
                governmental action, order, subpoena, request, demand or warrant. We may
                make such disclosures without providing notice to you.<br />
                ● Against Liability or Fraud. We reserve the right to disclose one or more clients&#39;
                and/or individual users&#39; information if it is appropriate or necessary in Adludio&#39;s
                discretion in order to: take precautions against liability; protect against
                fraudulent, abusive, or unlawful uses; investigate or defend against any
                third-party actions or allegations (including assisting a client or third party to do
                so); assist government enforcement agencies; or protect the security, integrity,
                rights, property, or personal safety of Adludio, its systems and services, as well
                as its employees, clients or others.<br />
                ● Business Transfers. If Adludio undertakes or is involved in any merger,
                acquisition, reorganization, sale or assets, bankruptcy, or insolvency event, then
                we may sell, transfer, or share some or all our assets, including your Personal
                Information. In this event, we will notify you before your Personal Information is
                transferred and becomes subject to a different privacy policy.<br />
                ● Adludio may also disclose your information under circumstances not mentioned
                above, with prior consent of the entity or third-party that submitted the
                information to us.<br />
              </Body2>
            </Box><br />
            <br />
            <H5>Transfer of information across regional/national borders</H5>
            <br />
            Adludio&#39;s servers are located in the US and in the Republic of Ireland. Any information
            collected and provided to us, including Personal Information, will be transferred to and
            processed through our servers in the US and/or the Republic of Ireland.
            By accepting our Terms of Use, you are consenting to the transfer of data to and
            processing of data, from your country of residence to the location of our servers. If you
            do not consent to this, do not submit Personal Information to Adludio, or contact
            Adludio to correct or delete your Personal Information via <Link> info@adludio.com</Link> or
            <Link> privacy@adludio.com</Link>.<br />
            <br />
            EU General Data Protection Regulation (GDPR)
            <br />
            <br />
            Adludio is committed compliance with GDPR which went into effect May 2018. If you
            are a resident of the European Union and are subject to jurisdiction under The
            European Parliament, you have the following rights:<br />
            <br />
            <Box p='1rem'>
              <Body2>
                ● The right to request access to, or ask us to provide you with copies of, Personal
                Data that we hold about you at any time, which may be subject to a fee
                specified by law for processing your request (but not for making a request for
                access). If Adludio is the Data Controller with respect to your data, we will
                respond to your request within a reasonable period, and if we are the Data
                Processor with respect to your data, we may redirect your request to our
                customer acting as the Data Controller with respect to your data. We may
                decline a request for access to Personal Data in circumstances prescribed by
                relevant privacy laws. If we decline a request, we will provide you with a written
                notice that sets out the reasons for the refusal (unless it would be unreasonable
                to provide those reasons) and provide you with a statement regarding the
                mechanisms available to you to make a complaint.<br />
                ● The right to ask us to delete any Personal Data that we hold about you free of
                charge, unless we are required to maintain a copy of this data pursuant to
                applicable law.<br />
                ● The right to ask us to update and correct any out-of-date or incorrect or
                inaccurate Personal Data that we hold about you free of charge. We will take
                reasonable steps to correct the information so that it is accurate, complete and
                up to date. If we refuse to correct your Personal Data, we will provide you with a
                written notice that sets out the reasons for our refusal (unless it would be
                unreasonable to provide those reasons) and provide you with a statement
                regarding the mechanisms available to you to make a complaint<br />
                ● The right to opt out of any marketing communications that we (or any third-party
                to whom we have disclosed your Personal Data with your consent) may send you
                or to withdraw your prior consent or object to any further processing of your
                Personal Data.<br />
                ● The right to request information about any automated decisions made using
                your Personal Data that have legal consequences for you or which significantly
                affect you.<br />
                ● The right to lodge a complaint with your local Data Protection Authority
                concerning Adludio&#39;s processing of your Personal Data.<br />
              </Body2>
            </Box>
            <br />
            If you wish to exercise any of the above rights, send us an email at
            <Link> privacy@adludio.com</Link>. Before we process any request, we may ask you for certain
            Personal Data to verify your identity, and in situations where Adludio is the Data
            Processor, consult with the Data Controller of your data. Where permitted by local law,
            we may reject requests that are unreasonable or impractical. Please allow us a
            reasonable time to respond to your inquiries and requests.<br />
            <br />
            Users residing in the EU who are not satisfied with our response, or believe we are
            processing your personal data not in accordance with the law, data subjects covered
            under the GDPR framework have the right to lodge a complaint with a supervisory
            authority in the Member State of their habitual residence, place of work, or place of the
            alleged infringement.<br />
            <br />
            <br />
            <H5>Subject Access Request as a Service (SARaaS)</H5>
            <br />
            We are committed to providing the information you request as part of your Subject
            Access Request and have procedures in place to ensure this occurs in a timely fashion.<br />
            <br />
            We do not have agreements in place with any third-party platforms that offer “Subject
            Access Requests as a Service”. As a responsible data controller of your personal
            information this represents significant risks when sharing data. The right of access
            afforded to you, does not obligate data controllers to share data with 3rd parties. It is
            our policy to provide the information directly to data subjects ensuring the safety and
            security of the information throughout the process.<br />
            <br />
            We will monitor future guidance from the Information Commissioner’s Office but
            currently undertake our own reasonable measures to verify the identity of data
            subjects. We would like to assure you that the protection of your data is our main
            concern and we are committed to providing information as part of any valid request.<br />
            <br />
            <br />
            <H5>Sharing and Social Media Features</H5><br />
            Adludio works with social media tools, services and networks, such as LinkedIn,
            Facebook and Twitter. We provide users with both proprietary and branded tools to
            enable them to share an item of Distributed Content, solicit or refer other users to view
            Distributed Content, or express or broadcast their opinion about an item of Distributed
            Content.<br />
            In some cases, the use of these tools will require a user to provide
            personally-identifiable information. For example, a user may send a link for certain
            content to one or more recipients by email with a personal message.<br />
            <br />
            Adludio does not use the email addresses, except as necessary to deliver the
            communication as requested, and does not store the email addresses. If you use a tag,
            button or sharing feature that is provided by a branded third-party social media service
            or network, such as a &#39;Like&#39; button, you may be required to log-in to your account or
            otherwise take steps to access such third party services. Such collection use and
            disclosure of any information by or in relation to such third-party service is subject to its
            privacy policy, and this Privacy Policy does not apply.<br />
            <br />
            <br />
            <H5>Security of your Personal Data</H5><br />
            Adludio adheres by reasonable technical and organizational security protocols to
            safeguard Personal Data that is submitted to us via our Site or Services, according to
            the voluntary principles of information sharing outlined in this document.<br />
            <br />
            No method of transmission over the Internet, or method of electronic storage, is 100%
            secure. We cannot control the actions of third parties with whom you elect to share
            your Personal Data and we cannot assume responsibility or liability for disclosure of
            your information due to errors in transmission, unauthorized third-party access or other
            causes beyond Adludio&#39;s reasonable control. We cannot guarantee that only
            authorized persons will view your Personal Data. We cannot ensure that information
            you share on our Site and/or through our Services will not become publicly available.<br />
            <br />
            <br />
            <H5>Children&#39;s Privacy</H5><br />
            Adludio adheres to strict protocols to safeguard children&#39;s privacy. You must be at least
            13 years old to use the Site and/or our Service. We do not knowingly collect Personal
            Data from any child under 16 years of age without consent or authorization of the
            holder of parental responsibility over said child, per EU GDPR guidelines. If you are
            under 16 years of age, do not use our Services and do not provide any Personal Data
            to us. If you are a parent of a child under 16 years of age and become aware that your
            child has provided Personal Data to Adludio without your consent, please contact us at
            <Link> info@adludio.com</Link> or <Link>privacy@adludio.com</Link> and you may request access, correction,
            deletion, and/or objection rights.<br />
            <br />
            <br />
            <H5>Changes to the Privacy Policy</H5><br />
            Adludio may update this Privacy Policy from time to time, and so you should review this
            Policy periodically. We&#39;ll notify you of material changes to the Privacy Policy via a
            notice on our Site(s) and/or a direct communication (i.e. via email). When appropriate
            and required, we may notify you and request continue acceptance of the revised Terms
            of Use and/or Privacy Policy. You may be provided other privacy-related information in
            connection with your use of offerings from Adludio, as well as for special features and
            services not described in this Privacy Policy that may be introduced in the future.
            Contact Information<br />
            If you have questions or concerns regarding this Privacy Policy, including our policies
            for the collection, processing, use, disclosure or retention of information, and/or our
            use of cookies, you can contact us at:
            <br />
            <Box p='1rem'>
              <Caption>
                Adludio Limited<br />
                10c Warner Street,<br />
                London,<br />
                EC1R 5HA,<br />
                United Kingdom
              </Caption>
            </Box>
          </Body2>
        </Grid>
      </Box>
      <Grid container xs justify='center' alignContent='center' className={container}>
        <Grid item xs={12}>
          <Button variant='contained' onClick={() => navigate('/').catch(console.log)}>Go To Home Page</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
