import {
  AdludioTheme,
  Box,
  Grid,
  IconButton,
  makeStyles,
  Subtitle1,
  useMediaQuery,
  useTheme,
} from '@adludio/components';
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';

import { ProgrammaticCampaignForm } from '../components/Forms/Programmatic/ProgrammaticCampaignForm';
import { MediaCampaignForm } from '../components/Forms/Media/MediaCampaignForm';
import { LiveCampaignForm } from '../components/Forms/Live/LiveCampaignForm';

import { RouteComponentProps } from '@reach/router';
import { TypeOfCampaignScreen } from '../components/TypeOfCampaignScreen';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ResellerCampaignForm } from '../components/Forms/Reseller/ResellerCampaignForm';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    padding: '30px',
    minHeight: '100vh',
    backgroundColor: theme.palette.secondary.contrastText,
  },
  autosave: {
    flexGrow: 0,
    position: 'sticky',
    bottom: 20,
    left: 2,
    borderRadius: '4px',
  },
  badgeSaving: {
    width: '16rem',
    boxShadow: theme.shadows[4],
    padding: '0.5rem',
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    borderRadius: '4px',
  },
  badgeSaved: {
    width: '16rem',
    padding: '0.5rem',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  goBack: {
    cursor: 'pointer',
  },
}));

interface AddNewCampaignPageProps extends RouteComponentProps {
  id?: string;
}

export function AddNewCampaignPage(
  props: AddNewCampaignPageProps
): JSX.Element {
  const { container, purple, goBack } = styles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const stopBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const [activeStep, setActiveStep] = useState(0);
  const [isMedia, setIsMedia] = useState('programmatic');

  useEffect(() => {
    if (activeStep > 0) {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', stopBack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      window.removeEventListener('popstate', stopBack);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <Grid
      container
      className={container}
      justify='flex-start'
      direction='column'
      alignItems='center'
    >
      <>
        {activeStep === 1 && (
          <Grid
            container
            xs={isSmallScreen ? 12 : 11}
            justifyContent='flex-start'
          >
            <Box className={goBack}>
              <IconButton
                onClick={() => setActiveStep(activeStep - 1)}
                className={purple}
                label=''
              >
                <ArrowBackIcon className={purple} />

                <Subtitle1 color='primary' className={purple}>
                  Back to brief selection
                </Subtitle1>
              </IconButton>
            </Box>
          </Grid>
        )}
        {activeStep === 0 && (
          <TypeOfCampaignScreen
            setIsMedia={setIsMedia}
            isMedia={isMedia}
            setActiveStep={setActiveStep}
            {...props}
          />
        )}
        {activeStep !== 0 && isMedia === 'programmatic' && (
          <ProgrammaticCampaignForm />
        )}
        {activeStep !== 0 && isMedia === 'media' && <MediaCampaignForm />}
        {activeStep !== 0 && isMedia === 'live' && <LiveCampaignForm />}
        {activeStep !== 0 && isMedia === 'reseller' && <ResellerCampaignForm />}
      </>
    </Grid>
  );
}
