export function getAverage(actual: any[][]) {
  if (actual.length > 0) {
    const data = actual.map((a) => a[1]);
    const average = (arr: number[]) =>
      arr.reduce((a, b) => a + b, 0) / arr.length;

    let avg = average(data);
    return avg;
  }
  return 0;
}
