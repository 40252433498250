import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { BreakdownOpt, BreakdownOpts } from '../../types';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import TextField from '@material-ui/core/TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export interface DropdownProps {
  options: BreakdownOpts;
  placeholder: string;
  breakdown: string;
  label: string;
  value: BreakdownOpts;
  onSubBreakdownsChange: (
    breakdown: string,
    subBreakdowns: BreakdownOpts
  ) => void;
}

const BreakdownFilterOptions = ({
  options,
  placeholder,
  breakdown,
  label,
  value,
  onSubBreakdownsChange,
}: DropdownProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const nullSafeValue = value ?? [];

  return (
    <Autocomplete
      multiple
      id='checkboxes-dropdowns'
      options={options}
      disableCloseOnSelect
      size='small'
      onChange={(_: React.ChangeEvent<{}>, filters: BreakdownOpts) =>
        onSubBreakdownsChange(breakdown, filters)
      }
      getOptionLabel={(option: BreakdownOpt) => option.label}
      value={nullSafeValue}
      renderOption={(
        option: BreakdownOpt,
        state: AutocompleteRenderOptionState
      ) => {
        const selectBreakdownIndex = nullSafeValue.findIndex(
          (breakdownOpt) => breakdownOpt.label.toLowerCase() === 'all'
        );
        if (selectBreakdownIndex > -1) {
          state.selected = true;
        }
        return (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected}
            />
            {option.label}
          </React.Fragment>
        );
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          variant='outlined'
          label={label}
          placeholder={placeholder}
          style={{ width: '300px' }}
        />
      )}
    />
  );
};
export default BreakdownFilterOptions;
