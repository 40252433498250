import React from 'react';

import { Avatar } from '@adludio/components';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';

type UserProfilePicture = {
  bgColor: string;
  textColor: string;
} & React.ComponentProps<typeof Avatar>;

const UserProfilePicture = (props: UserProfilePicture) => {
  const {
    auth: { user },
  } = React.useContext(AuthContext) as SuccessAuthContextProps;

  const initials = React.useMemo(() => {
    return user.name
      .split(' ')
      .map((n: string) => n[0])
      .join('');
  }, [user]);

  return (
    <Avatar bgColour={props.bgColor} fontColor={props.textColor} {...props}>
      {initials}
    </Avatar>
  );
};

export default UserProfilePicture;
