import { ApexOptions } from 'apexcharts';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Site } from '../../types';
import { formatDateString } from '../../utils/date';
import getIcons from './chartIcons';
import { removeURLPrefix } from '../../utils/stringFilter';
import { responsiveStyle } from '../../utils/Styles';

export interface ClickableBarChartsProps {
  campaignName: string;
  kpi: string;
  kpiLabel: string;
  siteList: Site[];
}

const ClickableBarCharts = ({
  campaignName,
  kpi,
  kpiLabel,
  siteList,
}: ClickableBarChartsProps) => {
  const classes = responsiveStyle();
  // Sort values descendingly by kpi value first
  siteList.sort((prev, curr) => curr[kpi] - prev[kpi]);
  const sites = siteList.map((site) => removeURLPrefix(site.site));
  const kpiValues = siteList.map((site) => Math.round(site[kpi] * 10000) / 100);
  const date = new Date();
  const maxRate = kpiValues[0] + 10;
  const downloadFilename = `${campaignName}_${formatDateString(date)}`;
  const dataSeries: ApexAxisChartSeries = [
    {
      name: kpi,
      data: kpiValues,
    },
  ];
  const options: ApexOptions = {
    chart: {
      width: '100%',
      type: 'bar',
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      selection: {
        enabled: true,
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 100,
        animateGradually: {
          enabled: true,
          delay: 10,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 100,
        },
      },
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: false,
          zoomout: false,
          pan: false,
          zoom: false,
          reset: false,
        },
        export: {
          png: {
            filename: downloadFilename,
          },
          svg: {
            filename: downloadFilename,
          },
          csv: {
            filename: downloadFilename,
          },
        },
      },
    },
    theme: {
      mode: 'light',
      monochrome: {
        enabled: true,
        color: '#4E55C3',
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000'],
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'normal',
      },

      formatter: (val: number | string): string => {
        return `${val}%`;
      },

      offsetX: 50,
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: sites,
      labels: {
        show: true,
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
      min: 0,
      max: maxRate,
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: '100%',
            height: 200,
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 0,
              offsetY: 0,
            },
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '50%',
            },
          },
          legend: {
            show: false,
          },
          title: {
            text: `Top 10 sites by ${kpiLabel}`,
            align: 'center',
          },
        },
      },
    ],
    title: {
      text: `Top ${10} site performance by ${kpiLabel}`,
      floating: true,
      align: 'center',
    },
    tooltip: {
      x: {
        show: true,
      },
      y: {
        formatter: (val: number): string => `${val}%`,
        title: {
          formatter: (): string => kpiLabel,
        },
      },
    },
    legend: {
      show: false,
    },
  };
  return (
    <div className={classes.stackedBar}>
      <ReactApexChart
        options={options}
        series={dataSeries}
        type='bar'
        height={355}
      />
    </div>
  );
};

export default ClickableBarCharts;
