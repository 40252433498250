import { EnvironmentTypes, MRAIDOptions } from './store/static/envChoices';
import { DSPType } from '../scriptGenerator/store/tagReducer';
import { List } from 'immutable';

const plainScript =
  '<script async defer src="https://wat.adludio.com/loaders/prod/adludio-v1.23.0.js">';
const mraidScript = '<script src="mraid.js"></script>';

const dspScriptCreator = (
  dsp: DSPType | '',
  thirdParty: List<String>
): string => {
  if (thirdParty[0]) {
    return '';
  } else {
    switch (dsp) {
      case 'DV360':
        return '<script type="application/javascript" src="https://pixel.adsafeprotected.com/jload?anId=930722&campId=${CAMPAIGN_ID}_IMP&pubId=${PUBLISHER_ID}_IMP&placementId=${CREATIVE_ID}_IMP&dealId=${INVENTORY_SOURCE_EXTERNAL_ID}_IMP&adsafe_par&bidurl=${SOURCE_URL_ENC}"></script>';
      case 'TTD':
        return '<script type="application/javascript" src="https://pixel.adsafeprotected.com/jload?anId=930756&advId=%%TTD_ADVERTISERID%%_IMP&campId=%%TTD_CAMPAIGNID%%_IMP&pubId=%%TTD_SUPPLYVENDOR%%_IMP&chanId=%%TTD_ADGROUPID%%_IMP&placementId=%%TTD_CREATIVEID%%_IMP&dealId=%%TTD_DEALID%%_IMP&adsafe_par&bidurl=%%TTD_SITE%%"></script>';
      case 'Xandr':
        return '<script type="application/javascript" src="https://pixel.adsafeprotected.com/jload?anId=930756&advId=${ADV_ID}_IMP&campId=${IO_ID}_IMP&pubId=${PUBLISHER_ID}_IMP&chanId=${CPG_ID}_IMP&placementId=${CREATIVE_ID}_IMP&dealId=${DEAL_ID}_IMP&adsafe_par&bidurl=${REFERER_URL}"></script>';
      case 'Kidoz':
        return '<SCRIPT TYPE="application/javascript" SRC="https://pixel.adsafeprotected.com/rjss/st/1154696/65393084/skeleton.js"></SCRIPT>';
      case 'Yahoo':
        return '<script https://pixel.adsafeprotected.com/jload?anId=930756&advId={ADV_ID}&campId=${ORDERID}&pubId=${PUBLISHERID}&chanId=&placementId=${PLACEMENTID}&dealId=&adsafe_par&bidurl=';
      default:
        return '<script type="application/javascript" src=""></script>';
    }
  }
};

const impressionLevelJsScriptCreator = (
  impressionLevelJsUrls: List<string> | ''
): string => {
  if (typeof impressionLevelJsUrls === 'string' && impressionLevelJsUrls !== '')
    return `<script type="application/javascript" src="${impressionLevelJsUrls}"></script>`;
  if (List.isList(impressionLevelJsUrls)) {
    return (
      impressionLevelJsUrls
        /*/toJS()*/
        .map((url: string) => {
          if (url !== '')
            return `<script type="application/javascript" src="${url}"></script>`;
          else return '';
        })
        .join('')
    );
  }
  return '';
};

const impressionLevelImageTagCreator = (
  impressionLevelImagePixelUrls: List<string> | ''
): string => {
  if (
    typeof impressionLevelImagePixelUrls === 'string' &&
    impressionLevelImagePixelUrls != ''
  )
    return `<img src="${impressionLevelImagePixelUrls}" style="display:none"/>`;
  if (List.isList(impressionLevelImagePixelUrls)) {
    return impressionLevelImagePixelUrls
      .map((url: string) => {
        if (url !== '') return `<img src="${url}" style="display:none"/>`;
        else return '';
      })
      .join('');
  }
  return '';
};
const scriptCreator = (
  json: object,
  impressionLevelJsUrls: List<string> | '',
  impressionLevelImagePixelUrls: List<string> | '',
  dsp: DSPType | '',
  thirdParty: List<string>
) => {
  return `${plainScript}
${JSON.stringify(json, null, 4)}
</script>
${dspScriptCreator(dsp, thirdParty)}
${impressionLevelImageTagCreator(impressionLevelImagePixelUrls)}
${impressionLevelJsScriptCreator(impressionLevelJsUrls)}
`;
};
const mraidScriptCreator = (
  json: object,
  impressionLevelJsUrls: List<string> | '',
  impressionLevelImagePixelUrls: List<string> | '',
  dsp: DSPType | '',
  thirdParty: List<string>
) => `${mraidScript}
${scriptCreator(
  json,
  impressionLevelJsUrls,
  impressionLevelImagePixelUrls,
  dsp,
  thirdParty
)}`;

export default function (
  environment: EnvironmentTypes | ''
): (
  json: object,
  impressionLevelJsUrls: List<string> | '',
  impressionLevelImagePixelUrls: List<string> | '',
  dsp: DSPType | '',
  thirdParty: List<string>
) => string {
  return MRAIDOptions.includes(environment)
    ? mraidScriptCreator
    : scriptCreator;
}
