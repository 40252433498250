import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '@adludio/react-sso';
import { Report, emptyReport } from '../types';
import { fetchReport } from '../api';

export interface ReportContextState {
  report: Report;
  isLoading: boolean;
  fetchReport: (campaignKey: string) => void;
  error: boolean;
}

export const initialReportState: ReportContextState = {
  report: emptyReport,
  isLoading: false,
  fetchReport: (campaignKey) => {
    throw Error('fetchReport not implemented');
  },
  error: false,
};

export const ReportContext =
  React.createContext<ReportContextState>(initialReportState);

export const useReport = () => useContext(ReportContext);

export interface ReportContextProviderProps {
  children: React.ReactNode;
}

export const ReportContextProvider = ({
  children,
}: ReportContextProviderProps) => {
  const [report, setReport] = useState<Report>(emptyReport);
  const [isFetching, setIsFetching] = useState(true);
  const [error, setError] = useState(false);

  const { auth } = React.useContext(AuthContext);
  const loadReport = useCallback(
    (campaignKey: string) => {
      setIsFetching(true);
      setReport(emptyReport);
      fetchReport(auth.authToken, campaignKey)
        .then((result) => {
          setReport(result);
          setIsFetching(false);
        })
        .catch(() => {
          setError(true);
          setIsFetching(false);
        });
    },
    [auth.authToken]
  );

  return (
    <ReportContext.Provider
      value={{
        report: report,
        isLoading: isFetching,
        fetchReport: loadReport,
        error: error,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
