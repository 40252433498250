import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import BootstrapTooltip from './bootstrapTooltip';
import { AdludioTheme } from '@adludio/components';

const useStyles = makeStyles((theme:AdludioTheme ) => ({
  root: {
    height: '7px',
    borderRadius: 5,
    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
      backgroundColor: theme.palette.grey[400]
    },
    width: '55px',
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.grey[400]
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#4E55C3',
      borderRadius: 5,
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      backgroundImage:
        'radial-gradient(##BDBDBD 0%, ##BDBDBD 16%, transparent 42%)'
    }
  }
}));

interface ProgressProps{
    value:number;
    tooltipText:string;
}
const LinearDeterminate = ({ value, tooltipText }:ProgressProps)=> {
  const classes = useStyles();

  return (
    <BootstrapTooltip title={tooltipText} placement='bottom-end'>
      <LinearProgress
        variant='determinate'
        color='primary'
        valueBuffer={40}
        value={value}
        classes={{
          root: classes.root
        }}
      />
    </BootstrapTooltip>
  );
};
export default LinearDeterminate;
