import { BreakdownValue, BreakdownOpts } from '../types';
import { BreakdownValueMapper } from './BreakdownValueMapper';

export const getOptions = (
  breakdownValues: BreakdownValue[],
  selectedSubBreakdowns: BreakdownOpts
): BreakdownOpts => {
  const isSelected = (element: string): boolean =>
    selectedSubBreakdowns.some((breakdown) => breakdown.value === element);

  const options = breakdownValues
    .map((element: BreakdownValue) => ({
      value: element.value,
      label: mapValue(element.name),
      disabled: isSelected(element.value),
    }))
    .concat({
      value: '',
      label: 'Select All',
      disabled: false,
    });

  console.log(options);
  return options;
};

export const getNames = (breakdownValues: BreakdownValue[]): string[] =>
  breakdownValues.map((element) => element.name);

export const mapValue = (key: string) => BreakdownValueMapper[key] ?? key;

export const getKey = (value: string) =>
  Object.keys(BreakdownValueMapper).find(
    (key) => BreakdownValueMapper[key] === value
  ) ?? value;
