import React from 'react';

import { FormLabel, FormGroup, TextField, InputAdornment, Typography } from '@material-ui/core';
import numeral from 'numeral';
import { defineCols } from '../utils/setKpiCols';
import isWithinInterval from 'date-fns/isWithinInterval';

const tableColumnFilterOption = (item: string, category: string[] = []) => {
  let colOptions = {};
  const labelName = defineCols(item);
  switch (item) {
    case 'date':
      colOptions = {
        name: item,
        label: labelName,
        options: {
          filter: true,
          filterType: 'custom',
          sortDirection: 'desc',
          customFilterListOptions: {
            render: (v: any) => {
              if (v[0] && v[1]) {
                return `From Date: ${v[0]}, To Date: ${v[1]}`;
              } else if (v[0]) {
                return `From Date: ${v[0]}`;
              } else if (v[1]) {
                return `To Date: ${v[1]}`;
              }
              return [];
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(dateCheck: string | Date, filters: any) {
              let check = new Date(dateCheck);
              let from: Date = new Date(filters[0]);
              let to: Date = new Date(filters[1]);

              if (filters[0]) {
                return check <= from;
              } else if (filters[1]) {
                return check >= to;
              } else if (filters[0] && filters[1]) {
                return isWithinInterval(check, { start: from, end: to });
              }
              return false;
            },
            display: function xyz(
              filterList: any,
              onChange: any,
              index: any,
              column: any
            ) {
              return (
                <div>
                  <FormLabel>Date</FormLabel>
                  <FormGroup row>
                    <TextField
                      name='date1'
                      label='From Date'
                      type='date'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}

                    />
                    <TextField
                      name='date2'
                      label='To Date'
                      type='date'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}

                    />
                  </FormGroup>
                </div>
              );
            },
          },
        },
      };
      break;
    case 'category':
      colOptions = {
        name: item,
        label: labelName,
        options: {
          filter: true,
          filterType: 'multiselect',
          customFilterListOptions: {
            render: (v:string) => v.toLowerCase()
          },
        }
      };
      break;
    default:
      colOptions = {
        name: item,
        label: labelName,
        options: {
          filter: true,
          sort: true,
          filterType: 'custom',
          responsive: 'scroll',
          sortCompare: (order: string) => {
            return (obj1: { data: any; }, obj2: { data: any; }) => {
              const val1 = numeral(obj1.data).value();
              const val2 = numeral(obj2.data).value();
              return ((val1 ? val1 : 0) - (val2 ? val2 : 0)) * (order === 'asc' ? 1 : -1);
            };
          },
          customFilterListOptions: {
            render: (v: any) => {
              if (v[0] && v[1]) {
                return `Min ${item}: ${v[0]}, Max ${item}: ${v[1]}`;
              } else if (v[0]) {
                return `Min ${item}: ${v[0]}`;
              } else if (v[1]) {
                return `Max ${item}: ${v[1]}`;
              }
              return [];
            },
            update: (filterList: any, filterPos: any, index: any) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, '');
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(selection: any, filters: any[]) {
              selection = numeral(selection).value();
              if (filters[0] && filters[1]) {
                return selection < filters[0] || selection > filters[1];
              } else if (filters[0]) {
                return selection < filters[0];
              } else if (filters[1]) {
                return selection > filters[1];
              }
              return false;
            },

            display: function xyz(
              filterList: any,
              onChange: any,
              index: any,
              column: any
            ) {
              return (
                <div>
                  <FormLabel>{labelName}</FormLabel>
                  <FormGroup row>
                    <TextField
                      label='Greater than'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterList[index][0] || ''}
                      onChange={(event) => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '2%' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Typography>{'>'}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label='Less than'
                      type='number'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={filterList[index][1] || ''}
                      onChange={(event) => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Typography>{'<'}</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormGroup>
                </div>

              );
            },
          },
        }
      };
      break;
  }
  return colOptions;
};

export default tableColumnFilterOption;
