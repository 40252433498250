import { Typography } from '@adludio/components';
import { Box } from '@adludio/components/dist/Layout';
import React from 'react';

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === index && (
        <Box p={3} style={{ maxWidth: '100%' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
