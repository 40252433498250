import { Grid, Card, Button, Box, H6, useSnackbar } from '@adludio/components';
import { AuthContext } from '@adludio/react-sso';
import React, { useContext, useState } from 'react';
import { initialConfig } from '../../..';
import { setConsent } from '../../../API/consent';
import CookieSettingsPanel from './CookieSettingsPanel';

export const CookieSettingsContainer = () => {
  const [performanceConsent, setPerformanceConsent] = useState(
    localStorage.getItem('performance') === 'true'
  );
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPerformanceConsent(checked);
  };
  const handleSavePreferences = () => {
    setConsent(initialConfig.ssoBEURL, authContext.auth.authToken, {
      consentKey: 'performance',
      consentValue: performanceConsent.toString(),
    })
      .then(() => {
        enqueueSnackbar('Successfully set preferences', { variant: 'success' });
        window.localStorage.setItem(
          'performance',
          performanceConsent.toString()
        );
        window.dispatchEvent(new Event('storage'));
      })
      .catch(() => {
        enqueueSnackbar("Couldn't set preferences, please try again", {
          variant: 'success',
        });
      });
  };

  return (
    <Grid container item direction='column' justify='flex-end'>
      <Grid item xs={12}>
        <Card>
          <Box p='2rem'>
            <Grid item container direction='column' spacing={3}>
              <Grid item xs container alignItems='center'>
                <H6> Manage cookie consent preferences</H6>
              </Grid>
              <CookieSettingsPanel
                onChange={handleChange}
                performanceConsent={performanceConsent}
              />
              <Grid item container justifyContent='flex-end'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSavePreferences}
                >
                  Save Preferences
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
