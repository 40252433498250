import React, { useEffect } from 'react';

import DashboardSkeleton from '../Skeletons/DashboardSkeleton';
import DashboardWithTabs from './DashboardWithTabs';
import { User } from '../../../generated/graphql';
import { useBreakdownReport } from '../../contexts/BreakdownReportContext';
import { usePrediction } from '../../contexts/PredictionContext';
import { useReport } from '../../contexts/ReportContext';
import { useSiteList } from '../../contexts/SiteListContext';
import { ErrorPage } from '../../../pages/ErrorPage';

interface DashboardProps {
  campaignKey?: string;
  team: User[];
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignName: string;
  campaignStatus: string;
}

function Dashboard({
  campaignKey = '',
  team,
  env,
  campaignName,
  campaignStatus,
}: DashboardProps) {
  const { report, isLoading, fetchReport, error } = useReport();
  const { fetchBreakdownReport } = useBreakdownReport();
  const { fetchSiteList } = useSiteList();
  const { fetchPrediction } = usePrediction();

  useEffect(() => {
    fetchReport(campaignKey);
  }, [campaignKey, fetchReport]);

  useEffect(() => {
    fetchBreakdownReport(campaignKey);
  }, [campaignKey, fetchBreakdownReport]);

  useEffect(() => {
    fetchSiteList(campaignKey);
  }, [campaignKey, fetchSiteList]);

  useEffect(() => {
    fetchPrediction(campaignKey);
  }, [campaignKey, fetchPrediction]);

  // fetching report return Skeleton page
  if (isLoading) {
    return <DashboardSkeleton />;
  }
  if (error) {
    return <ErrorPage />;
  }

  // return report with tabs
  return (
    <DashboardWithTabs
      campaignKey={campaignKey}
      report={report}
      team={team}
      env={env}
      campaignName={campaignName}
      campaignStatus={campaignStatus}
    />
  );
}

export default Dashboard;
