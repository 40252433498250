import { Grid, Typography, Skeleton } from '@adludio/components';
import { Paper } from '@material-ui/core';
import React from 'react';
import { responsiveStyle } from '../../utils/Styles';

const SiteBodySkeleton = () => {
  const classes = responsiveStyle();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={7}>
        <Paper style={{ height: 480, padding: '17px' }}>
          {[...Array(8)].map((i) => (
            <Typography key={i} variant='h3'>
              <Skeleton />
            </Typography>
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Paper
          className={`${classes.spacedGrid} ${classes.proportionPlot}`}
          style={{ padding: '17px' }}
        >
          <Skeleton variant='circle' width={300} height={300} />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default SiteBodySkeleton;
