import { DataMapType } from '..';
import { TagRecord } from '../store/tagReducer';

export default function (tag: TagRecord, data: DataMapType) {
  let region = tag.region;
  if (!region || region === 'eu-west-1') {
    return data;
  }

  return data.set('region', region);
}
