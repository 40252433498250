import React from 'react';
import { Body2, ListItemText } from '@adludio/components';
import { Grid, IconButton, ListItemIcon } from '@material-ui/core';
import { TrendingDown, TrendingUp, TrendingFlat } from '@material-ui/icons';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import BootstrapTooltip from '../ui/bootstrapTooltip';
import BorderLinearProgress from '../ui/linearProgress';
import numeral from 'numeral';
import { responsiveStyle } from '../../utils/Styles';
export interface SpendProps {
  count?: { primary: string; secondary: string };
  rate?: { primary: number; secondary: string; baseline: number };
  spend?: { primary: string; secondary: any; progress: number };
  pacing?: {
    primary: string;
    secondary: string;
    pacingFlag: number;
    pacingMoney: number;
  };
  tooltipText: string;
}

const KpiCard = ({ count, rate, spend, pacing, tooltipText }: SpendProps) => {
  let pacingTooltipText: string = 'On Target!';
  let pacingColor = '#3FA081';
  if (pacing) {
    if (pacing.pacingFlag > 0) {
      pacingTooltipText = `Overspending! ${numeral(pacing.pacingMoney).format(
        '0.0a'
      )} excess potential`;
      pacingColor = '#C77C02';
    } else if (pacing.pacingFlag < 0) {
      pacingTooltipText = `Underspending! ${numeral(pacing.pacingMoney).format(
        '0.0a'
      )} out of your total budget is at risk`;
      pacingColor = '#BF384C';
    }
  }
  let kpiTooltipText: string = '';
  if (rate) {
    kpiTooltipText =
      rate.baseline < 0.0
        ? `${numeral(rate.baseline).format('0.0')}% below baseline`
        : `+${numeral(rate.baseline).format('0.0')}% above baseline`;
  }

  const rateLabel = (lable: string) => (
    <Grid container>
      <Grid>{lable}&nbsp;</Grid>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <InfoOutlinedIcon style={{ fontSize: 12 }} />
      </Grid>
    </Grid>
  );
  const classes = responsiveStyle();
  return (
    <>
      {spend && (
        <BootstrapTooltip title={tooltipText} placement='top'>
          <ListItemText
            primaryTypographyProps={{ variant: 'h6', align: 'center' }}
            primary={spend.primary}
            secondaryTypographyProps={{ variant: 'body2' }}
            secondary={
              <Grid
                container
                spacing={0}
                direction='column'
                alignItems='center'
                justifyContent='center'
              >
                <Grid item>{spend.secondary}</Grid>
                <Grid item>
                  <BorderLinearProgress
                    value={spend.progress}
                    tooltipText={`${numeral(spend.progress).format(
                      '0.00'
                    )}% used`}
                  />
                </Grid>
              </Grid>
            }
          />
        </BootstrapTooltip>
      )}
      {count && (
        <BootstrapTooltip title={tooltipText} placement='top'>
          <ListItemText
            primaryTypographyProps={{ variant: 'h6', align: 'center' }}
            primary={count.primary}
            secondaryTypographyProps={{
              variant: 'body2',
              noWrap: true,
              align: 'center',
            }}
            secondary={count.secondary}
          />
        </BootstrapTooltip>
      )}
      {rate && (
        <>
          <BootstrapTooltip title={tooltipText} placement='top'>
            <ListItemText
              primaryTypographyProps={{ variant: 'h6' }}
              primary={`${numeral(rate.primary).format('0.0')}%`}
              secondaryTypographyProps={{ variant: 'body2', align: 'center' }}
              secondary={rateLabel(rate.secondary)}
            />
          </BootstrapTooltip>
          <BootstrapTooltip title={kpiTooltipText} placement='bottom-start'>
            <ListItemIcon className={classes.listIcon}>
              <IconButton edge='end'>
                {rate.baseline === rate.primary ? (
                  <TrendingFlat style={{ color: '#4E55C3' }} />
                ) : rate.baseline > rate.primary ? (
                  <TrendingDown style={{ color: '#BF384C' }} />
                ) : (
                  <TrendingUp style={{ color: '#3FA081' }} />
                )}
              </IconButton>
            </ListItemIcon>
          </BootstrapTooltip>
        </>
      )}
      {pacing && (
        <>
          <BootstrapTooltip
            title={pacing.pacingFlag === 0 ? pacingTooltipText : tooltipText}
            placement='top'
          >
            <ListItemText
              primaryTypographyProps={{
                style: { color: pacingColor, fontSize: '1.2rem' },
                align: 'center',
              }}
              primary={pacing.primary}
              secondaryTypographyProps={{ variant: 'body2', align: 'center' }}
              secondary={pacing.secondary}
            />
          </BootstrapTooltip>
          {pacing.pacingFlag !== 0 && (
            <BootstrapTooltip
              title={pacingTooltipText}
              placement='bottom-start'
            >
              <ListItemIcon>
                <IconButton edge='end'>
                  {pacing.pacingFlag < 0 ? (
                    <div className={classes.iconText}>
                      <ReportOutlinedIcon style={{ color: '#BF384C' }} />
                      <Body2 style={{ color: '#BF384C' }}>Underspending</Body2>
                    </div>
                  ) : (
                    <div className={classes.iconText}>
                      <AttachMoneyOutlinedIcon style={{ color: '#C77C02' }} />
                      <Body2 style={{ color: '#C77C02' }}>Overspending</Body2>
                    </div>
                  )}
                </IconButton>
              </ListItemIcon>
            </BootstrapTooltip>
          )}
        </>
      )}
    </>
  );
};

export default KpiCard;
