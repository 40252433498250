import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Select,
  InputLabel,
  Checkbox,
} from '@material-ui/core';
import {
  AdludioTheme,
  Grid,
  Box,
  Body1,
  makeStyles,
  TextInput,
  DatePicker,
  MenuItem,
  Button,
  Switch,
  IconButton,
} from '@adludio/components';

import VisibilityIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';

import { CreativeUploadValues } from '../types';
import FormControl from '@material-ui/core/FormControl';
import { FormikProps } from 'formik';
import { mockAdUnitSize } from '../../../mock-data/mockAdUnitSize';
import { mockTimeZone } from '../../../mock-data/mockTimeZone';
import validURL from '../../../pages/Creative Form/GenerateTagScript/scriptGenerator/store/tagValidator/isValidURL';
// to generate script:
import useGenerateScript from '../../../pages/Creative Form/GenerateTagScript/scriptGenerator/useGenerateScript';
import { validateLink } from '../../../helpers/validateLink';

const MILLISECONDS_IN_DAY = 1000 * 3600 * 24;
const styles = makeStyles((theme: AdludioTheme) => ({
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  dropDown: {
    minWidth: 120,
    width: '100%',
  },
  menuPaper: {
    maxHeight: '30vh',
  },
  errorText: {
    marginLeft: '0px',
    color: theme.palette.error.main,
  },
}));

export const CreativeUploadForm = ({
  formikProps,
  dsp,
  preview,
  setCurrentTag,
  campaignName,
}: {
  formikProps: FormikProps<CreativeUploadValues>;
  dsp?: string;
  preview: () => void;
  setCurrentTag: (tag: string) => void;
  campaignName?: string | null;
}) => {
  const { errors, values, setFieldValue, handleChange } = formikProps;
  const { purple, dropDown, menuPaper, errorText } = styles();

  const [radioGroupValue, setRadioGroupValue] = useState<string>();
  const [openSizeDropdown, setOpenSizeDropdown] = useState('');
  // const [openTrackingInput, handleTrackingInput] = useState('');
  const [openJSInput, setOpenJSInput] = useState(false);
  const [open1x1Input, setOpen1x1Input] = useState(false);
  const [openIASInput, setOpenIASInput] = useState(true);
  const [openDVInput, setOpenDVInput] = useState(false);
  const [openMOATInput, setOpenMOATInput] = useState(false);
  const [openOtherInput, setOpenOtherInput] = useState(false);
  const [selectedStartDate, handleStartDateChange] = useState<
    Date | string | null
  >(null);
  const [selectedEndDate, handleSelectedEndDate] = useState<
    Date | string | null
  >(null);
  const { generatedScript } = useGenerateScript(
    values,
    values.gameKey,
    dsp || 'TTD',
    campaignName || 'Not Provided'
  );

  const [pixelUrl, setPixelUrl] = useState('');
  const [jsUrl, setJsUrl] = useState('');

  React.useEffect(() => {
    setCurrentTag(generatedScript);
  }, [values, dsp]);

  React.useEffect(() => {
    if (values.startDate && !selectedStartDate) {
      handleStartDateChange(values.startDate);
    }
    if (values.endDate && !selectedEndDate) {
      handleSelectedEndDate(values.endDate);
    }
  }, [values]);

  // check if script works + avoid unused vars when I want this initialized
  const handleSizeInput = (value: any) => {
    if (value === 'Other') {
      setRadioGroupValue('Other');
    } else {
      setRadioGroupValue(value);
      setFieldValue('width', parseInt(value.split('x')[0]));
      setFieldValue('height', parseInt(value.split('x')[1]));
    }
  };

  const handleSizeDropdown = (value: any) => {
    setOpenSizeDropdown(value);
    setFieldValue('width', parseInt(value.split('x')[0]));
    setFieldValue('height', parseInt(value.split('x')[1]));
  };
  const handleClearStartDate = (e: any) => {
    e.stopPropagation();
    handleStartDateChange(null);
  };

  const handleClearEndDate = (e: any) => {
    e.stopPropagation();
    handleSelectedEndDate(null);
  };

  const setTracking =
    (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (type === '1x1') {
        setPixelUrl(event.target.value);
      } else {
        setJsUrl(event.target.value);
      }
      try {
        const trackingTag = JSON.parse(formikProps.values.trackingTag);
        trackingTag[type] = event.target.value;
        formikProps.setFieldValue('trackingTag', JSON.stringify(trackingTag));
      } catch {
        formikProps.setFieldValue(
          'trackingTag',
          JSON.stringify({ [type]: event.target.value })
        );
      }
    };

  useEffect(() => {
    if (values.width && values.height) {
      const adunitSize = mockAdUnitSize.filter((value) => {
        if (value.value === 'Other') return false;
        const [width, height] = value.value.split('x');
        return (
          values.height?.toString() === height &&
          values.width?.toString() === width
        );
      });
      if (['FS', 'MPU'].includes(adunitSize[0].label)) {
        setRadioGroupValue(adunitSize[0].value);
      } else {
        setRadioGroupValue('Other');
        setOpenSizeDropdown(adunitSize[0].value);
      }
    }
    if (values.trackingTag !== '') {
      try {
        const trackingObject = JSON.parse(values.trackingTag);
        const keys = Object.keys(trackingObject);
        if (keys.length) {
          if (trackingObject['JS']) {
            setJsUrl(trackingObject['JS']);
            setOpenJSInput(true);
          }
          if (trackingObject['1x1']) {
            setPixelUrl(trackingObject['1x1']);
            setOpen1x1Input(true);
          }
        }
      } catch (e) {
        formikProps.setFieldValue(
          'trackingTag',
          JSON.stringify({ JS: values.trackingTag })
        );
      }
    } else {
      setJsUrl('');
      setPixelUrl('');
    }
  }, [values]);

  return (
    <Grid container xs={12} spacing={4}>
      <Grid item xs={12}>
        <TextInput
          id='gameKey'
          name='gameKey'
          variant='outlined'
          placeholder='Game Key*'
          onChange={handleChange}
          error={!!errors.gameKey}
          FormHelperTextProps={{
            className: errorText,
          }}
          helperText={errors.gameKey}
          value={values.gameKey}
        />
        {values.gameKey && (
          <Button
            variant='text'
            size='small'
            color='default'
            onClick={() => preview()}
          >
            <VisibilityIcon />
            &nbsp;Preview Ad Unit
          </Button>
        )}
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Body1 className={purple}>Ad Unit Name</Body1>
        </Grid>
        <Grid item xs={12}>
          <Box mt='1rem'>
            <TextInput
              id='name'
              name='name'
              variant='outlined'
              placeholder='Please enter a unique name for this Ad Unit'
              onChange={handleChange}
              value={values.name}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt='2rem'>
            <FormControl variant='outlined' className={dropDown}>
              <InputLabel>Enviroment*</InputLabel>
              <Select
                value={values.enviroment}
                name='enviroment'
                onChange={handleChange}
                label='Enviroment*'
                error={!!errors.enviroment}
              >
                <MenuItem value={'MRAID1'}>MRAID1</MenuItem>
                <MenuItem value={'MRAID2'}>MRAID2</MenuItem>
                <MenuItem value={'MWEB'}>MWEB</MenuItem>
              </Select>
              {errors.enviroment && (
                <FormHelperText className={errorText}>
                  {errors.enviroment}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt='2rem'>
            <FormControl variant='outlined' className={dropDown}>
              <InputLabel>Region*</InputLabel>
              <Select
                value={values.region}
                name='region'
                onChange={handleChange}
                label='Region*'
                error={!!errors.region}
              >
                <MenuItem value={'eu-west-1'}>eu-west-1 (Ireland)</MenuItem>
                <MenuItem value={'us-east-1'}>us-east-1 (Virginia)</MenuItem>
                <MenuItem value={'us-west-1'}>
                  us-west-1 (N. California)
                </MenuItem>
                <MenuItem value={'ap-east-1'}>ap-east-1 (Hong Kong)</MenuItem>
                <MenuItem value={'ap-southeast-1'}>
                  ap-southeast-1 (Singapore)
                </MenuItem>
              </Select>
              {errors.region && (
                <FormHelperText className={errorText}>
                  {errors.region}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Body1 className={purple}>Redirect URL (i.e Click Tracker)</Body1>
        </Grid>
        <Grid item xs={12}>
          <Box my='1rem'>
            <TextInput
              id='redirectUrl'
              name='redirectUrl'
              variant='outlined'
              onChange={handleChange}
              value={values.redirectUrl}
              error={
                values.redirectUrl.trim() !== '' &&
                !validURL(values.redirectUrl)
              }
              helperText={
                values.redirectUrl.trim() !== '' &&
                !validURL(values.redirectUrl) &&
                'Introduce a valid URL'
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Body1 className={purple}>Landing Page URL</Body1>
        </Grid>
        <Grid item xs={12}>
          <Box my='1rem'>
            <TextInput
              id='landingPageUrl'
              name='landingPageUrl'
              variant='outlined'
              onChange={handleChange}
              value={values.landingPageUrl}
              error={
                values.landingPageUrl.trim() !== '' &&
                !validateLink(values.landingPageUrl)
              }
              helperText={
                values.landingPageUrl.trim() !== '' &&
                !validateLink(values.landingPageUrl) &&
                'Introduce a valid URL'
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Body1 className={purple}>Ad Unit Dimensions</Body1>
        </Grid>
        <Grid item container xs={12}>
          <FormControl fullWidth>
            <RadioGroup
              value={radioGroupValue}
              onChange={(e) => {
                handleSizeInput(e.target.value);
              }}
            >
              <>
                {errors.width && (
                  <FormHelperText>{errors.width}</FormHelperText>
                )}
                {mockAdUnitSize.slice(0, 3).map((o) => (
                  <FormControlLabel
                    value={o.value}
                    control={<Radio required color='primary' />}
                    label={o.label}
                    key={o.key}
                  />
                ))}
              </>
            </RadioGroup>
            {radioGroupValue === 'Other' && (
              <FormControl variant='outlined'>
                <InputLabel>Select AdUnit Dimensions</InputLabel>
                <Select
                  label='Select AdUnit Dimensions'
                  onChange={(e) => {
                    handleSizeDropdown(e.target.value);
                  }}
                  value={openSizeDropdown}
                  inputProps={{
                    InputLabelProps: { shrink: undefined },
                    notched: undefined,
                  }}
                >
                  {mockAdUnitSize.slice(-5).map((o) => (
                    <MenuItem value={o.value} key={o.key}>
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Body1 className={purple}>Dates</Body1>
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            inputVariant='outlined'
            fullWidth
            autoOk
            minDate={Date.now()}
            label='Start Date'
            format='DD-MM-YYYY'
            onChange={(time) => {
              handleStartDateChange(time);
              setFieldValue('startDate', time.toDate() as Date);
            }}
            error={!!errors.startDate}
            helperText={errors.startDate}
            value={selectedStartDate}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={(e) => handleClearStartDate(e)}
                  label={false}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            inputVariant='outlined'
            fullWidth
            minDate={
              values.startDate !== null
                ? new Date(
                    new Date(values.startDate).valueOf() + MILLISECONDS_IN_DAY
                  )
                : new Date(new Date().getDate() + 1)
            }
            autoOk
            label='End Date'
            format='DD-MM-YYYY'
            onChange={(time) => {
              handleSelectedEndDate(time);
              setFieldValue('endDate', time.toDate() as Date);
            }}
            error={!!errors.endDate}
            helperText={errors.endDate}
            value={selectedEndDate}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={(e) => handleClearEndDate(e)}
                  label={false}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Body1 className={purple}>Time</Body1>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant='outlined' className={dropDown}>
            <InputLabel>Select Time Zone</InputLabel>
            <Select
              label='Select Time Zone'
              onChange={handleChange}
              MenuProps={{ classes: { paper: menuPaper } }}
              value={values.timeZone}
              name='timeZone'
              id='timeZone'
            >
              {mockTimeZone.map((o) => (
                <MenuItem value={o.text} key={o.text}>
                  {o.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            variant='outlined'
            fullWidth
            id='time'
            name='time'
            type='time'
            label='Select Time'
            onChange={handleChange}
            value={values.time ?? ''}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Body1 className={purple}>Third Party Engagement</Body1>
        </Grid>
        <Grid item container xs={12}>
          <TextInput
            id='thirdPartyEng'
            name='thirdPartyEng'
            variant='outlined'
            placeholder='Place third party engagement url'
            value={values.thirdPartyEng}
            onChange={handleChange}
            error={
              values.thirdPartyEng?.trim() !== '' &&
              !validURL(values.thirdPartyEng!)
            }
            helperText={
              values.thirdPartyEng?.trim() !== '' &&
              !validURL(values.thirdPartyEng!) &&
              'Introduce a valid URL'
            }
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Body1 className={purple}>Impression Tracking Tag</Body1>
        </Grid>
        <Grid item container xs={12}>
          <FormControl
            fullWidth
            //  onChange={handleImpressionChange}
          >
            <FormControlLabel
              label='JS'
              control={
                <Checkbox
                  color='primary'
                  checked={openJSInput}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpenJSInput(true);
                      const trackingTag = JSON.parse(
                        formikProps.values.trackingTag
                      );
                      trackingTag['JS'] = '';
                      formikProps.setFieldValue(
                        'trackingTag',
                        JSON.stringify(trackingTag)
                      );
                    } else {
                      setOpenJSInput(false);
                      setJsUrl('');
                      const trackingTag = JSON.parse(
                        formikProps.values.trackingTag
                      );
                      delete trackingTag['JS'];
                      formikProps.setFieldValue(
                        'trackingTag',
                        JSON.stringify(trackingTag)
                      );
                    }
                  }}
                />
              }
            />
            {openJSInput && (
              <Box mb='0.5rem' mt='0.5rem'>
                <TextInput
                  variant='outlined'
                  id='jsTags'
                  name='jsTags'
                  placeholder='Place JS Impression Tracking Tag'
                  value={jsUrl}
                  onChange={setTracking('JS')}
                />
              </Box>
            )}
          </FormControl>
          <FormControl
            fullWidth
            // onChange={handleImpressionChange}
          >
            <FormControlLabel
              label='1x1'
              control={
                <Checkbox
                  color='primary'
                  checked={open1x1Input}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setOpen1x1Input(true);
                      const trackingTag = JSON.parse(
                        formikProps.values.trackingTag
                      );
                      trackingTag['1x1'] = '';
                      formikProps.setFieldValue(
                        'trackingTag',
                        JSON.stringify(trackingTag)
                      );
                    } else {
                      setOpen1x1Input(false);
                      setPixelUrl('');
                      const trackingTag = JSON.parse(
                        formikProps.values.trackingTag
                      );
                      delete trackingTag['1x1'];
                      formikProps.setFieldValue(
                        'trackingTag',
                        JSON.stringify(trackingTag)
                      );
                    }
                  }}
                />
              }
            />
            {open1x1Input && (
              <Box mb='0.5rem' mt='0.5rem'>
                <TextInput
                  variant='outlined'
                  id='1x1Tags'
                  name='1x1Tags'
                  placeholder='Place 1x1 Impression Tracking Tag'
                  value={pixelUrl}
                  onChange={setTracking('1x1')}
                />
              </Box>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={12}>
          <Body1 className={purple}>IAS</Body1>
        </Grid>
        <Grid item container xs={12} direction='column' spacing={1}>
          <Box ml='0.2rem'>
            <FormControl error={!!errors.adludioIAS}>
              <Box mt='1rem'>
                <RadioGroup>
                  <FormControlLabel
                    id='adludioIAS'
                    name='adludioIAS'
                    // value={values.adludioIAS}
                    control={
                      <Switch color='secondary' checked={values.adludioIAS} />
                    }
                    label='Adludio IAS'
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    id='thirdPartyIAS'
                    name='thirdPartyIAS'
                    // value={values.thirdPartyIAS}
                    control={
                      <Switch
                        color='secondary'
                        checked={values.thirdPartyIAS}
                      />
                    }
                    label='Third Party IAS'
                    onChange={handleChange}
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {values.thirdPartyIAS && (
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Body1 className={purple}>Client Impression Pixel</Body1>
          </Grid>
          <Grid item container xs={12}>
            <FormControl fullWidth>
              <FormControlLabel
                label='IAS'
                control={
                  <Checkbox
                    color='primary'
                    defaultChecked={openIASInput}
                    onChange={(e) => {
                      e.target.checked
                        ? setOpenIASInput(true)
                        : setOpenIASInput(false);
                    }}
                  />
                }
              />
              {openIASInput && (
                <Box mb='0.5rem' mt='0.5rem'>
                  <TextInput
                    id='IASImpression'
                    name='IASImpression'
                    placeholder='Place IAS Client impression pixel'
                    variant='outlined'
                    value={values.IASImpression}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                label='DV'
                control={
                  <Checkbox
                    color='primary'
                    onChange={(e) => {
                      e.target.checked
                        ? setOpenDVInput(true)
                        : setOpenDVInput(false);
                    }}
                  />
                }
              />
              {openDVInput && (
                <Box mb='0.5rem' mt='0.5rem'>
                  <TextInput
                    id='DVImpression'
                    name='DVImpression'
                    placeholder='Place DV Client impression pixel'
                    variant='outlined'
                    value={values.DVImpression}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                label='MOAT'
                control={
                  <Checkbox
                    color='primary'
                    onChange={(e) => {
                      e.target.checked
                        ? setOpenMOATInput(true)
                        : setOpenMOATInput(false);
                    }}
                  />
                }
              />
              {openMOATInput && (
                <Box mb='0.5rem' mt='0.5rem'>
                  <TextInput
                    id='MOATImpression'
                    name='MOATImpression'
                    placeholder='Place MOAT Client impression pixel'
                    variant='outlined'
                    value={values.MOATImpression}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                label='Other'
                control={
                  <Checkbox
                    color='primary'
                    onChange={(e) => {
                      e.target.checked
                        ? setOpenOtherInput(true)
                        : setOpenOtherInput(false);
                    }}
                  />
                }
              />
              {openOtherInput && (
                <Box mb='0.5rem' mt='0.5rem'>
                  <TextInput
                    multiline
                    id='otherImpression'
                    name='otherImpression'
                    placeholder='Place client impression pixel'
                    variant='outlined'
                    value={values.otherImpression}
                    onChange={handleChange}
                  />
                </Box>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
