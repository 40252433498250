import { camelCase, startCase } from 'lodash';
import { DataMapType } from '..';
import { TagRecord } from '../store/tagReducer';

export const getMraidVersion = (environment: string) => {
  if (environment.indexOf('MRAID') !== -1) {
    return startCase(camelCase(environment)).concat('0').replace(/\s/g, '');
  } else {
    return 'Nonmraid';
  }
};

export const getEnvironment = (environment: string) => {
  if (environment.indexOf('MRAID') !== -1) {
    return 'MRAID';
  } else {
    return environment;
  }
};

export default function (tag: TagRecord, data: DataMapType) {
  let environment = getEnvironment(tag.environment);

  return data.set('environment', environment);
}
