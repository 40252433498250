import React from 'react';
import { Box, Skeleton, Typography } from '@adludio/components';
import { responsiveStyle } from '../../utils/Styles';

const BreakdownSkeleton = () => {
  const classes = responsiveStyle();
  return (
    <Box className={classes.root}>
      <Typography variant='h3'>
        <Skeleton />
      </Typography>

      <Box display='flex' justifyContent='space-evenly'>
        {[...Array(3)].map((i) => (
          <Box key={i}>
            <Typography variant='h2' style={{ width: '300px' }}>
              <Skeleton />
            </Typography>
          </Box>
        ))}
      </Box>

      <Box display='flex' justifyContent='space-evenly' p={1} m={1}>
        <Box p={1} style={{ width: '20%' }}>
          <Skeleton variant='rect' height={400} />
        </Box>
        <Box p={1} flexGrow={1}>
          <Skeleton variant='rect' height={400} />
        </Box>
      </Box>
    </Box>
  );
};
export default BreakdownSkeleton;
