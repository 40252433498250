export const sendInviteEmail = (
  env: 'development' | 'testing' | 'staging' | 'production',
  authToken: string,
  body: {
    campaignId: string;
    campaignName: string;
    recipients: string[];
    inviteToCompany?: string[];
    campaignStatus: string;
  }
) => {
  const prefix = () => {
    if (env === 'development') {
      return 'http://dev.';
    } else if (env === 'production') {
      return 'https://';
    } else {
      return `https://${env}.`;
    }
  };

  return fetch(`${prefix()}api.sso.adludio.com/emails/send-campaign-invite`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*',
    },
    body: JSON.stringify({ ...body }),
  });
};

export const checkUsersBelogToMyCompany = (
  env: 'development' | 'testing' | 'staging' | 'production',
  authToken: string,
  body: { emails: string[] }
): Promise<{ data: { emailsOutOfCompany: string[] } }> => {
  const prefix = () => {
    if (env === 'development') {
      return 'http://dev.';
    } else if (env === 'production') {
      return 'https://';
    } else {
      return `https://${env}.`;
    }
  };

  return fetch(`${prefix()}api.sso.adludio.com/users/company-check`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*',
    },
    body: JSON.stringify({ ...body }),
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    throw Error('something went wrong');
  });
};
