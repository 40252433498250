
export const getBriefLink = (briefSubmitted?: boolean, creativeSubmitted?: boolean, isManaged?: boolean) => {

  if ((briefSubmitted && creativeSubmitted) || (isManaged && briefSubmitted) ){
    return 'campaignPlan';
  }
  else {
    return 'brief';
  }
};
