import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { responsiveStyle } from '../../utils/Styles';

import CloudErrorSVG from '../../assets/CloudError.svg';

const WordCloudNoData = () => {
  const classes = responsiveStyle();

  return (
    <div className={classes.root}>
      <Paper
        elevation={0}
        className={classes.paper}
        style={{ content: 'center' }}
      >
        <Box sx={{ p: 3 }}>
          <Typography>Not enough data to support visualisation</Typography>
          <Box sx={{ p: 1 }} />
          <img src={CloudErrorSVG} />
        </Box>
      </Paper>
    </div>
  );
};
export default WordCloudNoData;
