import React from 'react';

import DMZGateKeeper from '@adludio/sso-dmz-gatekeeper';

import { RouteComponentProps } from '@reach/router';
import { ConfigProps } from '../';

const GateKeeperContainer = ({
  config
}: RouteComponentProps & ConfigProps) => {
  return <DMZGateKeeper config={{ env: config.env }} />;
};

export default GateKeeperContainer;
