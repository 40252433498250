import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '@adludio/react-sso';
import { fetchBreakdownReport } from '../api';
import { BreakdownReport } from '../types';

export interface BreakdownReportContextState {
  breakdownReport: BreakdownReport;
  isLoading: boolean;
  fetchBreakdownReport: (campaignKey: string) => void;
}

export const initialReportState: BreakdownReportContextState = {
  breakdownReport: {},
  isLoading: false,
  fetchBreakdownReport: (campaignKey) => {
    throw Error('fetchBreakdownReport not implemented');
  },
};

export const BreakdownReportContext =
  React.createContext<BreakdownReportContextState>(initialReportState);

export const useBreakdownReport = () => useContext(BreakdownReportContext);

export interface BreakdownReportContextProviderProps {
  children: React.ReactNode;
}

export const BreakdownReportContextProvider = ({
  children,
}: BreakdownReportContextProviderProps) => {
  const [breakdownReport, setBreakdownReport] = useState<BreakdownReport>({});
  const [isFetching, setIsFetching] = useState(true);

  const { auth } = React.useContext(AuthContext);

  const loadBreakdownReport = useCallback(
    (campaignKey: string) => {
      setIsFetching(true);
      setBreakdownReport({});
      fetchBreakdownReport(auth.authToken, campaignKey)
        .then((result) => {
          setBreakdownReport(result);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    },
    [auth.authToken]
  );

  return (
    <BreakdownReportContext.Provider
      value={{
        breakdownReport: breakdownReport,
        isLoading: isFetching,
        fetchBreakdownReport: loadBreakdownReport,
      }}
    >
      {children}
    </BreakdownReportContext.Provider>
  );
};
