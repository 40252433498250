import { Report, BreakdownReport, SiteList, Prediction } from '../types';

const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
const apiUrlDev = 'http://localhost:5000';
const apiUrlProd = 'https://ard.futureadlabs.com';
const apiUrl = env === 'development' ? apiUrlDev : apiUrlProd;

export async function fetchReport(authToken: string, campaignKey: string) {
  return fetch(`${apiUrl}/report/${campaignKey}`, {
    method: 'GET',
    headers: buildHeaders(authToken),
  }).then(async (res) => {
    const result: Report = await res.json();
    return result;
  });
}

export async function fetchBreakdownReport(
  authToken: string,
  campaignKey: string
) {
  return fetch(`${apiUrl}/report/${campaignKey}/breakdown`, {
    method: 'GET',
    headers: buildHeaders(authToken),
  }).then(async (res) => {
    const result: BreakdownReport = await res.json();
    return result;
  });
}

export async function fetchSiteList(authToken: string, campaignKey: string) {
  return fetch(`${apiUrl}/site-list/${campaignKey}`, {
    method: 'GET',
    headers: buildHeaders(authToken),
  }).then(async (res) => {
    const result: SiteList = await res.json();
    return result;
  });
}

export async function fetchPrediction(authToken: string, campaignKey: string) {
  return fetch(`${apiUrl}/predict/${campaignKey}`, {
    method: 'GET',
    headers: buildHeaders(authToken),
  }).then(async (res) => {
    const result: Prediction = await res.json();
    return result;
  });
}

export async function fetchCustomReport(
  authToken: string,
  campaignKey: string,
  breakdownName: string,
  breakdownValues: string[],
  filter: { [breakdownName: string]: string[] }
) {
  const body = {
    campaignKey: campaignKey,
    breakdown: {
      breakdownName: breakdownName,
      breakdownValues: breakdownValues,
    },
    filter: filter,
  };

  return fetch(`${apiUrl}/report/custom`, {
    method: 'POST',
    headers: buildHeaders(authToken),
    body: JSON.stringify(body),
  }).then(async (res) => {
    const result: BreakdownReport = await res.json();
    return result;
  });
}

export function buildHeaders(authToken: string) {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + authToken,
  };
}
