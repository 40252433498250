import {
  AdludioTheme,
  Body2,
  Box,
  Caption,
  Divider,
  Grid,
  Icon,
  Link,
  MenuItem,
  Subtitle2,
  makeStyles,
} from '@adludio/components';
import React, { useCallback } from 'react';

import Menu from '@material-ui/core/Menu';
import UserProfilePicture from '../../Components/UserProfilePicture';
import { UserType } from '@adludio/react-sso/dist/types';
import { initialConfig } from '../../index';
import { logout } from '@adludio/react-sso/dist/checkAuth';
import { navigate } from '@reach/router';
import { useAuth } from '@adludio/react-sso';

interface MenuProps {
  user?: UserType;
  ssoBEURL?: string;
  envPrefix: string;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  menu: {
    marginTop: '3rem',
  },
  menuBox: {
    width: '20rem',
  },
  icon: {
    color: theme.palette.grey[600],
    size: '1.5rem',
  },
  links: {
    color: '#A100FF',
    cursor: 'pointer',
  },
}));

export default function UserMenu(props: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [isLogoutDisabled, setIsLogoutDisabled] = React.useState(false);
  const auth = useAuth(initialConfig.ssoBEURL);
  const styles = useStyles();
  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    if (!props.user) return;

    const token = window.localStorage.getItem('authToken');

    if (token && props.ssoBEURL) {
      setIsLogoutDisabled(true);
      window.localStorage.clear();
      logout(props.ssoBEURL, token)
        .then(() => setIsLogoutDisabled(false))
        .catch(console.log);
    }

    return window.location.replace('/');
  }, [props.ssoBEURL, props.user]);

  function navigateProfile(profile: string) {
    navigate(profile).catch(console.log);
    handleClose();
  }

  // Handle click is not being used yet as Avatar needs to be updated to take onClick events
  return (
    <>
      <UserProfilePicture
        bgColor='#fff'
        textColor='#000'
        onClick={handleClick}
      />
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        className={styles.menu}
      >
        <Box className={styles.menuBox} p='0.5rem'>
          <Grid container xs direction='column'>
            <Grid container item xs direction='row'>
              <Grid container item xs={2} alignItems='center' justify='center'>
                <Grid item>
                  <UserProfilePicture bgColor='#000' textColor='fff' />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={8}
                direction='column'
                justify='space-around'
              >
                <Grid item xs>
                  <Subtitle2>{auth.user?.name}</Subtitle2>
                </Grid>
                <Grid item xs>
                  <Caption>{auth.user?.email}</Caption>
                </Grid>
              </Grid>
            </Grid>
            <Box py='1rem'>
              <Divider />
            </Box>
            <Box pb='1.5rem'>
              <Grid container item xs direction='column'>
                <MenuItem onClick={() => navigateProfile('/user-profile')}>
                  <Body2>My Profile</Body2>
                </MenuItem>
                <MenuItem onClick={() => navigateProfile('/company-profile')}>
                  <Body2>Company Profile</Body2>
                </MenuItem>
                <Box py='.375rem'>
                  <Divider />
                </Box>
                <MenuItem onClick={handleLogout} disabled={isLogoutDisabled}>
                  <Box pt='0.4rem' pr='0.4rem'>
                    <Icon label='logout' className={styles.icon}>
                      logout
                    </Icon>
                  </Box>
                  <Body2>Logout</Body2>
                </MenuItem>
              </Grid>
            </Box>
            <Grid container item xs justify='space-around'>
              <Grid container item xs={3} justify='center'>
                <Link
                  className={styles.links}
                  href='https://www.notion.so/Adludio-Direct-Support-7649d74b69aa4450b8dfede011c6cf19'
                  target='_blank'
                >
                  <Caption>FAQ</Caption>
                </Link>
              </Grid>
              <Grid container item xs={5} justify='center'>
                <Link href='/privacy-policy' className={styles.links}>
                  <Caption>Privacy Policy</Caption>
                </Link>
              </Grid>
              <Grid container item xs={3} justify='center'>
                <Link href='/terms-and-conditions' className={styles.links}>
                  <Caption>T&C</Caption>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
}
