import React from 'react';

import { RouteComponentProps } from '@reach/router';

import ProjectsPage from './Campaigns';
import { ConfigProps } from '../';

const ProjectsPageContainer = ({
  config
}: RouteComponentProps & ConfigProps) => {
  return <ProjectsPage config={config} />;
};

export default ProjectsPageContainer;
