import {
  AdludioTheme,
  Body2,
  Box,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TabPanel,
  Divider,
  SearchBar,
} from '@adludio/components';

import { BriefGrid } from '../components/BriefGrid';
import { CampaignGrid } from '../components/CampaignGrid';
import React, { useState } from 'react';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircle';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  Campaign,
  ManagedService,
  useGetCampaignsQuery,
  useGetManagedServicesQuery,
} from '../generated/graphql';
import { LiveCampaignGrid } from '../components/LiveCampaignGrid';
import { IconResult } from '../components/IconResult';
import { AuthContext } from '@adludio/react-sso';

interface Props extends RouteComponentProps {
  envPrefix: string;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    paddingTop: '1rem',
    paddingLeft: '1rem',
    width: '100%',
    minHeight: '100vh',
    backgroundColor: theme.palette.primary.contrastText,
  },
  newCard: {
    width: '16rem',
    height: '2.5rem',
    [theme.breakpoints.down('xs')]: {
      width: '18rem',
      height: '2rem',
      textAlign: 'center',
    },
    margin: '2rem 0rem 3rem 0rem',
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  searchBarGridStyle: {
    margin: '3rem 0rem 3rem 0rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '2rem',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingRight: ' 4rem',
    },
  },
  searchBarCampaignsStyle: {
    border: `1px solid ${theme.palette.grey[400]} `,
    borderRadius: 100,
    minWidth: '14rem',
  },
}));

export function CampaignsPage(props: Props): JSX.Element {
  const { data, loading } = useGetCampaignsQuery();
  const { data: liveData } = useGetManagedServicesQuery();
  const [queryString, setQueryString] = React.useState('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [liveCampaigns, setLiveCampaigns] = useState<ManagedService[]>([]);
  const { container, newCard, searchBarGridStyle, searchBarCampaignsStyle } =
    styles();
  const [tab, setTab] = useState(0);
  const { auth } = React.useContext(AuthContext);

  const resellerBriefs = campaigns.filter(
    (campaign) => !!(campaign as any).resellerBrief
  );
  const briefs = campaigns
      .filter((campaign) => !!(campaign as any).brief)
      .concat(resellerBriefs);

  const isInternalUser =
    auth.user?.role !== 'guest' && auth.user?.role !== 'client';

  const isExternalWithNoBriefs = 
    !isInternalUser && briefs.length < 1
  
  React.useEffect(() => {
    const queriedCampaigns = data
      ? data.getCampaigns.map((c) => ({
          id: c.id!,
          title: c.title!,
          slug: c.slug!,
          thumbnailUrl: c.thumbnailUrl!,
          startDate: c.brief?.startDate! || c.resellerBrief?.startDate!,
          endDate: c.brief?.endDate! || c.resellerBrief?.endDate!,
          status: c.status!,
          adunits: c.adunits!,
          isManaged: c.brief?.isManaged! || c.resellerBrief?.isManaged!,
          brief: c.brief!,
          resellerBrief: c.resellerBrief!,
          creativeBrief: c.creativeBrief!,
        }))
      : [];
    setCampaigns(queriedCampaigns);
    const queriedLiveCampaigns = liveData?.getManagedServices;
    setLiveCampaigns(queriedLiveCampaigns as ManagedService[]);
    isExternalWithNoBriefs && setTab(1);
  }, [data, liveData]);

  const handleTabs = (event: React.ChangeEvent<{}>, newValue: any) => {
    localStorage.setItem('tab', newValue);
    setTab(newValue);
  };

  const filteredBriefs = briefs.filter((campaign) => {
    return (
      (campaign.brief?.name?.toLowerCase().includes(queryString) ||
        campaign.resellerBrief?.name?.toLowerCase().includes(queryString)) &&
      !campaign.title
    );
  });

  const filteredCampaigns = campaigns?.filter((campaign) =>
    campaign.title?.toLowerCase().includes(queryString)
  ) as any;

  const filteredManagedCampaigns = liveCampaigns?.filter((campaign) =>
    campaign.name?.toLowerCase().includes(queryString)
  );

  return (
    <Grid
      container
      className={container}
      alignItems='center'
      justifyContent='space-between'
      direction='column'
    >
      {loading ? (
        <Grid container item xs justify='center' alignItems='center'>
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='column' spacing={4} xs={12}>
          <Grid container item xs={12} style={{ zIndex: 2 }}>
            <Grid item container>
              <Grid item xs={12} md={3}>
                <Card
                  className={newCard}
                  elevation={4}
                  onClick={() => {
                    navigate('/campaigns/add-new-campaign').catch(console.log);
                  }}
                >
                  <Grid
                    item
                    container
                    xs
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    <Box pt='0.3rem'>
                      <Grid item>
                        <Body2> Create New Campaign</Body2>
                      </Grid>
                    </Box>
                    <Box pt='0.5rem' pl='1rem'>
                      <Grid item>
                        <AddCircleRoundedIcon />
                      </Grid>
                    </Box>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={9} className={searchBarGridStyle}>
                <Grid container item xs={11} md={3}>
                  <SearchBar
                    className={searchBarCampaignsStyle}
                    value={queryString}
                    onChange={(e: any) => setQueryString(e.target.value)}
                    placeholder='Search campaign'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={4} direction='row'>
              <Tabs
                value={tab}
                onChange={handleTabs}
                indicatorColor='secondary'
              >
                <Tab
                  label={
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      Briefs
                      {tab !== 0 && queryString && (
                        <IconResult value={filteredBriefs.length} />
                      )}
                    </Grid>
                  }
                />
                <Tab
                  label={
                    <Grid
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      Campaigns
                      {tab !== 1 && queryString && (
                        <IconResult value={filteredCampaigns.length} />
                      )}
                    </Grid>
                  }
                />
                {liveData?.getManagedServices &&
                  liveData?.getManagedServices.length >= 1 && (
                    <Tab
                      label={
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          justifyContent='center'
                        >
                          Managed Service
                          {tab !== 2 && queryString && (
                            <IconResult
                              value={filteredManagedCampaigns.length}
                            />
                          )}
                        </Grid>
                      }
                    />
                  )}
              </Tabs>
              <Grid xs={11}>
                <Divider style={{ width: '100%' }} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction='row'
                justifyContent='center'
                spacing={2}
              >
                <TabPanel currentTab={tab} index={0}>
                  <Grid xs={12}>
                    <Box mt='1rem'>
                      <BriefGrid campaigns={filteredBriefs} />
                    </Box>
                  </Grid>
                </TabPanel>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction='row'
                justifyContent='center'
                spacing={2}
              >
                <TabPanel currentTab={tab} index={1}>
                  <Grid item xs={12}>
                    <CampaignGrid campaigns={filteredCampaigns} />
                  </Grid>
                </TabPanel>
                {liveData?.getManagedServices &&
                  liveData?.getManagedServices.length >= 1 && (
                    <TabPanel currentTab={tab} index={2}>
                      <Grid xs={12}>
                        <LiveCampaignGrid
                          campaigns={filteredManagedCampaigns}
                        />
                      </Grid>
                    </TabPanel>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
