import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';
import { Router, navigate } from '@reach/router';

import { AddNewCampaignPage } from './pages/AddNewCampaignPage';
import { AuthContext } from '@adludio/react-sso';
import { BriefPage } from './pages/BriefPage';
import { CampaignsPage } from './pages/CampaignsPage';
import React from 'react';
import { SnackbarProvider } from '@adludio/components';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { ErrorPage } from './pages/ErrorPage';
import { LiveCampaignPage } from './pages/LiveCampaignPage';

interface Config {
  env: 'development' | 'staging' | 'production' | 'testing';
}

interface CampaignModuleProps {
  config: Config;
  runningIndependently?: boolean;
}

// TODO use proper react env for environment variables
export let ENV_CONFIGURATIONS: Config;

export default function CampaignModule({
  config: { env },
}: CampaignModuleProps): JSX.Element | null {
  ENV_CONFIGURATIONS = {
    env: env,
  };

  const { auth } = React.useContext(AuthContext);
  if (auth.authStatus !== 'success') {
    return null;
  }

  const envPrefix =
    env === 'development' ? 'dev.' : env === 'production' ? '' : env + '.';

  const link = createHttpLink({
    uri:
      env === 'development'
        ? `http://${envPrefix}api.campaigns.adludio.com/graphql`
        : `https://${envPrefix}api.campaigns.adludio.com/graphql`,
  });

  const wsLink = new WebSocketLink({
    uri:
      env === 'development'
        ? `ws://${envPrefix}api.campaigns.adludio.com/subscriptions`
        : `wss://${envPrefix}api.campaigns.adludio.com/subscriptions`,
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('authToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(link)
  );
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <SnackbarProvider>
        <Router primary={false}>
          <CampaignsPage path='/' envPrefix={envPrefix} />
          <BriefPage
            path='/campaign/:campaignId/:state'
            navigate={navigate}
            env={env}
          />
          <LiveCampaignPage
            path='/reporting/:campaignId/:state'
            env={env}
            navigate={navigate}
          />
          <AddNewCampaignPage path='/add-new-campaign' navigate={navigate} />
          <ErrorPage default notFound />
        </Router>
      </SnackbarProvider>
    </ApolloProvider>
  );
}
