import { ApexOptions } from 'apexcharts';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { formatAxis } from '../../utils/ChartAxis';
import { formatDateString } from '../../utils/date';
import getIcons from './chartIcons';
import { responsiveStyle } from '../../utils/Styles';
export interface DataProps {
  chartTitle?: string,
  categories: string[],
  data1: number[],
  data2: number[],
  y1Label?: string,
  y2Label?: string,
  chart1Type?: string,
  charrt2Type?: string,
  campaignName?: string
}

const DailyTrend = ({ data1, data2, categories, y1Label, y2Label, chartTitle, campaignName }: DataProps) => {

  const downloadFilename = `${campaignName}_${formatDateString(new Date())}`;
  const classes = responsiveStyle();
  const dataSeries = [
    {
      name: y1Label,
      type: 'column',
      data: data1,
    },
    {
      name: y2Label,
      type: 'line',
      data: data2,
    },
  ];
  const options: ApexOptions = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: getIcons(ZoomInIcon, 'Zoomin'),
          zoomout: getIcons(ZoomOutIcon, 'Zoomout'),
          pan: false,
          zoom: getIcons(SelectAllIcon, 'Selection view'),
          reset: getIcons(SettingsBackupRestoreIcon, 'Reset Original view'),
        },
        export: {
          csv: {
            filename: downloadFilename,
            columnDelimiter: ',',
            headerCategory: 'Date',
            headerValue: 'value',

          },
          png: {
            filename: downloadFilename,
          },
          svg: {
            filename: downloadFilename,
          },
        },
      },

    },
    markers: {
      size: 3,
      colors: ['#4E55C3']
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
      tickAmount: 10,
      tickPlacement: 'on',
      labels: {
        format: 'dd MMM'
      }
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center'
    },

    colors: ['#4E55C3', '#7DC3AA', '#58A0DA', '#495373'],
    stroke: {
      width: [0, 1]
    },
    title: {
      text: chartTitle
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1, 2]
    },
    yaxis: [
      {
        decimalsInFloat: 0,
        title: {
          text: y1Label,
        },
        labels: {
          formatter: (val: number, index: number): string => formatAxis(y1Label, val)
        }

      }, {
        opposite: true,
        decimalsInFloat: 0,
        title: {
          text: y2Label
        },
        labels: {
          formatter: (val: number, index: number): string => formatAxis(y2Label, val)
        }
      }
    ]
  };
  return (
    <div className={classes.chartsPadding}>
      <ReactApexChart options={options}
        series={dataSeries} height={500} width='100%'
      />
    </div>

  );
};

export default DailyTrend;
