import * as React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { responsiveStyle } from '../../utils/Styles';

interface OptionsProps {
  idKey: string;
  handleSelectOption: any;
  selectedValue: string;
  dropdownOptions: string[];
}
const DropdownOptions = ({
  idKey,
  handleSelectOption,
  selectedValue,
  dropdownOptions,
}: OptionsProps) => {
  const classes = responsiveStyle();
  return (
    <TextField
      className={classes.optionsStyle}
      size='small'
      variant='outlined'
      id={idKey}
      select
      label=''
      value={selectedValue}
      onChange={handleSelectOption}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
    >
      {dropdownOptions.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};
export default DropdownOptions;
