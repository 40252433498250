import { Box, Grid } from '@adludio/components';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { navigate } from '@reach/router';
import { useGetCampaignQuery } from '../generated/graphql';
import ViewCampaignBrief from './ViewCampaignBrief';
import { CampaignBriefEdit } from './CampaignBriefEdit';

const ViewCampaignResellerBrief = ({
  campaignId,
  setEditCampaignBrief,
  setBriefSubmitted,
  editCampaignBrief,
}: {
  campaignId?: string;
  setEditCampaignBrief: Dispatch<SetStateAction<boolean>>;
  setBriefSubmitted: Dispatch<SetStateAction<boolean>>;
  editCampaignBrief: boolean;
}) => {
  const { data } = useGetCampaignQuery({
    variables: { id: campaignId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!campaignId) navigate('/campaigns').catch(console.log);
  });

  const [isMediaCampaign, setIsMediaCampaign] = useState<boolean | null>(null);
  useEffect(() => {
    data?.getCampaignById?.resellerBrief &&
      setIsMediaCampaign(data?.getCampaignById?.resellerBrief?.isManaged!);
  }, [data]);

  const isSubmitted = data?.getCampaignById?.resellerBrief?.isSubmitted;

  return (
    <Grid container direction='column'>
      <Box mt='2rem'>
        <Grid container alignItems='center' direction='column'>
          <Grid container item xs={12}>
            {isSubmitted ? (
              <ViewCampaignBrief
                campaignId={campaignId}
                setEditCampaignBrief={setEditCampaignBrief}
                editCampaignBrief={editCampaignBrief}
              />
            ) : (
              <CampaignBriefEdit
                campaignId={campaignId!}
                isMediaCampaign={isMediaCampaign}
                onCancel={() => setEditCampaignBrief(false)}
                setBriefSubmitted={setBriefSubmitted}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ViewCampaignResellerBrief;
