const emailErrors = {
  disallowedEmail: {
    name: 'disallowedEmail',
    message: 'One or more email addresses are invalid.',
  },
  invalidEmail: {
    name: 'invalidEmail',
    message: 'One or more email addresses are invalid.',
  },
  noEmails: { name: 'noEmails', message: 'You must add at least 1 recipient' },
};


export interface ErrorType<K extends string> {
      name: K;
      message: string
    }

export type EmailError = {
      [K in keyof (typeof emailErrors)]: ErrorType<K>
    }

export type ErrorName = keyof EmailError

export default emailErrors as EmailError;
