import React from 'react';

import { Grid, Body1, H6 } from '@adludio/components';

import { CompanyProfileProps } from './CompanyProfileEdit';
import ProfileView from '../ProfileView';
import { SuccessAuthType, useAuth } from '@adludio/react-sso';
import { initialConfig } from '../../../index';

const CompanyProfileView = (props: CompanyProfileProps) => {
  const {
    user: { company },
  } = useAuth(initialConfig.ssoBEURL) as SuccessAuthType;

  const avatar = {
    text: 'logo',
    bgColor: '#212121',
    fontColor: '#fff',
  };

  return (
    <ProfileView switchView={props.switchView} avatar={avatar}>
      <Grid container item direction='column' justify='center'>
        <Grid item xs>
          <H6>{company.name}</H6>
          <Body1>{company.industry}</Body1>
        </Grid>
      </Grid>
    </ProfileView>
  );
};

export default CompanyProfileView;
