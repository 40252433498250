import {
  Theme,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core';

export const responsiveStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    breadcrumbs: {
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    container: {
      width: '100%',
      display: 'block',
    },
    chartsPadding: {
      paddingTop: '10px',
    },
    centerListItems: {
      display: 'flex',
      justifyContent: 'center',
    },
    appBarColor: {
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: '4px',
      color: '#000',
    },
    summaryBar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    link: {
      display: 'flex',
    },
    listItemText: {
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    listIcon: {
      minWidth: theme.spacing(0.25),
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    toggleButtons: {
      padding: '10px 0px 0px 10px',
    },
    wordcloudTitle: {
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial, sans-serif',
      fontWeight: 'bolder',
    },
    wordCloudText: {
      width: '100%',
      fontSize: '12px',
      textAlign: 'center',
    },
    toolbar: {
      padding: '10px',
    },
    iconText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
    },
    tabs: {
      flexDirection: 'row-reverse',
    },
    tabsLabel: {
      color: theme.palette.secondary.dark,
      paddingLeft: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    autocomplete: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignContent: 'flex-start',
      marginLeft: 0,
      paddingLeft: 0,
      '& ul': { padding: 0 },
    },
    centeredItems: {
      justifyContent: 'center',
      fontSize: '16px',
    },
    scroller: {
      flexGrow: 0,
    },
    h6Tpyos: {
      fontSize: '15px',
      paddingBottom: '10px',
    },
    fullWidthPaper: {
      width: '100%',
    },
    wrapText: {
      whiteSpace: 'unset',
      flexDirection: 'row',
      wordBreak: 'break-all',
    },
    proportionPlot: {
      height: 437,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 50,
      paddingLeft: 30,
    },
    overviewSection: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      bgcolor: 'background.paper',
      overflow: 'auto',
      '& ul': { padding: 0 },
    },
    sharePie: {
      padding: '15px 0px',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& div:not([class])': {
        width: 'unset',
      },
      '& .apexcharts-legend': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
      },
    },
    stackedBar: {
      padding: '16px',
    },
    portionDonut: {
      display: 'flex',
      justifyContent: 'center !important',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    siteCharts: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      margin: 0,
      padding: 0,
    },
    wordCloud: {
      height: 400,
      width: 800,
      padding: 0,
      marginLeft: 0,
      justifyContent: 'flex-start',
    },
    group: {
      width: 'auto',
      height: 'auto',
    },
    optionsStyle: {
      minWidth: '200px',
      maxWidth: '350px',
      paddingRight: theme.spacing(5),
    },
    spacedGrid: {
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      flexContainer: {
        flexWrap: 'wrap',
      },
      autocomplete: {
        flexWrap: 'wrap',
      },
      siteCharts: {
        flex: 1,
        flexDirection: 'column',
        flexWrap: 'wrap',
      },
      spacedGrid: {
        paddingRight: theme.spacing(2),
        margin: 'auto',
        height: 530,
      },
      wrapText: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'flex-start',
      },
      listItem: {
        width: 'auto',
      },
      summaryBar: {
        display: 'flex',
        justifyContent: 'center',
      },
      group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      optionsStyle: {
        width: '200px',
        paddingTop: theme.spacing(2),
      },
      wordCloud: {
        height: 900,
      },
    },
    horizontalPadding5: {
      paddingInline: '5px',
    },
    horizontalPadding10: {
      paddingInline: '10px',
    },
    horizontalPadding15: {
      paddingInline: '15px',
    },
    horizontalPadding20: {
      paddingInline: '15px',
    },
    horizontalPadding25: {
      paddingInline: '15px',
    },
  })
);

export const getMuiTheme = () =>
  createTheme({
    overrides: {
      MUIDataTableHeadCell: {
        data: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': {
            backgroundColor: '#EBECF0',
          },
        },
      },
      MUIDataTableToolbar: {
        icon: {
          color: '#8381F7',
          '&:hover': {
            color: '#8381F7',
          },
        },
      },
    },
  });
