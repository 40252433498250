import { AdludioMenuItem } from '@adludio/components';

export interface AdunitMenuItem extends AdludioMenuItem {
  width: number;
  height: number;
}

export const adunitTypes: AdunitMenuItem[] = [
  {
    label: 'Custom size',
    width: 320,
    height: 480,
    value: '0',
  },
  {
    label: 'FullScreen 320x480',
    key: '3',
    value: '3',
    width: 320,
    height: 480,
  },
  {
    label: 'MPU 300X250',
    key: '5',
    value: '5',
    width: 300,
    height: 250,
  },
  {
    label: 'DMPU 300X600',
    key: '7',
    value: '7',
    width: 300,
    height: 600,
  },
  {
    label: 'VPAID horizontal banner',
    key: '10',
    value: '10',
    width: 728,
    height: 90,
  },
  { label: '320X50', key: '13', value: '13', width: 320, height: 50 },
];
