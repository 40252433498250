const pageTitle = (path: string) => {
  switch (path) {
    case '/ATE':
      return 'ATE';
    case '/tag-builder':
      return 'Tag Builder';
    case '/support':
      return 'Support';
    case '/my-account':
      return 'My Account';
    default:
      return '';
  }
};

export default pageTitle;
