import React, { useEffect, useMemo, useState } from 'react';

import {
  Grid,
  TextInput,
  Subtitle1,
  H6,
  IconButton,
  Link,
  Body2,
  DatePicker,
  Chip,
  MenuItem,
} from '@adludio/components';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Radio,
  TextField,
  RadioGroup,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import AddCircle from '@material-ui/icons/AddCircle';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@material-ui/icons/Delete';

import countryList from 'react-select-country-list';

import { ErrorMessage, FormikProps } from 'formik';
import { mockObjectives } from '../../../mock-data/mockObjectives';
import { mockCurrencies } from '../../../mock-data/mockCurrencies';
import { mockKPIs } from '../../../mock-data/mockKPIs';
import { LiveFormValues } from '../types';
import { preventBriefExit } from '../../../helpers/preventBriefExit';
import { validateLink } from '../../../helpers/validateLink';
import { joinWithoutEmpty } from './CreativeForm';
import { liveStyles as styles } from '../styles';
import { mockCostCentre } from '../../../mock-data/mockCostCentre';
import { LiveUploads } from './LiveUploads';
import { mockSales } from '../../../mock-data/mockSales';
import { LiveUploadsMultiple } from './LiveUploadsMultiple';

export const LiveScreen = ({
  formikProps,
  wasSaved,
  campaignId,
}: {
  formikProps: FormikProps<LiveFormValues>;
  wasSaved: boolean;
  campaignId: string;
}) => {
  const { errors, values, handleChange, setFieldValue } = formikProps;
  const { column, helperText, purple, addLink, menuPaper, dropdown, fieldSet } =
    styles();

  const blockExit = preventBriefExit(wasSaved, formikProps.values);
  const [openAssetUploader, setOpenAssetUploader] = useState('');
  const [openContactEmailInput, setOpenContactEmailInput] = useState('');
  const [openPlacementInput, setOpenPlacementInput] = useState(false);
  const [openIOUploader, setOpenIOUploader] = useState('');
  const [openAudienceListUploader, setOpenAudienceListUploader] = useState('');
  const [agencyFee, setAgencyFee] = useState('');
  const [trackingTags, setTrackingTags] = useState('');

  useEffect(() => {
    blockExit;
  }, [formikProps.values, wasSaved]);

  const handleAssetRadio = (uploadAsset: string) => {
    if (uploadAsset !== 'uploadAsset') setFieldValue('assetFiles', uploadAsset);
    setOpenAssetUploader(uploadAsset);
  };

  const handleContactInput = (contact: string) => {
    if (contact === 'contactEmail') {
      setFieldValue('contactEmail', '');
      setOpenContactEmailInput('contactEmail');
    } else {
      setFieldValue('contactEmail', contact);
      setOpenContactEmailInput('noContactEmail');
    }
  };

  const handleIORadio = (uploadIO: string) => {
    if (uploadIO === 'uploadIO') {
      setOpenIOUploader(uploadIO);
    } else {
      setFieldValue('IO', uploadIO);
      setOpenIOUploader(uploadIO);
    }
  };

  const handleAudienceRadio = (audience: string) => {
    if (audience === 'uploadAudience') {
      setOpenAudienceListUploader(audience);
    } else {
      setFieldValue('audienceList', audience);
      setOpenAudienceListUploader(audience);
    }
  };

  const handleTagsUploader = (tag: string) => {
    if (tag === 'uploadTrackingTags') {
      setTrackingTags(tag);
    } else {
      setFieldValue('trackingTags', tag);
      setTrackingTags(tag);
    }
  };

  const ALPHA_NUMERIC_DASH_REGEX = /(?<=^| )\d+(\.\d+)?(?=$| ) /;

  const countries = useMemo(() => countryList().getData(), []);
  const getValue = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((label, i) => {
      const countryItem = countries.find((country) => country.label === label);
      countryItem?.value
        ? countriesList.push(countryItem.label)
        : countriesList.push(label);
    });
    return countriesList.filter((e) => e);
  };
  const getCountries = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((label, i) => {
      const countryItem = countries.find((country) => country.label === label);
      countryItem?.label
        ? countriesList.push(countryItem.label)
        : countriesList.push(label);
    });
    return countriesList.filter((e) => e);
  };

  return (
    <Box width='100%' justifyContent='center' mb='4rem'>
      <Grid className={column} container justify='center' xs={12}>
        <Grid item xs={10} md={8}>
          <Box mt='3rem'>
            <Subtitle1 className={purple}>Brand Name*</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='brandName'
            name='brandName'
            variant='outlined'
            value={values.brandName}
            onChange={handleChange}
            required
            error={!!errors.brandName}
            helperText='Please search the Commercial:Clients:Brands folder to see if we have already worked with the brand, and use the same exact name as you see there if we have.'
          />
        </Grid>
        <Grid item xs={10} md={8}>
          <Box mt='1rem'>
            <Subtitle1 className={purple}>Campaign Name*</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='name'
            name='name'
            variant='outlined'
            value={values.name}
            onChange={handleChange}
            required
            error={!!errors.name}
            helperText={errors.name}
          />
          <ErrorMessage name='required' />
        </Grid>
        <Grid xs={10} md={8}>
          <Box mt='1rem' />
          <H6 className={purple}>Creative Details</H6>
          <Box>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box mt='1rem'>
            <Subtitle1 className={purple}>Description*</Subtitle1>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <TextInput
            required
            variant='outlined'
            rows={4}
            multiline
            name='description'
            FormHelperTextProps={{
              className: helperText,
            }}
            inputProps={{ maxLength: 1000 }}
            value={values.description}
            onChange={handleChange}
            error={!!errors.description}
            helperText={
              errors.description ?? 'The main creative details should go here'
            }
          />
        </Grid>
        <Grid item xs={10} md={8}>
          <FormControl required error={!!errors.objective} component='fieldset'>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>Campaign Objectives</FormLabel>
            </Box>
            <FormGroup>
              {mockObjectives.map((o) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.objective?.includes(o.value)}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'objective',
                              values.objective?.concat(`${e.target.value},`)
                            )
                          : setFieldValue(
                              'objective',
                              values.objective?.replace(
                                `${e.target.value},`,
                                ''
                              )
                            );
                      }}
                      name='objective'
                      value={o.value}
                    />
                  }
                  label={o.label}
                />
              ))}
            </FormGroup>
            {errors.objective && (
              <FormHelperText>{errors.objective}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={10} md={8}>
          <FormControl required error={!!errors.KPI} component='fieldset'>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>KPIs</FormLabel>
            </Box>
            <FormGroup>
              {mockKPIs.map((kpi) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.KPI?.includes(kpi.value)}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'KPI',
                              values.KPI?.concat(`${e.target.value},`)
                            )
                          : setFieldValue(
                              'KPI',
                              values.KPI?.replace(`${e.target.value},`, '')
                            );
                      }}
                      name='KPI'
                      value={kpi.value}
                    />
                  }
                  label={kpi.label}
                />
              ))}
            </FormGroup>
            {errors.objective && (
              <FormHelperText>{errors.objective}</FormHelperText>
            )}
            {errors.KPI && <FormHelperText>{errors.KPI}</FormHelperText>}
          </FormControl>
          <Grid item xs={10} md={8}>
            <Box mt='1.5rem' mb='1rem'>
              <Subtitle1 className={purple}>Web Links*</Subtitle1>
            </Box>
          </Grid>
          {values.websiteUrl?.split(',').map((url, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box width='100%' key={index} my='1rem'>
              <Grid container item xs justify='space-between'>
                <Grid item xs={10}>
                  <TextInput
                    id='websiteUrl'
                    name='websiteUrl'
                    variant='outlined'
                    label='Brand website links'
                    value={url.trim()}
                    onChange={(e) => {
                      if (
                        e.target.value[e.target.value.length - 1] === ',' ||
                        e.target.value[e.target.value.length - 1] === ' '
                      )
                        return;
                      const newValue = values.websiteUrl
                        ? values.websiteUrl?.split(',')
                        : [''];
                      newValue[index] = e.target.value;
                      setFieldValue('websiteUrl', joinWithoutEmpty(newValue));
                    }}
                    required
                    error={
                      (url.trim() !== '' && !validateLink(url)) ||
                      !!errors.websiteUrl
                    }
                    helperText={
                      errors.websiteUrl ??
                      (url.trim() !== '' &&
                        !validateLink(url) &&
                        'Introduce a valid URL')
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  {values.websiteUrl?.split(',').length > 1 && (
                    <Box pt='0.7rem'>
                      <IconButton
                        onClick={() => {
                          const newValue = values.websiteUrl.split(',');
                          newValue.splice(index, 1);
                          setFieldValue(
                            'websiteUrl',
                            joinWithoutEmpty(newValue)
                          );
                        }}
                        label=''
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))}
          {!values.websiteUrl?.split(',').includes(' ') &&
            !values.websiteUrl?.split(',').includes('') && (
              <Grid container item xs={12}>
                <Body2>
                  <Link
                    className={addLink}
                    onClick={() =>
                      setFieldValue(
                        'websiteUrl',
                        joinWithoutEmpty(
                          values.websiteUrl.concat(', ').split(',')
                        )
                      )
                    }
                  >
                    <Grid container item xs alignItems='center'>
                      <Box mr='0.3rem'>
                        <AddCircle />
                      </Box>

                      <Body2>Add another URL</Body2>
                    </Grid>
                  </Link>
                </Body2>
              </Grid>
            )}
          <Grid xs={10} md={8}>
            <FormControl
              component='fieldset'
              error={!!errors.assetFiles}
              className={fieldSet}
            >
              <Box mt='1.5rem' mb='1rem'>
                <FormLabel component='legend'>Assets* </FormLabel>
              </Box>
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                name='radio-buttons-group'
                value={openAssetUploader}
                onChange={(e) => handleAssetRadio(e.target.value)}
              >
                <FormControlLabel
                  value='uploadAsset'
                  control={<Radio color='primary' />}
                  label='Upload Assets'
                />
                <FormControlLabel
                  value='noAssets'
                  control={<Radio color='primary' />}
                  label='No assets provided'
                />
              </RadioGroup>
              {errors.assetFiles && (
                <FormHelperText>{errors.assetFiles}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {openAssetUploader === 'uploadAsset' && (
            <LiveUploadsMultiple
              id={campaignId}
              setLink={(linkData) => {
                formikProps.setFieldValue('assetsUrl', linkData);
              }}
            />
          )}
        </Grid>
        <Grid xs={10} md={8}>
          <Box mt='1rem'>
            <H6 className={purple}>Client Contact Details</H6>
          </Box>
          <Box>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Who booked this?* </Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='bookedBy'
            name='bookedBy'
            variant='outlined'
            placeholder='Please input the name of the brand or agency'
            value={values.bookedBy}
            onChange={handleChange}
            required
            error={!!errors.bookedBy}
            helperText={errors.bookedBy && errors.bookedBy}
          />
        </Grid>
        <Grid xs={10} md={8}>
          <Box my='1.5rem'>
            <Subtitle1 className={purple}>Company Address* </Subtitle1>
          </Box>
        </Grid>

        <Grid container xs={10} md={8}>
          <TextInput
            id='companyAddress'
            name='companyAddress'
            variant='outlined'
            placeholder='Type the address'
            value={values.companyAddress}
            onChange={handleChange}
            required
            error={!!errors.companyAddress}
            helperText={errors.companyAddress && errors.companyAddress}
          />
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl component='fieldset' error={!!errors.contactEmail}>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>Finance Contact Email* </FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={openContactEmailInput}
              onChange={(e) => handleContactInput(e.target.value)}
            >
              <FormControlLabel
                value='contactEmail'
                control={<Radio color='primary' />}
                label='I have the finance contact email'
              />
              <FormControlLabel
                value='noContactEmail'
                control={<Radio color='primary' />}
                label='I do not have the finance contact email'
              />
            </RadioGroup>
            {openContactEmailInput === 'contactEmail' && (
              <Grid container>
                <TextInput
                  id='contactEmail'
                  name='contactEmail'
                  variant='outlined'
                  placeholder='example@example.com'
                  value={values.contactEmail}
                  onChange={handleChange}
                  required
                  error={!!errors.contactEmail}
                />
              </Grid>
            )}
            {errors.contactEmail && (
              <FormHelperText>{errors.contactEmail}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={10} md={8}>
          <Box mt='1.5rem'>
            <H6 className={purple}>Campaign Details</H6>
          </Box>
          <Box mb='1rem'>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl component='fieldset' error={!!errors.placement}>
            <FormLabel component='legend'>Placement* </FormLabel>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={openPlacementInput}
            >
              <FormControlLabel
                value='320x480 (fullscreen mobile / interstitial)'
                control={
                  <Checkbox
                    checked={values.placement?.includes(
                      '320x480 (fullscreen mobile / interstitial)'
                    )}
                    onChange={(e) => {
                      e.target.checked
                        ? setFieldValue(
                            'placement',
                            values.placement?.concat(`${e.target.value},`)
                          )
                        : setFieldValue(
                            'placement',
                            values.placement?.replace(`${e.target.value},`, '')
                          );
                    }}
                    name='placement'
                    value='320x480 (fullscreen mobile / interstitial)'
                  />
                }
                label='320x480 (fullscreen mobile / interstitial)'
              />
              <FormControlLabel
                value='300x250 (MPU)'
                control={
                  <Checkbox
                    checked={values.placement?.includes('300x250 (MPU)')}
                    onChange={(e) => {
                      e.target.checked
                        ? setFieldValue(
                            'placement',
                            values.placement?.concat(`${e.target.value},`)
                          )
                        : setFieldValue(
                            'placement',
                            values.placement?.replace(`${e.target.value},`, '')
                          );
                    }}
                    name='placement'
                    value='300x250 (MPU)'
                  />
                }
                label='300x250 (MPU)'
              />
              <FormControlLabel
                value='Social'
                control={
                  <Checkbox
                    checked={values.placement?.includes('Social')}
                    onChange={(e) => {
                      e.target.checked
                        ? setFieldValue(
                            'placement',
                            values.placement?.concat(`${e.target.value},`)
                          )
                        : setFieldValue(
                            'placement',
                            values.placement?.replace(`${e.target.value},`, '')
                          );
                    }}
                    name='placement'
                    value='Social'
                  />
                }
                label='Social'
              />
              <FormControlLabel
                value=''
                control={
                  <Checkbox
                    checked={openPlacementInput}
                    onChange={(e) => setOpenPlacementInput(e.target.checked)}
                    name='placement'
                    value='Other'
                  />
                }
                label='Other'
              />
            </RadioGroup>
            {openPlacementInput && (
              <Grid container xs={10} md={8}>
                <TextInput
                  id='placement'
                  name='placement'
                  variant='outlined'
                  placeholder='Please type the placement dimesion'
                  value={
                    values.placement?.includes('Other:')
                      ? values.placement.substring(
                          values.placement.indexOf('O') + 6,
                          values.placement.indexOf(';')
                        )
                      : ''
                  }
                  onChange={(e) => {
                    if (values.placement?.includes('Other:')) {
                      var mySubString = values.placement?.substring(
                        values.placement.indexOf('O'),
                        values.placement.lastIndexOf(';') + 1
                      );
                      if (e.target.value.length === 0) {
                        setFieldValue(
                          'placement',
                          values.placement?.replace(mySubString, '')
                        );
                        return;
                      }
                      setFieldValue(
                        'placement',
                        values.placement?.replace(
                          mySubString,
                          `Other:${e.target.value};`
                        )
                      );
                    } else {
                      setFieldValue(
                        'placement',
                        values.placement?.concat(`Other:${e.target.value};`)
                      );
                    }
                  }}
                  required
                  error={!!errors.placement}
                />
              </Grid>
            )}
            {errors.placement && (
              <FormHelperText>{errors.placement}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid container xs={10} md={8}>
          <Grid item xs={10} md={8}>
            <Box mt='1.5rem' mb='1rem'>
              <Subtitle1 className={purple}>Campaign Dates* </Subtitle1>
            </Box>
          </Grid>
          <Grid xs={6} md={5}>
            <Box width='90%' mr='1.5rem'>
              <DatePicker
                required
                inputVariant='outlined'
                fullWidth
                format='DD-MM-YYYY'
                label='Start Date'
                autoOk
                name='startDate'
                value={values.startDate}
                onChange={(time) => {
                  setFieldValue('startDate', time.toISOString().split('T')[0]);
                }}
                error={!!errors.startDate}
                helperText={errors.startDate}
              />
            </Box>
          </Grid>
          <Grid container justify='flex-end' xs={6} md={5}>
            <Box width='100%'>
              <DatePicker
                required
                name='endDate'
                inputVariant='outlined'
                fullWidth
                format='DD-MM-YYYY'
                label='End Date'
                autoOk
                minDate={values.startDate}
                value={values.endDate}
                onChange={(time) =>
                  setFieldValue('endDate', time.toISOString().split('T')[0])
                }
                error={!!errors.endDate}
                helperText={errors.endDate}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container xs={10} md={8}>
          <Grid item xs={10} md={8}>
            <Box mt='1.5rem' mb='1rem'>
              <Subtitle1 className={purple}>Target Audience*</Subtitle1>
            </Box>
          </Grid>
          <TextInput
            id='audience'
            fullWidth
            name='audience'
            variant='outlined'
            value={values.audience}
            onChange={handleChange}
            required
            rows={4}
            multiline
            error={!!errors.audience}
            helperText={errors.audience ?? 'Add target audience groups here.'}
          />
        </Grid>
        <Grid container xs={10} md={8} direction='column'>
          <Box mb='1rem' mt='1.5rem'>
            <Subtitle1 className={purple}>Serving Information</Subtitle1>
          </Box>
          <Autocomplete
            value={
              typeof values.geographies === 'string'
                ? getCountries(values.geographies!.split(','))
                : []
            }
            onChange={(event: React.ChangeEvent<{}>, newValue: string[]) => {
              const countriesValues = getValue(newValue);
              setFieldValue('geographies', countriesValues.join(',') as string);
            }}
            multiple
            id='geographies'
            options={countries.map((option) => option.label)}
            freeSolo
            renderTags={(
              value: string[],
              getTagProps: AutocompleteGetTagProps
            ) =>
              value.map((option: string, index: number) => (
                <Chip key={option} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name='geographies'
                required
                variant='outlined'
                label='Serving Locations'
                placeholder='Search'
                inputProps={{
                  ...params.inputProps,
                  required: values.geographies?.length === 0,
                }}
                error={!!errors.geographies}
                helperText={errors.geographies}
              />
            )}
          />
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl
            component='fieldset'
            error={!!errors.IO}
            className={fieldSet}
          >
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>IO included?* </FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={openIOUploader}
              onChange={(e) => handleIORadio(e.target.value)}
            >
              <FormControlLabel
                value='uploadIO'
                control={<Radio color='primary' />}
                label='Upload IO'
              />
              <FormControlLabel
                value='IO coming later'
                control={<Radio color='primary' />}
                label='IO coming later'
              />
              <FormControlLabel
                value='IO in hand'
                control={<Radio color='primary' />}
                label='IO in hand'
              />
            </RadioGroup>
            {openIOUploader === 'uploadIO' && (
              <LiveUploads
                id={campaignId}
                setLink={(linkData) => {
                  formikProps.setFieldValue('IO', linkData);
                }}
              />
            )}
            {errors.IO && <FormHelperText>{errors.IO}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl
            component='fieldset'
            error={!!errors.audienceList}
            className={fieldSet}
          >
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>
                Exclusion/Inclusion/audience list*
              </FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={openAudienceListUploader}
              onChange={(e) => handleAudienceRadio(e.target.value)}
            >
              <FormControlLabel
                value='uploadAudience'
                control={<Radio color='primary' />}
                label='Upload Exclusion/Inclusion/audience list'
              />
              <FormControlLabel
                value='No exclusion/Inclusion/audience list for this campaign'
                control={<Radio color='primary' />}
                label='No exclusion/Inclusion/audience list for this campaign'
              />
              <FormControlLabel
                value='Exclusion/Inclusion/audience list will be provided later'
                control={<Radio color='primary' />}
                label='Exclusion/Inclusion/audience list will be provided later'
              />
            </RadioGroup>
            {errors.audienceList && (
              <FormHelperText>{errors.audienceList}</FormHelperText>
            )}
            {openAudienceListUploader === 'uploadAudience' && (
              <LiveUploads
                id={campaignId}
                setLink={(linkData) => {
                  formikProps.setFieldValue('audienceList', linkData);
                }}
              />
            )}
          </FormControl>
        </Grid>
        <Grid container xs={10} md={8} direction='column'>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Cost Centre*</Subtitle1>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box width='100%'>
            <FormControl
              variant='outlined'
              className={dropdown}
              error={!!errors.costCentre}
            >
              <Select
                value={values.costCentre}
                placeholder='Select from dropdown'
                name='costCentre'
                required
                onChange={handleChange}
                error={!!errors.costCentre}
              >
                {mockCostCentre.map((c) => (
                  <MenuItem value={c.value} key={c.key}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.costCentre && (
                <FormHelperText>{errors.costCentre}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
        <Grid container xs={10} md={8} direction='column'>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Currency*</Subtitle1>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box width='100%'>
            <FormControl variant='outlined' className={dropdown}>
              <Select
                value={values.currency}
                name='currency'
                required
                onChange={handleChange}
              >
                {mockCurrencies.map((c) => (
                  <MenuItem value={c.value} key={c.key}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Gross Cost/Budget*</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            required
            variant='outlined'
            onKeyDown={(event) => {
              if (
                ALPHA_NUMERIC_DASH_REGEX.test(event.key) ||
                event.key === 'e' ||
                event.key === 'E'
              ) {
                event.preventDefault();
              }
            }}
            type='number'
            name='budget'
            value={values.budget}
            onChange={handleChange}
            error={!!errors.budget}
            helperText={errors.budget}
          />
        </Grid>

        <Grid item xs={10} md={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Buy Rate (CPE)</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='CPE'
            name='CPE'
            variant='outlined'
            value={values.CPE}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={10} md={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Volume agreed</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='volume'
            name='volume'
            variant='outlined'
            value={values.volume}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl component='fieldset'>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>Agency Fee </FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={agencyFee}
              onChange={(e) => setAgencyFee(e.target.value)}
            >
              <FormControlLabel
                value='flatFee'
                control={<Radio color='primary' />}
                label='Flat Fee'
              />
              <FormControlLabel
                value='percentage'
                control={<Radio color='primary' />}
                label='Percentage'
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {agencyFee === 'flatFee' && (
          <Grid container xs={10} md={8}>
            <TextInput
              required
              variant='outlined'
              name='flatFee'
              value={values.flatFee}
              onChange={handleChange}
              placeholder='Flat Fee'
            />
          </Grid>
        )}
        {agencyFee === 'percentage' && (
          <Grid container xs={10} md={8}>
            <TextInput
              required
              variant='outlined'
              name='percentage'
              value={values.percentage}
              onChange={handleChange}
              placeholder='Percentage'
            />
          </Grid>
        )}
        <Grid item xs={10} md={8}>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>Net Cost</Subtitle1>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            required
            variant='outlined'
            name='newCost'
            value={values.newCost}
            onChange={handleChange}
            placeholder='New Cost'
          />
        </Grid>
        <Grid xs={10} md={8}>
          <Box mt='1.5rem'>
            <H6 className={purple}>Extra Information</H6>
          </Box>
          <Box mb='1rem'>
            <Divider></Divider>
          </Box>
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl
            component='fieldset'
            error={!!errors.trackingTags}
            className={fieldSet}
          >
            <Box mt='0.5rem' mb='1rem'>
              <FormLabel component='legend'>Tracking Tags*</FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={trackingTags}
              onChange={(e) => handleTagsUploader(e.target.value)}
            >
              <FormControlLabel
                value='uploadTrackingTags'
                control={<Radio color='primary' />}
                label='Upload tracking tags'
              />
              <FormControlLabel
                value='No tracking tags for this campaign'
                control={<Radio color='primary' />}
                label='No tracking tags for this campaign'
              />
              <FormControlLabel
                value='Tracking tags will be provided later'
                control={<Radio color='primary' />}
                label='Tracking tags will be provided later'
              />
            </RadioGroup>
            {trackingTags === 'uploadTrackingTags' && (
              <LiveUploads
                id={campaignId}
                setLink={(linkData) => {
                  formikProps.setFieldValue('trackingTags', linkData);
                }}
              />
            )}
            {errors.trackingTags && (
              <FormHelperText>{errors.trackingTags}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid xs={10} md={8}>
          <FormControl component='fieldset' error={!!errors.standardCampaign}>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>
                Is This a Standard Campaign?*
              </FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={values.standardCampaign}
              onChange={(e) =>
                setFieldValue('standardCampaign', e.target.value)
              }
            >
              <FormControlLabel
                value='Standard CPE'
                control={<Radio color='primary' />}
                label='Standard CPE'
              />
              <FormControlLabel
                value='Not CPE'
                control={<Radio color='primary' />}
                label='Not CPE'
              />
              <FormControlLabel
                value='Creative only'
                control={<Radio color='primary' />}
                label='Creative only'
              />
            </RadioGroup>
            {errors.trackingTags && (
              <FormHelperText>{errors.trackingTags}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl component='fieldset' error={!!errors.clientType}>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>Type of Client*</FormLabel>
            </Box>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={values.clientType}
              onChange={(e) => setFieldValue('clientType', e.target.value)}
            >
              <FormControlLabel
                value='Normal client'
                control={<Radio color='primary' />}
                label='Normal client'
              />
              <FormControlLabel
                value='Subscription client'
                control={<Radio color='primary' />}
                label='Subscription client'
              />
            </RadioGroup>
            {errors.clientType && (
              <FormHelperText>{errors.clientType}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid xs={10} md={8}>
          <FormControl component='fieldset'>
            <Box mt='1.5rem' mb='1rem'>
              <FormLabel component='legend'>
                Does this brief qualify for a non-standard build?
              </FormLabel>
            </Box>

            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              name='radio-buttons-group'
              value={values.standardBuild}
              onChange={(e) => setFieldValue('standardBuild', e.target.value)}
            >
              <FormControlLabel
                value='Standard build'
                control={<Radio color='primary' />}
                label='Standard build'
              />
              <FormControlLabel
                value='Non-standard build'
                control={<Radio color='primary' />}
                label='Non-standard build'
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container xs={10} md={8} direction='column'>
          <Box mt='1.5rem'>
            <Subtitle1 className={purple}>
              Adludio Sales Representative*
            </Subtitle1>
          </Box>
        </Grid>
        <Grid item xs={10} md={8}>
          <Box width='100%'>
            <FormControl
              variant='outlined'
              className={dropdown}
              error={!!errors.salesEmail}
            >
              <Select
                value={values.salesEmail}
                MenuProps={{ classes: { paper: menuPaper } }}
                placeholder='Select from dropdown'
                name='salesEmail'
                required
                onChange={handleChange}
              >
                {mockSales.map((c) => (
                  <MenuItem value={c.value} key={c.key}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.salesEmail && (
                <FormHelperText>{errors.salesEmail}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Grid>
        <Grid container xs={10} md={8}>
          <Grid item xs={10} md={8}>
            <Box mt='1.5rem' mb='1rem'>
              <Subtitle1 className={purple}>Additional Information</Subtitle1>
            </Box>
          </Grid>
          <TextInput
            id='additionalInfo'
            fullWidth
            name='additionalInfo'
            variant='outlined'
            value={values.additionalInfo}
            onChange={handleChange}
            required
            rows={4}
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
