/* eslint react/prop-types: 0 */
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  ChipInput,
  Grid,
  makeStyles,
  Chip,
} from '@adludio/components';
import emailErrors, { ErrorName } from './emailErrors';
import { validateEmail } from '../../helpers/validateEmail';

import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { ChipRendererProps } from '@adludio/components/dist/Inputs/ChipInput';
import EmailIcon from '@material-ui/icons/Email';
import { Recipient } from './';

interface MyTeamProps {
  submitCallback: (data: { emails: string[] }) => Promise<any>;
  loading: boolean;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  element: {
    width: '20rem',
  },
}));
const chipRenderer = (chipProps: ChipRendererProps) => {
  return <Chip style={{ maxWidth: 280 }} {...chipProps} size='small' />;
};

const InviteTeam: React.FC<MyTeamProps> = ({ submitCallback, loading }) => {
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [errorKey, setErrorKey] = useState<ErrorName | null>(null);
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);

  let emails = recipients.map((r) => r.value);

  // chip controllers

  useEffect(() => {
    if (!recipients.length && userHasInteracted) {
      return setErrorKey('noEmails');
    }
    if (recipients.some(recipientHasError)) {
      return setErrorKey('invalidEmail');
    }
    return setErrorKey(null);
  }, [recipients, userHasInteracted]);

  // Check if individual recipient has an error
  const recipientHasError = (val: Recipient) => !!val.error;

  const handleDeleteChip = (deletedChip: Recipient) => {
    setRecipients(
      recipients.filter((recipient) => recipient.key !== deletedChip.key)
    );
  };

  // Add a recipient to the recipients array.
  const addRecipient = (email: string) => {
    // Delete spaces
    email = email.trim();
    // Check it is a valid email address.
    if (validateEmail(email)) {
      setRecipients([
        ...recipients,
        {
          value: email,
          key: email,
          // If emails are blocked by user, add an error and render a red chip.
          error: null,
        },
      ]);
      setUserHasInteracted(true);
    } else {
      setRecipients([
        ...recipients,
        { value: email, key: email, error: 'invalidEmail' },
      ]);
      setUserHasInteracted(true);
    }
  };

  const emptyRecipients = () => {
    setRecipients([]);
    setUserHasInteracted(false);
  };

  const submitEmails = async () => {
    submitCallback({ emails: emails })
      .then((res) => {
        emptyRecipients();
      })
      .catch(console.error);
  };

  const { element } = styles();

  return (
    <Grid container justify='center'>
      <Box width={'100%'}>
        <ChipInput
          required
          error={!!errorKey}
          helperText={
            errorKey
              ? emailErrors[errorKey].message
              : 'You can enter multiple emails'
          }
          onAdd={addRecipient}
          value={recipients.map((r) => r.value)}
          onDelete={(value, index) => {
            handleDeleteChip(recipients[index]);
          }}
          label='Email'
          variant='outlined'
          fullWidthInput
          blurBehavior='add'
          fullWidth
          chipRenderer={chipRenderer}
        />
      </Box>
      <Box pt='2rem'>
        <Grid item xs>
          <Button
            data-testId={'submit-emails'}
            className={element}
            variant='contained'
            endIcon={<EmailIcon />}
            onClick={submitEmails}
          >
            Send Invite
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

export default InviteTeam;
