import {
  AdludioTheme,
  Box,
  Button,
  Dialog,
  DialogTitle,
  Dropdown,
  Grid,
  H6,
  makeStyles,
} from '@adludio/components';
import {
  FileType,
  useUpdateDocumentStatusMutation,
} from '../../generated/graphql';

import { FileStatus } from '../../models/document';
import React from 'react';

const styles = makeStyles((theme: AdludioTheme) => ({
  wrapper: {
    minHeight: '12.5rem',
    minWidth: '30rem',
  },
  smallText: {
    color: theme.palette.grey[600],
  },
  dropdownRoot: {
    minWidth: '100%',
  },
}));

interface MenuItem {
  value: FileStatus;
  label: string;
  key: string;
}

const menuItemsStoryBoard: MenuItem[] = [
  { value: FileStatus.archived, label: 'Archived', key: 'Archived' },
  { value: FileStatus.pending, label: 'Awaiting', key: 'Awaiting' },
];
const menuItems: MenuItem[] = [
  { value: FileStatus.accepted, label: 'Accepted', key: 'Accepted' },
  ...menuItemsStoryBoard,
];

const ChangeStatusModal = ({
  isOpen,
  onClose,
  id,
  currentStatus,
  type,
  isManaged,
}: {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  currentStatus: FileStatus;
  type: FileType;
  isManaged?: boolean;
}) => {
  const [statusValue, setStatusValue] = React.useState<FileStatus>();
  const isStoryBoard = type === FileType.Storyboard;

  React.useEffect(() => {
    setStatusValue(currentStatus);
  }, [currentStatus, isOpen]);

  const [updateDocumentStatusMutation] = useUpdateDocumentStatusMutation({
    variables: {
      id,
      status: statusValue!,
    },
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setStatusValue(event.target.value as FileStatus);
  };

  const handleSubmit = async () => {
    await updateDocumentStatusMutation();
    onClose();
  };

  const { wrapper, dropdownRoot: root } = styles();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      BackdropProps={{ invisible: true }}
      hasCloseButton
    >
      <DialogTitle>
        <Box pt='1rem'>
          <H6 align='center'>Add a status to your file</H6>
        </Box>
      </DialogTitle>
      <Grid
        container
        className={wrapper}
        direction='column'
        alignItems='center'
        xs
      >
        <Grid item container direction='column' alignItems='stretch' xs={6}>
          <Grid item>
            <Box pt='1rem'>
              <Dropdown
                fullWidth
                label='Select status'
                menuItems={
                  isStoryBoard && isManaged ? menuItemsStoryBoard : menuItems
                }
                variant='outlined'
                className={root}
                onChange={handleChange}
                value={statusValue}
              />
            </Box>
          </Grid>
          <Box pt='2rem' style={{ width: '100%' }}>
            <Grid
              container
              direction='row'
              justify='center'
              style={{ alignSelf: 'flex-end' }}
            >
              <Button variant='contained' onClick={handleSubmit}>
                Accept
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ChangeStatusModal;
