import {
  Box,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
} from '@adludio/components';
import React, { Dispatch, SetStateAction } from 'react';
import { CampaignBriefOverviewRead } from './CampaignBriefOverviewRead';
import { useGetCampaignQuery } from '../../../generated/graphql';
// import { AuthContext } from '@adludio/react-sso';

interface CampaignBriefOverviewrops {
  campaignId: string;
  setEditCampaignBrief: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '90vw',
  },
}));

export function CampaignBriefOverview({
  campaignId,
  setEditCampaignBrief,
}: CampaignBriefOverviewrops): JSX.Element {
  // const handleClick = () => setEditCampaignBrief(true);
  const { card } = useStyles();
  const { data, loading } = useGetCampaignQuery({
    variables: { id: campaignId },
    fetchPolicy: 'no-cache',
  });
  // const { auth } = React.useContext(AuthContext);
  // const isInternalUser =
  //   auth.user?.role !== 'guest' && auth.user?.role !== 'client';

  return loading ? (
    <Grid
      container
      item
      xs
      justify='center'
      alignItems='center'
      style={{ height: '90vh' }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <>
      <Grid container direction='column'>
        <Card elevation={2} className={card}>
          <Box p='2rem'>
            {/* <Grid direction='row-reverse' container>
              <Grid container item xs={6} justify='flex-end'>
                <Box mb='1rem'>
                  {(data?.getCampaignById?.title === null ||
                    isInternalUser) && (
                    <Button variant='outlined' onClick={handleClick}>
                      Edit brief
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid> */}
            <CampaignBriefOverviewRead data={data} />
          </Box>
        </Card>
      </Grid>
    </>
  );
}
