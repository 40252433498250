/* eslint-disable complexity */
import {
  AdludioTheme,
  Body2,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  H6,
  makeStyles,
  useSnackbar,
} from '@adludio/components';

import React from 'react';
import { useDeleteDocumentMutation } from '../../generated/graphql';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  reFetch: () => void;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  content: {
    margin: '10px 80px',
  },
  buttonsSection: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
  },
  padding: {
    paddingBottom: '0',
  },
}));

export const DeleteDocumentModal = ({
  isOpen,
  onClose,
  documentId,
  reFetch,
}: DeleteModalProps) => {
  const { content, buttonsSection, padding } = styles();
  const [deleteDocumentMutation, { data: deletedDocument }] =
    useDeleteDocumentMutation();

  React.useEffect(() => {
    reFetch();
  }, [deletedDocument, reFetch]);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={isOpen} onClose={onClose} hasCloseButton>
      <DialogTitle className={padding}>
        <Grid container direction='row' justifyContent='center'>
          <H6 align='right'>Are you sure you want to delete this file?</H6>
        </Grid>
      </DialogTitle>
      <DialogContent className={content}>
        <Body2 align='center'>
          The file will be completely erased from the table
        </Body2>
        <Grid container justify='center'>
          <Grid
            item
            container
            direction='row'
            className={buttonsSection}
            xs={8}
          >
            <Grid container xs={6} justify='center'>
              <Button variant='outlined' onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid container xs={6} justify='center'>
              <Button
                variant='contained'
                onClick={(e) => {
                  deleteDocumentMutation({
                    variables: { id: documentId },
                  }).catch((err) => {
                    console.log(err);
                    onClose();
                    enqueueSnackbar('Error trying to delete document', {
                      variant: 'error',
                    });
                  });
                  onClose();
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
