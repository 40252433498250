/* eslint-disable complexity */
import {
  AdludioTheme,
  Body1,
  Body2,
  Subtitle1,
  Grid,
  H6,
  makeStyles,
  Link,
} from '@adludio/components';
import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { GetCampaignQuery } from '../../../generated/graphql';

const styles = makeStyles((theme: AdludioTheme) => ({
  link: {
    overflowWrap: 'anywhere',
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
}));
interface CreativeBriefOverviewReadProps extends RouteComponentProps {
  data?: GetCampaignQuery;
}

export function CreativeBriefOverviewRead(
  props: CreativeBriefOverviewReadProps
): JSX.Element {
  const { link, purple } = styles();
  const assetsFiles = props.data?.getCampaignById?.creativeBrief?.assetFiles;
  const assetsUrl = props.data?.getCampaignById?.creativeBrief?.assetsUrl;

  const handleOther = (value?: string | null) => {
    if (value?.includes(';')) {
      return value?.substring(0, value.length - 1);
    } else {
      return value;
    }
  };

  const openLink = (url: string) => {
    if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
    }
    return window.open(url, '_blank');
  };

  return (
    <Grid container xs={10} direction='row' spacing={4}>
      <Grid container item xs={10}>
        <H6 color='secondary'>Creative Information</H6>
      </Grid>
      <Grid item xs={10}>
        <Subtitle1 className={purple}>Creative URLs</Subtitle1>
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Brand website links</Body2>
        {props.data?.getCampaignById?.creativeBrief?.websiteUrl
          ?.split(',')
          .map((url) => (
            <Link
              onClick={() => openLink(url)}
              target='_blank'
              key={url}
              style={{ textDecoration: 'none' }}
            >
              <Body1 className={link} key={url} color='textPrimary'>
                {url}
              </Body1>
            </Link>
          )) || <Body1>Not specified</Body1>}
      </Grid>

      <Grid item xs={10}>
        <Body2 color='textSecondary'>Assets url</Body2>
        {assetsUrl ? (
          assetsUrl.split(',').map((asset) => (
            <Link
              onClick={() => openLink(asset)}
              target='_blank'
              key={asset}
              style={{ textDecoration: 'none' }}
            >
              <Body1 className={link} key={asset} color='textPrimary'>
                {asset}
              </Body1>
            </Link>
          ))
        ) : (
          <Body1>No asset link provided</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Assets Files</Body2>
        {assetsFiles && assetsFiles.length > 1 ? (
          assetsFiles?.split(',').map((asset) => (
            <Link
              onClick={() => navigate('files')}
              key={asset}
              style={{ textDecoration: 'none' }}
            >
              <Body1 className={link} key={asset} color='textPrimary'>
                {asset}
              </Body1>
            </Link>
          ))
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Clickthrough URL</Body2>
        {props.data?.getCampaignById?.creativeBrief?.clicktroughUrl ? (
          props.data?.getCampaignById?.creativeBrief?.clicktroughUrl
            .split(',')
            .map((url) => (
              <Link
                onClick={() => openLink(url)}
                target='_blank'
                key={url}
                style={{ textDecoration: 'underline' }}
              >
                <Body1 className={link} key={url} color='textPrimary'>
                  {url}
                </Body1>
              </Link>
            ))
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Subtitle1 className={purple}>Creative options</Subtitle1>
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Device</Body2>
        {props.data?.getCampaignById?.creativeBrief?.device ? (
          handleOther(props.data?.getCampaignById?.creativeBrief?.device)
            ?.split(', ')
            .map((v) => <Body1>{v}</Body1>)
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Ad Unit Type</Body2>
        {props.data?.getCampaignById?.creativeBrief?.device ? (
          handleOther(props.data?.getCampaignById?.creativeBrief?.adunitType)
            ?.split(', ')
            .map((v) => <Body1>{v}</Body1>)
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Ad Unit Dimensions</Body2>
        {props.data?.getCampaignById?.creativeBrief?.device ? (
          handleOther(
            props.data?.getCampaignById?.creativeBrief?.adunitDimensions
          )
            ?.split(', ')
            .map((v) => <Body1>{v}</Body1>)
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Ad Unit Copy</Body2>
        {props.data?.getCampaignById?.creativeBrief?.adunitCopy ? (
          <Body1>
            {props.data?.getCampaignById?.creativeBrief?.adunitCopy}
          </Body1>
        ) : (
          <Body1>Not specified</Body1>
        )}
      </Grid>
      <Grid item xs={10}>
        <Body2 color='textSecondary'>Promo message</Body2>
        {props.data?.getCampaignById?.creativeBrief?.promoMessage ? (
          <Body1>
            {props.data?.getCampaignById?.creativeBrief?.promoMessage}
          </Body1>
        ) : (
          <Body1>No promo message</Body1>
        )}
      </Grid>
    </Grid>
  );
}
