import React, { useContext, useState } from 'react';

import {
  Grid,
  TextInput,
  Dropdown,
  makeStyles,
  useSnackbar,
  Box,
  H6,
  Body2,
  AdludioTheme,
  Chip,
  Body1,
} from '@adludio/components';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { industryList } from './industryList';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';
import { updateCompany } from '../../../API';
import { initialConfig } from '../../../index';
import ProfileEdit from '../ProfileEdit';
import { validateEmail } from '../../../util/validateEmail';
import countryList from 'react-select-country-list';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  companyProfileDataContainer: {
    minHeight: '12rem',
  },
  rootExpanded: {
    width: '75%',
    height: '91%',
    padding: '20px',
  },
  rootShrinked: {
    width: '75%',
    height: '14%',
    padding: '20px',
  },
  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.grey[300],
  },
  button: {
    backgroundColor: theme.palette.primary.main,
  },
  buttonText: {
    color: theme.palette.grey[50],
  },
  invoicingText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'initial',
    maxWidth: '35rem',
  },
  containerDivider: {
    borderRight: '1px solid grey',
  },
}));

export interface CompanyProfileProps {
  switchView: () => void;
  isEditing: boolean;
}

const CompanyProfileEdit = (props: CompanyProfileProps) => {
  const {
    auth: { user, authToken },
  } = useContext(AuthContext) as SuccessAuthContextProps;
  const [company, setCompany] = useState({
    name: user.company.name,
    industry: user.company.industry,
    address: user.company.address,
    country: user.company.country,
    contactEmail: user.company.contactEmail,
  });
  const { companyProfileDataContainer, invoicingText, containerDivider } =
    useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const countries = React.useMemo(() => countryList().getData(), []);

  function handleSubmit() {
    updateCompany(initialConfig.ssoBEURL, user.companyId, authToken, company)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          console.log(res.error);
          enqueueSnackbar('Something went wrong', { variant: 'error' });
        } else {
          enqueueSnackbar('Change saved', { variant: 'success' });
          props.switchView();
        }
      })
      .catch(console.log);
  }
  function handleChange(e: any) {
    setCompany({ ...company, [e.target.name]: e.target.value });
  }

  const isEmailValid = validateEmail(company.contactEmail);
  const isCountryValid = () => {
    const filteredCountries = countries.filter(
      (iterationCountry) => iterationCountry.label === company.country
    );
    return filteredCountries.length > 0;
  };

  return (
    <ProfileEdit
      handleSubmit={handleSubmit}
      switchView={props.switchView}
      isEditing={props.isEditing}
    >
      <Grid container item direction='row'>
        <Grid
          item
          container
          className={companyProfileDataContainer}
          direction='column'
          xs={6}
          md={6}
        >
          <Grid
            className={containerDivider}
            container
            direction='row'
            alignContent='flex-start'
            xs={11}
            spacing={4}
          >
            <Grid xs={7} item>
              <H6>{company.name}</H6>
              <Body1>{company.industry}</Body1>
            </Grid>

            <Grid xs={11} item>
              <Box pt='1rem' pr='2rem'>
                <TextInput
                  variant='outlined'
                  label='Company name'
                  name='name'
                  value={company.name}
                  onChange={handleChange}
                  style={{ width: '100%' }}
                />
              </Box>
            </Grid>
            <Grid xs={11} item>
              <Box pr='2rem'>
                <Dropdown
                  variant='outlined'
                  label='Industry'
                  name='industry'
                  value={company.industry}
                  menuItems={industryList}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={6}>
          <Grid container>
            <>
              <Grid item container>
                <Grid item xs={12}>
                  <H6>Finance Contact Email</H6>
                </Grid>
                <Box py='1rem'>
                  <Grid item xs={12}>
                    <Body2 className={invoicingText}>
                      Please provide an email from your finance department for
                      communications about invoicing
                    </Body2>
                  </Grid>
                </Box>
                <Grid item xs={10}>
                  <Box pt='0.5rem' pb='1.5rem'>
                    <TextInput
                      label='Contact Email'
                      value={company.contactEmail}
                      variant='outlined'
                      name={'contactEmail'}
                      helperText={
                        isEmailValid ? '' : 'Please enter a valid email address'
                      }
                      onChange={handleChange}
                      error={!isEmailValid}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <H6>Company Address</H6>
                </Grid>
                <Grid item xs={10}>
                  <Box py='1rem'>
                    <Autocomplete
                      value={company.country}
                      id='country'
                      options={countries.map((option) => option.label)}
                      freeSolo
                      onChange={(
                        event: React.ChangeEvent<{}>,
                        value: string | null
                      ) => {
                        if (value) setCompany({ ...company, country: value });
                        //setCountry(newValue);
                      }}
                      onInputChange={(
                        event: React.ChangeEvent<{}>,
                        value: string
                      ) => {
                        // event && setCountry(event.target.value);
                        setCompany({ ...company, country: value });
                      }}
                      aria-required
                      fullWidth
                      renderTags={(
                        value: string[],
                        getTagProps: AutocompleteGetTagProps
                      ) =>
                        value.map((option: string, index: number) => (
                          <Chip
                            key={option}
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextInput
                          {...params}
                          required
                          error={!isCountryValid()}
                          variant='outlined'
                          label='Country'
                          placeholder='Search'
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box pt='0.5rem' pb='1.5rem'>
                    <TextInput
                      label='Address'
                      minRows={3}
                      multiline
                      value={company.address}
                      variant='outlined'
                      onChange={handleChange}
                      name='address'
                      error={!company.address || company.address.length < 5}
                      helperText={
                        !company.address || company.address.length > 5
                          ? ''
                          : 'Please enter an address'
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Grid>
    </ProfileEdit>
  );
};

export default CompanyProfileEdit;
