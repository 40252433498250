import React from 'react';

import { Card, Grid, Button, makeStyles, Box } from '@adludio/components';
import FormCard from '../../Components/FormCard';

const useStyles = makeStyles({
  editView: {
    padding: '2rem 1.5rem',
  },
  editButtons: {},
  editingCard: {
    maxWidth: '95rem',
  },
});

type ProfileEditProps = React.PropsWithChildren<{
  switchView: () => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  isEditing: boolean;
}>;

export default function ProfileEdit(props: ProfileEditProps) {
  const styles = useStyles();
  return (
    <Card elevation={6} className={props.isEditing ? styles.editingCard : ''}>
      <Box m='15px' width='100%'>
        <FormCard
          handleSubmit={props.handleSubmit}
          handleDiscard={props.switchView}
        >
          <Grid container className={styles.editView}>
            <Grid item xs={12} direction='column' spacing={4}>
              <Grid item justify='space-around'>
                {props.children}
              </Grid>
              <Grid
                xs={11}
                className={styles.editButtons}
                container
                item
                wrap='nowrap'
                justify='flex-end'
              >
                <Button onClick={props.switchView} size='large' variant='text'>
                  Cancel
                </Button>
                <Button type='submit' size='large' variant='contained'>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </FormCard>
      </Box>
    </Card>
  );
}
