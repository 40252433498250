import { AdludioTheme, Box, Grid, makeStyles } from '@adludio/components';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { CreativeBriefOverview } from '../components/SingleBriefOverview/CreativeBrief/CreativeBriefOverview';

export interface ViewSingleBriefProps extends RouteComponentProps {
  campaignId?: string;
  setAddCreativeBrief: Dispatch<SetStateAction<boolean>>;
  setEditCreative: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    width: '100%',
    minHeight: '100vh',
  },
}));

export function ViewCreativeBrief(props: ViewSingleBriefProps): JSX.Element {
  const { root } = useStyles();

  useEffect(() => {
    if (!props.campaignId) navigate('/campaigns').catch(console.log);
  });

  return (
    <Grid container direction='column' className={root}>
      <Box mt='2rem'>
        <Grid container alignItems='center' direction='column' spacing={4}>
          <Grid container item sm={12} md={8}>
            <CreativeBriefOverview
              campaignId={props.campaignId!}
              setEditCreative={props.setEditCreative}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}
