import React, { PropsWithChildren, useCallback, useState } from 'react';
import { Dialog, H5, Button, Grid, makeStyles, ClickAwayListener } from '@adludio/components';

type FormCardProps = PropsWithChildren<{
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
  handleDiscard: () => void
}>

const useStyles = makeStyles({
  dialogContentWrapper: {
    padding: '2rem',
    minHeight: '12rem'
  }
});

export default function FormCard(props: FormCardProps){

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.handleSubmit(e);
  }, [props]);

  const styles = useStyles();

  return (
    <ClickAwayListener mouseEvent='onMouseDown' touchEvent='onTouchStart' onClickAway={() => setIsDialogOpen(true)}>
      <form onSubmit={handleSubmit}>
        {props.children}
        <Dialog open={isDialogOpen}>
          <Grid className={styles.dialogContentWrapper} container direction='column' justify='space-between'>
            <H5>Discard Unsaved Changes</H5>
            <Grid container justify='flex-end'>
              <Button onClick={props.handleDiscard}>Yes</Button>
              <Button onClick={() => setIsDialogOpen(false)}>No</Button>
            </Grid>
          </Grid>
        </Dialog>
      </form>
    </ClickAwayListener>
  );
}
