import { Grid, Skeleton } from '@adludio/components';
import React from 'react';

const PredictionSkeleton = () => {
  return (
    <Grid item xs={12} md={12} lg={7}>
      <Skeleton variant='rect' height={400} />
    </Grid>
  );
};
export default PredictionSkeleton;
