import { useEffect, useRef } from 'react';

export const useInjectConsentRequiredScripts = (
  url: string,
  consentKey: string,
  id: string,
  beforeLoadScript?: () => void
) => {
  const scriptElement = useRef<HTMLScriptElement>();

  const manageScript = () => {
    if (!scriptElement.current) {
      const script = document.createElement('script');
      script.setAttribute('src', url);
      script.async = true;
      script.id = id;
      if (localStorage.getItem(consentKey) === 'true') {
        if (beforeLoadScript) beforeLoadScript();
        document.body.appendChild(script);
        scriptElement.current = script;
      }
    } else {
      if (localStorage.getItem(consentKey) === 'true') {
        return;
      }
      document.body.removeChild(scriptElement.current);
      // eslint-disable-next-line no-undefined
      scriptElement.current = undefined;
    }
  };

  useEffect(() => {
    window.addEventListener('storage', manageScript);
    manageScript();
    return () => {
      if (scriptElement.current) {
        document.body.removeChild(scriptElement.current);
        // eslint-disable-next-line no-undefined
        scriptElement.current = undefined;
      }
      window.removeEventListener('storage', manageScript);
    };
  }, [url, consentKey]);
};
