import { getEnvPrefix } from './getEnvPrefix';

const envPrefix = getEnvPrefix();
const apiSSOUrl = `//${envPrefix}api.sso.adludio.com`;

export function login(email: string, password: string) {
  return fetch(`${apiSSOUrl}/auth/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'content-type': 'application/json',
    },
  });
}

