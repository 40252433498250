import {
  AdludioTheme,
  Body2,
  Dropzone,
  Grid,
  Icon,
  makeStyles,
} from '@adludio/components';
import React, { useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useUploadManaged } from '../../../helpers/useUploadManaged';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  icon: {
    width: '1.5rem',
    height: '2rem',
    paddingRight: '0.8rem',
  },
  waiting: {
    color: theme.palette.warning.dark,
  },
  success: {
    color: theme.palette.success.dark,
  },
  failed: {
    color: theme.palette.error.dark,
  },
}));

interface LiveUploadsProps {
  id: string;
  setLink: (linkData: string) => void;
}

export const LiveUploads = ({ id, setLink }: LiveUploadsProps) => {
  const [file, setFile] = useState<File | null>(null);
  const { uploadDocument, status, isUploading, uploadedUrl } =
    useUploadManaged();

  const { icon, waiting, success, failed } = useStyles();

  const handleUploaded = (droppedFile: File | null) => {
    if (droppedFile) uploadDocument(droppedFile, id);
  };

  const StatusFileIcon = ({ fileName }: { fileName: string }): JSX.Element => {
    const fileExt = fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />;
  };

  const UploadStatusIcon = ({
    uploadStatus,
  }: {
    uploadStatus: boolean | null;
  }) => {
    // eslint-disable-next-line eqeqeq
    if (uploadStatus == null) {
      return (
        <Icon className={waiting} label={''}>
          hourglass_empty
        </Icon>
      );
    }
    return (
      <Icon className={uploadStatus ? success : failed} label={''}>
        {uploadStatus ? 'check_circle' : 'error'}
      </Icon>
    );
  };
  React.useEffect(() => {
    if (!isUploading && status === true && uploadedUrl) {
      setLink(uploadedUrl.split('?')[0]);
    }
  }, [isUploading, status, uploadedUrl]);

  return (
    <Grid item container justifyContent='center' spacing={2}>
      <Dropzone
        numberOfFiles='single'
        setFile={(droppedFile) => {
          setFile(droppedFile);
          handleUploaded(droppedFile as File);
        }}
        file={file}
        accept={''}
      />
      {file && (
        <Grid
          item
          container
          xs={8}
          direction='row'
          alignItems='center'
          wrap='nowrap'
        >
          <div className={icon}>
            <StatusFileIcon fileName={file.name} />
          </div>
          <Grid
            item
            container
            xs={11}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Body2>{file.name}</Body2>
          </Grid>
          <UploadStatusIcon uploadStatus={status} />
        </Grid>
      )}
    </Grid>
  );
};
