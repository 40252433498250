/*
 * there's no way for the dropzone to accept every filetype
 * best workaround is adding the most common MIME types that are
 * going to be used to this list
 */
export const acceptedFiletypes = `
image/*,
video/*,
audio/*, 
application/*,
text/*,
epub/*,
font/*
application/vnd.ms-powerpoint,
application/vnd.openxmlformats,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
application/vnd.openxmlformats-officedocument.spreadsheetml.template,
application/vnd.ms-excel.sheet.macroEnabled.12,
application/vnd.ms-excel.template.macroEnabled.12,
application/vnd.ms-excel.addin.macroEnabled.12,
application/vnd.ms-excel.sheet.binary.macroEnabled.12,
application/vnd.openxmlformats-officedocument.presentationml.presentation,
application/vnd.openxmlformats-officedocument.presentationml.template,
application/vnd.openxmlformats-officedocument.presentationml.slideshow,


`;
