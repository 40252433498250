import { Divider, ListItem, ListItemText } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { responsiveStyle } from '../../utils/Styles';

interface TabHeaderProps {
  dates?: { min: string; max: string };
  header: string;
}

export function TabHeader({ dates, header }: TabHeaderProps) {
  const classes = responsiveStyle();

  const secondaryText = dates
    ? `From ${format(new Date(dates.min), 'do MMMM')} - ${format(new Date(dates.max), 'do MMMM yyyy')}`
    : '';

  return (
    <div>
      <ListItem>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary={`${header}`}
          secondary={secondaryText}
        />
      </ListItem>
      <Divider component='li' />
    </div>
  );
}
