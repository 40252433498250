import React from 'react';
import { Grid, makeStyles, H5, H3, Button, Box, Body2, Link } from '@adludio/components';
import { navigate } from '@reach/router';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: 'auto',
  },
}));

const TermsAndConditions = (_: { path?: string }) => {
  const { container } = useStyles();

  return (
    <Grid container item xs direction='column'>
      <Box p='2rem' pl='5rem'>
        <Grid container item xs={8} direction='column'>
          <H3>Terms and conditions of use</H3>
          <Box py='1rem'>
            <Body2>
              Helpful links:
            </Body2>
          </Box>
          <Link>
            Website Terms & Conditions
          </Link>
          <Link>
            Advertiser Terms and Conditions
          </Link>
          <Link>
            Cookies Policy
          </Link>
          <Box py='1rem'>
            <H3>
              Website Terms & Conditions
            </H3>
          </Box>
          <br />
          <Body2>
            Welcome to the Adludio website. Adludio.com is a website operated by Adludio
            Limited (&#39;Adludio&#39;, &#39;we&#39;, &#39;us&#39; or &#39;our&#39;). Adludio is registered in England and Wales
            under company number 8227542, at 10c Warner St, London EC1R 5HA. <br />Our VAT
            number is 163494394.
          </Body2><br />
          <H5>
            Agreement between you and Adludio
          </H5><br />
          <Body2>
            These terms and conditions of use (the &#39;Terms of Use&#39;) govern your use of the Adludio
            website, all associated sites by Adludio, its subsidiaries, and affiliates, including
            Adludio sites around the world, and your use of our creative platform Adludio Direct
            (collectively, the &#39;Site(s)&#39;). The Sites are property of Adludio Limited (&#39;Adludio&#39;, &#39;we&#39;,
            &#39;us&#39; or &#39;our&#39;) and they provide information about our services and products. The use
            of products and services accessible through our sites is covered by terms and
            conditions either provide on the Site or by other means to you from Adludio.<br />
            <br />
            BY ACCESSING AND USING THE SITE, YOU ARE AGREEING TO THESE TERMS OF
            USE. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, STOP
            USING THE WEBSITE IMMEDIATELY.<br />
            <br />
            Adludio reserves the right, at its sole discretion, to make changes to the Site and these
            Terms of Use at any time. The continued use of the Site will mean you accept and
            agree to the current Terms of Use, at the time of use.<br />
            <br />
            If you have any questions about these Terms of Use, you can contact us at <Link> privacy@adludio.com</Link> .<br />
            <br />
            In these terms and conditions, User or Users means any third party that accesses the
            Site and is not either (i)employed by Adludio and acting in the course of their
            employment or (ii) engaged as a consultant or otherwise providing services to Adludio
            or accessing the Site in connection with the provision of such services.<br />
            <br />
            You must be the legal age of majority in your country of residence to use this Site. Use
            of this Site is not permitted to those under the age of 13.<br />
            <br />
          </Body2>
          <br />
          <H5>
            Intellectual Property and acceptable use
          </H5><br />
          <Body2>
            1. All Content included on the Site, unless uploaded by Users, is the property of
            Adludio, our affiliates or other relevant third parties. In these terms and conditions,
            Content means any text, graphics, images, audio, video, software, data compilations,
            page layout, underlying code and software and any other form of information capable
            of being stored in a computer that appears on or forms part of this Site. By continuing
            to use the Site you acknowledge that such Content is protected by copyright,
            trademarks, database rights and other intellectual property rights. Nothing on this site
            shall be construed as granting, by implication, estoppel, or otherwise, any license or
            right to use any trademark, logo or service mark displayed on the site without the
            owner&#39;s prior written permission<br />
            <br />
            2. You may, for your own personal, non-commercial use only, do the following: a.
            retrieve, display and view the Content on a computer, tablet or mobile phone screen<br />
            <br />
            3. You must not otherwise reproduce, modify, copy, distribute or use for commercial
            purposes any Content without the written permission of Adludio.<br />
            <br />
            In using or accessing our Site or Adludio services (as defined in this Policy), you agree
            to our privacy policies outlined herein. Adludio will have the right to use your
            information as described in this Privacy Policy. If you do not agree to have your
            information used in any of the ways described in this Privacy Policy, you must
            discontinue use of the Adludio Site and any services provided by Adludio.<br />
            <br />
            If you have any questions about these Terms of Use, you can contact us at <Link>privacy@adludio.com</Link>.
          </Body2><br />
          <br />
          <H5>
            Prohibited use
          </H5><br />
          <Body2>
            4. You may not use the Site for any of the following purposes:<br />
            <br />
            a. in any way which causes, or may cause, damage to the Site or interferes with any
            other person&#39;s use or enjoyment of the Site;<br />
            b. in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or
            otherwise objectionable or in breach of any applicable law, regulation, governmental
            order;<br />
            c. making, transmitting or storing electronic copies of Content protected by copyright
            without the permission of the owner.
          </Body2><br />
          <br />
          <H5>
            Registration
          </H5>
          <br />
          <Body2>
            For purposes of this policy:<br />
            5. You must ensure that the details provided by you on registration or at any time are
            correct and complete.<br />
            <br />
            6. You must inform us immediately of any changes to the information that you provide
            when registering by updating your personal details to ensure we can communicate with
            you effectively.<br />
            <br />
            7. We may suspend or cancel your registration with immediate effect for any
            reasonable purposes or if you breach these terms and conditions.<br />
            <br />
            8. You may cancel your registration at any time by informing us in writing to the
            address at the end of these terms and conditions. If you do so, you must immediately
            stop using the Site. Cancellation or suspension of your registration does not affect any
            statutory rights.<br />
          </Body2>
          <br />
          <H5>
            Links to other websites
          </H5><br />
          <Body2>
            9. This Site may contain links to other sites. Unless expressly stated, these sites are not
            under the control of Adludio or that of our affiliates.<br />
            10. We assume no responsibility for the content of such Sites and disclaim liability for
            any and all forms of loss or damage arising out of the use of them.<br />
            11. The inclusion of a link to another site on this Site does not imply any endorsement
            of the sites themselves or of those in control of them.
          </Body2><br />
          <br />
          <H5>
            Privacy Policy and Cookies Policy
          </H5><br />
          <Body2>
            12. Use of the Site is also governed by our Privacy Policy and Cookies Policy, which are
            incorporated into these terms and conditions by this reference. You can view our
            Privacy Policy at www.adludio.com/privacypolicy and our Cookies Policy at
            www.adludio.com/cookiespolicy.
          </Body2><br />
          <br />
          <H5>
            Availability of the Site and disclaimers
          </H5><br />
          <Body2>
            13. Any online facilities, tools, services or information that Adludio makes available
            through the Site (the Service) is provided &#39;as is&#39; and on an &#39;as available&#39; basis. We
            give no warranty that the Service will be free of defects and/or faults. To the maximum
            extent permitted by the law, we provide no warranties (express or implied) of fitness for
            a particular purpose, accuracy of information, compatibility and satisfactory quality.
            Adludio is under no obligation to update information on the Site.<br />
            <br />
            14. Whilst Adludio uses reasonable endeavours to ensure that the Site is secure and
            free of errors, viruses and other malware, we give no warranty or guaranty in that
            regard and all Users take responsibility for their own security, that of their personal
            details and their computers.<br />
            <br />
            15. Adludio accepts no liability for any disruption or non-availability of the Site.<br />
            <br />
            16. Adludio reserves the right to alter, suspend or discontinue any part (or the whole of)
            the Site including, but not limited to, any products and/or services available. These
            terms and conditions shall continue to apply to any modified version of the Site unless
            it is expressly stated otherwise.
          </Body2><br />
          <H5>
            Limitation of liability
          </H5><br />
          <Body2>
            17. Nothing in these terms and conditions will: (a) limit or exclude our or your liability
            for death or personal injury resulting from our or your negligence, as applicable; (b)
            limit or exclude our or your liability for fraud or fraudulent misrepresentation; or (c) limit
            or exclude any of our or your liabilities in any way that is not permitted under
            applicable law.<br />
            <br />
            18. We will not be liable to you in respect of any losses arising out of events beyond
            our reasonable control.<br />
            <br />
            19. To the maximum extent permitted by law, Adludio accepts no liability for any of the
            following: a. any business losses, such as loss of profits, income, revenue, anticipated
            savings, business, contracts, goodwill or commercial opportunities;<br />
            <br />
            b. loss or corruption of any data, database or software;<br />
            c. any special, indirect or consequential loss or damage.
          </Body2><br />
          <br />
          <H5>
            General
          </H5><br />
          <Body2>
            20. You may not transfer any of your rights under these terms and conditions to any
            other person. We may transfer our rights under these terms and conditions where we
            reasonably believe your rights will not be affected.<br />
            <br />
            21. These terms and conditions may be varied by us from time to time. Such revised
            terms will apply to the Site from the date of publication. Users should check the terms
            and conditions regularly to ensure familiarity with the then current version.<br />
            <br />
            22. These terms and conditions together with the Privacy Policy and Cookies Policy
            contain the whole agreement between the parties relating to its subject matter and
            supersede all prior discussions, arrangements or agreements that might have taken
            place in relation to the terms and conditions.<br />
            <br />
            23. The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and
            conditions and no third party will have any right to enforce or rely on any provision of
            these terms and conditions.<br />
            <br />
            24. If any court or competent authority finds that any provision of these terms and
            conditions (or part of any provision) is invalid, illegal or unenforceable, that provision or
            part-provision will, to the extent required, be deemed to be deleted, and the validity
            and enforceability of the other provisions of these terms and conditions will not be
            affected.<br />
            <br />
            25. Unless otherwise agreed, no delay, act or omission by a party in exercising any
            right or remedy will be deemed a waiver of that, or any other, right or remedy.<br />
            <br />
            26. This Agreement shall be governed by and interpreted according to the law of
            England and Wales and all disputes arising under the Agreement (including
            non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the
            English and Welsh courts.
          </Body2><br />
          <br />
          <H5>
            Advertisers
          </H5><br />
          <Body2>
            This page (together with the documents referred to on it) tells you the terms and
            conditions on which we supply any of the services (Services) listed on the above
            Commercial Terms Schedule (the “Insertion Order” and/or “IO”) and/or described on
            our website www.adludio.com. This agreement is entered by and between Adludio Ltd
            (we), where the company number is 8227542 and the registered office is 10C Warner
            Street, London, EC1R 5HA. Upon mutual acceptance (by signing on paper or
            electronically or by acknowledging acceptance online through an email
            acknowledgement of services) of an Insertion Order, these Terms of Business and the
            terms stipulated in the IO (together, the “Contract”) shall become a binding contract
            between Adludio and the party identified as the “Advertiser” in the IO (the
            “Advertiser”). All advertisers should print a copy of these terms and conditions for
            future reference.
          </Body2><br />
          <br />
          <H5>
            1. Definition of Terms
          </H5><br />
          <Body2>
            1.1 Adludio Network: Our network of online publishers, online applications and
            persons controlling the publication of content on the web<br />
            1.2 Briefs: Details of the advertising campaigns as set out in memoranda prepared by
            the Advertiser<br />
            1.3 Affiliates: An Online publisher, online application or person(s) controlling the
            publication of content on the web, who are paid a fee for placing advertisements
            within their online property<br />
            1.4 IO: &#39;the Insertion Order&#39; or email acknowledgement of acceptance of terms of
            service, shall mean the order form in effect at the Order Date<br />
            1.5 Campaign: Means an advertising and marketing campaign that we undertake for
            one or more of our Advertisers via the display of certain content<br />
            1.6 Adludio Player: means the software media player or other applet that is rendered
            on an end-user’s browser via the javascript (or other) code that we make available to
            our affiliates to insert into the code of online publications or applications, in order to
            serve the advertisers content.<br />
            1.7 Code of Conduct: The specific terms of use that Adludio requires all affiliates to
            adhere to when displaying any of an advertisers’ content<br />
            1.8 Advertiser IP: the trade marks, trading names, brand names, logos and other
            designs, slogans, signs or designations of origin and any literary or artistic work,
            images, films, illustrations, drawings, sound recordings and other works provided by
            the Advertiser to Adludio for the purpose of developing and building Content.<br />
            1.9 Engagement: active engagement of an end-user with Content delivered by Adludio
            through the Adludio Network.<br />
            1.10 Adludio IP: all IPR subsisting in or relating to the Service and the IPR in the
            Adludio platform on which Content is built or designed, including the structure, design
            and layout of the Service, the screens, interfaces, functionalities, the computer code
            that operates the Service, know-how and other system elements, all template games,
            ideas, methods, designs, design methods, rules and interfaces for games or user
            interactions, literary or artistic works, images, films, illustrations, drawings, sound
            recordings and other works, as may be developed or created by Adludio from time to
            time (including such things created in the course of the design and built of Content
            under the Contract); all data captured by the Service; the trademarks PLAYCAPTCHA,
            PLAYUNLOCK, FUTURE AD LABS and MAD SCIENCE DIVISION, SENSORY ADS,
            SENSORY ADVERTISING, LOOMIEN ADLUDIO and ADLUDIO DIRECT and any other
            trademarks, trading names, brand names, logos and other designs, slogans, signs or
            designations of origin (other than Advertiser IP) adopted or used from time to time by
            Adludio or with its consent in connection with the Service.<br />
            1.11 Intellectual Property Rights (or IPR): all intellectual and industrial property rights
            whether registered or not including rights in confidential information and know-how
            and any right of equivalent or similar effect anywhere in the world.<br />
            1.12 Fees: the fees payable to Adludio in connection with the Service as set out in the
            IO.<br />
            1.13 Content: content in digital form incorporating or using Advertiser IP, which is to be
            designed or built using the Adludio Studio and platform for delivery to the public
            through the Adludio Network.<br />
            1.14 Disclaimer – The clear and unambiguous labelling of all paid for content as
            sponsored<br />
          </Body2>
          <br />
          <H5>
            2. Information About Us
          </H5><br />
          <Body2>
            2.1 We enable and facilitate the production of advertising and/or branded creatives via
            our proprietary Creative Studio tools, to develop Sensory, standard rich media display,
            social and other advertising or brand content display formats.<br />
            2.2 We enable the advertiser or agency to submit advertising campaigns to the
            Adludio platform which, if we accept them, we then make available to our network of
            online publishers, online applications and persons controlling the publication of
            content on the web (Affiliates). The Affiliates can then choose to post material
            consistent with the advertiser or agencies advertising campaigns on their online
            publication or application. If they choose to post such material, you agree to pay us in
            accordance with these terms and conditions and we agree to pass on an agreed
            payment to the applicable Affiliate(s) in accordance with our General Terms and
            Conditions of use for Adludio Publishers<br />
            2.3 All Affiliates are registered with us and are subject to our General Terms and
            Conditions of Use for Adludio Affiliates, but they are not employees, agents or
            subcontractors of adludio – they are independent third parties.
          </Body2><br />
          <br />
          <H5>
            3. Service Availability
          </H5><br />
          <Body2>
            3.1 The Adludio platform is intended for use by client resident in the target territory for
            an advertiser’s campaign.<br />
            3.2 An end-user is determined as visiting from within a Target Territory if we determine
            that (in our sole opinion) his IP address is located within that Target Territory<br />
            <br />
          </Body2>
          <H5>
            4. Your Status
          </H5><br />
          <Body2>
            4.1 By placing an order for our services, you warrant and represent that:<br />
            4.1.1 You are legally capable of entering into binding contracts;<br />
            4.1.2 You have authority to sign the IO;<br />
            4.1.3 You agree to these Terms and Conditions;<br />
            4.1.4 If you are an individual, you are at least 17 years old;<br />
            4.2 If any of the details provided in the io change or require updating, you agree to
            inform us immediately<br />
            4.3 We reserve the right to suspend or terminate your campaign and use of the
            Adludio platform and/or network or, if appropriate, to take legal action against you if:<br />
            4.3.1 You fail to update or inform us of any company detail that requires updating;<br />
            4.3.2 You fail to make any payment to us on the due date for payment.
          </Body2><br />
          <br />
          <H5>
            5. Term
          </H5><br />
          <Body2>
            5.1 The Service will commence on the date indicated in the Commercial Terms (the
            “IO”) until the expiry of the period indicated in the Commercial Terms for the provision
            of the Service or such additional periods as may be agreed from time to time in writing
            between Adludio and the Advertiser (the “Term”).
          </Body2><br />
          <br />
          <H5>
            6. Content Design and Build Services
          </H5><br />
          <Body2>
            6.1 Subject to the Advertiser’s compliance with the Contract and payment of the Fees,
            Adludio shall design and build the Content using the Adludio platform including using
            its stock of tools, templates, user interaction methods, games, software code and other
            know-how.<br />
            6.2 Adludio shall design and build the Content using the Advertiser IP and in
            accordance with the Advertiser’s written instruction and such creative brief as may be
            agreed in writing between Adludio and the Advertiser.<br />
            6.3 Upon completion of the design work, Adludio shall submit the Content for the
            Advertiser’s approval. If any modifications or additions are reasonably required by the
            Advertiser, Adludio shall use reasonable efforts to complete and deliver such
            modifications or additions within a reasonable time and upon completion of such
            modifications or additions shall submit the revised Content to the Advertiser for
            approval. Modifications or additions exceeding the initial creative brief as agreed by
            the parties or which require exceptional or significant resources or time, may be subject
            to additional fees.<br />
            6.4 Unless any named individual is designated by the Advertiser in the Commercial
            Terms (or otherwise in writing) as the Advertiser’s representative for the purpose of
            approval of Content, the approval of Content by the Advertiser’s usual point of contact
            shall be deemed to be the Advertiser’s approval and shall authorise the delivery of such
            Content through the Adludio Network.<br />
            6.5 Following the Advertiser’s approval (paragraph 2.4 above), Adludio reserves the
            right to present the Content to a testing sample of users. Results of such testing may
            be used to make modifications to the Content. The Advertiser understands that such
            modifications are for the benefit of the Service (e.g. improve successful completion
            rate) and hereby authorises the delivery of such Content through the Adludio Network.
          </Body2><br />
          <H5>
            7. Advertiser&#39;s Responsibilities
          </H5><br />
          <Body2>
            7.1 The Advertiser shall give Adludio clear and timely briefings and all necessary
            cooperation and support as may be necessary to enable Adludio to carry out its
            obligations under the contract and shall act in a timely manner to approve content
            submitted by Adludio, or to indicate the required modifications or additions to content
            submitted by Adludio<br />
            7.2 The Advertiser shall furnish Adludio with all artwork, images or other works, in such
            format as Adludio may reasonably require, incorporating any Advertiser IP that the
            Advertiser desires to be used in content to be designed and built by Adludio.<br />
            7.3 Where inventory ID bought by the Advertiser or Agency, the Advertiser will
            maintain up to date SLA’s (service level agreements) with 3rd party inventory delivery
            mechanisms, such as managed DSP’s (demand side platforms). Adludio can not be held
            accountable or responsible for any delay in campaign activation due to delayed QA
            process at Advertiser designated DSP.<br />
            7.4 In order to fulfill the service agreement with the client, Adludio may accept to
            deliver a campaign to first party data segments and/or Adludio may accept to apply
            third party tracking tags to a creative build and/or to any ad unit delivery mechanism
            through a DSP/deliver platform setup It is the sole responsibility of the Advertiser or
            Agency to ensure any data collection, processing, use, sharing and retention by the
            Advertiser/Agency and/or third party supplier abides by laws and regulations in the
            location of the campaign delivery, including being compliant to GDPR standards if the
            campaign is active in the European Union (including the UK).
          </Body2><br />
          <br />
          <H5>
            8. Licence to use the Advertiser IP as part of content
          </H5><br />
          <Body2>
            8.1 The Advertiser hereby grants to Adludio and its affiliates a non-exclusive,
            royalty-free, personal licence for the Term to use the Advertiser IP solely in the design,
            built and delivery of Content through the Adludio Network and to do all such things as
            are reasonably required to enable Adludio to fulfil its obligations under the Contract.
            Such licence shall be non-transferable and non-sublicensable save that Adludio and its
            affiliates shall be entitled to permit their consultants and subcontractors and affiliates to
            use such Advertiser IP in connection with the design, built and delivery of Content in
            accordance with the Contract.
          </Body2><br />
          <br />
          <Body2>
            9. Content Delivery Services<br />
            9.1 Subject to the Advertiser’s compliance with the Contract and payment of the Fees,
            Adludio shall, during the Term, arrange for the Content to be delivered through the
            Adludio Network and shall use all reasonable efforts to optimise delivery of Content
            through Channels and Engagement by end-users up to the maximum number of
            Engagements or other limits set out in the Commercial Terms.<br />
            9.2 Except where the Advertiser indicates in writing that any particular Channels should
            be excluded from the Service, Adludio shall have full discretion to decide which
            Channels are best used for delivery of Content in order to achieve optimal exposure to
            the Content and Engagement by end users. The Advertiser acknowledges and
            understands that some affiliates within the Adludio Network may have the right to
            refuse to deliver any particular Content and upon an affiliates request Adludio shall
            cause such Content not to be delivered through the affiliates Channel. The Advertiser
            acknowledges and understands further that the make-up of the Adludio Network may
            vary from time to time.
          </Body2><br />
          <br />
          <H5>
            10. Content
          </H5><br />
          <Body2>
            10.1 The Advertiser shall ensure that Advertiser IP and any Content incorporating or
            using Advertiser IP and approved by the Advertiser does not include anything that may
            or may be alleged as being unlawful, defamatory, infringing of third party rights
            (including any third party IPRs) or in breach of any duty (including confidentiality duties)
            to third parties, or otherwise giving rise to third party liability, offensive, misleading,
            obscene, discriminatory, racist or otherwise contrary to generally acceptable ethical or
            moral standards in the United Kingdom, or contrary to any applicable advertising code
            or industry code or otherwise objectionable (“Inappropriate Material”).<br />
            10.2 Adludio reserves the right at its discretion to withhold or to discontinue the
            delivery of any Content that contains or is alleged by any third party to contain
            Inappropriate Material.<br />
            10.3 Adludio shall not knowingly or deliberately incorporate any Inappropriate Material
            in Content designed or built by it.<br />
            10.4 Except to the extent it is in breach of an express covenant or warranty under these
            Terms of Business and then subject to the limitations on Adludio’s liability as set out in
            these Terms of Business, Adludio shall not be liable for any loss, cost or expense or any
            liability incurred by the Advertiser or any claim brought or threatened against it arising
            out of Content delivered through the Adludio Network as part of the Service including
            any liability arising out of the delivery of Content containing Inappropriate Materials.<br />
            10.5 The Advertiser shall indemnify Adludio, its affiliates, officers, directors, employees
            and agents (“Indemnified Persons”) and shall keep the Indemnified Persons
            indemnified on demand against any loss, liability or cost (including reasonable legal
            costs) arising to the Indemnified Persons as a result of any claim, demand or
            proceedings brought or threatened against the Indemnified Persons in connection with
            the delivery of Content through the Adludio Network as part of the Service (including
            in connection with any Inappropriate Material contained in such Content), save where
            such claim or allegation arises out of the breach by Adludio of an express covenant or
            warranty under these Terms of Business.
          </Body2><br />
          <br />
          <H5>
            11. Data and Analytics obtained through the service
          </H5><br />
          <Body2>
            11.1 Adludio shall be entitled to capture and use data comprising the IP addresses and
            other data (including personal data) of end-users who Engage with Content via the
            Channels for the purpose of administering and improving the functionality of the
            Service, performing analytics and obtaining statistical data, including for the purpose of
            providing security for the Service. The data and all statistics and analysis deriving from
            it shall be deemed Adludio IP.
          </Body2><br />
          <br />
          <H5>
            12. Intellectual Property
          </H5><br />
          <Body2>
            12.1 As between the parties, Adludio is and shall remain the sole owner of and shall
            have the exclusive right to exploit, use, license, sell, reproduce, modify or adapt any
            and all parts of the Service and the Adludio IP (including any Adludio IP created in the
            course of the design or built of Content under the Contract). The Advertiser agrees and
            acknowledges that, save as expressly provided below, it shall not acquire and it
            irrevocable disclaims any right, title, interest or licence in or under any of the Adludio
            IP.<br />
            12.2 The foregoing notwithstanding, and for the avoidance of doubt, Adludio shall not
            use any Advertiser IP incorporated or used in Content for any purpose other than for
            internal use or to fulfil its obligations under the Contract.<br />
            12.3 As between the parties, the Advertiser is and shall remain the sole owner of and
            shall have the exclusive right to exploit, use, license, sell, reproduce, modify or adapt
            any and all parts of the Advertiser IP. Adludio agrees and acknowledges that, save as
            expressly licensed under the Contract, it shall not acquire and it irrevocable disclaims
            any right, title, interest or licence in or under any of the Advertiser IP
          </Body2><br />
          <br />
          <H5>
            13. Warranties
          </H5><br />
          <Body2>
            13.1 Adludio warrants to the Advertiser as set out below:<br />
            13.1.1 it has the right to provide the Service and owns or has the right to use the
            Adludio IP.<br />
            13.1.2 it shall use all reasonable care, diligence and skill and sound judgment in
            providing the design services and Content delivery services to the Advertiser in
            accordance with the Contract.<br />
            13.1.3 Adludio IP used in the design of Content or incorporated in Content does not
            infringe any third party Copyright.<br />
            13.1.4 it shall use reasonable efforts to ensure that:<br />
            13.1.4.1 Content shall be free of computer viruses, worms, trojans, spyware or other
            malicious code; and<br />
            13.1.4.2 Adludio IP incorporated in Content does not include any Inappropriate
            Material.<br />
            13.2 The Advertiser acknowledges and agrees that the proper operation of the Service
            (and Adludio’s warranty in paragraph 2 above) are subject to Channels and their
            systems operating without disruption and in accordance with the technical
            specifications provided by Adludio to Channels from time to time.<br />
            13.3 Other than the warranties, covenants and representations expressly set out above,
            Adludio gives no warranty nor makes any representation in relation to the Service, the
            Content or the Adludio IP. The parties expressly disclaim to the fullest extent permitted
            by law any representation or warranty by Adludio relating to Service, the Content or
            the Adludio IP that may be implied by the Contract, by custom, or by law or otherwise
            and which is not expressly set out herein, including any implied warranties of quality,
            merchantability, title or entitlement, fitness for a particular purpose, ability to achieve a
            particular result or functionality, absence of computer viruses or other malicious code,
            or non-infringement of third party rights.
          </Body2><br />
          <br />
          <H5>
            14. Payments
          </H5><br />
          <Body2>
            14.1 Fees shall be payable by the Advertiser in accordance with the IO. Unless
            otherwise specified in the IO, or where an IO is not present (programmatic campaigns),
            in accordance with the tracked views as recorded through the Adludio platform.<br />
            14.2 Payment is due thirty (30) calendar days from the date of invoice,<br />
            14.3 Invoices will be sent out to the address of either the advertiser or the agency as
            set out in the io.<br />
            14.4 Failure for Adludio to send out a timely invoice, for whatever reason, in
            accordance with the io, in no way effects the advertisers obligation to pay the invoice
            in its entirety<br />
            14.5 All invoices created by Adludio for work performed and delivered shall be in
            accordance with measurement and tracking performed by Adludio In addition, all
            payments will be based upon Adludio’s measurements and not based upon the
            advertiser or agencies tracking or any third party&#39;s measurements, unless expressly
            outlined in the IO.<br />
            14.6 The Advertiser or Agency may, at its or their cost, use a third party to track an Ad
            upon Adludio’s prior written consent, which shall be given or withheld at Adludio’s sole
            discretion<br />
            14.7 In the unlikely event that the number of engagements, impressions, clicks or other
            defined metric are not delivered in the timescale as defined in the IO, the advertiser
            agrees to pay Adludio for engagements, impressions, clicks that have been delivered
            as defined by the measurement and tracking performed by Adludio within the
            timescale as defined on the io.<br />
            14.8 When payment is being delivered for technical and creative services and delivered
            through an agency or advertisers 3rd party DSP (Programmatic campaigns), the
            payments schedule is calculated at the end of each month, based on the impressions
            as measured through the Adludio platform. Payment is due immediately upon receipt
            of invoice.<br />
            14.9 All amounts payable under this Contract shall be paid in full without any
            withholding or deduction on account of any taxes, duties, levies or charges, unless the
            party due to make the payment is required by law to make such deduction or
            withholding. If it is so required it shall duly deduct or withhold the amount as required
            by law, provided that it shall give the other party such assistance and co-operation,
            including the provision of documentation as may be required by any tax authority, as
            may be reasonably necessary to enable the other party to obtain an exemption,
            reduction, repayment or tax accreditation in regard to any such deduction or
            withholding.<br />
            14.10 Neither party shall be entitled to assert any credit, set-off or counterclaim against
            any payment obligations (or part thereof) under this Contract, except undisputed
            amounts due from the other party to the paying party, if such undisputed debts are
            due and payable by the due date of payment of the amount due under this Contract.<br />
            14.11 Without prejudice to any other right or remedy available to Adludio, if the
            Advertiser fails to pay an amount of Fees within 15 days from the due date for
            payment, Adludio shall be entitled to interest on the outstanding amounts at an annual
            rate equal to the three (3) month London Interbank Offered Rate (LIBOR) denominated
            in British pounds plus five per cent. (5%), calculated on a daily basis, from the date on
            which the payment originally fell due until the date of receipt of payment in cleared
            funds.
          </Body2><br />
          <br />
          <H5>
            15. Limitations on Liability
          </H5><br />
          <Body2>
            15.1 In no event shall Adludio be liable to the Advertiser under this Contract or in
            connection with its performance or breach, or in connection with any warranties
            hereunder, or in connection with the provision of the Service, including for its
            negligence, for any indirect, incidental, consequential or special damages, including
            any loss of profits or savings or anticipated profits or savings, loss of data, loss of
            opportunity, loss or reputation, goodwill or business, even if Adludio has been advised
            in advance of the possibility of such damages.<br />
            15.2 Adludio shall have no liability whatsoever for any damage, liability or loss
            (including loss of revenue) that the Advertiser may incur, or to any other undesirable
            consequences, resulting from any temporary suspension or disruption of the Service,
            including where such temporary suspension or disruption results from Adludio’s
            negligence.<br />
            15.3 Adludio’s maximum aggregate liability for any single event (or a series of related
            events) giving rise to a claim in connection with this Contract or in relation to the
            Service, either for breach of contract, breach of warranty, misrepresentation or
            negligence shall be limited to a sum equal to the total Fees payable to Adludio.<br />
            15.4 Adludio shall not be in breach of the Contract, nor liable for any failure or delay in
            performance of any of its obligations under the Contract where such failure or delay
            arises from or is attributable to acts, events, omissions or accidents beyond its
            reasonable control including an act of God, fire, flood, earthquake, windstorm or other
            natural disaster, explosion or accidental damage, war, threat of or preparation for war,
            armed conflict, imposition of sanctions, embargo, breaking off of diplomatic relations
            or similar actions, terrorist attack, civil war, civil commotion or riots, shortage of raw
            materials or supplies, industrial action or strike, power cuts electronic or
            communication network breakdowns including any disruption, suspension or
            breakdown of any ‘cloud’, hosting or network services (“Force Majeure Events”). If a
            Force Majeure Event prevents the proper performance of Adludio’s obligations under
            the Contract and such disruption continues for a continuous period of more than 30
            days, either Adludio or the Advertiser may terminate the Contract by giving the other
            15 days’ prior written notice which shall take effect on the expiry of such notice period
            unless by that date Adludio can resume the proper performance of its obligations.<br />
            15.5 Notwithstanding anything to the contrary in the Contract, nothing herein shall be
            deemed to limit a party’s liability for death or personal injury caused by that party’s
            negligence or for that party’s fraud or fraudulent misrepresentation or for any other
            liability that cannot be limited or excluded by law.
          </Body2><br />
          <br />
          <H5>
            16. Confidentiality
          </H5><br />
          <Body2>
            16.1 Each party (the “Receiving Party”) shall, for the duration of the Terms and for an
            unlimited period of time thereafter, keep confidential all information (whether or not
            marked as confidential) received from the other party (the “Disclosing Party”) or
            otherwise obtained by the Receiving Party in connection with the Service relating to
            the Disclosing Party’s business (“Confidential Information”) and shall not use or
            disclose such information to any third parties other than as permitted by the Disclosing
            Party.<br />
            16.2 Adludio shall be deemed to be the Disclosing Party in relation to any information
            relating to the Service or the Adludio IP, or Adludio’s business or financial affairs and
            business plans. The Advertiser shall be deemed to be the Disclosing Party in relation to
            any information relating to the Advertiser IP, the Advertiser’s business or financial affairs
            and business plans.<br />
            16.3 The financial details of this Contract and other details set out in the Commercial
            Terms shall be deemed to be Confidential Information of both parties and each party
            shall treat such information as a Receiving Party.<br />
            16.4 The requirements of this section shall not apply: (i) to any information to the
            extent that it is or becomes (not as a result of a breach of this section) generally
            available to the public; or (ii) to any disclosure of information required by operation of
            law or any stock exchange regulations or any binding judgment or order of a court of
            law, or by any requirement of any competent authority, governmental regulatory
            agency or stock exchange, subject where possible to reasonable prior consultation with
            the Disclosing Party and provided that in the event that such disclosure is required, the
            Receiving Party shall take reasonable steps to protect the confidentiality of the
            information and to limit the disclosure as much as possible
          </Body2><br />
          <br />
          <H5>
            17. Termination
          </H5><br />
          <Body2>
            17.1 A party (the “Terminating Party”) shall be entitled to terminate the Contract by
            written notice with immediate effect to the other party (the “Breaching Party”) in the
            event that:<br />
            17.1.1 The Breaching Party commits a material breach of its obligations,
            representations or warranties under this Contract; provided, however, where such
            breach is capable of cure, that the Breaching Party shall have 15 days from the date of
            receipt of a notice from the Terminating Party to cure such breach, following which
            time, unless such breach is materially cured, the Terminating Party shall be entitled to
            serve notice terminating this Contract with immediate effect; or<br />
            17.1.2 The Breaching Party is unable to pay its debts as they become due or
            bankruptcy proceedings are initiated against it or an order for its winding up is made
            against it (except voluntary winding up for the purpose of reorganisation).<br />
            17.2 Adludio shall be entitled to terminate this Contract by written notice with
            immediate effect to the Advertiser in the event that<br />
            17.2.1 The Advertiser challenges, opposes or seeks to invalidate or revoke any of the
            Adludio IP or makes any claims relating to Adludio’s title or entitlement or licensed
            rights to the Adludio IP, or if the Advertiser assists another person to do any of those
            things, or<br />
            17.3 The Advertiser fails to pay the Fees within 7 days of the due date for payment.The
            termination or expiry of this Contract shall not affect any accrued rights or liabilities of
            any party and shall not affect any provision of this Contract intended to have effect
            after termination or necessary for its interpretation and in particular it shall not affect
            the confidentiality or intellectual property provisions hereunder or the general
            provisions below
          </Body2><br />
          <br />
          <H5>
            18. Public Announcements
          </H5><br />
          <Body2>
            18.1 Neither party shall make or send a public announcement, communication or
            circular concerning the Contract unless it has first obtained the other party’s written
            consent, which may not be unreasonably withheld or delayed.<br />
            <br />
            19. Display of Final Product or Services<br />
            19.1 Adludio reserves the right to use and share a final creative production (the “Ad”)
            for promotional activity, including displaying it on our website and demo platform(s)
            which can be publicly accessed.<br />
            19.2 In addition to the ad’s visual and interactive forms, Adludio also reserves the right
            to use brief and performance results in promotional and research material which may
            include case studies, white papers, articles, award submission, to be publicly
            distributed.
          </Body2><br />
          <br />
          <H5>
            20. General
          </H5><br />
          <Body2>
            20.1 The Contract is personal to the parties and neither party shall be entitled to assign
            or transfer its rights or obligations without the other’s prior written consent.<br />
            20.2 Nothing in this Contract shall create, or be deemed to create, a partnership or
            joint venture and shall not be construed as giving rise to the relationship of principal
            and agent between the parties.<br />
            20.3 If any provision of this Contract is or becomes illegal, invalid or unenforceable in
            any respect under the law of any jurisdiction, that shall not affect the legality, validity or
            enforceability in that jurisdiction or any other jurisdiction of any other provision of these
            Terms.<br />
            20.4 A person who is not a party to this Contract shall have no rights to enforce the
            provisions of this Contract under the Contracts (Rights of Third Parties) Act 1999.<br />
            20.5 No modification, alteration or waiver of any of the provisions of this Contract shall
            be effective unless in writing and signed on behalf of each of the parties.<br />
            20.6 No omission or delay on the part of either party in exercising any right, power or
            privilege hereunder shall operate as a waiver thereof, nor shall any single or partial
            exercise of any such right, power or privilege preclude any other or further exercise
            thereof or of any other right, power or privilege. The rights and remedies herein
            provided are cumulative with and not exclusive of any right or remedies provided by
            law.<br />
            20.7 These Terms of Business together with the Commercial Terms constitute the entire
            agreement between the parties and supersede all other agreements, statements,
            letters and other arrangements between the parties in relation to the subject matter
            hereof. Each party acknowledges that it has not relied on or been induced to enter this
            Contract by a representation other than those expressly set out in this Contract. This
            clause does not affect a party’s liability in respect of a fraudulent misrepresentation.<br />
            20.8 This Contract and any dispute between the parties arising out of the subject
            matter of this Contract including as to its formation, interpretation and effect and
            including claims based on non-contractual grounds shall be governed by English law
            and the parties submit to the exclusive jurisdiction of the courts of England.
          </Body2><br />
          <br />
          <H5>
            ----Cookies Policy -----
          </H5><br />
          <br />
          <Body2>
            Adludio uses cookies to help customise your experience on our Site.
            This page contains further information on what cookies are, how they are used on the
            Adludio Site and how you can turn them off in your browser. If you do not find all the
            information you need on this page, please refer to our Privacy Policy or contact us at
            privacy@adludio.com.<br />
            If you believe that any information we are holding about you is incorrect or incomplete,
            please email us, and we will promptly correct it.<br />
            <br />
            What are &#39;Cookies&#39;?<br />
            <br />
            &#39;Cookies&#39; are a small text file sent to and stored by your browser, (Internet Explorer,
            Safari, Chrome) on your computer or mobile device (tablet or mobile phone).
            They are unique identifiers that point to your behaviour on a website, and they allow
            websites to store and remember things like your preferences (ie. language, etc) and
            settings. Cookies are necessary to enable you to move around a site comfortably or to
            provide basic features. They can also help improve the performance of a website,
            deliver a better user experience, allow a website to recognise you when you return it
            and to respond appropriately.<br />
            <br />
            Cookies can be &#39;persistent&#39; meaning that it is stored on your device until it expires or
            you delete the cookie. Alternatively, cookies may be temporary or &#39;session&#39; which are
            stored only in temporary memory and are not retained after the browser is closed. We
            do not sell, distribute or lease the information collected by cookies.
            You can choose to accept or decline cookies. Most web browsers automatically accept
            cookies, but you can usually modify your browser settings (see below &#39;How to opt-out
            of cookies in your browser&#39;)<br />
            <br />
            How Adludio uses cookies on the Site<br />
            <br />
            Adludio uses cookies to enable certain functionalities on the Site, to provide analytics
            and to store your preferences.<br />
            In addition to our own cookies, we may also use third-party cookies. For example, we
            use cookies supplied by Google Analytics to track and report on our website traffic.
            These analytics do not provide with Adludio any personal identifiable information
            about you, nor do we upload personal information or identifiable data (including
            device identifiers) to Google Analytics.<br />
            Adludio is committed to providing you with a safe and secure browsing environment in
            our Site. We keep your data private and have placed suitable physical, electronic and
            organisational safeguards to prevent the unauthorised access or disclose of your
            information.<br />
            To learn more about our privacy policy, visit www.adludio.com/privacypolicy.<br />
            <br />
            How to opt-out of cookies in your browser<br />
            <br />
            The browser that you use will allow you to opt-out of or switch-off cookies, and will also
            allow you to delete previous cookie history. You can change your permissions and and
            manage your history in your cookie settings, usually found under the &#39;options&#39;,
            &#39;settings&#39; or &#39;preferences&#39; section of the browser.<br />
            <br />
            Here are a few useful links for cookie settings in each of the following browsers:<br />
            ● Google Chrome<br />
            ● Internet Explorer<br />
            ● MacOS Safari<br />
            ● Mozilla Firefox<br />
            ● Opera Browser<br />
            <br />
            How to opt-out from Advertiser cookies<br />
            <br />
            You can opt-out specifically from third party cookies generated for advertising
            purposes.<br />
            <br />
            If you are in the EU, can learn more about how to opt-out on the European Interactive
            Digital Advertising Alliance. If you are in North America, you can visit the Network
            Advertising Initiative. The Digital Advertising Alliance is also a helpful site.<br />
            <br />
            You can learn more about Advertiser cookies in privacy policy, visit
            www.adludio.com/privacypolicy.<br />
            <br />
            Links to other websites<br />
            <br />
            Adludio&#39;s Site may contain links to other website. Once you have used these links and
            left the Adludio Site, you should be aware your experience is not longer governed by
            our policies -- and we have no control over that new location. We cannot be
            responsible for the protection and privacy of any information you provide when
            browsing such websites as they are not governed by this Cookie policy.
          </Body2>
        </Grid>
      </Box>
      <Grid container xs justify='center' alignContent='center' className={container}>
        <Grid item xs={12}>
          <Button variant='contained' onClick={() => navigate('/').catch(console.log)}>Go To Home Page</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
