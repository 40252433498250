import {
  AdludioTheme,
  Body2,
  Box,
  Card,
  Grid,
  H5,
  makeStyles,
} from '@adludio/components';
import {
  InviteTeamWrapper,
  InviteTeamWrapperHandle,
} from '../InviteTeam/InviteTeamWrapper';
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

import Avatars from '../Avatars';
import { User } from '../../generated/graphql';
import { convertToDate } from '../../helpers/date';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  titleBox: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '16px',
    width: '100%',
    padding: '20px',
  },
  date: {
    color: theme.palette.primary.dark,
  },
}));

export interface CampaignHeaderProps {
  startDate?: string;
  endDate?: string;
  team: User[];
  campaignId: string;
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignName: string;
  campaignStatus: string;
  children?: React.ReactNode;
}

function getUserInitials(user: User): string {
  return (
    user.name
      ?.split(' ')
      .map((n: string) => n[0])
      .join('') || ''
  );
}

export const CampaignHeader: React.FC<CampaignHeaderProps> = ({
  campaignName,
  team,
  campaignId,
  campaignStatus,
  env,
  children,
  startDate,
  endDate,
}) => {
  const [teamInitials, setTeamInitials] = useState<string[]>([]);
  const inviteTeamRef = useRef<InviteTeamWrapperHandle>(null);

  const handleHoverLarge = (open: boolean) => {
    inviteTeamRef.current?.setModalOpen(open);
    inviteTeamRef.current?.setTeamOpen(open);
  };

  useEffect(() => {
    setTeamInitials(
      team
        // TODO  Check User this probably should not be an optional field
        .filter((user: User) => user.name !== null)
        .map((user: User) => getUserInitials(user)) || []
    );
  }, [team]);

  const { titleBox, date } = useStyles();
  return (
    <Grid container xs={12} justify='center'>
      <Grid container item direction='row' xs={10}>
        <Card className={titleBox} elevation={2}>
          <Grid container direction='row'>
            <Grid item xs={6} md={8}>
              <H5>{campaignName}</H5>
              {startDate && endDate && (
                <Grid item xs>
                  <Body2 className={date}>
                    {convertToDate(startDate)}- {convertToDate(endDate)}
                  </Body2>
                </Grid>
              )}
            </Grid>
            <Grid container direction='row' xs={6} md={4} justify='flex-end'>
              <Grid
                container
                xs={6}
                sm={10}
                justifyContent='flex-end'
                direction='row'
                alignItems='center'
                wrap='nowrap'
              >
                <Grid>
                  <Avatars
                    team={team}
                    teamInitials={teamInitials}
                    openModal={handleHoverLarge}
                  />
                </Grid>
                <Grid>
                  <Box ml='1rem'>
                    <InviteTeamWrapper
                      ref={inviteTeamRef}
                      env={env}
                      campaignId={campaignId}
                      team={team}
                      campaignName={campaignName}
                      campaignStatus={campaignStatus}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction='row'>
            {children}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
