import * as React from 'react';
import {
  AdludioTheme,
  makeStyles,
  Grid,
  Body2,
  Box,
} from '@adludio/components';
import SvgCreative from '../../assets/Illustration-Creative.svg';
import SvgCampaign from '../../assets/CampaignPlan.svg';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  svg: {
    maxWidth: '572.9px',
    maxHeight: '481.31px',
    margin: '0 auto',
    display: 'block',
    zIndex: 1,
  },
  buildingMessage: {
    fontSize: '1rem',
    color: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
}));

interface EmptyPageProps {
  isCreative?: boolean;
}
const EmptyCreativePage = (props: EmptyPageProps) => {
  const { svg, buildingMessage } = useStyles();
  return (
    <Grid container xs={12} justifyContent='center'>
      <Grid md={10} direction='column'>
        <Box mt='3rem'>
          <Body2 className={buildingMessage} align='center'>
            {props.isCreative ? (
              <Grid container direction='column'>
                <Body2 className={buildingMessage} align='center'>
                  Your personal designer is currently building your adunits.{' '}
                </Body2>
                <Body2 className={buildingMessage} align='center'>
                  Hold tight!
                </Body2>
              </Grid>
            ) : (
              <Grid container direction='column'>
                <Body2 className={buildingMessage} align='center'>
                  Adludio is currently building a plan to satisfy the
                  requirement on the brief.
                </Body2>
                <Body2 className={buildingMessage} align='center'>
                  Please wait..
                </Body2>
              </Grid>
            )}
          </Body2>
        </Box>
        <Box
          mt='3rem'
          width='100%'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={props.isCreative ? SvgCreative : SvgCampaign}
            width='50%'
            className={svg}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default EmptyCreativePage;
