import { Grid } from '@adludio/components';
import {
  List,
  Tabs,
  Tab,
  Divider,
  withStyles,
  styled,
} from '@material-ui/core';
import React from 'react';
import { SingleSiteList } from '../../types';
import { SiteTabSections } from '../../types/Report';

import { TabHeader } from '../ui/tabHeader';
import { TabPanel } from '../ui/tabPanel';
import OverviewTab from './overviewTab';
import SentimentTab from './sentimentTab';

interface StyledTabsProps {
  value: number;
  indicatorColor: 'secondary' | 'primary' | undefined;
  textColor: 'secondary' | 'primary' | undefined;
  variant: 'scrollable' | 'standard' | 'fullWidth' | undefined;
  scrollButtons: 'auto' | 'on' | 'off' | 'desktop' | undefined;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  children: React.ReactNode;
}

const StyledTabs = withStyles({
  indicator: {
    display: 'none',
  },
  scroller: {
    flexGrow: 0,
  },
})((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const SmallTab = styled(Tab)(({ theme }) => ({
  minWidth: '50px',
  textTransform: 'capitalize',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '2px',
  backgroundColor: theme.palette.primary.main,
}));

interface SiteProps {
  campaignName: string;
  topSiteList: SingleSiteList;
  bottomSiteList: SingleSiteList;
  kpiCols: string[];
  dates: { min: string; max: string };
  sections: SiteTabSections;
}
const SiteBody = ({
  campaignName,
  topSiteList,
  bottomSiteList,
  kpiCols,
  dates,
  sections,
}: SiteProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!sections.sentiment) {
    // Site overview only without tabs
    return (
      <Grid container>
        <Grid item xs={12}>
          <List>
            <TabHeader
              dates={dates}
              header={'Top Performing Site Insight Dashboard'}
            />
          </List>
        </Grid>
        <Grid item xs={12}>
          <OverviewTab
            campaignName={campaignName}
            topSiteList={topSiteList}
            bottomSiteList={bottomSiteList}
            kpiCols={kpiCols}
            dates={dates}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <List>
          <TabHeader
            dates={dates}
            header={'Top Performing Site Insight Dashboard'}
          />
        </List>
      </Grid>
      <Grid item xs={12}>
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor='secondary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
        >
          <SmallTab value={0} label='Overview' />
          <StyledDivider orientation='vertical' variant='middle' flexItem />
          <SmallTab value={1} label='Sentiment Insignts' />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <OverviewTab
            campaignName={campaignName}
            topSiteList={topSiteList}
            bottomSiteList={bottomSiteList}
            kpiCols={kpiCols}
            dates={dates}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SentimentTab
            campaignName={campaignName}
            topSiteList={topSiteList}
            bottomSiteList={bottomSiteList}
            kpiCols={kpiCols}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
export default SiteBody;
