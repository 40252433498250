import { TagRecord } from '../store/tagReducer';
import { DataMapType } from '..';

export default function (tag: TagRecord, data: DataMapType) {
  let service = tag.serviceLevel;
  if (!service) {
    return data;
  }
  return data.set('service_level', service);
}
