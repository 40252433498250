export interface ReportOverview {
  aat: number;
  ctrAat: number;
  impression: number;
  engagement: number;
  click: number;
  videoStart: number;
  videoEnd: number;
  imps: number;
  viewedImps: number;
  curatorRevenue: number;
  er: number;
  ctr: number;
  ectr: number;
  vtr: number;
  viewability: number;
  pacing: number;
  offBudget: number;
  spendProgress: number;
  erBenchmark: number;
  ctrBenchmark: number;
  ectrBenchark: number;
  vtrBenchmark: number;
  moneyLeft?: number;
}

export interface ReportDaily {
  date: string[];
  site?: string[];
  impression: number[];
  engagement: number[];
  click: number[];
  videoStart: number[];
  videoEnd: number[];
  imps: number[];
  viewedImps: number[];
  curatorRevenue: number[];
  er: number[];
  ctr: number[];
  ectr: number[];
  vtr: number[];
  viewability: number[];
}

export interface BreakdownValue {
  name: string;
  value: string;
}

export interface ReportBreakdown {
  breakdownName: string;
  breakdownLevel: string;
  breakdownValues: BreakdownValue[];
}

export interface BreakdownSections {
  dailyTrend: Boolean;
  sharePie: Boolean;
  overallBar: Boolean;
  dailyTabular: Boolean;
}
export interface BreakdownTab {
  show: boolean;
  sections: BreakdownSections;
}

export interface LiveTabSections {
  dailyTrend: Boolean;
  dailyTabular: Boolean;
}
export interface LiveTab {
  show: boolean;
  sections: LiveTabSections;
}

export interface MoreKpiTab {
  show: boolean;
  sections: { [section: string]: boolean };
}

export interface PredictionTab {
  show: boolean;
  sections: { [section: string]: boolean };
}

export interface SiteTabSections {
  sentiment: Boolean;
  topSites: Boolean;
  impressionFraction: Boolean;
  worstSites: Boolean;
}
export interface SiteTab {
  show: boolean;
  sections: SiteTabSections;
}

export interface Layout {
  summary: {
    aat: boolean;
    ctrAat: boolean;
    pacing: {
      show: boolean;
      offValue: boolean;
    };
    spend: {
      show: boolean;
      progress: boolean;
    };
    click: boolean;
    ctr: {
      show: boolean;
      trend: boolean;
    };
    ectr: {
      show: boolean;
      trend: boolean;
    };
    engagement: boolean;
    er: {
      show: boolean;
      trend: boolean;
    };
    impression: {
      show: boolean;
      value: 'tracking' | 'xandr';
    };
    viewability: boolean;
    viewableImp: boolean;
    views: boolean;
    vtr: {
      show: boolean;
      trend: boolean;
    };
  };
  tabs: {
    breakdown: BreakdownTab;
    live: LiveTab;
    moreKpi: MoreKpiTab;
    prediction: PredictionTab;
    site: SiteTab;
  };
}
export interface Report {
  campaignName: string;
  overview: ReportOverview;
  daily: ReportDaily;
  startDate: string;
  endDate: string;
  breakdown?: ReportBreakdown[];
  layout: Layout;
}

export const emptyReportOverview: ReportOverview = {
  aat: 0.0,
  ctrAat: 0.0,
  impression: 0.0,
  engagement: 0.0,
  click: 0.0,
  videoStart: 0.0,
  videoEnd: 0.0,
  imps: 0.0,
  viewedImps: 0.0,
  curatorRevenue: 0.0,
  er: 0.0,
  ctr: 0.0,
  ectr: 0.0,
  vtr: 0.0,
  viewability: 0.0,
  pacing: 0.0,
  offBudget: 0.0,
  spendProgress: 0.0,
  erBenchmark: 0.0,
  ctrBenchmark: 0.0,
  ectrBenchark: 0.0,
  vtrBenchmark: 0.0,
};

export const emptyReportDaily: ReportDaily = {
  date: [],
  impression: [],
  engagement: [],
  click: [],
  videoStart: [],
  videoEnd: [],
  imps: [],
  viewedImps: [],
  curatorRevenue: [],
  er: [],
  ctr: [],
  ectr: [],
  vtr: [],
  viewability: [],
};

const emptyBreakdownTab: BreakdownTab = {
  show: true,
  sections: {
    dailyTrend: true,
    sharePie: true,
    overallBar: true,
    dailyTabular: true,
  },
};

const emptyLiveTab: LiveTab = {
  show: true,
  sections: {
    dailyTrend: true,
    dailyTabular: true,
  },
};

const emptyMoreKpiTab: MoreKpiTab = {
  show: true,
  sections: {},
};

const emptyPredictionTab: PredictionTab = {
  show: true,
  sections: {},
};

const emptySiteTab: SiteTab = {
  show: true,
  sections: {
    topSites: true,
    impressionFraction: true,
    worstSites: true,
    sentiment: true,
  },
};

export const emptyLayout: Layout = {
  summary: {
    aat: true,
    ctrAat: true,
    click: true,
    pacing: {
      show: true,
      offValue: true,
    },
    ctr: {
      show: true,
      trend: true,
    },
    ectr: {
      show: true,
      trend: true,
    },
    engagement: true,
    er: {
      show: true,
      trend: true,
    },
    impression: {
      show: true,
      value: 'tracking',
    },
    spend: {
      show: true,
      progress: true,
    },
    viewability: true,
    viewableImp: true,
    views: true,
    vtr: {
      show: true,
      trend: true,
    },
  },
  tabs: {
    breakdown: emptyBreakdownTab,
    live: emptyLiveTab,
    moreKpi: emptyMoreKpiTab,
    prediction: emptyPredictionTab,
    site: emptySiteTab,
  },
};

export const emptyReport: Report = {
  campaignName: '',
  overview: emptyReportOverview,
  daily: emptyReportDaily,
  startDate: '',
  endDate: '',
  breakdown: [],
  layout: emptyLayout,
};
