import { Grid, makeStyles } from '@adludio/components';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { sideNavItems, topNavItems } from '../../shared/Data/nav';

import { AuthContext } from '@adludio/react-sso';
import { ConfigProps } from '../../Pages';
import TopNav from '../TopNav';
import classNames from 'classnames';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  toolContent: {
    height: 'calc(100vh-3.75rem)',
    '& div:not([class])': {
      width: '100%',
    },
  },
  mobView: {
    marginTop: '3.75em',
    toolContent: {
      width: '100vw',
    },
  },
  contentAndTopNav: {
    width: '100%',
    transition: 'transform 225ms ease-in-out 0ms',
    marginTop: '3.75em',
    zIndex: 1201,
    '&.slide': {
      transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
      maxWidth: '100%',
    },
  },
  wrapper: {
    overflow: 'hidden',
  },
}));

// eslint-disable-next-line react/display-name
export default (props: PropsWithChildren<{}> & ConfigProps) => {
  const styles = useStyles();
  const {
    auth: { user },
  } = useContext(AuthContext);
  // Toggle mob or desktop view depending on screen width
  const [sideNavOpen, setSideNavOpen] = useState<boolean>(true);

  const mobView = useMediaQuery('(max-width:768px)');

  // will update when useMediaQueryChanges (including mount)
  useEffect(() => {
    mobView ? setSideNavOpen(false) : setSideNavOpen(true);
  }, [mobView]);

  const toggleSideNav = (): void => {
    setSideNavOpen(!sideNavOpen);
  };

  const slideClass = classNames(styles.contentAndTopNav, {
    slide: sideNavOpen && user, // slide contentAndTopNav if mob nav is open
  });

  return (
    <Grid container direction='column' wrap='nowrap' className={styles.wrapper}>
      <Grid item container>
        <TopNav
          sideNavOpen={sideNavOpen}
          toggleSideNav={toggleSideNav}
          supportItems={topNavItems}
          tools={sideNavItems}
          mobView={mobView}
          user={user}
          config={props.config}
        />
      </Grid>
      <div className={!mobView ? slideClass : styles.mobView}>
        <Grid item xs container className={styles.toolContent}>
          {props.children}
        </Grid>
      </div>
    </Grid>
  );
};
