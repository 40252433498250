import {
  Grid,
  Box,
  H6,
  Subtitle1,
  makeStyles,
  Button,
} from '@adludio/components';
import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import ErrorSVG from '../assets/404.svg';
import ErrorSVG2 from '../assets/error.svg';

export interface ErrorProps extends RouteComponentProps {
  notFound?: boolean;
}

const useStyles = makeStyles(() => ({
  mainDiv: {
    margin: 0,
    background: '#FFF',
    minHeight: '90vh',
  },
  svg: {
    width: '70%',
    maxWidth: '30rem',
  },
  link: {
    textDecoration: 'none',
  },
}));

export function ErrorPage(props: ErrorProps): JSX.Element {
  const { mainDiv, svg, link } = useStyles();

  return (
    <Box pt='5rem' className={mainDiv}>
      <Grid
        container
        xs={12}
        justifyContent='center'
        direction='column'
        alignItems='center'
      >
        <img src={props.notFound ? ErrorSVG : ErrorSVG2} className={svg} />
        <Grid item>
          <H6>
            {props.notFound
              ? 'Oops! Something went wrong.'
              : 'Something went wrong...'}
          </H6>
        </Grid>
        <Grid>
          <Subtitle1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            {props.notFound
              ? "We can't find the page you're looking for..."
              : 'Please try again later.'}
          </Subtitle1>
        </Grid>
        <Grid>
          <Box my='1.5rem'>
            <Link to='/campaigns' className={link}>
              <Button variant='contained'>Go Home</Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
