/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  GetCampaignBriefQuery,
  GetCampaignQuery,
} from '../../generated/graphql';
import {
  ProgrammaticFormValues,
  ManagedFormValues,
  CreativeFormValues,
  LiveFormValues,
  CreativeUploadValues,
} from './types';
import { v4 as uuidv4 } from 'uuid';

// Programmatic

export const PROGRAMMATIC_FORM_VALUES: ProgrammaticFormValues = {
  brandName: '',
  name: '',
  currency: 'USD',
  budget: null,
  description: '',
  industry: '',
  startDate: null,
  endDate: null,
  objective: '',
  KPI: '',
  audience: '',
  isManaged: false,
  geographies: '',
  DSP: '',
  seatId: '',
  sitelistUrl: '',
  isSubmitted: false,
};

// eslint-disable-next-line complexity
export const validateProgrammatic = (
  values: ProgrammaticFormValues,
  step: number,
  handleStepChange: (n: number) => void,
  save: (...args: any) => Promise<any>,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};
  // Multi-step validation
  if (step === 1) {
    if (!values.brandName) {
      errors['brandName'] = 'Brand name is required.';
    }
    if (!values.name) {
      errors['name'] = 'Campaign name is required.';
    }
    if (!values.budget) {
      errors['budget'] = 'Budget is required.';
    }
    if (!values.description) {
      errors['description'] = 'Description is required.';
    }
    if (!values.industry) {
      errors['industry'] = 'Industry is required.';
    }
    if (!values.startDate) {
      errors['startDate'] = 'Start date is required.';
    }
    if (!values.endDate) {
      errors['endDate'] = 'End date is required.';
    } else if (values.startDate && values.endDate < values.startDate) {
      errors['endDate'] = 'End date must be after the Start date.';
    }
    if (!values.objective) {
      errors['objective'] = 'Objective is required.';
    }
    if (!values.KPI) {
      errors['KPI'] = 'KPI is required.';
    }

    if (Object.keys(errors).length === 0) {
      save({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...values,
            name: values.name ? values.name : null,
          },
        },
      })
        .then(() => {
          enqueueSnackbar('Successfully saved draft.', { variant: 'success' });
        })
        .catch(
          () => (errors['name'] = 'A campaign with that name already exists.')
        );

      if (Object.keys(errors).length === 0) {
        handleStepChange(step + 1);
        return;
      } else {
        return errors;
      }
    }
    return errors;
  } else {
    if (!values.audience) {
      errors['audience'] = 'Audience is required.';
    }
    if (!values.geographies) {
      errors['geographies'] = 'Serving Locations is required.';
    }
    if (!values.DSP) {
      errors['DSP'] = 'DSP is required.';
    }
    if (!values.seatId) {
      errors['seatId'] = 'SeatID is required.';
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
  }
};

export const parseQueryToFormValues = (data?: GetCampaignBriefQuery) => {
  if (data) {
    const { id, client, creator, __typename, ...rest } = data.getCampaignBrief!;
    return rest as ProgrammaticFormValues;
  } else {
    return;
  }
};

// Managed

export const MANAGED_FORM_VALUES: ManagedFormValues = {
  brandName: '',
  name: undefined,
  currency: 'USD',
  budget: null,
  description: '',
  industry: '',
  startDate: null,
  endDate: null,
  objective: '',
  KPI: '',
  audience: '',
  geographies: '',
  DSP: '',
  sitelistUrl: '',
  websiteUrl: '',
  assetsUrl: '',
  assetFiles: '',
  focus: '',
  promoMessage: undefined,
  isSeasonal: null,
  isNewProduct: null,
  device: '',
  adunitType: '',
  adunitDimensions: '',
  adunitCopy: '',
  clicktroughUrl: '',
  isSubmitted: false,
  isManaged: true,
};

// eslint-disable-next-line complexity
export const validateManaged = (
  values: ManagedFormValues,
  save: (...args: any) => Promise<any>,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};
  if (!values.brandName) {
    errors['brandName'] = 'Brand name is required.';
  }
  if (!values.name) {
    errors['name'] = 'Campaign name is required.';
  }
  if (!values.budget) {
    errors['budget'] = 'Budget is required.';
  }
  if (!values.description) {
    errors['description'] = 'Description is required.';
  }
  if (!values.industry) {
    errors['industry'] = 'Industry is required.';
  }
  if (!values.objective) {
    errors['objective'] = 'Objective is required.';
  }
  if (!values.KPI) {
    errors['KPI'] = 'KPI is required';
  }
  if (!values.audience) {
    errors['audience'] = 'Audience is required.';
  }
  if (!values.geographies) {
    errors['geographies'] = 'Geographies is required.';
  }
  if (!values.startDate) {
    errors['startDate'] = 'Start date is required.';
  }
  if (!values.endDate) {
    errors['endDate'] = 'End date is required.';
  } else if (values.startDate && values.endDate < values.startDate) {
    errors['endDate'] = 'End date must be after the Start date.';
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const parseQueryToFormValuesMedia = (data?: GetCampaignQuery) => {
  if (data) {
    const managed = {
      ...data.getCampaignById?.brief,
      ...data.getCampaignById?.creativeBrief,
    };
    const { id, __typename, ...rest } = managed;
    return rest as ManagedFormValues;
  } else {
    return;
  }
};

// Creative

export const CREATIVE_FORM_VALUES: CreativeFormValues = {
  websiteUrl: '',
  assetsUrl: '',
  assetFiles: '',
  focus: '', // eslint-disable-next-line no-undefined
  promoMessage: undefined,
  isSeasonal: null,
  isNewProduct: null,
  device: '',
  adunitType: '',
  adunitDimensions: '',
  adunitCopy: '',
  clicktroughUrl: '',
};

// eslint-disable-next-line complexity

export const parseQueryToFormValuesCreative = (data?: GetCampaignQuery) => {
  if (data?.getCampaignById?.creativeBrief) {
    const creative = { ...data.getCampaignById.creativeBrief };
    const { id, __typename, isSubmitted, ...rest } = creative;
    return rest as ManagedFormValues;
  } else {
    return;
  }
};

// LIVE
export const LIVE_FORM_VALUES: LiveFormValues = {
  brandName: '',
  name: undefined,
  id: '',
  GUID: '',
  description: '',
  contactEmail: null,
  placement: '',
  KPI: '',
  audienceList: null,
  IO: null,
  percentage: '',
  flatFee: '',
  newCost: '',
  websiteUrl: '',
  assetFiles: '',
  currency: 'USD',
  costCentre: '',
  volume: '',
  CPE: '',
  trackingTags: null,
  standardCampaign: null,
  clientType: null,
  additionalInfo: '',
  salesEmail: '',
  standardBuild: null,
  budget: null,
  bookedBy: '',
  startDate: null,
  endDate: null,
  objective: '',
  audience: '',
  geographies: '',
  sitelistUrl: '',
  assetsUrl: '',
  isSubmitted: false,
  companyAddress: '',
  companyName: '',
  createdAt: new Date(Date.now()),
};

export const validateLive = (
  values: LiveFormValues,
  campaignId: string,
  enqueueSnackbar: (message: string, options: any) => void
) => {
  const errors = {};

  if (!values.brandName) {
    errors['brandName'] = 'Brand name is required.';
  }
  if (!values.name) {
    errors['name'] = 'Campaign name is required.';
  }
  if (!values.description) {
    errors['description'] = 'Description is required.';
  }
  if (!values.objective || values?.objective?.length < 1) {
    errors['objective'] = 'Objective is required.';
  }
  if (!values.KPI) {
    errors['KPI'] = 'KPI is required';
  }
  if (!values.bookedBy) {
    errors['bookedBy'] = 'This field is required';
  }
  if (!values.budget) {
    errors['budget'] = 'Budget is required.';
  }
  if (!values.companyAddress) {
    errors['companyAddress'] = 'Company Address is required';
  }
  if (values.assetFiles === null) {
    errors['assetFiles'] = 'Asset is required';
  }
  if (!values.contactEmail) {
    errors['contactEmail'] = 'This field is required';
  }
  if (!values.placement) {
    errors['placement'] = 'Placement is required';
  }
  if (!values.trackingTags) {
    errors['trackingTags'] = 'This field is required';
  }
  if (!values.standardCampaign) {
    errors['standardCampaign'] = 'This field is required';
  }
  if (!values.clientType) {
    errors['clientType'] = 'Client type is required';
  }
  if (!values.salesEmail) {
    errors['salesEmail'] = 'Sales Email is required';
  }
  if (!values.audience) {
    errors['audience'] = 'Audience is required.';
  }
  if (!values.geographies) {
    errors['geographies'] = 'Geographies is required.';
  }
  if (!values.websiteUrl) {
    errors['websiteUrl'] = 'Must provide a brand website.';
  }
  if (!values.currency) {
    errors['currency'] = 'Must provide a currency.';
  }
  if (!values.costCentre) {
    errors['costCentre'] = 'Must provide a costCentre.';
  }

  if (!values.contactEmail) {
    errors['contactEmail'] = 'Contact Email is required.';
  }
  if (values.audienceList === null) {
    errors['audienceList'] = 'Audience List is required.';
  }
  if (values.IO === null) {
    errors['IO'] = 'IO is required.';
  }

  if (!values.startDate) {
    errors['startDate'] = 'Start date is required.';
  }
  if (!values.endDate) {
    errors['endDate'] = 'End date is required.';
  } else if (values.startDate && values.endDate < values.startDate) {
    errors['endDate'] = 'End date must be after the Start date.';
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};

// Creative Upload

export const CREATIVE_UPLOAD_FORM_VALUES: CreativeUploadValues = {
  id: uuidv4(),
  gameKey: '',
  name: '',
  enviroment: '',
  region: '',
  redirectUrl: '',
  landingPageUrl: '',
  width: 320,
  height: 480,
  startDate: null,
  endDate: null,
  timeZone: '',
  time: null,
  adludioIAS: false,
  thirdPartyIAS: false,
  trackingTag: '',
  IASImpression: '',
  DVImpression: '',
  MOATImpression: '',
  otherImpression: '',
  thirdPartyEng: '',
};

export const validateCreativeUpload = (
  values: CreativeUploadValues,
  creativeUploadId: string,
  campaignId: string,
  dsp: string,
  advertiserID: string
) => {
  const errors = {};
  if (!values.gameKey) {
    errors['gameKey'] = 'Please provide a Game Key';
  }
  if (dsp.length < 1) {
    errors['dsp'] = 'Please choose a DSP';
  }
  if (advertiserID.length < 1) {
    errors['advertiserID'] = 'Please provide an Advertiser ID';
  }
  if (!values.enviroment) {
    errors['enviroment'] = 'Please choose an enviroment.';
  }
  if (!values.region) {
    errors['region'] = 'Please choose region.';
  }
  if (values.startDate && values.endDate! <= values.startDate) {
    errors['endDate'] = 'End date must be after the Start date.';
  }
  return Object.keys(errors).length > 0 ? errors : undefined;
};
