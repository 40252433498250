import { MuiThemeProvider } from '@material-ui/core';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';
import * as React from 'react';
import tableColumnFilterOption from '../../reducers/muiTableOptions';
import { Site } from '../../types';
import { getMuiTheme } from '../../utils/Styles';
import numeral from 'numeral';
import { format } from 'date-fns';
import CustomToolbar from '../dashboard/CustomToolbar';

export interface WorstSiteProps {
    selectedBottomSiteList: Site[];
    campaignName:string;
    kpi:string;
}

export default function WorstSiteReport({
  selectedBottomSiteList,
  campaignName,
  kpi
}: WorstSiteProps) {
  let tableRows: string[][] = [];
  for (let index = 0; index < selectedBottomSiteList.length; index++) {
    tableRows.push([
      selectedBottomSiteList[index].site,
      numeral(selectedBottomSiteList[index].impression).format('0,0'),
      numeral(selectedBottomSiteList[index].engagement).format('0,0'),
      selectedBottomSiteList[index].er.toFixed(2),
      numeral(selectedBottomSiteList[index].click).format('0,0'),
      selectedBottomSiteList[index].ctr.toFixed(2),
      selectedBottomSiteList[index].ectr.toFixed(2)
    ]);

  }

  const tableCols: string[] = ['site', 'impression', 'engagement', 'er', 'click', 'ctr', 'ectr'];

  const columns: any[] = [];


  // datatable column handler array
  tableCols.forEach((element) => {
    if (element == 'site') {
      columns.push({
        name: 'site',
        label: 'Site',
        options: {
          filter: true,
          filterType: 'multiselect',
          customFilterListOptions: {
            render: (v: any) => v.toLowerCase()
          },
        }
      });
    } else {
      columns.push(tableColumnFilterOption(element));
    }

  });
  const getCustomToolBar = () => {
    return (
      <CustomToolbar
        report={tableRows}
        campaignName={campaignName}
        kpiCols={tableCols}
        isSite
      />
    );
  };

  const options: MUIDataTableOptions = {
    customToolbar: getCustomToolBar,
    filter: true,
    print: false,
    selectableRows: 'none',
    tableBodyHeight: '100%',
    fixedHeader: true,
    search: false,
    elevation: 0,
    downloadOptions: {
      filename: `${format(new Date(), 'yyy-MM-dd')} ${campaignName} ${kpi} worst performing sites.csv`,
      separator: ';',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    },
    textLabels: {
      body: {
        noMatch: 'Sorry, no matching records found',
        toolTip: 'Sort',
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
    },
    download: false,
  };


  return (

    <div style={{ width: '100%' }}>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          data={tableRows}
          columns={columns}
          options={options}
          title={'Worst Performing Sites Insight'}
        />
      </MuiThemeProvider>
    </div>
  );
}
