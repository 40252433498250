import React from 'react';

import adludioLogo from '../shared/img/adludio_logo.svg';

export const AdludioLogo = (props: { isLogin?: boolean }) => (
  <img
    height={props.isLogin ? 60 : 54}
    src={adludioLogo}
    alt='The Adludio Logo'
  />
);
