import React from 'react';
import ReactDOMServer from 'react-dom/server';

const getIcons = (Icon: any, Tooltip: string): string => {
  return ReactDOMServer.renderToString(
    <div title={Tooltip}>
      <Icon color='primary' />
    </div>
  );
};
export default getIcons;
