import { Box, Grid } from '@adludio/components';
import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { CampaignBriefOverview } from '../components/SingleBriefOverview/CampaignBrief/CampaignBriefOverview';
import { navigate } from '@reach/router';

const ViewCampaignBrief = ({
  campaignId,
  setEditCampaignBrief,
  editCampaignBrief,
}: {
  campaignId?: string;
  setEditCampaignBrief: Dispatch<SetStateAction<boolean>>;
  editCampaignBrief: boolean;
}) => {
  useEffect(() => {
    if (!campaignId) navigate('/campaigns').catch(console.log);
  });

  return (
    <Grid container direction='column'>
      <Box mt='2rem'>
        <Grid container alignItems='center' direction='column'>
          <Grid container item xs={11} md={8} lg={8}>
            <CampaignBriefOverview
              campaignId={campaignId!}
              setEditCampaignBrief={setEditCampaignBrief}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ViewCampaignBrief;
