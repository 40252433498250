import numeral from 'numeral';

import jsPDF from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';

import { BreakdownReportValue, BreakdownReportValues } from '../types';
import { defineCols } from './setKpiCols';

// This is a hack to make lint shut-up
class JsPDF extends jsPDF {}

export const getFormatedValues = (
  col: string,
  report: BreakdownReportValue,
  day: number = -1
) => {
  let value: string;
  let unformatted = day !== -1 ? report.daily[col][day] : report.overview[col];

  // spend value
  if (col === 'curatorRevenue') {
    value = numeral(unformatted).format('$0,0');
  }
  // rate values
  else if (['er', 'ctr', 'ectr', 'vtr', 'viewability'].includes(col)) {
    value = `${numeral(unformatted).format('0.00')}`;
  } else if (col === 'date') {
    // date value
    value = report.daily[col][day];
  } else {
    // count values
    value = numeral(unformatted).format('0,0');
  }
  return value;
};

export const exportPDF = (
  report: BreakdownReportValues,
  campaignName: String,
  kpiCols: string[]
) => {
  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape

  const marginLeft = 40;
  const doc = new JsPDF(orientation, unit, size);

  /*
   * take intersection of below list and report column names
   * any column that is not in report but in the list will be discarded
   */
  const dailyCols = kpiCols;
  const cols = kpiCols.filter((item) => item !== 'date');

  // table content
  const commonOptions: UserOptions = {
    theme: 'grid',
    headStyles: {
      fillColor: [230, 230, 230],
      fontStyle: 'bold',
      textColor: 'black',
      lineColor: [128, 128, 128],
    },
  };

  // get breakdown names from report
  let breakdowns = Object.keys(report);

  // for each breakdown render tables
  breakdowns.map((bk, index) => {
    let breakdownReport = report[bk];
    const days = breakdownReport.daily.date.length;

    // Overview rows
    const overview = ['Campaign'];
    cols.map((col) => overview.push(getFormatedValues(col, breakdownReport)));

    // Daily rows
    const daily: (string | number)[][] = [];

    for (let day = 0; day < days; day++) {
      let dayReport: (string | number)[] = [];
      dailyCols.forEach((col) =>
        dayReport.push(getFormatedValues(col, breakdownReport, day))
      );
      daily.push(dayReport);
    }

    // table headers
    const overviewHeaders = [['', ...cols.map((col) => defineCols(col))]];
    const dailyHeaders = [dailyCols.map((col) => defineCols(col))];

    const overviewContent: UserOptions = {
      startY: 90,
      head: overviewHeaders,
      body: [overview],
      ...commonOptions,
    };

    const dailyContent: UserOptions = {
      startY: 180,
      head: dailyHeaders,
      body: daily,
      ...commonOptions,
    };

    // start from new page for all subsequent breakdowns
    if (index > 0) {
      doc.addPage();
    }

    // campaign name
    doc.setFont('helvetica');
    doc.setFontSize(27);
    doc.text(`${campaignName} ${bk}`, marginLeft, 40);

    // overview table
    doc.setFontSize(16);
    doc.setFont('courier');
    doc.text('Performance Overview', marginLeft, 80);
    autoTable(doc, overviewContent);

    // daily table
    doc.text('Daily Breakdown', marginLeft, 170);
    autoTable(doc, dailyContent);
  });

  // save pdf
  let today = new Date().toISOString().slice(0, 10);
  doc.save(`${campaignName}_${today}.pdf`);
};
