// import { format } from 'date-fns';
import numeral from 'numeral';
import { ReportDaily } from '../types/Report';
import { mapValue } from './breakdownsConfig';
import { formatDateString } from './date';

export function populateData(dailyReport: ReportDaily, tableCols: string[], category?: string) {
  const TableData: (any)[] = [];

  for (let index = 0; index < dailyReport.date.length; index++) {
    let data = [];

    if (category) {
      data.push(mapValue(category));
    }
    data.push(formatDateString(new Date(dailyReport.date[index])));
    data.push(numeral(dailyReport.curatorRevenue[index]).format('$0,0'));
    data.push(numeral(dailyReport.impression[index]).format('0,0'));
    if (tableCols.includes('engagement')) {
      data.push(numeral(dailyReport.engagement[index]).format('0,0'));
      data.push(dailyReport.er[index].toFixed(2));
    }
    if (tableCols.includes('click')) {
      data.push(numeral(dailyReport.click[index]).format('0,0'));
      data.push(dailyReport.ctr[index].toFixed(2));
      if (tableCols.includes('engagement')) {
        data.push(dailyReport.ectr[index].toFixed(2));
      }
    }
    if (tableCols.includes('videoEnd')) {
      data.push(numeral(dailyReport.videoEnd[index]).format('0,0'));
      data.push(dailyReport.vtr[index].toFixed(2));
    }
    if (tableCols.includes('viewedImps')) {
      data.push(numeral(dailyReport.viewedImps[index]).format('0,0'));
      // data.push(dailyReport.viewability[index].toFixed(2));
    }
    TableData.push(data);
  }

  return TableData;

}

