import { DropDownChoice } from './_types';

export type EnvironmentTypes = 'MWEB' | 'MRAID1' | 'MRAID2';

export const MRAIDOptions = ['MRAID1', 'MRAID2'];

const envChoices: DropDownChoice<EnvironmentTypes>[] = [
  {
    key: 'mraid1',
    label: 'MRAID1',
    value: 'MRAID1',
  },
  {
    key: 'mraid2',
    label: 'MRAID2',
    value: 'MRAID2',
  },
  {
    key: 'mweb',
    label: 'MWEB',
    value: 'MWEB',
  },
];

export default envChoices;
