import React from 'react';

import { Divider, Grid, Box, makeStyles } from '@adludio/components';
import { Body2 } from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  text: {
    color: theme.palette.grey[600]
  }
}));

const Separator = () => (
  <Grid container wrap='nowrap' justify='center' alignItems='center' xs={12}>
    <Grid item xs={6} md={5}>
      <Divider />
    </Grid>
    <Grid item>
      <Box px='1.25rem'>
        <Body2 className={useStyles().text}>or</Body2>
      </Box>
    </Grid>
    <Grid item xs={6} md={5}>
      <Divider />
    </Grid>
  </Grid>
);

export default Separator;
