import {
  AdludioTheme,
  Box,
  Grid,
  Body2,
  makeStyles,
} from '@adludio/components';
/* eslint-disable complexity */
import React, { Dispatch, SetStateAction } from 'react';
import {
  createMuiTheme,
  MuiThemeProvider,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import Icon from '@material-ui/core/Icon';
import { Link } from '@reach/router';
import { DetailedTooltip } from './detailedTooltip';

const useStyles = makeStyles<
  AdludioTheme,
  { textVisible: boolean; disabled: boolean }
>((theme: AdludioTheme) => ({
  root: {
    fontSize: '1.5rem',
    justifyContent: 'left',
    transition: 'all 0.2s ease-out',
    '&:hover': {
      transition: 'font-size 0.5s',
      fontSize: '1.7rem',
    },
  },
  thumbnailIcon: {
    fontSize: '1.5rem',
    '&:hover $title': {
      transition: 'font-size 0.5s',
      fontSize: '1.7rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  boxDisabled: {
    color: theme.palette.grey[400],
  },
  boxClickable: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      borderTop: 'unset',
    },
  },
  itemSelected: {
    cursor: 'pointer',
    color: theme.palette.secondary.light,
    borderTop: `4px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('md')]: {
      borderTop: 'unset',
      borderRight: `4px solid ${theme.palette.secondary.light}`,
      borderRadius: '4px',
      color: theme.palette.secondary.light,
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  text: {
    width: ({ textVisible }) => (textVisible ? '100%' : '0%'),
    transition: 'all 0.4s ease-out',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tooltip: {
    fontSize: '0.875em',
    backgroundColor: theme.palette.grey[700],
  },
  title: {
    '&:hover $thumbnailIcon': {
      transition: 'font-size 0.5s',
      fontSize: '1.7rem',
    },
  },
  mainDiv: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const themeTooltip = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: grey[600],
      },
    },
  },
});
interface NavElementProps {
  id: string;
  title: string;
  icon: string;
  disabled?: boolean;
  campaignId?: string;
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
}
const NavElement = (props: NavElementProps) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const {
    thumbnailIcon,
    boxDisabled,
    boxClickable,
    root,
    itemSelected,
    link,
    title,
    mainDiv,
    text,
    tooltip,
  } = useStyles({
    textVisible: false,
    disabled: props.disabled ?? false,
  });
  let elementStyle = '';
  const isSelectedItem = props.selectedItem === props.id;
  if (isSelectedItem) {
    elementStyle = itemSelected;
  } else {
    elementStyle = `${props.disabled ? boxDisabled : boxClickable}`;
  }
  const handleClick = () => {
    if (!props.disabled) props.setSelectedItem(props.id);
  };

  return (
    <Grid xs={isSmallScreen ? 2 : 12} className={mainDiv}>
      <Link
        to={`/campaigns/reporting/${props.campaignId}/${props.id}`}
        className={link}
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
          }
        }}
      >
        <MuiThemeProvider theme={themeTooltip}>
          <Tooltip
            classes={{ tooltip: tooltip }}
            title={
              props.disabled ? (
                <DetailedTooltip
                  icon={props.icon}
                  title={props.title}
                  closeTooltip={() => setOpenTooltip(false)}
                />
              ) : (
                props.title
              )
            }
            placement={!isSmallScreen ? 'right-end' : 'top'}
            open={openTooltip}
          >
            <Box
              className={elementStyle}
              my={isSmallScreen ? 0 : '1rem'}
              py={isSmallScreen ? '0.8rem' : '0.2rem'}
              onClick={handleClick}
            >
              <Grid
                wrap='nowrap'
                container
                direction={isSmallScreen ? 'column' : 'row'}
                alignItems='center'
                justifyContent='center'
              >
                <Grid
                  container
                  item
                  wrap='nowrap'
                  justifyContent='flex-start'
                  className={title}
                >
                  <Box
                    display='flex'
                    id='icon'
                    className={root}
                    mx='0.5rem'
                    ml='1.3rem'
                  >
                    <Icon className={thumbnailIcon}>{props.icon}</Icon>
                  </Box>
                  <Grid className={text}>
                    <Box display='flex' className={root}>
                      <Body2 noWrap align='left' className={title} id='title'>
                        {' '}
                        {props.title}{' '}
                      </Body2>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Tooltip>
        </MuiThemeProvider>
      </Link>
    </Grid>
  );
};

export default NavElement;
