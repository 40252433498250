import { Grid, List, Paper } from '@material-ui/core';
import { Prediction, Report } from '../../types';
import React, { useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getKpiName, { getFullNotation, mapKpis } from '../../utils/setKpiCols';

import BootstrapTooltip from '../ui/bootstrapTooltip';
import ForecastPlot from '../charts/Forecast';
import { KpiPrediction } from '../../types';
import { TabHeader } from '../ui/tabHeader';
import { getAverage } from '../../utils/computePerformance';
import { responsiveStyle } from '../../utils/Styles';

export interface PredictionProps {
  kpiCols: string[];
  campaignName: string;
  predictions: Prediction;
  report: Report;
  dates: { min: string; max: string };
}

const getGraphData = (
  _kpiPrediction: KpiPrediction,
  report: Report,
  kpi: string
) => {
  const _prediction: number[][] = [];
  const _upper: number[][] = [];
  const _lower: number[][] = [];
  const _actual: number[][] = [];
  let _forecastFrom: Date = new Date();

  _kpiPrediction.dates.map((date, i) => {
    _prediction.push([date, _kpiPrediction.predictions[i]]);
    _upper.push([date, _kpiPrediction.upper[i]]);
    _lower.push([date, _kpiPrediction.lower[i]]);
  });

  let dateSplitter = 0;
  if (report.daily.date.length > 30)
    dateSplitter = report.daily.date.length - 30;

  /*
   * report.daily.<>.slice(dateSplitter) will slice and return the
   * latest 30 days report. This is required to depict the prediction
   * a little bit better
   */

  const _intervals = { upper: _upper, lower: _lower };
  report.daily.date.slice(dateSplitter).map((date, i) => {
    _actual.push([+new Date(date), report.daily[kpi].slice(dateSplitter)[i]]);
    // add last actual date's data to connect prediction with actual data on graph
    if (report.daily.date.slice(dateSplitter).length === i + 1) {
      _forecastFrom = new Date(report.daily.date.slice(dateSplitter)[i]);
      _prediction.unshift([+new Date(date), report.daily[kpi].slice(dateSplitter)[i]]);
      _upper.unshift([+new Date(date), report.daily[kpi].slice(dateSplitter)[i]]);
      _lower.unshift([+new Date(date), report.daily[kpi].slice(dateSplitter)[i]]);
    }
  });

  return {
    prediction: _prediction,
    actual: _actual,
    intervals: _intervals,
    forecastFrom: _forecastFrom,
  };
};

const PredictionBody = ({
  kpiCols,
  campaignName,
  predictions,
  report,
  dates,
}: PredictionProps) => {
  const classes = responsiveStyle();
  const kpiList = mapKpis(kpiCols, true, true, true, true);

  const initKpiPrediction = predictions.find(
    (pred) => pred.kpi.toLowerCase() === 'er'
  ) as KpiPrediction;
  const initPredictionData = getGraphData(initKpiPrediction, report, 'er');

  const [kpi, setKpi] = useState<string>('er');

  const [actual, setAcutal] = useState<number[][]>(initPredictionData.actual);
  const [predictionVals, setPredictionVals] = useState<number[][]>(
    initPredictionData.prediction
  );
  const [intervals, setIntervals] = useState<{
    upper: number[][];
    lower: number[][];
  }>(initPredictionData.intervals);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newKpi: string | null
  ) => {
    if (newKpi === null) {
      return;
    }

    const _kpiPrediction = predictions.filter(
      (pred) => pred.kpi.toLowerCase() === newKpi
    )[0];

    const predictionData = getGraphData(_kpiPrediction, report, newKpi);

    setKpi(newKpi);
    setAcutal(predictionData.actual);
    setPredictionVals(predictionData.prediction);
    setIntervals(predictionData.intervals);
  };

  const averagePerfomance = getAverage(actual);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <TabHeader
              dates={dates}
              header={'Performance Prediction Dashboard'}
            />
          </List>
        </Grid>
        <Grid item xs={12}>
          <ToggleButtonGroup
            color='primary'
            value={kpi}
            size='small'
            exclusive
            onChange={handleChange}
            aria-label={'kpi buttons'}
          >
            {kpiList.map((element) => (
              <ToggleButton
                key={element.value}
                value={element.value}
                color='primary'
                aria-label={element.value}
                style={{ textTransform: 'none' }}
              >
                <BootstrapTooltip
                  key={element.label}
                  title={getFullNotation(element.value)}
                  placement='bottom'
                >
                  <span>{element.label}</span>
                </BootstrapTooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} className={classes.paper}>
            <ForecastPlot
              actual={actual}
              prediction={predictionVals}
              intervals={intervals}
              kpi={getKpiName(kpi, kpiList)}
              campaignName={campaignName}
              avergarePerfomance={averagePerfomance}
              forecastFrom={initPredictionData.forecastFrom}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default PredictionBody;
