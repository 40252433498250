import { List } from 'immutable';
import { TagRecord } from '../store/tagReducer';
import { DataMapType } from '..';

export default function (tag: TagRecord, data: DataMapType) {
  const thirdParty = tag.thirdParty;
  const requiredDefaultThirdParty = data.get(
    'third_party_engagement'
  ) as string[];
  // TODO: check if casting is working
  if (thirdParty) {
    const combinedThirdParty = List([
      ...requiredDefaultThirdParty,
      ...thirdParty,
    ]) // Combine default tags, and user added tags
      .map((v) => v.trim()) // Remove accidental whitespace
      .filter((v) => v !== ''); // Remove empty tags (Default tag in UI)
    return data.set('third_party_engagement', combinedThirdParty);
  }
  return data;
}
