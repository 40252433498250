import React, { useState } from 'react';

import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionPanelDetails,
  Body1,
  makeStyles,
  Switch,
  AdludioTheme,
  Divider,
  Body2,
} from '@adludio/components';
import { Paper, Chip } from '@material-ui/core';

const styles = makeStyles((theme: AdludioTheme) => ({
  summary: {
    width: '100%',
    paddingLeft: '1rem',
  },
  details: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0px !important',
  },
  expandIcon: {
    order: -1,
    '&.Mui-expanded': {
      transform: 'rotate(90deg) !important',
    },
  },
  fistAccordion: {
    margin: '0px !important',
  },
  secondAccordion: {
    margin: '0px !important',
  },
}));

interface CookieSettingsPanelProps {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  performanceConsent: boolean;
}

const CookieSettingsPanel = (props: CookieSettingsPanelProps) => {
  const [expanded, setExpanded] = useState('panel2');
  const { summary, details, expandIcon, secondAccordion, fistAccordion } =
    styles();

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid container item xs={12} justify='center' direction='column'>
      <Grid item xs className={details}>
        <Paper elevation={0}>
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            elevation={0}
            className={fistAccordion}
          >
            <AccordionSummary
              aria-controls='panel1d-content'
              id='panel1d-header'
              IconButtonProps={{ edge: 'end' }}
              classes={{ expandIcon }}
            >
              <Grid
                container
                item
                xs
                direction='row'
                alignItems='center'
                className={summary}
                justify='space-between'
              >
                <Body1>Functional Cookies</Body1>
                <Chip
                  size='small'
                  color='primary'
                  variant='outlined'
                  label='Always Active'
                />
              </Grid>
            </AccordionSummary>
            <AccordionPanelDetails aria-expanded>
              <Body2>
                Functional Cookies are cookies that we use in order to run the
                website and provide the basic functions across the platform.
                These cookies include ones that enable users to log into secure
                areas of the platform. These cookies do not track any personal
                data of visitors.
              </Body2>
            </AccordionPanelDetails>
          </Accordion>
          <Divider light />
          <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            elevation={0}
            className={secondAccordion}
          >
            <AccordionSummary
              aria-controls='panel2d-content'
              id='panel2d-header'
              IconButtonProps={{ edge: 'end' }}
              classes={{ expandIcon }}
            >
              <Grid
                container
                item
                xs
                direction='row'
                alignItems='center'
                className={summary}
                justify='space-between'
              >
                <Body1>Performance Cookies</Body1>
                <Box pr='1rem'>
                  <Switch
                    color='secondary'
                    onChange={props.onChange}
                    checked={props.performanceConsent}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </Box>
              </Grid>
            </AccordionSummary>
            <AccordionPanelDetails aria-expanded>
              <Body2>
                We use performance cookies via Hotjar in order to collect
                information about how users access our platform, how long they
                stay on the site for, and what parts of the site they visit.
                This is help us provide a overall user experience.
              </Body2>
            </AccordionPanelDetails>
          </Accordion>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CookieSettingsPanel;
