/* eslint-disable complexity */
import {
  AdludioTheme,
  Body2,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  useSnackbar,
  Box,
} from '@adludio/components';

import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDeleteAdunitMutation } from '../../generated/graphql';
import { AdunitData } from '../UploadAdunit';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  adunitId: string;
  setAdunits: Dispatch<SetStateAction<string[]>>;
  adunitData: AdunitData;
  adunits: string[];
  adunitsData: AdunitData[];
  setAdunitData: Dispatch<SetStateAction<AdunitData[]>>;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  content: {
    margin: '0.5rem 5rem',
  },
  buttonsSection: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
  },
  padding: {
    paddingBottom: '0',
  },
}));

export const DeleteAdunitModal = ({
  isOpen,
  onClose,
  adunitId,
  adunitData,
  adunits,
  adunitsData,
  setAdunitData,
  setAdunits,
}: DeleteModalProps) => {
  const { content, buttonsSection, padding } = styles();
  const [deleteAdunitMutation] = useDeleteAdunitMutation();
  const [refresh, setRefresh] = useState(false);

  React.useEffect(() => {
    if (refresh) {
      setRefresh(false);
      setAdunitData(adunitsData.filter((x) => x !== adunitData));
      const adunitList = adunits;
      adunitList[adunitsData.indexOf(adunitData)] = '';
      adunitList.splice(adunitList.indexOf(''), 1);
      setAdunits(adunitList);
    }
  }, [refresh]);

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={isOpen} onClose={onClose} hasCloseButton>
      <Box pt='2rem'>
        <DialogTitle className={padding}>
          <Grid container direction='row' justifyContent='center'>
            <Body2 align='right'>
              Are you sure you want to delete this AdUnit?
            </Body2>
          </Grid>
        </DialogTitle>
      </Box>
      <DialogContent className={content}>
        <Grid container justify='center'>
          <Grid
            item
            container
            direction='row'
            className={buttonsSection}
            xs={8}
          >
            <Grid container xs={6} justify='center'>
              <Button variant='outlined' onClick={onClose}>
                Cancel
              </Button>
            </Grid>
            <Grid container xs={6} justify='center'>
              <Button
                variant='contained'
                onClick={(e) => {
                  deleteAdunitMutation({
                    variables: { id: adunitId },
                  })
                    .then(() => setRefresh(true))
                    .catch((err) => {
                      console.log(err);
                      onClose();
                      enqueueSnackbar('Error trying to delete adunit', {
                        variant: 'error',
                      });
                    });
                  onClose();
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
