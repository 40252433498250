export const setConsent = (
  ssoBEURL: string,
  authToken: string,
  body: { consentKey: string; consentValue: string }
) => {
  return fetch(`//${ssoBEURL}/users/consent`, {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*',
    },
    body: JSON.stringify(body),
  });
};

export const getConsent = (ssoBEURL: string, authToken: string) => {
  return fetch(`//${ssoBEURL}/users/consent`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*',
    },
  });
};
