import { BreakdownReportValues, Report } from '../../types';
import { Grid, List, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import getKpiName, { mapKpis } from '../../utils/setKpiCols';

import DailyTrend from '../charts/daily-trends';
import NumericReport from './numericReport';
import SettingsContainer from './GraphSettingContainer';
import { TabHeader } from '../ui/tabHeader';
import { populateData } from '../../utils/compute';
import { responsiveStyle } from '../../utils/Styles';

export interface reportProps {
  report: Report;
  campaignName: string;
  kpiCols: string[];
  dates: { min: string; max: string };
}

const KPITrend = ({ report, dates, campaignName, kpiCols }: reportProps) => {
  const isMicrosoft = ['Microsoft Windows11 UK'].includes(campaignName);

  const [kpi1, setkpi1] = useState<string>('curatorRevenue');
  const [kpi2, setkpi2] = useState<string>('impression');

  let kpilist;
  if (isMicrosoft) {
    kpilist = [{ value: 'imps', label: 'Impression' }].concat(
      mapKpis(kpiCols).filter(({ value, label }) =>
        ['curatorRevenue', 'er', 'ctr', 'ectr', 'vtr', 'viewability'].includes(
          value
        )
      )
    );
  } else {
    kpilist = mapKpis(kpiCols);
  }

  const chartTitle = 'Daily Trends';


  const [categories, setcategories] = useState<string[]>(report.daily['date']);
  const [data1, setdata1] = useState(report.daily[kpi1]);
  const [data2, setdata2] = useState(report.daily[kpi2]);

  React.useEffect(() => {
    if (['Microsoft Windows11 UK'].includes(campaignName)) {
      if (kpi2 === 'impression') {
        setkpi2('imps');
      }
    }
  }, [campaignName, kpi2]);

  React.useEffect(() => {
    setcategories(report.daily['date']);
  }, [report.daily]);
  React.useEffect(() => {
    setdata1(report.daily[kpi1]);
  }, [report.daily, kpi1]);
  React.useEffect(() => {
    setdata2(report.daily[kpi2]);
  }, [report.daily, kpi2]);

  const y1axix = getKpiName(kpi1, kpilist);
  const y2axis = getKpiName(kpi2, kpilist);
  const handleKPI1Select = (e: any) => {
    let selectedValue: string = (e.target as any).value;
    setkpi1(selectedValue);
  };

  const handleKPI2Select = (e: any) => {
    console.log(e.target.value);
    let selectedValue: string = (e.target as any).value;
    setkpi2(selectedValue);
  };
  const classes = responsiveStyle();

  const dailyReport = report.daily;
  const TableRows = populateData(dailyReport, kpiCols);
  const overallReport: BreakdownReportValues = {
    '': {
      daily: report.daily,
      overview: report.overview,
    },
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <TabHeader dates={dates} header={'Live Performance Dashboard'} />
          </List>
        </Grid>
        <Grid item xs={12}>
          <SettingsContainer
            dates={dates}
            kpi1={kpi1}
            kpi2={kpi2}
            kpilist={kpilist}
            handleKPI1Select={handleKPI1Select}
            handleKPI2Select={handleKPI2Select}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <DailyTrend
              chartTitle={chartTitle}
              data1={data1}
              data2={data2}
              categories={categories}
              y1Label={y1axix}
              y2Label={y2axis}
              campaignName={campaignName}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <NumericReport
              report={overallReport}
              tableRows={TableRows}
              campaignName={report.campaignName.toString()}
              kpiCols={kpiCols}
              titleText={'Daily Performance'}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default KPITrend;
