// Returns an array of dates between the two dates
export function getDatesBetweenDates(start: Date|undefined, end: Date|undefined) {
  let arr = [];
  if (start){

    let dt = new Date(start);
    if (end){
      while (dt <= end) {
        arr.push(formatDateString(new Date(dt)));
        dt.setDate(dt.getDate() + 1);
      }
    }

  }

  return arr;
}

export function getMinMaxDate(dates: string[]) {
  const min = dates.reduce((acc, date) => { return acc && new Date(acc) < new Date(date) ? acc : date; }, '');
  const max = dates.reduce((acc, date) => { return acc && new Date(acc) > new Date(date) ? acc : date; }, '');

  return { min, max };
}

export function formatDateString(date: Date|undefined) {
  if (date){
    let dd:string|number = date.getDate();
    let mm:string|number = date.getMonth() + 1;
    let yyyy:string|number = date.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${yyyy}-${mm}-${dd}`;
  }
  return '';
}
