import React from 'react';
import { Divider, List, ListItem } from '@adludio/components';
import { ReportOverview } from '../../types';
import numeral from 'numeral';
import KpiCard from '../kpis/KpiCard';
import { responsiveStyle } from '../../utils/Styles';
import { Layout } from '../../types/Report';
import SummaryMetric from './summaryMetric';

export interface SummarySectionProps {
  SummaryData: ReportOverview;
  layout: Layout;
  kpiCols: string[];
}
export default function SummarySection({
  SummaryData,
  layout,
  kpiCols,
}: SummarySectionProps) {
  const classes = responsiveStyle();
  function isKPI(kpi: string) {
    return kpiCols.includes(kpi);
  }
  const getValue = (metric?: number) => (metric ? metric : 0);

  return (
    <List className={classes.overviewSection}>
      {/* Spend */}
      <SummaryMetric disable={layout.summary.spend.show} hasValue>
        <>
          <ListItem>
            <KpiCard
              spend={{
                primary: numeral(SummaryData.curatorRevenue).format('$0.00a'),
                secondary: 'Spend',
                progress: getValue(SummaryData.spendProgress),
              }}
              tooltipText={'Overall Spend'}
            />
          </ListItem>
        </>
      </SummaryMetric>
      {/* Pacing */}
      <SummaryMetric disable={layout.summary.pacing.show} hasValue>
        <>
          <ListItem>
            <KpiCard
              pacing={{
                primary: `${numeral(SummaryData.pacing).format('0.0')}%`,
                secondary: 'Pacing',
                pacingFlag: getValue(SummaryData.pacing),
                pacingMoney: getValue(SummaryData.offBudget),
              }}
              tooltipText={`${numeral(SummaryData.pacing).format(
                '0.0'
              )}% spending status`}
            />
          </ListItem>
          <Divider orientation='vertical' flexItem />
        </>
      </SummaryMetric>

      {/* Impression */}
      <SummaryMetric disable={layout.summary.impression.show} hasValue>
        <>
          <ListItem>
            <KpiCard
              count={{
                primary: numeral(
                  layout.summary.impression.value === 'xandr'
                    ? SummaryData.imps
                    : SummaryData.impression
                ).format('0.0a'),
                secondary: 'Impression',
              }}
              tooltipText={
                'Number of times ad was rendered on the screen of end user'
              }
            />
          </ListItem>
        </>
      </SummaryMetric>

      {/* Engagement | ER Divider */}
      <SummaryMetric
        disable={layout.summary.er.show || layout.summary.engagement}
        hasValue={isKPI('engagement')}
      >
        <Divider orientation='vertical' flexItem />
      </SummaryMetric>
      {/* Engagement */}
      <SummaryMetric
        disable={layout.summary.engagement}
        hasValue={isKPI('engagement')}
      >
        <ListItem>
          <KpiCard
            count={{
              primary: numeral(SummaryData.engagement).format('0.0a'),
              secondary: 'Engagements',
            }}
            tooltipText={'Number of times end user engaged with our ad'}
          />
        </ListItem>
      </SummaryMetric>
      {/* ER */}
      <SummaryMetric
        disable={layout.summary.er.show}
        hasValue={isKPI('engagement')}
      >
        <ListItem>
          <KpiCard
            rate={{
              primary: SummaryData.er,
              secondary: 'ER',
              baseline: getValue(SummaryData.erBenchmark),
            }}
            tooltipText={
              'Engagement Rate (or ER) is the percentage of people who saw a piece of content or ad, and engaged with it'
            }
          />
        </ListItem>
      </SummaryMetric>

      {/* ER | CTR Attention Time */}
      <SummaryMetric disable={layout.summary.aat} hasValue>
        <ListItem>
          <KpiCard
            count={{
              primary: `${numeral(SummaryData.aat).format('0.0a')} s`,
              secondary: 'ER Attention Time',
            }}
            tooltipText={
              'The average amount of time in seconds a person spent viewing the ad before engaged'
            }
          />
        </ListItem>
      </SummaryMetric>

      {/* Click | CTR | eCTR Divider */}
      <SummaryMetric
        disable={
          layout.summary.click ||
          layout.summary.ctr.show ||
          layout.summary.ectr.show
        }
        hasValue={isKPI('click')}
      >
        <Divider orientation='vertical' flexItem />
      </SummaryMetric>
      {/* Click */}
      <SummaryMetric disable={layout.summary.click} hasValue={isKPI('click')}>
        <ListItem>
          <KpiCard
            count={{
              primary: numeral(SummaryData.click).format('0.0a'),
              secondary: 'Clicks',
            }}
            tooltipText={
              'Number of times end user clicked on CTA button in our ad'
            }
          />
        </ListItem>
      </SummaryMetric>
      {/* CTR */}
      <SummaryMetric
        disable={layout.summary.ctr.show}
        hasValue={isKPI('click')}
      >
        <ListItem>
          <KpiCard
            rate={{
              primary: SummaryData.ctr,
              secondary: 'CTR',
              baseline: getValue(SummaryData.ctrBenchmark),
            }}
            tooltipText={
              'CTR is the number of clicks that your ad receives divided by the number of times your ad is shown: clicks ÷ impressions = CTR'
            }
          />
        </ListItem>
      </SummaryMetric>
      {/* eCTR */}
      <SummaryMetric
        disable={layout.summary.ectr.show}
        hasValue={isKPI('click')}
      >
        <ListItem>
          <KpiCard
            rate={{
              primary: SummaryData.ectr,
              secondary: 'eCTR',
              baseline: getValue(SummaryData.ectrBenchark),
            }}
            tooltipText={
              'The expected clickthrough rate (eCTR) measures how likely it is that your ads will get clicked when shown for that keyword'
            }
          />
        </ListItem>
      </SummaryMetric>
      {/* CTR  */}
      <SummaryMetric disable={layout.summary.ctrAat} hasValue>
        <ListItem>
          <KpiCard
            count={{
              primary: `${numeral(SummaryData.ctrAat).format('0.0a')} s`,
              secondary: 'CTR Attention Time',
            }}
            tooltipText={
              'The average amount of time in seconds a person spent engaging with the ad before click'
            }
          />
        </ListItem>
      </SummaryMetric>
      {/* videoEnd | VTR Divider */}
      <SummaryMetric
        disable={layout.summary.views || layout.summary.vtr.show}
        hasValue={isKPI('click')}
      >
        <Divider orientation='vertical' flexItem />
      </SummaryMetric>
      {/* videoEnd */}
      <SummaryMetric
        disable={layout.summary.views}
        hasValue={isKPI('videoEnd')}
      >
        <ListItem>
          <KpiCard
            count={{
              primary: numeral(SummaryData.videoEnd).format('0.0a'),
              secondary: 'Views',
            }}
            tooltipText={
              'Number of times end user stayed in the ad till the end of the video in our ad'
            }
          />
        </ListItem>
      </SummaryMetric>
      {/* VTR */}
      <SummaryMetric
        disable={layout.summary.vtr.show}
        hasValue={isKPI('videoEnd')}
      >
        <ListItem>
          <KpiCard
            rate={{
              primary: SummaryData.vtr,
              secondary: 'VTR',
              baseline: getValue(SummaryData.vtrBenchmark),
            }}
            tooltipText={
              'View Through Rate (VTR) is the number of completed views of a skippable ad over the number of initial impressions'
            }
          />
        </ListItem>
      </SummaryMetric>

      {/* Viewable Imps | Viewability Divider */}
      <SummaryMetric
        disable={layout.summary.viewableImp || layout.summary.viewability}
        hasValue={isKPI('viewedImps')}
      >
        <Divider orientation='vertical' flexItem />
      </SummaryMetric>
      {/* Viewable Imps */}
      <SummaryMetric
        disable={layout.summary.viewableImp}
        hasValue={isKPI('viewedImps')}
      >
        <ListItem>
          <KpiCard
            count={{
              primary: numeral(SummaryData.viewedImps).format('0.0a'),
              secondary: 'Viewable Imps',
            }}
            tooltipText={'Viewable Impressions as per IAB MRC standard'}
          />
        </ListItem>
      </SummaryMetric>
    </List>
  );
}
