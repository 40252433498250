import React from 'react';

import AdludioAdmin from '@adludio/adludio-admin';

import { RouteComponentProps } from '@reach/router';
import { ConfigProps } from '../';

const AdludioAdminContainer = ({
  config
}: RouteComponentProps & ConfigProps) => {
  return <AdludioAdmin env={config.env} />;
};

export default AdludioAdminContainer;
