import { Grid, useSnackbar } from '@adludio/components';

import React from 'react';
import UpdatePassword from './UpdatePassword';

export type AdludioError = {
  message: string;
  status: number;
  label: string;
};
const passwordRegexp : RegExp = new RegExp( /^.*(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}.*$/);
// Regex matches strings with at least one capital letter, one special character and one number that has a length 8 or higher

const UpdatePasswordContainer = () => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [invalidPassword, toggleInvalidPassword] = React.useState(false);
  const [incorrectPassword, toggleIncorrectPassword] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  function resetState() {
    toggleInvalidPassword(false);
    toggleIncorrectPassword(false);
    setCurrentPassword('');
    setNewPassword('');
  }

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.currentTarget;
    if (name === 'currentPassword'){
      setCurrentPassword(value);
    } else {
      setNewPassword(value);
      toggleInvalidPassword(!passwordRegexp.test(value));
    }
  }

  function handleIncorrectPassword() {
    toggleInvalidPassword(false);
    toggleIncorrectPassword(true);
  }

  function handleErrors(error: AdludioError) {
    const { status } = error;

    if (status === 400) toggleInvalidPassword(true);
    else if (status === 401) handleIncorrectPassword();
    else enqueueSnackbar('Something went wrong', { variant: 'error' });
  }

  return (
    <Grid item xs={12}>
      <UpdatePassword
        currentPassword={currentPassword}
        newPassword={newPassword}
        invalidPassword={invalidPassword}
        incorrectPassword={incorrectPassword}
        resetState={resetState}
        handlePasswordChange={handlePasswordChange}
        handleErrors={handleErrors}
      />
    </Grid>
  );
};

export default UpdatePasswordContainer;
