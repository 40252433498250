import { AppBar, Divider, Typography } from '@material-ui/core';
import { Box, List, ListItem, Skeleton } from '@adludio/components';
import { Tab, Tabs } from '@adludio/components';

import React from 'react';
import { responsiveStyle } from '../../utils/Styles';

function DashboardSkeleton() {
  const classes = responsiveStyle();

  return (
    <Box mt='2rem' className={classes.container}>
      <List>
        <ListItem>
          <List className={classes.overviewSection}>
            {[...Array(5)].map((i) => (
              <ListItem key={i}>
                <div style={{ width: '200px' }}>
                  <Typography variant='h2'>
                    <Skeleton />
                  </Typography>
                  <Skeleton />
                </div>
              </ListItem>
            ))}
          </List>
        </ListItem>
      </List>

      <Divider />
      <AppBar position='static' color='transparent' elevation={1}>
        <Tabs
          classes={{
            root: classes.centeredItems,
            scroller: classes.scroller,
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
          variant={'scrollable'}
          scrollButtons={'auto'}
        >
          {[...Array(5)].map((i) => (
            <Skeleton key={i} style={{ marginInline: '10px', height: '90px' }}>
              <Tab />
            </Skeleton>
          ))}
        </Tabs>
      </AppBar>
      <Divider />

      <Box style={{ paddingInline: '2%' }}>
        <Typography variant='h3'>
          <Skeleton />
        </Typography>
        <Box style={{ height: '10px' }} />
        <Skeleton variant='rect' height={400} />
      </Box>
    </Box>
  );
}

export default DashboardSkeleton;
