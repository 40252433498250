/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import {
  AdludioTheme,
  Grid,
  TextInput,
  makeStyles,
  Subtitle1,
  Body2,
  Link,
  IconButton,
  Box,
} from '@adludio/components';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Card,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import { FormikProps } from 'formik';

import { filter } from 'lodash';
import { validateLink } from '../../../helpers/validateLink';
import { AssetUpload } from './AssetUpload';
import { CreativeFormValues, ManagedFormValues } from '../types';
import { preventBriefExit } from '../../../helpers/preventBriefExit';

const styles = makeStyles((theme: AdludioTheme) => ({
  column: {
    display: 'flex',
    rowGap: '1.5rem',
  },
  helperText: {
    marginLeft: '0px',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  addLink: {
    color: theme.palette.grey[600],
  },
  mobileCard: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  errorText: {
    color: theme.palette.error.main,
  },
  linkText: {
    color: '#A100FF',
    textDecoration: 'underline',
  },
}));

export function joinWithoutEmpty(values: string[], delimiter = ',') {
  return filter(values).join(delimiter);
}

export const CreativeForm = ({
  formikProps,
  campaignId,
  isManaged,
  wasSaved,
}: {
  formikProps: FormikProps<ManagedFormValues> | FormikProps<CreativeFormValues>;
  campaignId: string;
  isManaged?: boolean;
  wasSaved: boolean;
}) => {
  const { values, handleChange, setFieldValue, errors, setFieldError } =
    formikProps;
  const { column, addLink, purple, mobileCard, linkText } = styles();

  const [openDeviceInput, setOpenDeviceInput] = useState(false);
  const [openDimensionsInput, setOpenDimensionsInput] = useState(false);

  const handleAdunitType = (e: any) => {
    e.target.checked && values.adunitType === ''
      ? setFieldValue('adunitType', e.target.name)
      : setFieldValue(
          'adunitType',
          values.adunitType.concat(', ', e.target.name)
        );
  };

  const blockExit = preventBriefExit(wasSaved, formikProps.values);

  useEffect(() => {
    blockExit;
  }, [formikProps.values, wasSaved]);

  const mobileWebImg = require('../../../static-assets/mobile-web.png');
  const mobileAppImg = require('../../../static-assets/mobile-app.png');

  return (
    <Grid container justify='center'>
      <Grid container xs={10} md={8}>
        <Box width='100%' justifyContent='center' mb='1rem'>
          <Grid className={column} container item xs={12}>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Your Brand</Subtitle1>
              </Box>
            </Grid>
            <Grid container item xs={12}>
              {/* WEBSITELINKS */}
              {values.websiteUrl?.split(',').map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box width='100%' key={index} my='0.5rem'>
                  <Grid container item xs justify='space-between'>
                    <Grid item xs={12}>
                      <TextInput
                        id='websiteUrl'
                        name='websiteUrl'
                        variant='outlined'
                        label='Brand website links'
                        value={url.trim()}
                        onChange={(e) => {
                          if (
                            e.target.value[e.target.value.length - 1] === ',' ||
                            e.target.value[e.target.value.length - 1] === ' '
                          )
                            return;
                          const newValue = values.websiteUrl
                            ? values.websiteUrl?.split(',')
                            : [''];
                          newValue[index] = e.target.value;
                          setFieldValue(
                            'websiteUrl',
                            joinWithoutEmpty(newValue)
                          );
                        }}
                        error={url.trim() !== '' && !validateLink(url)}
                        helperText={
                          errors.websiteUrl ??
                          (!validateLink(url) && 'Introduce a valid URL')
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {values.websiteUrl?.split(',').length > 1 && (
                        <Box pt='0.7rem'>
                          <IconButton
                            onClick={() => {
                              const newValue = values.websiteUrl.split(',');
                              newValue.splice(index, 1);
                              setFieldValue(
                                'websiteUrl',
                                joinWithoutEmpty(newValue)
                              );
                            }}
                            label=''
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {!values.websiteUrl?.split(',').includes(' ') &&
                !values.websiteUrl?.split(',').includes('') && (
                  <Grid container item xs={12}>
                    <Body2>
                      <Link
                        className={addLink}
                        onClick={() =>
                          setFieldValue(
                            'websiteUrl',
                            joinWithoutEmpty(
                              values.websiteUrl.concat(', ').split(',')
                            )
                          )
                        }
                      >
                        <Grid container item xs alignItems='center'>
                          <Box mr='0.3rem'>
                            <AddCircle />
                          </Box>
                          <Body2>Add another URL</Body2>
                        </Grid>
                      </Link>
                    </Body2>
                  </Grid>
                )}
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Device</Subtitle1>
                <Body2>Please select all applicable</Body2>
              </Box>
            </Grid>
            <Grid item container xs={12} direction='column'>
              <Grid item container xs={12}>
                <Box mr='2rem'>
                  <Card variant='outlined'>
                    <Box p='1rem' mt='1rem' mx='1rem'>
                      <Grid item container className={mobileCard}>
                        <img
                          alt='mobile'
                          style={{ width: '80%' }}
                          src={String(mobileWebImg.default)}
                        />
                        <Box mt='1rem'>
                          <Body2>Mobile Web</Body2>
                        </Box>
                        <Checkbox
                          color='primary'
                          name='mobileWeb'
                          value='Mobile Web'
                          checked={values.device?.includes('Mobile Web')}
                          onChange={(e) => {
                            e.target.checked
                              ? setFieldValue(
                                  'device',
                                  values.device?.concat(`${e.target.value}, `)
                                )
                              : setFieldValue(
                                  'device',
                                  values.device?.replace(
                                    `${e.target.value}, `,
                                    ''
                                  )
                                );
                          }}
                        />
                      </Grid>
                    </Box>
                  </Card>
                </Box>
                <Card variant='outlined'>
                  <Box p='1rem' mt='1rem' mx='1rem'>
                    <Grid item container className={mobileCard}>
                      <img
                        alt='mobile'
                        style={{ width: '80%' }}
                        src={String(mobileAppImg.default)}
                      />
                      <Box mt='1rem'>
                        <Body2>Mobile App</Body2>
                      </Box>
                      <Checkbox
                        color='primary'
                        name='mobileApp'
                        value='Mobile App'
                        checked={values.device?.includes('Mobile App')}
                        onChange={(e) => {
                          e.target.checked
                            ? setFieldValue(
                                'device',
                                values.device?.concat(`${e.target.value}, `)
                              )
                            : setFieldValue(
                                'device',
                                values.device?.replace(
                                  `${e.target.value}, `,
                                  ''
                                )
                              );
                        }}
                      />
                    </Grid>
                  </Box>
                </Card>
                <Grid item xs={12}>
                  <Box mt='1rem' ml='0.25rem'>
                    <FormControlLabel
                      label='Other'
                      control={
                        <Checkbox
                          color='primary'
                          name='other'
                          onChange={(e) => {
                            e.target.checked
                              ? setOpenDeviceInput(true)
                              : setOpenDeviceInput(false);
                          }}
                        />
                      }
                    />
                  </Box>
                  {openDeviceInput && (
                    <Grid container xs={12}>
                      <Box mt='0.5rem' width='100%'>
                        <TextInput
                          id='device'
                          name='device'
                          variant='outlined'
                          value={
                            values.device?.includes('Other: ')
                              ? values.device.substring(
                                  values.device.indexOf('O') + 6,
                                  values.device.indexOf(';')
                                )
                              : ''
                          }
                          onChange={(e) => {
                            if (values.device?.includes('Other: ')) {
                              var mySubString = values.device?.substring(
                                values.device.indexOf('O'),
                                values.device.lastIndexOf(';') + 1
                              );
                              if (e.target.value.length === 0) {
                                setFieldValue(
                                  'device',
                                  values.device?.replace(mySubString, '')
                                );
                                return;
                              }
                              setFieldValue(
                                'device',
                                values.device?.replace(
                                  mySubString,
                                  `Other:${e.target.value};`
                                )
                              );
                            } else {
                              setFieldValue(
                                'device',
                                values.device?.concat(
                                  `Other: ${e.target.value};`
                                )
                              );
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Ad Unit Type</Subtitle1>
                <Body2>
                  Please select the elements you would like the ad unit to have
                </Body2>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth onChange={handleAdunitType}>
                <FormControlLabel
                  label='Rich Media Display'
                  control={
                    <Checkbox color='primary' name='Rich Media Display' />
                  }
                />
                <FormControlLabel
                  label='Standard Banner'
                  control={<Checkbox color='primary' name='Standard Banner' />}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Ad Unit dimensions</Subtitle1>
                <Body2>
                  Please select the elements you would like the ad unit to have
                </Body2>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  label='Full Screen (320 x 480)'
                  control={
                    <Checkbox
                      color='primary'
                      value='Full Screen (320 x 480)'
                      checked={values.adunitDimensions?.includes(
                        'Full Screen (320 x 480)'
                      )}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.concat(
                                `${e.target.value}, `
                              )
                            )
                          : setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.replace(
                                `${e.target.value}, `,
                                ''
                              )
                            );
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='Mid Page Unit (300 x 250)'
                  control={
                    <Checkbox
                      color='primary'
                      value='Mid Page Unit (300 x 250)'
                      checked={values.adunitDimensions?.includes(
                        'Mid Page Unit (300 x 250)'
                      )}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.concat(
                                `${e.target.value}, `
                              )
                            )
                          : setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.replace(
                                `${e.target.value}, `,
                                ''
                              )
                            );
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='336 x 280px'
                  control={
                    <Checkbox
                      color='primary'
                      value='336 x 280px'
                      checked={values.adunitDimensions?.includes('336 x 280px')}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.concat(
                                `${e.target.value}, `
                              )
                            )
                          : setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.replace(
                                `${e.target.value}, `,
                                ''
                              )
                            );
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='300 x 600px'
                  control={
                    <Checkbox
                      color='primary'
                      value='300 x 600px'
                      checked={values.adunitDimensions?.includes('300 x 600px')}
                      onChange={(e) => {
                        e.target.checked
                          ? setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.concat(
                                `${e.target.value}, `
                              )
                            )
                          : setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.replace(
                                `${e.target.value}, `,
                                ''
                              )
                            );
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label='Other'
                  control={
                    <Checkbox
                      color='primary'
                      value='Other'
                      onChange={(e) => {
                        e.target.checked
                          ? setOpenDimensionsInput(true)
                          : setOpenDimensionsInput(false);
                      }}
                    />
                  }
                />
                {openDimensionsInput && (
                  <Grid container xs={12}>
                    <Box mt='0.5rem' width='100%'>
                      <TextInput
                        id='adunitDimensions'
                        name='adunitDimensions'
                        variant='outlined'
                        value={
                          values.adunitDimensions?.includes('Other: ')
                            ? values.adunitDimensions.substring(
                                values.adunitDimensions.indexOf('O') + 6,
                                values.adunitDimensions.indexOf(';')
                              )
                            : ''
                        }
                        onChange={(e) => {
                          if (values.adunitDimensions?.includes('Other: ')) {
                            var mySubString =
                              values.adunitDimensions?.substring(
                                values.adunitDimensions.indexOf('O'),
                                values.adunitDimensions.lastIndexOf(';') + 1
                              );
                            if (e.target.value.length === 0) {
                              setFieldValue(
                                'adunitDimensions',
                                values.adunitDimensions?.replace(
                                  mySubString,
                                  ''
                                )
                              );
                              return;
                            }
                            setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.replace(
                                mySubString,
                                `Other:${e.target.value};`
                              )
                            );
                          } else {
                            setFieldValue(
                              'adunitDimensions',
                              values.adunitDimensions?.concat(
                                `Other: ${e.target.value};`
                              )
                            );
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem' mb='0.75rem'>
                <Subtitle1 className={purple}>Ad Unit Copy</Subtitle1>
              </Box>
              <Body2>
                Please input the copy to be included in the ad unit, including
                any translations if needed (case of multi-market campaigns) and
                any Terms & Conditions applicable.
              </Body2>
            </Grid>
            <Grid item xs={12}>
              <Body2 noWrap>
                <Link
                  className={linkText}
                  onClick={() =>
                    window.open(
                      'https://s3.eu-west-1.amazonaws.com/cdn.adludio.com/assets/Ad+Unit+User+Flow.pptx.pdf'
                    )
                  }
                >
                  Guide for recommended copy
                </Link>
              </Body2>
            </Grid>
            <Grid item xs={12}>
              <Box width='100%'>
                <TextInput
                  multiline
                  variant='outlined'
                  minRows={5}
                  fullWidth
                  id='adunitCopy'
                  name='adunitCopy'
                  value={values.adunitCopy}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Share your assets</Subtitle1>
              </Box>
              <Body2>
                Please upload all relevant assets including logos, font files,
                high res images and brand guidelines. For more information on
                accepted file types please refer to our guide below.
              </Body2>
            </Grid>
            <Grid item xs={12}>
              <Body2 noWrap>
                <Link
                  className={linkText}
                  onClick={() =>
                    window.open(
                      'https://s3.eu-west-1.amazonaws.com/cdn.adludio.com/assets/Assets+Spec+Sheet.pptx.pdf'
                    )
                  }
                >
                  Guide for recommended assets
                </Link>
              </Body2>
            </Grid>

            <FormControl required={true} fullWidth={true}>
              <AssetUpload
                formikProps={formikProps}
                campaignId={campaignId}
                isManaged={!!isManaged}
              />

              {/* Assets Links */}
              {values.assetsUrl?.split(',').map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box width='100%' key={index} my='0.5rem' mt='1.5rem'>
                  <Grid container item xs justify='space-between'>
                    <Grid item xs={12}>
                      <TextInput
                        id='assetsUrl'
                        name='assetsUrl'
                        variant='outlined'
                        label='Assets links'
                        value={url}
                        onChange={(e) => {
                          setFieldError('assetFiles', '');
                          if (
                            e.target.value[e.target.value.length - 1] === ',' ||
                            e.target.value[e.target.value.length - 1] === ' '
                          )
                            return;
                          const newValue = values.assetsUrl
                            ? values.assetsUrl?.split(',')
                            : [''];
                          newValue[index] = e.target.value;
                          setFieldValue(
                            'assetsUrl',
                            joinWithoutEmpty(newValue)
                          );
                        }}
                        helperText={
                          errors.assetsUrl ??
                          (url !== '' &&
                            !validateLink(url) &&
                            'Introduce a valid URL')
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {values.assetsUrl?.split(',').length > 1 && (
                        <IconButton
                          onClick={() => {
                            const newValue = values.assetsUrl.split(',');
                            newValue.splice(index, 1);
                            setFieldValue(
                              'assetsUrl',
                              joinWithoutEmpty(newValue)
                            );
                          }}
                          label=''
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </FormControl>
            {!values.websiteUrl?.split(',').includes(' ') &&
              !values.websiteUrl?.split(',').includes('') && (
                <Grid container item xs={12}>
                  <Body2>
                    <Link
                      className={addLink}
                      onClick={() =>
                        setFieldValue(
                          'assetsUrl',
                          joinWithoutEmpty(
                            values.assetsUrl.concat(', ').split(',')
                          )
                        )
                      }
                    >
                      <Grid container item xs alignItems='center'>
                        <Box mr='0.3rem'>
                          <AddCircle />
                        </Box>
                        <Body2>Add another URL</Body2>
                      </Grid>
                    </Link>
                  </Body2>
                </Grid>
              )}
            <Grid item xs={12}>
              <Box mt='1.5rem'>
                <Subtitle1 className={purple}>Clickthrough URL</Subtitle1>
              </Box>
              <Body2>
                Please copy and paste the clickthrough URL for the ad unit to
                link to.
              </Body2>
            </Grid>
            <Grid container item xs={12}>
              {/* WEBSITELINKS */}
              {values.clicktroughUrl?.split(',').map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box width='100%' key={index} my='0.5rem'>
                  <Grid container item xs justify='space-between'>
                    <Grid item xs={12}>
                      <TextInput
                        id='clicktroughUrl'
                        name='clicktroughUrl'
                        variant='outlined'
                        label='Clickthrough URL'
                        value={url.trim()}
                        onChange={(e) => {
                          if (
                            e.target.value[e.target.value.length - 1] === ',' ||
                            e.target.value[e.target.value.length - 1] === ' '
                          )
                            return;
                          const newValue = values.clicktroughUrl
                            ? values.clicktroughUrl?.split(',')
                            : [''];
                          newValue[index] = e.target.value;
                          setFieldValue(
                            'clicktroughUrl',
                            joinWithoutEmpty(newValue)
                          );
                        }}
                        error={url.trim() !== '' && !validateLink(url)}
                        helperText={
                          errors.clicktroughUrl ??
                          (url.trim() !== '' &&
                            !validateLink(url) &&
                            'Introduce a valid URL')
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {values.clicktroughUrl?.split(',').length > 1 && (
                        <Box pt='0.7rem'>
                          <IconButton
                            onClick={() => {
                              const newValue = values.clicktroughUrl.split(',');
                              newValue.splice(index, 1);
                              setFieldValue(
                                'clicktroughUrl',
                                joinWithoutEmpty(newValue)
                              );
                            }}
                            label=''
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              ))}
              {!values.clicktroughUrl?.split(',').includes(' ') &&
                !values.clicktroughUrl?.split(',').includes('') && (
                  <Grid container item xs={12}>
                    <Body2>
                      <Link
                        className={addLink}
                        onClick={() =>
                          setFieldValue(
                            'clicktroughUrl',
                            joinWithoutEmpty(
                              values.clicktroughUrl.concat(', ').split(',')
                            )
                          )
                        }
                      >
                        <Grid container item xs alignItems='center'>
                          <Box mr='0.3rem'>
                            <AddCircle />
                          </Box>
                          <Body2>Clickthrough URL</Body2>
                        </Grid>
                      </Link>
                    </Body2>
                  </Grid>
                )}
            </Grid>
            <Grid container xs={12}>
              <FormControl>
                <FormLabel id='promo-radio-buttons-group-label'>
                  Are you looking to promote an offer?
                </FormLabel>
                <RadioGroup
                  name='promoMessage'
                  value={
                    // eslint-disable-next-line no-undefined
                    values.promoMessage !== undefined
                      ? typeof values.promoMessage === 'string'
                        ? 'yes'
                        : 'no'
                      : ''
                  }
                  onChange={(event) => {
                    setFieldValue(
                      'promoMessage',
                      event.target.value === 'yes' ? '' : null
                    );
                  }}
                >
                  <FormControlLabel
                    value={'yes'}
                    control={<Radio required color='primary' />}
                    label={'Yes'}
                  />
                  <FormControlLabel
                    value={'no'}
                    control={<Radio required color='primary' />}
                    label={'No'}
                  />
                </RadioGroup>
              </FormControl>
              {typeof values.promoMessage === 'string' && (
                <Grid item xs={12}>
                  <Box mt='1rem'>
                    <TextInput
                      variant='outlined'
                      label='Promo message'
                      name='promoMessage'
                      defaultValue={values.promoMessage}
                      value={values.promoMessage}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
