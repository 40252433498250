import { defineCols } from './setKpiCols';
import { getFormatedValues } from './PdfUtils';

export const extractDataForCsv = (
  kpiCols: string[],
  report: any
) => {
  const dailyCols = kpiCols;
  let breakdowns = Object.keys(report);
  let hasBreakdown = breakdowns.join('') !== '';
  const headers = dailyCols.map((col) => defineCols(col));
  const daily: (string | number)[][] = [(hasBreakdown ? ['type'] : []).concat(headers)];

  // for each breakdown render tables
  breakdowns.map((bk, index) => {
    let breakdownReport = report[bk];
    const days = breakdownReport.daily.impression.length;

    for (let day = 0; day < days; day++) {
      let dayReport: (string | number)[] = hasBreakdown ? [bk] : [];
      dailyCols.forEach((col) =>
        dayReport.push(getFormatedValues(col, breakdownReport, day))
      );
      daily.push(dayReport);
    }
  });

  return daily;
};

export const extractSiteDataForCsv = (
  kpiCols: string[],
  report: string[][]
)=>{
  return [kpiCols, ...report];
};
