import React from 'react';

import {
  Grid,
  Button,
  Avatar,
  Card,
  makeStyles,
  AdludioTheme,
} from '@adludio/components';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  avatarContainer: {
    padding: '2rem 0.8rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0',
    },
  },
  profileViewContainer: {
    padding: '0 1.5rem',
    minHeight: '17rem',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      paddingBottom: '2rem',
    },
  },
}));

type ProfileAvatarProps = {
  text: string;
  bgColor: string;
  fontColor: string;
  src?: string;
};

type ProfileViewProps = React.PropsWithChildren<{
  switchView: () => void;
  avatar: ProfileAvatarProps;
}>;

export default function ProfileView(props: ProfileViewProps) {
  const styles = useStyles();
  return (
    <Card>
      <Grid container xs={12} className={styles.profileViewContainer}>
        <Grid
          container
          item
          direction='column'
          xs={12}
          sm={7}
          md={6}
          lg={6}
          xl={4}
        >
          <Grid container item xs spacing={4} alignContent='center'>
            {props.children}
            <Grid item container xs>
              <Button
                onClick={props.switchView}
                type='submit'
                size='large'
                variant='outlined'
              >
                Edit details
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={5}
          className={styles.avatarContainer}
          justify='flex-end'
        >
          <Grid item>
            <Avatar
              large
              bgColour={props.avatar.bgColor}
              fontColor={props.avatar.fontColor}
              src={props.avatar.src}
            >
              {props.avatar.text}
            </Avatar>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
