import { useState, useEffect } from 'react';
import { sideNavItems } from '../shared/Data/nav';
import { UserType } from '@adludio/react-sso/dist/types';

const usePortalPermissions = (
  currentPath: string | undefined,
  user?: UserType
) => {
  const [isPathValid, setIsPathValid] = useState(false);
  const [isToolPermitted, setIsToolPermitted] = useState(false);
  const currentTool = currentPath?.slice(1);

  useEffect(() => {
    if (!user) return;

    const permitted = user.permissions.some(
      (tool: string) => currentTool === tool
    );
    setIsToolPermitted(permitted);
    const isPortalToolPath = sideNavItems.some(
      tool => currentTool === tool.permission
    );
    const isRoot = currentPath === '/';
    setIsPathValid(isPortalToolPath || isRoot);
  }, [user, currentPath, currentTool]);

  return { isPathValid, isToolPermitted };
};

export default usePortalPermissions;
