import { AdludioTheme, Box, Drawer, Grid } from '@adludio/components';
import React, { Dispatch, SetStateAction } from 'react';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import NavElement from './navElement';

interface SideNavProps {
  status?: string | null;
  briefSubmitted?: boolean;
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
  campaignId?: string;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    overflow: 'hidden',
    maxWidth: '100vw',
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.3s ease-out',
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
      paddingTop: '2.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      transition: 'none',
    },
  },
}));

// eslint-disable-next-line complexity
export const LiveSideNav = ({
  status,
  selectedItem,
  setSelectedItem,
  campaignId,
}: SideNavProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const getWhite = (theme: AdludioTheme) => theme.palette.common.white;
  const { root } = useStyles();

  const navElementProps = {
    selectedItem,
    setSelectedItem,
    campaignId,
  };

  const navWidth = '65px';

  return (
    <Drawer
      variant='permanent'
      width={isSmallScreen ? '100%' : navWidth}
      PaperProps={{ elevation: 7, className: root }}
      bgColor={getWhite}
      open
      anchor={isSmallScreen ? 'bottom' : 'left'}
    >
      <Box pt={isSmallScreen ? 0 : '1rem'}>
        <Grid container xs={12} justifyContent='space-between' direction='row'>
          <Box
            display='flex'
            flexDirection={isSmallScreen ? 'row' : 'column'}
            width='100%'
            justifyContent='space-between'
            pt={isSmallScreen ? 0 : '2rem'}
          >
            <NavElement
              title='Brief'
              icon='description'
              id='brief'
              {...navElementProps}
            />
            <NavElement
              title='Files'
              icon='file_copy'
              id='files'
              disabled
              {...navElementProps}
            />
            <NavElement
              title='Campaign Documents'
              icon='event_icon'
              id='campaignPlan'
              disabled
              {...navElementProps}
            />
            <NavElement
              title='Creative'
              icon='brush'
              id='creativeProduction'
              {...navElementProps}
            />
            <NavElement
              title='Reporting'
              icon='bar_chart_outlined'
              id='reporting'
              {...navElementProps}
            />
          </Box>
        </Grid>
      </Box>
    </Drawer>
  );
};
