export const mockAdUnitSize = [
  { label: 'FS', value: '320x480', key: 'FS' },
  { label: 'MPU', value: '300x250', key: 'MPU' },
  { label: 'Other', value: 'Other', key: 'Other' },
  { label: '320x50', value: '320x50', key: '320x50' },
  { label: '728x90', value: '728x90', key: '320x50' },
  { label: '300x600', value: '300x600', key: '300x600' },
  { label: '320x480', value: '320x480', key: '320x480' },
  { label: '320x100', value: '320x100', key: '320x100' },
  { label: '336x280', value: '336x280', key: '336x280' },
];
