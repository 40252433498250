import { Dialog, Grid, Box, H6, Body1, Button } from '@adludio/components';
import React, { useState } from 'react';
import SessionExpiredSVG from '../../shared/img/session-expired.svg';
import { useIdleTimer } from 'react-idle-timer';
import { logout } from '@adludio/react-sso/dist/checkAuth';
import { AuthContext } from '@adludio/react-sso';

export const SessionTimeoutModal = ({ ssoBEURL }: { ssoBEURL: string }) => {
  const { auth } = React.useContext(AuthContext);

  const timeout = 45 * 60000; // 45 mins

  const [isIdle, setIsIdle] = useState(false);

  const handleOnIdle = () => {
    if (auth.authStatus !== 'fail') {
      setIsIdle(true);
      handleLogout();
    }
  };
  useIdleTimer({
    timeout,
    onIdle: handleOnIdle,
  });

  const handleLogout = () => {
    const token = window.localStorage.getItem('authToken');
    if (token && ssoBEURL) {
      window.localStorage.clear();
      logout(ssoBEURL, token).catch(console.log);
    }
  };

  return (
    <Dialog open={isIdle} fullWidth>
      <Grid container justify='center'>
        <Grid xs={5}>
          <Box width='100%' mt='2rem'>
            <img src={SessionExpiredSVG} width='100%' alt='Session expired' />
          </Box>
        </Grid>
        <Grid xs={10}>
          <H6 align='center' color='primary'>
            Session Timeout
          </H6>
        </Grid>
        <Grid xs={10}>
          <Box my='1rem'>
            <Body1 align='center'>
              Your session has expired due to inactivity and have been logged
              out. To get back in, just login again
            </Body1>
          </Box>
        </Grid>
        <Grid container justify='center'>
          <Box my='1rem'>
            <Button
              variant='contained'
              onClick={() => window.location.replace('/')}
            >
              {' '}
              LOGIN{' '}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};
