import { AdludioTheme, Box, Drawer, Grid } from '@adludio/components';
import React, { Dispatch, SetStateAction } from 'react';
import { Theme, makeStyles, useMediaQuery } from '@material-ui/core';

import { ArrowNav } from './arrowBack';
import { AuthContext } from '@adludio/react-sso';
import NavElement from './navElement';

interface SideNavProps {
  status?: string | null;
  briefSubmitted?: boolean;
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
  creativeSubmitted: boolean;
  campaignId?: string;
  expandedNav: boolean;
  startDate: Date | null;
  setExpandedNav: (value: boolean) => void;
  managedCampaign?: boolean;
  manualCampaign?: boolean;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    overflow: 'hidden',
    maxWidth: '100vw',
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.3s ease-out',
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
      paddingTop: '2.2rem',
    },
    [theme.breakpoints.down('sm')]: {
      transition: 'none',
    },
  },
}));

// eslint-disable-next-line complexity
export const SideNav = ({
  status,
  briefSubmitted,
  manualCampaign,
  selectedItem,
  setSelectedItem,
  creativeSubmitted,
  campaignId,
  expandedNav,
  setExpandedNav,
  startDate,
  managedCampaign,
}: SideNavProps) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const getWhite = (theme: AdludioTheme) => theme.palette.common.white;
  const { root } = useStyles();
  const { auth } = React.useContext(AuthContext);

  const today = new Date();
  const navElementProps = {
    selectedItem,
    setSelectedItem,
    campaignId,
    expandedNav,
  };
  const isSubmitted =
    (creativeSubmitted && briefSubmitted) ||
    (briefSubmitted && managedCampaign);
  const isStatusExist = !!status;
  const isCampaignStarted = startDate ? today >= startDate : false;
  const navWidth = expandedNav ? '220px' : '65px';
  const isInternalUser =
    auth.user?.role !== 'guest' && auth.user?.role !== 'client';
  return (
    <Drawer
      variant='permanent'
      width={isSmallScreen ? '100%' : navWidth}
      PaperProps={{ elevation: 7, className: root }}
      bgColor={getWhite}
      open
      anchor={isSmallScreen ? 'bottom' : 'left'}
    >
      <Box pt={isSmallScreen ? 0 : '1rem'}>
        <Grid container xs={12} justifyContent='space-between' direction='row'>
          {!isSmallScreen && (
            <ArrowNav
              setExpandedNav={setExpandedNav}
              expandedNav={expandedNav}
            />
          )}
          <Box
            display='flex'
            flexDirection={isSmallScreen ? 'row' : 'column'}
            width='100%'
            justifyContent='space-between'
            pt={isSmallScreen ? 0 : '2rem'}
          >
            <NavElement
              title='Brief'
              icon='description'
              id='brief'
              {...navElementProps}
              disabled={manualCampaign}
            />
            <NavElement
              title='Files'
              icon='file_copy'
              id='files'
              disabled={false}
              {...navElementProps}
            />
            <NavElement
              title='Campaign Documents'
              icon='event_icon'
              id='campaignPlan'
              disabled={isInternalUser ? false : !isSubmitted}
              {...navElementProps}
            />
            <NavElement
              title='Creative'
              icon='brush'
              id='creativeProduction'
              disabled={isInternalUser ? false : !isStatusExist}
              {...navElementProps}
            />
            <NavElement
              title='Reporting'
              icon='bar_chart_outlined'
              id='reporting'
              disabled={isInternalUser ? false : !isCampaignStarted}
              {...navElementProps}
            />
          </Box>
        </Grid>
      </Box>
    </Drawer>
  );
};
