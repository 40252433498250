import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@adludio/components';
import { CampaignCard } from './CampaignCard';
import { Campaign } from '../models/campaign';

export interface Props {
  campaigns: Campaign[];
}

export function CampaignGrid(props: Props): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  return (
    <Grid item xs container spacing={7}>
      {props.campaigns.map(
        (campaign) =>
          campaign.title !== null && (
            <Grid
              item
              key={campaign.title}
              container
              justifyContent={isDesktop ? 'flex-start' : 'center'}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
            >
              <CampaignCard campaign={campaign} />
            </Grid>
          )
      )}
    </Grid>
  );
}
