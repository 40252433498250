import React from 'react';
import {
  Avatar,
  Body2,
  Grid,
  makeStyles,
  Card,
  Caption,
  Button,
  Box,
} from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';

export interface MemberCardProps {
  userName: string;
  userEmail: string;
  campaignId?: string;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: '0.9rem',
  },
  linkIcon: {
    fontSize: '1.2rem',
    paddingLeft: '0.5rem',
  },
  confirmationText: {
    color: theme.palette.text.secondary,
  },
  userAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export function MemberCard(props: MemberCardProps): JSX.Element {
  const [resendForm] = React.useState<boolean>(false);

  const { root, confirmationText, userAvatar } = useStyles();
  return (
    <Grid container direction='column' className={root}>
      <Box px='1rem' py='0.5rem'>
        <Grid container direction='row'>
          <Grid item xs={2}>
            <Avatar bgColour='red' className={userAvatar}>
              {props.userName!.slice(0, 1)}
            </Avatar>
          </Grid>
          <Grid container item direction='column' xs={8}>
            <Body2>{props.userName}</Body2>
            <Body2>{props.userEmail}</Body2>
          </Grid>
        </Grid>
      </Box>
      {resendForm && (
        <Card>
          <Box padding='1rem'>
            <Box textAlign='center' pb='1rem'>
              <Caption className={confirmationText}>
                Are you sure you want to re-send invitation?{' '}
              </Caption>
            </Box>
            <Grid justify='space-evenly' container>
              <Button variant='contained' size='small'>
                Send Invite
              </Button>
              <Button color='default' variant='contained' size='small'>
                Cancel
              </Button>
            </Grid>
          </Box>
        </Card>
      )}
    </Grid>
  );
}
