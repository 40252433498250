import React from 'react';
import { BreakdownReportValues } from '../../types';
import { exportPDF } from '../../utils/PdfUtils';
import { Button, Icon, makeStyles, Menu, MenuItem, Tooltip } from '@material-ui/core';

import { CSVLink } from 'react-csv';
import { extractDataForCsv, extractSiteDataForCsv } from '../../utils/CsvUtils';
import exportSite from '../../utils/exportSites';

export interface CustomToolbarProps {
  report: any;
  campaignName: string;
  kpiCols: string[];
  isSite:boolean;
}

const onDownloadClick = async (
  report: BreakdownReportValues,
  campaignName: string,
  kpiCols: string[]
) => {
  exportPDF(report, campaignName, kpiCols);
};

const onSiteDownloadClick = async (
  sites:string[][],
  campaignName: string,
  kpiCols: string[]
) => {
  exportSite(kpiCols, sites, campaignName);
};

const defaultToolbarStyles = makeStyles((theme) => ({
  iconButton: {
    color: '#8381F7',
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
    textDecoration: 'none'
  },
}));
const CustomToolbar = ({
  report,
  campaignName,
  kpiCols,
  isSite = false
}: CustomToolbarProps) => {
  const classes = defaultToolbarStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={'Download Report'}>
        <Button
          id='download-button'
          aria-controls={open ? 'table-download' : ''}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : 'false'}
          onClick={handleClick}
          className={classes.iconButton}
        >
          <Icon>download</Icon>
        </Button>
      </Tooltip>
      <Menu
        id='table-download'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'download-button',
        }}
      >
        <MenuItem className={classes.iconButton} onClick={() => isSite ? onSiteDownloadClick(report, campaignName, kpiCols) : onDownloadClick(report, campaignName, kpiCols)}>
          Download as PDF
        </MenuItem>
        <MenuItem>
          <CSVLink
            className={classes.iconButton}
            filename={`${campaignName}_${new Date()
              .toISOString()
              .slice(0, 10)}.csv`}
            data={isSite ? extractSiteDataForCsv(kpiCols, report) : extractDataForCsv(kpiCols, report)}
          >
            Download as CSV
          </CSVLink>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CustomToolbar;
