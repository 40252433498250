import { initialConfig } from '../index';


interface User {
  name?: string;
  companyId?: number;
  jobTitle?: string;
  currentPassword?: string;
  password?: string;
}

export const updateUser = (
  authToken: string,
  userId: number,
  body: User
) => {
  return fetch(`${initialConfig.ssoBEURL}/users/${userId}`, {
    method: 'PATCH',
    headers: {
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*'
    },
    body: JSON.stringify({ ...body })
  });
};
