/* eslint-disable complexity */
import {
  Box,
  Button,
  Card,
  Grid,
  H5,
  useSnackbar,
  CircularProgress,
  IconButton,
  Subtitle1,
  useMediaQuery,
  useTheme,
} from '@adludio/components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { programmaticCampaignStyles as styles } from '../styles';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetCampaignBriefLazyQuery,
  useSaveCampaignBriefMutation,
  useSubmitCampaignBriefMutation,
} from '../../../generated/graphql';

import { CampaignInformationScreen } from '../Components/CampaignInformationForm';
import { navigate, RouteComponentProps } from '@reach/router';
import { TargetingForm } from '../Components/TargetingForm';
import { Form, Formik } from 'formik';
import {
  parseQueryToFormValues,
  PROGRAMMATIC_FORM_VALUES,
  validateProgrammatic,
} from '../const';
import { v4 as uuidv4 } from 'uuid';
import { StepLabel, Stepper } from '@material-ui/core';
import { Step } from '@material-ui/core';

interface ProgrammaticCampaignProps extends RouteComponentProps {
  id?: string;
  onCancel?: () => void;
}

export function ProgrammaticCampaignForm(
  props: ProgrammaticCampaignProps
): JSX.Element {
  // Styles
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { title, stepper, container, buttons, purple, column } = styles({
    isSmallScreen: isSmallScreen,
  });

  // States
  const [step, setStep] = useState(1);
  const [isSaved, setIsSaved] = useState(false);

  const [save, { loading: isSaving }] = useSaveCampaignBriefMutation();
  const [fetch, { data, loading: isFetching }] = useGetCampaignBriefLazyQuery();
  const [submit] = useSubmitCampaignBriefMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isEditing = !!props.id;
  const campaignId = useMemo(() => props.id ?? uuidv4(), [props.id]);

  useEffect(() => {
    if (isEditing) {
      fetch({ variables: { campaignId: props.id! } });
    }
  }, [fetch, isEditing, props.id]);

  // Form's Intial Values will change depending on if we're editing an existing Brief.
  const initialValues = useMemo(
    () => (isEditing ? parseQueryToFormValues(data) : PROGRAMMATIC_FORM_VALUES),
    [data, isEditing]
  );

  const handleStepChange = (stepSelected: number) => {
    setStep(stepSelected);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues ?? PROGRAMMATIC_FORM_VALUES}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values) =>
        validateProgrammatic(
          values,
          step,
          handleStepChange,
          save,
          campaignId,
          enqueueSnackbar
        )
      }
      onSubmit={async (values) => {
        try {
          await save({
            variables: {
              id: campaignId,
              fieldsToSave: {
                ...values,
                name: values.name ? values.name : null,
              },
            },
          });
          await submit({ variables: { campaignId } });
          enqueueSnackbar('Successfully submitted Brief.', {
            variant: 'success',
          });
          await navigate(`/campaigns/campaign/${campaignId}/brief`);
        } catch (e) {
          enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
        }
      }}
    >
      {(formikProps) =>
        isFetching ? (
          <Box mt={30}>
            <CircularProgress size={40} />
          </Box>
        ) : (
          <Form style={{ width: '100%' }} onSubmit={formikProps.handleSubmit}>
            <Box width='100%' justifyContent='center' mb='4rem'>
              <Grid className={column} container justify='center' xs={12}>
                {!isEditing && !isSmallScreen && (
                  <>
                    <Grid container xs={12} justify='center' className={title}>
                      {step === 1 ? (
                        <Grid container xs={8} justify='center'>
                          <H5 align='center' className={purple}>
                            We're looking forward to delivering your mobile
                            campaign, but let's get some details first
                          </H5>
                        </Grid>
                      ) : (
                        <Grid container xs={8} justify='center'>
                          <H5 align='center' className={purple}>
                            Let's get your campaign in front of the right people
                          </H5>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                <Stepper
                  className={stepper}
                  activeStep={step - 1}
                  elevation={2}
                  alternativeLabel={isSmallScreen}
                >
                  <Step>
                    <StepLabel>Campaign Information</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Serving & Targeting</StepLabel>
                  </Step>
                </Stepper>
                <Grid container justify='center' item xs={12} md={8}>
                  <Card className={container} elevation={2}>
                    <Grid container justify='center' item xs={12}>
                      {step === 1 ? (
                        <>
                          <CampaignInformationScreen
                            wasSaved={isSaved}
                            formikProps={formikProps}
                          />
                        </>
                      ) : (
                        <>
                          <Grid container item xs justifyContent='flex-start'>
                            <Box p='0.5rem' pb='0' m='2rem'>
                              <IconButton
                                onClick={() => setStep(1)}
                                className={purple}
                                label=''
                              >
                                <ArrowBackIcon />
                                <Subtitle1 style={{ fontWeight: 100 }}>
                                  Back
                                </Subtitle1>
                              </IconButton>
                            </Box>
                          </Grid>
                          <TargetingForm
                            wasSaved={isSaved}
                            formikProps={formikProps}
                            campaignId={campaignId}
                          />
                        </>
                      )}
                      <Grid
                        item
                        xs={10}
                        md={8}
                        className={buttons}
                        spacing={4}
                        direction='column'
                      >
                        {step === 1 ? (
                          <Button
                            variant='contained'
                            onClick={() => {
                              window.scrollTo(0, 0);
                              formikProps.validateForm();
                            }}
                          >
                            Next step
                          </Button>
                        ) : (
                          <Button
                            variant='contained'
                            isLoading={formikProps.isSubmitting}
                            onClick={(e) => formikProps.handleSubmit(e as any)}
                          >
                            Submit
                          </Button>
                        )}
                        {!data?.getCampaignBrief?.isSubmitted ? (
                          <Box>
                            <Button
                              variant='outlined'
                              disabled={isSaving}
                              isLoading={isSaving}
                              onClick={() => {
                                const { values, setFieldError } = formikProps;
                                if (values.name) {
                                  save({
                                    variables: {
                                      id: campaignId,
                                      fieldsToSave: {
                                        ...values,
                                        name: values.name ? values.name : null,
                                      },
                                    },
                                  })
                                    .then(() => {
                                      setIsSaved(true);
                                      enqueueSnackbar(
                                        'Successfully saved draft.',
                                        { variant: 'success' }
                                      );
                                      // eslint-disable-next-line no-undefined
                                      setFieldError('name', undefined);
                                    })
                                    .catch(() =>
                                      setFieldError(
                                        'name',
                                        'A campaign with that name already exists.'
                                      )
                                    );
                                } else {
                                  setFieldError(
                                    'name',
                                    'Please set a Campaign Name before saving.'
                                  );
                                }
                              }}
                            >
                              Save as draft
                            </Button>
                          </Box>
                        ) : (
                          <Button
                            variant='outlined'
                            isLoading={formikProps.isSubmitting}
                            onClick={(e) => props.onCancel && props.onCancel()}
                          >
                            Cancel
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )
      }
    </Formik>
  );
}
