import { BioTagState, DisplayTagState, TagRecord } from '../store/tagReducer';
import { DataMapType } from '..';

function isBioTag(t: any): t is TagRecord<BioTagState> {
  return t.unitType === 'bio';
}

function setBioData(tag: TagRecord<BioTagState>, data: DataMapType) {
  return data.merge({
    bio_question: tag.question,
    bio_answers: tag.answer === '' ? [] : tag.answer,
    creative_type: tag.creativeType,
    bio_question_type: tag.bioQuestionType,
    main_campaign_game_keys:
      tag.mainCampaignGameKeys &&
      tag.mainCampaignGameKeys
        .toJS()
        /*/toJS()*/
        .map((v: any) => v.trim())
        .filter((v) => v !== ''),
  });
}

function isDisplayTag(t: any): t is TagRecord<DisplayTagState> {
  return t.unitType === 'display';
}

function setDisplayData(tag: TagRecord<DisplayTagState>, data: DataMapType) {
  return data.merge({
    game_key: tag.gameKey,
    redirect_url: tag.redirect,
  });
}

export default function (tag: TagRecord, data: DataMapType) {
  if (isBioTag(tag)) {
    return setBioData(tag, data);
  } else if (isDisplayTag(tag)) {
    return setDisplayData(tag, data);
  }
  return data;
}
