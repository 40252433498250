import { Grid, List, ListItem } from '@adludio/components';
import { Paper, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { SingleSiteList, Site } from '../../types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getKpiName, { getFullNotation } from '../../utils/setKpiCols';

import BootstrapTooltip from '../ui/bootstrapTooltip';
import DropdownOptions from './siteDropdown';
import SentimentTableViews from './sentimentTableView';
import StackedBarChart from '../charts/stackedBarChart';
import WordCloud from '../charts/wordCloud';
import WordCloudNoData from './WordCloudNoData';
import { removeURLPrefix } from '../../utils/stringFilter';
import { responsiveStyle } from '../../utils/Styles';

const StyledToggleButton = withStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    '&$selected': {
      backgroundColor: '#4E55C3',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4E55C3',
        color: '#ffffff',
      },
    },
  },
  selected: {},
})(ToggleButton);

interface SentimentCount
  extends Array<{
    name: string;
    data: number[];
  }> {}

interface WordCloud extends Array<{ text: string; value: number }> {}

const getSentimentCount = (topSites: Site[]) => {
  const newSentimentCount: SentimentCount = [
    {
      name: 'Positive',
      data: [],
    },
    {
      name: 'Neutral',
      data: [],
    },
    {
      name: 'Negative',
      data: [],
    },
  ];

  topSites.forEach((site) => {
    newSentimentCount[0]['data'].push(site.positive);
    newSentimentCount[1]['data'].push(site.neutral);
    newSentimentCount[2]['data'].push(site.negative);
  });

  return newSentimentCount;
};

const getWordCloud = (
  topSites: Site[],
  selectedSite: string,
  sentimentType: string
) => {
  const site = topSites.find(
    (singleSite) => removeURLPrefix(singleSite.site) === selectedSite
  );

  let wordList: (string | number)[][] = site
    ? JSON.parse(site[`${sentimentType.toLowerCase()}Words`] ?? '[]')
    : [];
  if (!Array.isArray(wordList)) {
    wordList = [];
  }
  const _wordCloud: WordCloud = wordList.map((word) => ({
    text: word[0] as string,
    value: word[1] as number,
  }));

  return _wordCloud;
};

const getSentimentLabels = (_siteList: Site[], _selectedSite: String) => {
  const site = _siteList.find(
    (_site) => removeURLPrefix(_site.site) === _selectedSite
  ) as Site;

  let _sentimentLabel = [];
  if (site.positive > 0) _sentimentLabel.push('Positive');
  if (site.negative > 0) _sentimentLabel.push('Negative');
  if (site.neutral > 0) _sentimentLabel.push('Neutral');
  if (_sentimentLabel.length === 0) _sentimentLabel.push('No Sentiment');

  return _sentimentLabel;
};

const kpilist = [
  {
    value: 'er',
    label: 'ER',
  },
  {
    value: 'ctr',
    label: 'CTR',
  },
  {
    value: 'ectr',
    label: 'eCTR',
  },
];

interface SiteOverviewProps {
  campaignName: string;
  topSiteList: SingleSiteList;
  bottomSiteList: SingleSiteList;
  kpiCols: string[];
}

const SentimentTab = ({
  campaignName,
  topSiteList,
  bottomSiteList,
  kpiCols,
}: SiteOverviewProps) => {
  const classes = responsiveStyle();
  const initSites = topSiteList['er'].map((site) => removeURLPrefix(site.site));
  const initSentimentLabels = getSentimentLabels(
    topSiteList['er'],
    initSites[0]
  );

  const [kpi, setKpi] = useState<string>('er');
  const [selectedTopSiteList, setSelectedTopSiteList] = useState<Site[]>(
    topSiteList['er']
  );
  const [sentimentCount, setSentimentCount] = useState<SentimentCount>(
    getSentimentCount(topSiteList['er'])
  );

  const [sentimentLabels, setSentimentLabels] =
    useState<string[]>(initSentimentLabels);

  const [wordCloud, setWordsCloud] = useState(
    getWordCloud(topSiteList['er'], initSites[0], initSentimentLabels[0])
  );
  const [sites, setSites] = useState(initSites);
  const [selectedSite, setSelectedSite] = useState<string>(initSites[0]);
  const [selectedSentiment, setSelectedSentiment] = useState<string>(
    initSentimentLabels[0]
  );

  const kpiName = getKpiName(kpi, kpilist);

  const handleKpiChange = (
    event: React.MouseEvent<HTMLElement>,
    newKpi: string | null
  ) => {
    if (newKpi === null) {
      return;
    }

    const topSites = topSiteList[newKpi];
    const cleanedSites = topSites.map((site) => removeURLPrefix(site.site));

    setKpi(newKpi);
    setSelectedTopSiteList(topSites);
    setSentimentCount(getSentimentCount(topSites));

    setSites(cleanedSites);
    setSelectedSite(cleanedSites[0]);
    const _sentimentLabel = getSentimentLabels(topSites, cleanedSites[0]);
    setSelectedSentiment(_sentimentLabel[0]);
    setSentimentLabels(_sentimentLabel);
    setWordsCloud(getWordCloud(topSites, cleanedSites[0], _sentimentLabel[0]));
  };

  const handleSiteSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue: string = e.target.value;
    setSelectedSite(selectedValue);
    const _sentimentLabel = getSentimentLabels(
      selectedTopSiteList,
      selectedValue
    );
    setSelectedSentiment(_sentimentLabel[0]);
    setSentimentLabels(_sentimentLabel);
    setWordsCloud(
      getWordCloud(selectedTopSiteList, selectedValue, _sentimentLabel[0])
    );
  };

  const handleSentimentSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue: string = e.target.value;
    setSelectedSentiment(selectedValue);
    setWordsCloud(
      getWordCloud(selectedTopSiteList, selectedSite, selectedValue)
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <ToggleButtonGroup
          color='primary'
          size='small'
          value={kpi}
          exclusive
          onChange={handleKpiChange}
        >
          {kpilist.map((element) => (
            <StyledToggleButton
              key={element.value}
              value={element.value}
              color='primary'
            >
              <BootstrapTooltip
                title={getFullNotation(element.value)}
                placement='bottom'
                key={element.label}
              >
                <span>{element.label}</span>
              </BootstrapTooltip>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      <Grid item container xs={12} spacing={2}>
        <Grid item container xs={12} md={12} lg={6} spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper elevation={2}>
              <StackedBarChart
                kpi={kpi}
                kpiLabel={kpiName}
                campaignName={campaignName}
                siteList={selectedTopSiteList}
                sentiment={sentimentCount}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper elevation={2}>
              <List>
                <ListItem>
                  <Typography
                    variant='h5'
                    component='h2'
                    className={classes.wordcloudTitle}
                  >
                    Sentiment informative words wordcloud
                  </Typography>
                </ListItem>
                <ListItem>
                  <List className={classes.flexContainer}>
                    <ListItem className={classes.wordCloudText}>
                      <DropdownOptions
                        idKey={'siteList'}
                        handleSelectOption={handleSiteSelect}
                        selectedValue={selectedSite}
                        dropdownOptions={sites}
                      />
                    </ListItem>
                    <ListItem className={classes.wordCloudText}>
                      <DropdownOptions
                        idKey={'siteList'}
                        handleSelectOption={handleSentimentSelect}
                        selectedValue={selectedSentiment}
                        dropdownOptions={sentimentLabels}
                      />
                    </ListItem>
                  </List>
                </ListItem>
                {wordCloud.length === 0 ? (
                  <ListItem>
                    <WordCloudNoData />
                  </ListItem>
                ) : (
                  <>
                    <ListItem>
                      <Typography
                        variant='caption'
                        className={classes.wordCloudText}
                      >
                        Most common words found on the pages with the sentiment
                        selected.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <WordCloud words={wordCloud} />
                    </ListItem>
                  </>
                )}
              </List>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={12} lg={6}>
          <Paper
            elevation={2}
            style={{ width: '100%', minHeight: '350px', padding: '24px' }}
          >
            <SentimentTableViews
              kpi={kpiName}
              dropdownOptions={sites}
              sites={selectedTopSiteList}
              campaignName={campaignName}
            />
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SentimentTab;
