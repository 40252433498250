import { ApexOptions } from 'apexcharts';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Site } from '../../types';
import { formatDateString } from '../../utils/date';
import getIcons from './chartIcons';
import { removeURLPrefix } from '../../utils/stringFilter';
import { responsiveStyle } from '../../utils/Styles';
interface ChartProps {
  kpi: string;
  kpiLabel: string;
  campaignName: string;
  siteList: Site[];
  sentiment: {
    name: string; // could be either of Positive, Neutral, and Negative
    data: number[];
  }[];
}
const StackedBarChart = ({
  kpi,
  kpiLabel,
  campaignName,
  siteList,
  sentiment,
}: ChartProps) => {
  const classes = responsiveStyle();
  siteList.sort((prev, curr) => curr[kpi] - prev[kpi]);
  const sites = siteList.map((site) => removeURLPrefix(site.site));
  const date = new Date();
  const dataSeries: ApexAxisChartSeries = sentiment;
  const options: ApexOptions = {
    chart: {
      stacked: true,
      type: 'bar',
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 200,
        animateGradually: {
          enabled: true,
          delay: 15,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 100,
        },
      },
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: false,
          zoomout: false,
          pan: false,
          zoom: false,
          reset: false,
        },
        export: {
          png: {
            filename: `${campaignName}_${formatDateString(date)}`,
          },
          svg: {
            filename: `${campaignName}_${formatDateString(date)}`,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        barHeight: '90%',
        horizontal: true,
        dataLabels: {
          position: 'center',
        },
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff'],
    },
    title: {
      text: `${kpiLabel} Top performing sites sentiment insights`,
      align: 'center',
    },
    xaxis: {
      categories: sites,
      labels: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    colors: ['#8381F7', '#5B39CC', '#495373'],
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#fff', '#fff', '#fff'],
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'normal',
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      offsetX: 40,
      position: 'bottom',
      horizontalAlign: 'center',
    },
    responsive: [
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: '100%',
            height: 400,
          },
          xaxis: {
            labels: {
              show: false,
            },
          },
          tooltip: {
            fixed: {
              enabled: true,
              position: 'topRight',
              offsetX: 0,
              offsetY: 0,
            },
          },

          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '70%',
            },
          },

          title: {
            text: `Sites sentiment of ${kpiLabel}`,
            align: 'center',
          },
        },
      },
    ],
  };
  return (
    <div className={classes.chartsPadding}>
      <ReactApexChart
        options={options}
        series={dataSeries}
        type='bar'
        height={450}
      />
    </div>
  );
};
export default StackedBarChart;
