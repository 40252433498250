import {
  AdludioTheme,
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  useSnackbar,
} from '@adludio/components';
import React, { useContext, useMemo, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { LiveScreen } from '../Components/LiveForm';

import { useSubmitManagedServiceMutation } from '../../../generated/graphql';

import { LIVE_FORM_VALUES, validateLive } from '../const';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext, SuccessAuthContextProps } from '@adludio/react-sso';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    marginTop: '15px',
    paddingTop: '20px',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    '@media only screen and (max-width: 425px)': {
      display: 'none',
    },
  },
}));

interface MediaCampaignFormProps extends RouteComponentProps {
  id?: string;
  onCancel?: () => void;
}

export function LiveCampaignForm(props: MediaCampaignFormProps): JSX.Element {
  const { container } = styles();
  const [isSaved] = useState(false);
  const [submit, { loading: isSaving }] = useSubmitManagedServiceMutation();
  const { enqueueSnackbar } = useSnackbar();

  const campaignId = useMemo(() => props.id ?? uuidv4(), [props.id]);

  const {
    auth: { user },
  } = useContext(AuthContext) as SuccessAuthContextProps;

  return (
    <Formik
      enableReinitialize
      initialValues={LIVE_FORM_VALUES}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values) => validateLive(values, campaignId, enqueueSnackbar)}
      onSubmit={async (values) => {
        try {
          await submit({
            variables: {
              id: campaignId,
              name: values.name!,
              payload: JSON.stringify(values),
            },
          });
          enqueueSnackbar('Successfully submitted Brief.', {
            variant: 'success',
          });
          await navigate(`/campaigns/reporting/${campaignId}/brief`);
        } catch (e) {
          enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
        }
      }}
    >
      {(formikProps) => (
        <Grid container justify='center' item xs={11} md={8} spacing={2}>
          <Card className={container} elevation={2}>
            <LiveScreen
              wasSaved={isSaved}
              formikProps={formikProps}
              campaignId={campaignId}
            />
            <Box mb='3rem' mt='1rem'>
              <Grid container item justify='center'>
                <Grid container xs={10} md={8} direction='row'>
                  <Box pr='1rem'>
                    <Button
                      isLoading={isSaving}
                      disabled={isSaving}
                      variant='contained'
                      onClick={async (e) => {
                        formikProps.setFieldValue(
                          'createdAt',
                          new Date(Date.now())
                        );
                        formikProps.setFieldValue('id', campaignId);
                        formikProps.setFieldValue(
                          'GUID',
                          `LBRFID-${campaignId}`
                        );
                        formikProps.setFieldValue(
                          'companyName',
                          user.company.name
                        );
                        formikProps.handleSubmit(e as any);
                        Object.keys(await formikProps.validateForm()).length !==
                          0 &&
                          enqueueSnackbar(
                            `There are ${
                              Object.keys(await formikProps.validateForm())
                                .length
                            } errors in the form. Please correct them to submit form.`,
                            {
                              variant: 'error',
                            }
                          );
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      )}
    </Formik>
  );
}
