import {
  FileType,
  Operation,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useGetMagicLinkLazyQuery,
  useUpdateUploadedStatusMutation,
} from '../generated/graphql';
import axios, { CancelToken } from 'axios';

import React from 'react';

interface UseUploadProps {
  reFetch: () => void;
  cancelToken: CancelToken;
}
export function useUpload(props?: UseUploadProps) {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [createDocumentMutation, { data: uploadedDocument }] =
    useCreateDocumentMutation();
  const [getMagicLink, { data: linkData }] = useGetMagicLinkLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<boolean | null>(null);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);
  const [currentDocument, setCurrentDocument] = React.useState<string>('');
  const [deleteDocumentMutation, { data: failed }] =
    useDeleteDocumentMutation();

  const [updateUploadStatus] = useUpdateUploadedStatusMutation();
  /* Create document on our database and the cdn.adludio.dom bucket on s3 */
  const uploadDocument = (e: File, campaignId: string) => {
    setIsUploading(true);
    setSelectedFile(e);
    createDocumentMutation({
      variables: {
        campaignId: campaignId,
        fileName: e.name,
        fileType: FileType.Assets,
      },
    })
      .then((newDocument) => {
        setCurrentDocument(newDocument.data!.createDocument!.id!);
        getMagicLink({
          variables: {
            fileId: newDocument.data!.createDocument!.id!,
            type: FileType.Assets,
            campaignId: campaignId,
            operation: Operation.Put,
          },
        });
      })
      .catch(console.log);
  };
  /* Only created the document on our database, not on AWS */
  const createDocument = (campaignId: string, fileName: string) => {
    setIsUploading(true);
    return createDocumentMutation({
      variables: {
        campaignId: campaignId,
        fileName: fileName,
        fileType: FileType.Assets,
      },
    });
  };

  React.useEffect(() => {
    if (selectedFile) {
      if (linkData) {
        axios
          .put(linkData.getMagicLink!, selectedFile, {
            maxBodyLength: Infinity,
            cancelToken: props?.cancelToken,
            onUploadProgress: ({ loaded, total }: ProgressEvent) => {
              setUploadProgress((loaded / total) * 100);
            },
          })
          .then(() => {
            updateUploadStatus({ variables: { id: currentDocument } })
              .then(() => {
                if (props?.reFetch)
                  setTimeout(() => {
                    props.reFetch();
                  }, 1000);
              })
              .catch(console.log);
            setStatus(true);
            setIsUploading(false);
          })
          .catch((error: any) => {
            console.log(error);
            deleteDocumentMutation({
              variables: { id: currentDocument },
            }).catch((err) => {
              console.log(err);
            });
            if (!axios.isCancel(error)) {
              setStatus(false);
            }
            setIsUploading(false);
          })
          .finally(() => {
            setUploadProgress(0);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkData]);

  return {
    uploadDocument,
    isUploading,
    uploadedDocument,
    createDocument,
    status,
    uploadProgress,
    failed,
  };
}
