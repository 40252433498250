import {
  Grid,
  Box,
  H6,
  Subtitle1,
  makeStyles,
  Button,
} from '@adludio/components';
import { Link, RouteComponentProps } from '@reach/router';
import React from 'react';
import ErrorSVG from '../shared/img/404.svg';


const useStyles = makeStyles(() => ({
  mainDiv: {
    margin: 0,
    background: '#F9FBFF',
    minHeight: '90vh',
  },
  svg: {
    width: '70%',
    maxWidth: '30rem',
  },
  link: {
    textDecoration: 'none',
  },
}));

export function ErrorPage(_props: RouteComponentProps): JSX.Element {
  const { mainDiv, svg, link } = useStyles();

  return (
    <Box pt='5rem' className={mainDiv}>
      <Grid
        container
        xs={12}
        justifyContent='center'
        direction='column'
        alignItems='center'
      >
        <img src={ErrorSVG} alt='404 error' className={svg} />
        <Grid item>
          <H6>
          Oops! Something went wrong.
          </H6>
        </Grid>
        <Grid>
          <Subtitle1>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
           We can't find the page you're looking for...
          </Subtitle1>
        </Grid>
        <Grid>
          <Box my='1.5rem'>
            <Link to='/campaigns' className={link}>
              <Button variant='contained'>Go Home</Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
