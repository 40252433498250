import { useContext, useEffect } from 'react';
import { getConsent } from '../../../API/consent';
import { AuthContext } from '@adludio/react-sso';
import { initialConfig } from '../../..';

export const useFetchConsent = ({
  openConsent,
}: {
  openConsent: (open: boolean) => void;
}) => {
  const authContext = useContext(AuthContext);
  const ssoBEURL = initialConfig.ssoBEURL;

  const handleFetchedConsent = (consentPairs: string) => {
    if (!consentPairs) {
      openConsent(true);
      return;
    }
    const consents = consentPairs.split(';');
    for (const consent of consents) {
      const splitConsent = consent.split(':');
      if (splitConsent.length === 2) {
        localStorage.setItem(splitConsent[0], splitConsent[1]);
      }
    }
    window.dispatchEvent(new Event('storage'));
  };
  useEffect(() => {
    if (authContext.auth.authToken) {
      getConsent(ssoBEURL, authContext.auth.authToken)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            console.log(res.error.message);
            return;
          }
          handleFetchedConsent(res.data.consent);
        })
        .catch(console.log);
    }
  }, [ssoBEURL, authContext]);
};
