/* eslint-disable complexity */
import React from 'react';
import { navigate } from '@reach/router';
import {
  AdludioTheme,
  Card,
  Grid,
  makeStyles,
  CardContent,
  Body1,
  Caption,
  CardMedia,
  Tooltip,
  useMediaQuery,
  useTheme,
  Box,
} from '@adludio/components';
import { Campaign } from '../generated/graphql';
import { convertToDate } from '../helpers/date';
import BriefImage from '../static-assets/BriefPlaceholder.png';
import { StatusBadge } from './StatusBadge';
import { getBriefLink } from '../helpers/getBriefLink';
import { BriefTypeBadge } from './BriefTypeBadge';
import { AuthContext } from '@adludio/react-sso';

const styles = makeStyles((theme: AdludioTheme) => ({
  addNewCard: {
    height: '21rem',
    width: '14.5rem',
    [theme.breakpoints.only('xs')]: {
      height: '21rem',
      width: '16.5rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '21rem',
      width: '16.5rem',
    },
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    cursor: 'pointer',
  },
  plusIcon: {
    fontSize: '4.25rem',
    fontWeight: 'bold',
  },
  thumbnail: {
    height: '14rem',
    width: '14.5rem',
    [theme.breakpoints.only('sm')]: {
      width: '16.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      width: '16.5rem',
      height: '15rem',
    },
    backgroundRepeat: 'no-repeat',
    background: `url(${BriefImage})`,
    backgroundSize: '100% 100%',
    ObjectFit: 'cover',
  },
  date: {
    color: theme.palette.grey[600],
  },
  test: {
    height: '100%',
  },
  title: {
    maxHeight: '1.3rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  cardContent: {
    height: '100%',
  },
  divContent: {
    height: '4.4rem',
  },
}));

export interface BriefGridProps {
  campaigns: Campaign[];
}

export function BriefGrid(props: BriefGridProps): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { addNewCard, thumbnail, date, title, cardContent, divContent } =
    styles();

  const { auth } = React.useContext(AuthContext);
  const isInternalUser =
    auth.user?.role !== 'guest' && auth.user?.role !== 'client';

  const handleClick = (campaign: Campaign) => {
    navigate(
      `campaigns/campaign/${campaign.id}/${getBriefLink(
        campaign.brief?.isSubmitted,
        campaign.creativeBrief?.isSubmitted ?? false,
        campaign.brief?.isManaged ?? false
      )}`
    ).catch(console.log);
  };

  const handleType = (campaign: Campaign) => {
    let campaignType: string;
    campaign.resellerBrief
      ? (campaignType = 'RS')
      : campaign.brief?.isManaged
      ? (campaignType = 'BD')
      : (campaignType = 'PMP');
    return campaignType;
  };

  return (
    <Grid item xs container spacing={9}>
      {/* Briefs rendered here */}
      {props.campaigns.map(
        (campaign) =>
          campaign.title === null && (
            <Grid
              item
              container
              key={campaign.id}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2}
              justifyContent={isDesktop ? 'flex-start' : 'center'}
            >
              <Card
                className={addNewCard}
                elevation={2}
                onClick={() => handleClick(campaign)}
              >
                <CardMedia className={thumbnail} />
                <CardContent className={cardContent}>
                  <Grid
                    container
                    direction='column'
                    wrap='nowrap'
                    xs={12}
                    className={divContent}
                  >
                    {/* /eslint-disable-next-line eqeqeq */}
                    {campaign.resellerBrief !== null ? (
                      campaign.resellerBrief?.name != null &&
                      campaign.resellerBrief?.name.length > 20 ? (
                        <Tooltip
                          title={campaign.resellerBrief?.name}
                          placement='top-start'
                        >
                          <Grid item xs>
                            <Body1 className={title}>
                              {campaign.resellerBrief?.name}
                            </Body1>
                          </Grid>
                        </Tooltip>
                      ) : (
                        <Grid item xs>
                          <Body1>{campaign.resellerBrief?.name}</Body1>
                        </Grid>
                      )
                    ) : campaign.brief?.name != null &&
                      campaign.brief!.name.length > 20 ? (
                      <Tooltip
                        title={campaign.brief?.name!}
                        placement='top-start'
                      >
                        <Grid item xs>
                          <Body1 className={title}>
                            {campaign.brief?.name}
                          </Body1>
                        </Grid>
                      </Tooltip>
                    ) : (
                      <Grid item xs>
                        <Body1>{campaign.brief?.name}</Body1>
                      </Grid>
                    )}
                    {campaign.resellerBrief !== null
                      ? campaign.resellerBrief?.startDate &&
                        campaign.resellerBrief?.endDate && (
                          <Grid item container xs={12}>
                            <Caption className={date}>
                              {convertToDate(campaign.resellerBrief?.startDate)}
                              - {convertToDate(campaign.resellerBrief?.endDate)}
                            </Caption>
                          </Grid>
                        )
                      : campaign.brief?.startDate &&
                        campaign.brief?.endDate && (
                          <Grid item container xs={12}>
                            <Caption className={date}>
                              {convertToDate(campaign.brief!.startDate)}-{' '}
                              {convertToDate(campaign.brief!.endDate)}
                            </Caption>
                          </Grid>
                        )}
                    <Box mt='0.5rem'>
                      <Grid
                        container
                        xs
                        justify='flex-end'
                        alignContent='flex-end'
                      >
                        {isInternalUser && (
                          <BriefTypeBadge campaignType={handleType(campaign)} />
                        )}
                        {campaign.resellerBrief !== null ? (
                          <StatusBadge
                            status={getBriefLink(
                              campaign.resellerBrief?.isSubmitted,
                              campaign.creativeBrief?.isSubmitted ?? false,
                              campaign.resellerBrief?.isManaged ?? false
                            )}
                          />
                        ) : (
                          <StatusBadge
                            status={getBriefLink(
                              campaign.brief?.isSubmitted,
                              campaign.creativeBrief?.isSubmitted ?? false,
                              campaign.brief?.isManaged ?? false
                            )}
                          />
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )
      )}
    </Grid>
  );
}
