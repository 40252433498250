import Preview from '@adludio/components/dist/Preview';
import { URLAdunitProps } from '@adludio/components/dist/Preview/Adunit/FromURL';
import React, { useLayoutEffect, useRef, useState } from 'react';

export const ResponsiveAdunitWrapper = (props: URLAdunitProps) => {
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const div = useRef<HTMLDivElement>(null);
  const handleResize = () => {
    const aspectRatio =
      props.width && props.height ? props.width / props.height : 1;
    // eslint-disable-next-line no-undefined
    if (div.current !== null && div.current !== undefined) {
      setHeight(div.current.getBoundingClientRect().width / aspectRatio);
      setWidth(div.current.getBoundingClientRect().width);
    }
  };
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div ref={div} style={{ width: '100%', height: 'auto' }}>
      {width && <Preview src={props.src} width={width!} height={height} />}
    </div>
  );
};
