import * as React from 'react';
import { Body2, Box, Grid } from '@adludio/components';
import InvitationSVG from '../../assets/InvitationSent.svg';

export const EmailSent = () => {
  return (
    <Grid container justify='center'>
      <img src={InvitationSVG} />
      <Box width='100%' pt='0.5rem'>
        <Body2 align='center' color='primary'>
          Invitation Sent
        </Body2>
      </Box>
    </Grid>
  );
};
