import * as React from 'react';
import {
  AdludioTheme,
  Divider,
  Grid,
  Tab,
  Tabs,
  makeStyles,
} from '@adludio/components';
import { CampaignDocument, FileStatus } from '../../models/document';
import {
  FileType,
  UserType,
  useEmailResolverLazyQuery,
  useUpdateDocumentStatusMutation,
} from '../../generated/graphql';

import CampaignPlan from '../../assets/CampaignPlan.svg';
import { DocumentCard } from '../../components/DocumentCard/DocumentCard';
import EmptyPage from '../../components/EmptyPage';

interface ExternalUserScreenProps {
  files: CampaignDocument[];
  reFetch: () => void;
  campaignId: string;
  isManaged?: boolean | null | undefined;
}

export const ExternalUserScreen = (
  props: ExternalUserScreenProps
): JSX.Element => {
  const [tab, setTab] = React.useState(0);

  const [updateDocumentStatusMutation] = useUpdateDocumentStatusMutation();
  const campaignPlan: CampaignDocument | undefined = props.files.find(
    (document) => document
  );
  const spendFloorCPM: CampaignDocument | undefined = props.files.find(
    (document) =>
      document.fileType === FileType.SpendFloorCpm &&
      document.fileStatus !== FileStatus.archived
  );
  const activeMediaPlan: CampaignDocument[] | undefined = props.files.filter(
    (document) =>
      document.fileType === FileType.Storyboard &&
      document.fileStatus !== FileStatus.archived
  );
  const siteList: CampaignDocument[] | undefined = props.files.filter(
    (document) =>
      document.fileType === FileType.SiteList &&
      document.fileStatus !== FileStatus.archived
  );
  const archivedDocuments = props.files.filter(
    (document) => document.fileStatus === FileStatus.archived
  );
  const [sendEmail] = useEmailResolverLazyQuery({
    variables: {
      campaignId: props.campaignId,
      templateName: 'CampaignPlanAccepted',
      userType: UserType.Internal,
    },
  });
  const useStyles = makeStyles((theme: AdludioTheme) => ({
    root: {
      width: '100vw',
      overflow: 'hidden',
    },
    cards: {
      marginBottom: '1rem',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
  }));

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setTab(newValue);
  };

  const handleApprove = (documentId: string, newStatus: FileStatus) => {
    updateDocumentStatusMutation({
      variables: { id: documentId, status: newStatus },
    }).catch(console.log);
    props.reFetch();
    sendEmail();
  };

  const campaignDocument = {
    SpendFloorCPM: (
      <Grid item sm={6} md={4} lg={3} key={spendFloorCPM?.id}>
        <DocumentCard
          isManaged={props.isManaged}
          document={spendFloorCPM!}
          handleApprove={handleApprove}
          hideName={false}
        />
      </Grid>
    ),
    CampaignPlan: (
      <Grid item md={4} lg={3} key={campaignPlan?.id}>
        <DocumentCard
          isManaged={props.isManaged}
          document={campaignPlan!}
          handleApprove={handleApprove}
          hideName={true}
        />
      </Grid>
    ),
  };

  const renderDocument = () => {
    return props.isManaged
      ? campaignPlan && campaignDocument.CampaignPlan
      : spendFloorCPM && campaignDocument.SpendFloorCPM;
  };

  const { root, cards } = useStyles();
  return campaignPlan || activeMediaPlan.length > 0 ? (
    <Grid container alignContent='flex-start' spacing={5} className={root}>
      <Grid item xs={12}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label='Campaign Plan Documents' />
          <Tab
            disabled={archivedDocuments.length === 0}
            data-testid='documents-tab'
            label='Documents Archive'
          />
        </Tabs>
        <Divider />
      </Grid>

      <Grid item container xs={12}>
        <Grid container spacing={5} className={cards}>
          {tab === 1 ? (
            <>
              {archivedDocuments.map((document) => (
                <Grid item md={4} lg={3} key={document.id}>
                  <DocumentCard
                    document={document}
                    handleApprove={handleApprove}
                    hideStatus={false}
                    hideName={!!props.isManaged}
                  />
                </Grid>
              ))}
              {props.files.length === 0 && <img src={CampaignPlan} />}
            </>
          ) : (
            <>
              {renderDocument()}

              {activeMediaPlan.map((mediaPlan) => (
                <Grid item md={4} lg={3} key={mediaPlan.id}>
                  <DocumentCard
                    isManaged={props.isManaged}
                    document={mediaPlan}
                    handleApprove={handleApprove}
                    hideStatus={!!props.isManaged}
                    hideName={false}
                  />
                </Grid>
              ))}
              {siteList.map((item) => (
                <Grid item sm={6} md={4} lg={3} key={item.id}>
                  <DocumentCard
                    isManaged={props.isManaged}
                    document={item}
                    handleApprove={handleApprove}
                    hideStatus={true}
                    hideName={false}
                  />
                </Grid>
              ))}
              <Grid container justify='center' />
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <EmptyPage />
  );
};
