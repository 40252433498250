import { Company } from '../Pages/SettingsPage/CompanyProfile';

export const updateCompany = (
  ssoBEURL: string,
  userCompanyID: number,
  authToken: string,
  body: Omit<Company, 'id'>
) => {
  return fetch(`//${ssoBEURL}/companies/${userCompanyID}`, {
    method: 'PATCH',
    headers: {
      'Authorization': 'Bearer ' + authToken,
      'Content-Type': 'application/json',
      'Access-Origin': '*'
    },
    body: JSON.stringify(body)
  });
};
