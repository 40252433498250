import {
  AdludioTheme,
  Grid,
  makeStyles,
  Subtitle1,
  Divider,
  TextInput,
  Box,
  Button,
  Card,
} from '@adludio/components';
import { Select, MenuItem } from '@material-ui/core';
import React, { Dispatch, SetStateAction } from 'react';
import DraftCard from './draftCard';
import DevicesRounded from '@material-ui/icons/DevicesRounded';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { mockDSPs } from '../../../../Projects/Campaigns/mock-data/mockDSPs';
import { GetCreativeUploadsQuery } from '../../generated/graphql';
import { CreativeUploadValues } from '../Forms/types';
import { CREATIVE_UPLOAD_FORM_VALUES } from '../Forms/const';

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    marginBottom: '2rem',
  },
  titles: {
    color: theme.palette.primary.dark,
  },
  iconButton: {
    paddingLeft: '0.5rem',
  },
  addKeyButton: {
    margin: '1rem 0 2rem 0',
  },
}));

export interface CreativeUploadError {
  name: string;
  error: boolean;
}
interface SidePanelProps {
  setDsp: Dispatch<SetStateAction<string>>;
  creativeSelected: number;
  dsp: string;
  setAdvertiserID: Dispatch<SetStateAction<string>>;
  setCreativeSelected: Dispatch<SetStateAction<number>>;
  setForms: Dispatch<SetStateAction<CreativeUploadValues[]>>;
  forms: CreativeUploadValues[];
  advertiserID: string;
  advertiserError: boolean;
  getForm: (creative: any) => CreativeUploadValues;
  dspError: Boolean;
  creativeUploads?: GetCreativeUploadsQuery;
  isDraft: boolean;
  copyTag: () => void;
  creativeUploadId: string;
  isLoading: boolean;
  uploadCreatives: () => Promise<boolean>;
}

const uploadCapableDSP = ['TTD'];
const SidePanel = (props: SidePanelProps) => {
  const { root, titles, iconButton, addKeyButton } = styles();

  return (
    <>
      <Card elevation={2}>
        <Box p='2rem'>
          <Grid container item xs direction='column'>
            <Box pb='2rem'>
              <Select
                fullWidth
                error={!!props.dspError}
                variant='outlined'
                value={[props.dsp]}
                onChange={(e) => props.setDsp(e.target.value as string)}
              >
                {mockDSPs.map((c) => (
                  <MenuItem value={c.value} key={c.key}>
                    {c.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Subtitle1 className={titles}>Advertiser ID*</Subtitle1>
            <Box pb='1.5rem'>
              <Divider />
            </Box>
            <Grid item xs className={root}>
              <TextInput
                error={props.advertiserError}
                value={props.advertiserID}
                onChange={(e) => props.setAdvertiserID(e.target.value)}
                helperText='Please input the advertiser ID for this campaign'
                variant='outlined'
              />
            </Grid>
            <Grid container item xs direction='row'>
              <Box pt='0.1rem' pr='0.6rem' className={titles}>
                <DevicesRounded />
              </Box>
              <Subtitle1 className={titles}>Ad Units</Subtitle1>
            </Grid>
            <Box pb='1.5rem'>
              <Divider />
            </Box>
            <Grid item xs direction='column'>
              {props.forms.length >= 0 ? (
                props.forms.map((c, idx) => (
                  <DraftCard
                    name={c.name}
                    idx={idx}
                    creativeSelected={props.creativeSelected === idx}
                    creativeUploadId={props.creativeUploadId}
                    getForm={props.getForm}
                    forms={props.forms}
                    setForms={props.setForms}
                    setCreativeSelected={props.setCreativeSelected}
                  />
                ))
              ) : (
                <DraftCard
                  idx={0}
                  name='Draft'
                  setCreativeSelected={props.setCreativeSelected}
                  getForm={props.getForm}
                  creativeUploadId={props.creativeUploadId}
                  forms={props.forms}
                  setForms={props.setForms}
                  creativeSelected={props.creativeSelected === 0}
                />
              )}
            </Grid>
            <Grid item xs className={addKeyButton}>
              <Button
                variant='outlined'
                onClick={() => {
                  props.setForms((prevState) => [
                    ...prevState,
                    CREATIVE_UPLOAD_FORM_VALUES,
                  ]);
                  props.setCreativeSelected(props.forms.length);
                }}
              >
                Add Game Key
                <AddCircleOutline className={iconButton} />
              </Button>
            </Grid>
            {/* Add upload functionality */}
            <Grid container item>
              {uploadCapableDSP.includes(props.dsp) ? (
                <Grid item xs>
                  <Button
                    variant='contained'
                    onClick={props.uploadCreatives}
                    isLoading={props.isLoading}
                  >
                    Upload Ad Units
                  </Button>
                </Grid>
              ) : (
                <Grid item xs>
                  <Button variant='contained' onClick={props.copyTag}>
                    Copy Tag
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default SidePanel;
