import React from 'react';

import { Grid, makeStyles } from '@adludio/components';
import { AdludioTheme } from '@adludio/components/dist/Theme/types';
import { CircularProgress } from '@adludio/components';

type MainCard = React.PropsWithChildren<{secondary?: boolean, isLoading?: boolean, wide?:boolean}>

const useStyles = makeStyles((theme: AdludioTheme) => ({
  wrapper: ({ secondary }: {secondary?: boolean}) => ({
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      minHeight: '100%',
    },
    minHeight: secondary ? 100 : 600,
    width: '100%',
    maxWidth: 400,
    background: '#FFFFFF',
    borderRadius: 4,
    boxShadow: theme.shadows[3],
    textAlign: 'center',
  }),
}));

const MainCard = ({ children, secondary, isLoading }: MainCard ) => {
  const { wrapper } = useStyles({ secondary });
  return (
    <Grid
      container
      xs={12}
      className={wrapper}
      direction='column'
      justify={isLoading ? 'center' : 'flex-start'}
    >
      {isLoading ? <Grid item><CircularProgress /></Grid> : children}
    </Grid>
  );
};

export default MainCard;
