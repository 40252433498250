export const sideNavItems = [
  { icon: 'tag-builder', href: 'tag-builder', title: 'Tag Builder', permission: 'tag-builder' },
  { icon: 'reporting-dashboard', href: 'reporting-dashboard', title: 'Reporting Dashboard', permission: 'bio-dashboard' }

];

export const topNavItems = [
  { icon: 'account_circle', href: 'my-account', title: 'My Account' },
  { icon: 'logout', href: 'login', title: 'Logout' }
];
