import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '@adludio/react-sso';
import { SingleSiteList } from '../types';
import { fetchSiteList } from '../api';

export interface SiteListContextState {
  topSiteList: SingleSiteList;
  bottomSiteList: SingleSiteList;
  isSiteLoading: boolean;
  fetchSiteList: (campaignKey: string) => void;
}

export const initialSiteListState: SiteListContextState = {
  topSiteList: {},
  bottomSiteList: {},
  isSiteLoading: false,
  fetchSiteList: (campaignKey) => {
    throw Error('fetchSiteList not implemented');
  },
};

export const SiteListContext =
  React.createContext<SiteListContextState>(initialSiteListState);

export const useSiteList = () => useContext(SiteListContext);

export interface SiteListContextProviderProps {
  children: React.ReactNode;
}

export const SiteListContextProvider = ({
  children,
}: SiteListContextProviderProps) => {
  const [topSiteList, setTopSiteList] = useState<SingleSiteList>({});
  const [bottomSiteList, setBottomSiteList] = useState<SingleSiteList>({});
  const [isFetching, setIsFetching] = useState(true);

  const { auth } = React.useContext(AuthContext);

  const loadSiteList = useCallback(
    (campaignKey: string) => {
      setIsFetching(true);
      setTopSiteList({});
      setBottomSiteList({});
      fetchSiteList(auth.authToken, campaignKey)
        .then((result) => {
          setTopSiteList(result.top);
          setBottomSiteList(result.bottom);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    },
    [auth.authToken]
  );

  return (
    <SiteListContext.Provider
      value={{
        topSiteList: topSiteList,
        bottomSiteList: bottomSiteList,
        isSiteLoading: isFetching,
        fetchSiteList: loadSiteList,
      }}
    >
      {children}
    </SiteListContext.Provider>
  );
};
