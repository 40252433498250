import { BreakdownReport, BreakdownReportValues } from '../types';
import { mapValue } from './breakdownsConfig';

export class BreakdownComputation {
  breakdownReport: BreakdownReport;

  constructor(breakdownReport: BreakdownReport) {
    this.breakdownReport = breakdownReport;
  }

  getSelectedBreakdown(selectedBreakdown: string): BreakdownReportValues {
    return this.breakdownReport[selectedBreakdown];
  }

  getSelectedBreakdownKpiVal(
    selectedBreakdownReport: BreakdownReportValues,
    kpi: string
  ): number[] {
    return Object.keys(selectedBreakdownReport).map(
      (bk) => selectedBreakdownReport[bk]['overview'][kpi]
    );
  }

  getSelectedBreakdownKpis(selectedBreakdownReport: BreakdownReportValues) {
    let possibleKpis = ['er', 'ctr', 'ectr', 'vtr'];// , 'viewability'

    let randOverview =
      selectedBreakdownReport[Object.keys(selectedBreakdownReport)[0]][
        'overview'
      ];

    let _kpis: string[] = [];
    possibleKpis.map(
      (_kpi) => Object.keys(randOverview).includes(_kpi) && _kpis.push(_kpi)
    );

    return _kpis;
  }

  getSelectedBreakdownKpiSummary(
    selectedBreakdownReport: BreakdownReportValues
  ) {
    let kpis = this.getSelectedBreakdownKpis(selectedBreakdownReport);

    let kpiSummary: { name: string; data: number[] }[] = [];
    Object.keys(selectedBreakdownReport).map((bk) => {
      kpiSummary.push({
        name: mapValue(bk),
        data: kpis.map((_kpi) => selectedBreakdownReport[bk]['overview'][_kpi]),
      });
    });

    return kpiSummary;
  }

  getDates(selectedBreakdownReport: BreakdownReportValues): string[] {
    let dates: string[] = [];

    Object.keys(selectedBreakdownReport).map((bk) => {
      dates = dates.concat(selectedBreakdownReport[bk]['daily']['date']);
    });

    dates = Array.from(new Set(dates)).sort();
    return dates;
  }

  getSelectedKpiTrend(
    selectedBreakdownReport: BreakdownReportValues,
    selectedKpi: string
  ): {
    name: string;
    data: number[];
  }[] {
    // map all dates with zeros
    let zeroDates: { [date: string]: number } = {};
    this.getDates(selectedBreakdownReport).map((date) => (zeroDates[date] = 0));

    let _dailyTrend: { name: string; data: number[] }[] = [];
    Object.keys(selectedBreakdownReport).map((bk) => {
      let kpiValues =
        selectedBreakdownReport[bk]['daily'][selectedKpi.toLowerCase()];

      let kpiDates: { [date: string]: number } = {};
      selectedBreakdownReport[bk]['daily']['date'].map(
        (date, i) => (kpiDates[date] = kpiValues[i] as number)
      );

      kpiDates = { ...zeroDates, ...kpiDates };

      let sortedKpiDates = Object.keys(kpiDates)
        .sort()
        .reduce((r, k) => ((r[k] = kpiDates[k]), r), {});

      _dailyTrend.push({
        name: mapValue(bk),
        data: Object.values(sortedKpiDates),
      });
    });

    return _dailyTrend;
  }

  getSelectedBreakdownLabel(selectedBreakdownReport: BreakdownReportValues) {
    return Object.keys(selectedBreakdownReport).map((k) => mapValue(k));
  }

  getSelectedBreakdownSpend(selectedBreakdownReport: BreakdownReportValues) {
    return this.getSelectedBreakdownKpiVal(
      selectedBreakdownReport,
      'curatorRevenue'
    );
  }

  getSelectedBreakdownImp(selectedBreakdownReport: BreakdownReportValues) {
    return this.getSelectedBreakdownKpiVal(
      selectedBreakdownReport,
      'impression'
    );
  }
}
