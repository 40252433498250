import {
  AdludioTheme,
  Body1,
  Chip,
  IconButton,
  makeStyles,
} from '@adludio/components';
import { Avatar, Grid } from '@material-ui/core';
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
} from 'mui-datatables';

import ChangeStatusModal from './Modals/ChangeStatus';
import { DeleteDocumentModal } from './Modals/DeleteDocument';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Edit from '@material-ui/icons/Edit';
import { FileStatus } from '../models/document';
import { FileType } from '../generated/graphql';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React from 'react';
import { capitalize } from 'lodash';
import { useDownload } from '../helpers/useDownload';

export type RestructuredFile = [
  string,
  string,
  string,
  string,
  string,
  FileStatus,
  string
];

export interface Document {
  campaignId: { id: string };
  id: string;
  fileName: string;
  fileStatus: FileStatus;
  fileType: FileType;
  uploadedAt: Date;
  creatorId?: { id: number; name: string };
  deletedAt?: Date;
}

const dateTime = (date: string) => {
  const newDate = new Date(date);
  return newDate.toLocaleDateString();
};

const restructureFileData = ({
  id,
  fileName,
  fileStatus: status,
  fileType,
  creatorId: creator,
  campaignId: { id: campaignId },
  uploadedAt,
}: Document): RestructuredFile => [
  campaignId,
  fileName,
  dateTime(uploadedAt?.toString()),
  fileType,
  creator!.name,
  status,
  id,
];

const options: MUIDataTableOptions = {
  filter: false,
  responsive: 'vertical',
  download: false,
  search: false,
  selectableRows: 'none',
  print: false,
  sort: true,
  pagination: false,
  viewColumns: false,
  sortOrder: { name: 'Date', direction: 'desc' },
};
interface FileTableProps {
  isManaged?: boolean;
  includesStatus?: boolean;
  data: Document[];
  reFetch: () => void;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  ACCEPTED: {
    backgroundColor: `${theme.palette.success.dark} !important`,
  },
  AWAITING: {
    backgroundColor: `${theme.palette.warning.dark} !important`,
  },
  ARCHIVED: {
    backgroundColor: `${theme.palette.grey[600]} !important`,
  },
  UPLOADED: {
    backgroundColor: `${theme.palette.info.dark} !important`,
  },
  campaignPlan: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: '0.5em',
    color: 'white !important',
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
  file: {
    width: '100%',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    overflowWrap: 'anywhere',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      textOverflow: 'revert',
      '-webkit-line-clamp': 'unset',
    },
  },
  table: {
    width: '100%',
    '&& .MuiToolbar-root': {
      display: 'none',
    },
  },
}));

interface AvatarRenderProps {
  value: string;
  tableMeta?: any;
  updateValue?: any;
}

const AvatarRenderer: React.FC<AvatarRenderProps> = ({
  value,
  tableMeta,
  updateValue,
}: AvatarRenderProps) => {
  const style = styles();
  return (
    <Grid container wrap='nowrap' alignItems='center' direction='row'>
      <Avatar variant='circular' className={style.avatar}>
        {value.substring(0, 1)}
      </Avatar>
      <Body1 noWrap>{value}</Body1>
    </Grid>
  );
};

type SelectedRow = { id: string; currentStatus: FileStatus; type: FileType };

export const FileTable: React.FC<FileTableProps> = (props: FileTableProps) => {
  const [statusModalIsOpen, setStatusModal] = React.useState(false);
  const emptyState = {};
  const [selectedRow, setSelectedRow] = React.useState<SelectedRow>(
    emptyState as SelectedRow
  );
  const { downloadDocument } = useDownload();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedId, setSelectedId] = React.useState<string>('');
  const style = styles();

  const onClose = () => {
    setSelectedRow(emptyState as SelectedRow);
    setStatusModal(false);
  };

  const handleOpenModal = (document: Document) => {
    setSelectedRow({
      id: document.id,
      currentStatus: document.fileStatus,
      type: document.fileType,
    });
    setStatusModal(true);
  };
  const handleOpenDelete = (id: string) => {
    setIsOpen(true);
    setSelectedId(id);
  };
  const handleCloseDelete = () => {
    setIsOpen(false);
    setSelectedId('');
  };

  const actionsRenderer = (
    document: Document,
    onDelete: (fileId: string) => void
  ) => {
    return (
      <Grid container wrap='nowrap'>
        <IconButton
          onClick={() => {
            downloadDocument(document);
          }}
          aria-label='download'
          label=''
        >
          <GetAppRoundedIcon color='primary' />
        </IconButton>
        <IconButton
          onClick={() => onDelete(document.id)}
          aria-label='delete'
          label=''
        >
          <DeleteRoundedIcon color='primary' />
        </IconButton>
      </Grid>
    );
  };
  const fileNameRenderer = (fileName: string) => {
    return <Body1 className={style.file}>{fileName}</Body1>;
  };
  const fileNameColumn = {
    name: 'File Name',
    options: {
      filter: false,
      customBodyRender: (value: string) => fileNameRenderer(value),
    },
  };

  const typeColumn = {
    name: 'Type',
    options: {
      filter: false, // eslint-disable-next-line react/display-name
      customBodyRenderLite: (dataIndex: number) => {
        if (!props.includesStatus) {
          return (
            <Body1>{props.data[dataIndex].fileName.split('.').pop()}</Body1>
          );
        }
        /* regex expression to add a space between lowercase and uppercase */
        return (
          <Body1 noWrap>
            {props.data[dataIndex].fileType.replace(/([a-z])([A-Z])/g, '$1 $2')}
          </Body1>
        );
      },
    },
  };
  const uploaderColumn = {
    name: 'Uploader',
    options: {
      filter: false,
      // eslint-disable-next-line react/display-name
      customBodyRender: (value: string, tableMeta: any, updateValue: any) => {
        return <AvatarRenderer value={value} />;
      },
    },
  };
  const idColumn: MUIDataTableColumn = {
    name: 'Id',
    options: {
      display: 'excluded',
    },
  };
  const statusColumn = {
    name: 'Status',
    options: {
      filter: false,
      // eslint-disable-next-line react/display-name
      customBodyRenderLite: (dataIndex: number) => {
        const document: Document = props.data[dataIndex];
        const handleClick = () => {
          handleOpenModal(document);
        };
        if (
          (document.fileType === FileType.Storyboard && props.isManaged) ||
          document.fileType === FileType.SiteList
        ) {
          return (
            <Chip
              className={
                document.fileStatus === FileStatus.archived
                  ? style['ARCHIVED']
                  : style['UPLOADED']
              }
              label={
                document.fileStatus === FileStatus.archived
                  ? 'Archived'
                  : 'Uploaded'
              }
              onClick={handleClick}
              deleteIcon={<Edit />}
              onDelete={handleClick}
            />
          );
        }
        return (
          <Chip
            className={style[document.fileStatus]}
            label={
              document.fileStatus === FileStatus.pending
                ? 'Awaiting Approval'
                : capitalize(document.fileStatus)
            }
            onClick={handleClick}
            deleteIcon={<Edit />}
            onDelete={handleClick}
          />
        );
      },
    },
  };
  const actionsColumn = {
    name: '',
    label: '',
    options: {
      customBodyRenderLite: (dataIndex: number) =>
        actionsRenderer(props.data[dataIndex], () =>
          handleOpenDelete(props.data[dataIndex].id)
        ),
    },
  };
  const extendedColumns = [
    idColumn,
    fileNameColumn,
    'Date',
    typeColumn,
    uploaderColumn,
    statusColumn,
    actionsColumn,
  ];
  const columns = [
    idColumn,
    fileNameColumn,
    'Date',
    typeColumn,
    uploaderColumn,
    actionsColumn,
  ];

  return (
    <>
      <DeleteDocumentModal
        isOpen={isOpen}
        onClose={handleCloseDelete}
        documentId={selectedId}
        reFetch={props.reFetch}
      />
      <ChangeStatusModal
        isOpen={statusModalIsOpen}
        isManaged={props.isManaged}
        onClose={onClose}
        {...selectedRow}
      />
      <Grid xs={12} className={style.table}>
        <MUIDataTable
          title={''}
          data={props.data.map((file: Document) => restructureFileData(file))}
          columns={props.includesStatus ? extendedColumns : columns}
          options={options}
        />
      </Grid>
    </>
  );
};
