/* eslint-disable complexity */

import {
  Box,
  Divider,
  Grid,
  Tab,
  TabPanel,
  Tabs,
  makeStyles,
  AdludioTheme,
} from '@adludio/components';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CampaignBriefEdit } from '../../pages/CampaignBriefEdit';
import CheckIcon from '@material-ui/icons/Check';
import ViewCampaignBrief from '../../pages/ViewCampaignBrief';
import ViewCampaignResellerBrief from '../../pages/ViewCampaignResellerBrief';
import { useGetCampaignTypeLazyQuery } from '../../generated/graphql';
import { CreativeCampaignForm } from '../Forms/Creative/CreativeForm';
import { ViewCreativeBrief } from '../../pages/ViewCreativeBrief';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';

const styles = makeStyles((theme: AdludioTheme) => ({
  pageContent: {
    height: '100%',
    overflow: 'hidden',
  },
}));

const CampaignBrief = ({
  campaignId,
  briefSubmitted,
  isReseller,
  setBriefSubmitted,
  creativeSubmitted,
  setCreativeBriefSubmitted,
  campaignStatus,
}: {
  campaignId?: string;
  briefSubmitted: boolean;
  isReseller: boolean;
  setBriefSubmitted: Dispatch<SetStateAction<boolean>>;
  creativeSubmitted: boolean;
  setCreativeBriefSubmitted: Dispatch<SetStateAction<boolean>>;
  campaignStatus: string;
}) => {
  const location = useLocation();
  const searchParams = parse(location.search);
  // STATES
  const [value, setValue] = React.useState(
    searchParams.creative ? parseInt(searchParams.creative as string) : 0
  );
  const [addCreativeBrief, setAddCreativeBrief] = useState(false);
  const [editCreative, setEditCreative] = useState(false);
  const [isMediaCampaign, setIsMediaCampaign] = useState<boolean | null>(null);

  const { pageContent } = styles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };

  const [fetch, { data }] = useGetCampaignTypeLazyQuery();
  const [editCampaignBrief, setEditCampaignBrief] = useState(
    !data?.getCampaignBrief?.isSubmitted
  );

  useEffect(() => {
    fetch({ variables: { campaignId: campaignId! } });
  }, [fetch, campaignId]);

  useEffect(() => {
    setEditCampaignBrief(!data?.getCampaignBrief?.isSubmitted);
  }, [data]);

  useEffect(() => {
    data?.getCampaignBrief &&
      setIsMediaCampaign(data.getCampaignBrief.isManaged!);
  }, [data]);


  return (
    <Grid
      container
      justifyContent='center'
      direction='row'
      className={pageContent}
    >
      <Grid container xs={12} justifyContent='center'>
        <Grid item xs={8} sm={6} md={10}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label={
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                >
                  Campaign brief
                  {briefSubmitted && (
                    <Box ml='0.5rem'>
                      <CheckIcon />
                    </Box>
                  )}
                </Grid>
              }
            />
            {!isMediaCampaign && !isReseller && (
              <Tab
                label={
                  <Grid
                    container
                    direction='row'
                    xs={12}
                    alignItems='center'
                    justifyContent='center'
                  >
                    Creative brief
                    {creativeSubmitted && (
                      <Box ml='0.5rem'>
                        <CheckIcon />
                      </Box>
                    )}
                  </Grid>
                }
              />
            )}
          </Tabs>
        </Grid>
      </Grid>
      <Grid xs={10}>
        <Divider style={{ width: '100%' }} />
      </Grid>
      <Grid container xs={12} justifyContent='flex-start' direction='column'>
        <TabPanel currentTab={value} index={0}>
          {data?.getCampaignBrief === null ? (
            <ViewCampaignResellerBrief
              setBriefSubmitted={setBriefSubmitted}
              campaignId={campaignId}
              setEditCampaignBrief={setEditCampaignBrief}
              editCampaignBrief={editCampaignBrief}
            />
          ) : !data?.getCampaignBrief?.isSubmitted || editCampaignBrief ? (
            <CampaignBriefEdit
              campaignId={campaignId!}
              isMediaCampaign={isMediaCampaign}
              onCancel={() => setEditCampaignBrief(false)}
              setBriefSubmitted={setBriefSubmitted}
            />
          ) : (
            <ViewCampaignBrief
              campaignId={campaignId}
              setEditCampaignBrief={setEditCampaignBrief}
              editCampaignBrief={editCampaignBrief}
            />
          )}
        </TabPanel>
        {!isMediaCampaign && (
          <TabPanel currentTab={value} index={1}>
            {addCreativeBrief || editCreative || !creativeSubmitted ? (
              <Grid container justifyContent='center'>
                <CreativeCampaignForm
                  id={campaignId!}
                  onCancel={() => setEditCreative(false)}
                />
              </Grid>
            ) : (
              <ViewCreativeBrief
                campaignId={campaignId}
                setAddCreativeBrief={setAddCreativeBrief}
                setEditCreative={setEditCreative}
              />
            )}
          </TabPanel>
        )}
      </Grid>
    </Grid>
  );
};

export default CampaignBrief;
