import { Map } from 'immutable';
import { DSPType } from '../scriptGenerator/store/tagReducer';
import { DataMapType } from '.';

const DV360Data: DataMapType = Map({
  dsp: 'DV360',
  adludio_brief_ref: '',
  environment: '',
  height: '',
  width: '',
  auction_id: '${AUCTION_ID}',
  advertiser_id: '${INSERTION_ORDER_ID}',
  insertion_order_id: '${INSERTION_ORDER_ID}',
  campaign_id: '${CAMPAIGN_ID}',
  line_item_id: '${CAMPAIGN_ID}',
  creative_id: '${CREATIVE_ID}',
  exchange_id: '${EXCHANGE_ID}',
  publisher_id: '${PUBLISHER_ID}',
  inventory_source_external_id: '${INVENTORY_SOURCE_EXTERNAL_ID}',
  pixel_ids: '${PIXEL_ID_COMMA}',
  universal_site_id: '${UNIVERSAL_SITE_ID}',
  click_url: '${CLICK_URL}',
  site_name: '${SOURCE_URL}',
  third_party_engagement: [
    'https://pixel.adsafeprotected.com/jload?anId=930722&campId=${CAMPAIGN_ID}_ENG&pubId=${PUBLISHER_ID}_ENG&placementId=${CREATIVE_ID}_ENG&dealId=${INVENTORY_SOURCE_EXTERNAL_ID}_ENG&adsafe_par&bidurl=${SOURCE_URL_ENC}',
  ],
});

const TTDData: DataMapType = Map({
  dsp: 'TTD',
  adludio_brief_ref: '',
  environment: '',
  height: '',
  width: '',
  auction_id: '%%TTD_IMPRESSIONID%%',
  advertiser_id: '%%TTD_ADVERTISERID%%',
  ttd_adformat: '%%TTD_ADFORMAT%%',
  ttd_user_id: '%%TTD_TDID%%',
  campaign_id: '%%TTD_CAMPAIGNID%%',
  line_item_id: '%%TTD_ADGROUPID%%',
  creative_id: '%%TTD_CREATIVEID%%',
  inventory_source: '%%TTD_SUPPLYVENDOR%%',
  ttd_deal_id: '%%TTD_DEALID%%',
  inventory_category: '%%TTD_CATEGORY%%',
  ttd_language: '%%TTD_LANGUAGE%%',
  device_type: '%%TTD_DEVICETYPE%%',
  platform_os: '%%TTD_DEVICEOSFAMILY%%',
  ttd_environment: '%%TTD_RENDERING_CONTEXT%%',
  geo_country: '%%TTD_COUNTRY%%',
  geo_region: '%%TTD_REGION%%',
  geo_metro: '%%TTD_METRO%%',
  geo_city: '%%TTD_CITY%%',
  is_gdpr: '%%TTD_GDPR_APPLIES%%',
  consent_string: '%%TTD_GDPR_CONSENT_STRING%%',
  click_url: '%%TTD_CLK%%',
  site_name: '%%TTD_SITE%%',
  third_party_engagement: [
    'https://pixel.adsafeprotected.com/jload?anId=930756&advId=%%TTD_ADVERTISERID%%_ENG&campId=%%TTD_CAMPAIGNID%%_ENG&pubId=%%TTD_SUPPLYVENDOR%%_ENG&chanId=%%TTD_ADGROUPID%%_ENG&placementId=%%TTD_CREATIVEID%%_ENG&dealId=%%TTD_DEALID%%_ENG&adsafe_par&bidurl=%%TTD_SITE%%',
  ],
  ttd_imp_aud_seg_name:
    'IMP_AGID_%%TTD_ADGROUPID%%_CRID_%%TTD_CREATIVEID%%_AUD',
  ttd_eng_aud_seg_name:
    'ENG_AGID_%%TTD_ADGROUPID%%_CRID_%%TTD_CREATIVEID%%_AUD',
  ttd_imp_aud_user_ttl_min: '86400',
  ttd_eng_aud_user_ttl_min: '86400',
});

const XandrData: DataMapType = Map({
  inventory_category: '${TOP_LEVEL_CATEGORY_ID}',
  xandr_age: '${AGE}',
  xandr_gender: '${GENDER}',
  xandr_bid_price: '${BID_PRICE}',
  xandr_data_center: '${DATACENTER}',
  xandr_ecp_publisher: '${ECP}',
  xandr_app_id: '${EXT_APP_ID}',
  xandr_price_paid: '${PRICE_PAID}',
  xandr_publisher_id: '${PUBLISHER_ID}',
  xandr_placement_id: '${TAG_ID}',
  xandr_carrier_id: '${CARRIER_ID}',
  xandr_make_id: '${DEVICE_MAKE_ID}',
  xandr_model_id: '${DEVICE_MODEL_ID}',
  dsp: 'XANDR',
  adludio_brief_ref: '',
  xandr_deal_id: '${DEAL_ID}',
  site_name: '${REFERER_URL}',
  width: '${WIDTH}',
  height: '${HEIGHT}',
  geo_country: '${USER_COUNTRY}',
  xandr_environment: '${SUPPLY_TYPE}',
  campaign_id: '${IO_ID}',
  creative_id: '${CREATIVE_ID}',
  redirect_url: '',
  advertiser_id: '${ADV_ID}',
  environment: '',
  xandr_creative_size: '${CREATIVE_SIZE}',
  auction_id: '${AUCTION_ID}',
  click_url: '${CLICK_URL}',
  geo_city: '${USER_CITY}',
  line_item_id: '${CPG_ID}',
  is_gdpr: '${GDPR_APPLIES}',
  consent_string: '${GDPR_CONSENT_STRING}',
  third_party_engagement: [
    'https://pixel.adsafeprotected.com/jload?anId=930756&advId=${ADV_ID}_ENG&campId=${IO_ID}_ENG&pubId=${PUBLISHER_ID}_ENG&chanId=${CPG_ID}_ENG&placementId=${CREATIVE_ID}_ENG&dealId=${DEAL_ID}_ENG&adsafe_par&bidurl=${REFERER_URL}',
  ],
  inventory_source: '${SELLER_MEMBER_ID}',
});

const KidozData: DataMapType = Map({
  dsp: 'KIDOZ',
  site_name: '${SOURCE_URL}',
  campaign_id: '${CAMPAIGN_ID}',
  creative_id: '${CREATIVE_ID}',
  inventory_source_external_id: '${INVENTORY_SOURCE_EXTERNAL_ID}',
  advertiser_id: '${INSERTION_ORDER_ID}',
  third_party_engagement: [
    'https://pixel.adsafeprotected.com/jload?anId=930722&campId=${CAMPAIGN_ID}&pubId=${PUBLISHER_ID}&placementId=${CREATIVE_ID}&dealId=${INVENTORY_SOURCE_EXTERNAL_ID}&adsafe_par&bidurl=${SOURCE_URL_ENC}',
  ],
  adludio_brief_ref: '',
  environment: '',
  redirect_url: '',
  auction_id: '${AUCTION_ID}',
  exchange_id: '${EXCHANGE_ID}',
  click_url: '${CLICK_URL}',
  line_item_id: '${CAMPAIGN_ID}',
  insertion_order_id: '${INSERTION_ORDER_ID}',
  publisher_id: '${PUBLISHER_ID}',
  pixel_ids: '${PIXEL_ID_COMMA}',
  universal_site_id: '${UNIVERSAL_SITE_ID}',
});

const YahooData: DataMapType = Map({
  dsp: 'Yahoo',
  device_id: '${DEVICE_ID}',
  campaign_id: '${ORDERID}',
  creative_id: '${LIBRARYADID}',
  redirect_url: ' ',
  advertiser_id: '{ADV_ID}',
  adludio_brief_ref: '',
  third_party_engagement: [
    'https://pixel.adsafeprotected.com/jload?anId=930756&advId={ADV_ID}&campId=${ORDERID}&pubId=${PUBLISHERID}&chanId=&placementId=${PLACEMENTID}&dealId=&adsafe_par&bidurl=',
  ],
  environment: '',
  is_gdpr: '${GDPR}',
  consent_string: '${GDPR_CONSENT_447}',
  click_url: '${CLICKURL}',
  line_item_id: '${PLACEMENTID}',
  inventory_source: '${PUBLISHERID}',
});

type TTDType = typeof TTDData;
type DV360Type = typeof DV360Data;
type XandrType = typeof XandrData;
type KidozType = typeof KidozData;
type YahooType = typeof YahooData;

export type DSPTagType =
  | TTDType
  | DV360Type
  | XandrType
  | KidozType
  | YahooType;

function makeDataForDSP(
  dsp: DSPType | ''
): TTDType | DV360Type | XandrType | null {
  if (dsp === 'DV360') {
    return DV360Data;
  } else if (dsp === 'TTD') {
    return TTDData;
  } else if (dsp === 'Xandr') {
    return XandrData;
  } else if (dsp === 'Kidoz') {
    return KidozData;
  } else if (dsp === 'Yahoo') {
    return YahooData;
  } else {
    return null;
  }
}

export default makeDataForDSP;
