import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatAxis } from '../../utils/ChartAxis';
import { formatDateString } from '../../utils/date';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import getIcons from './chartIcons';
export interface DataProps {
  prediction: number[][];
  actual: number[][];
  intervals: { upper: number[][], lower: number[][] };
  kpi: string,
  campaignName: string,
  forecastFrom: Date,
  avergarePerfomance: number

}
const ForecastPlot = ({ prediction, actual, intervals, kpi, campaignName, forecastFrom, avergarePerfomance }: DataProps) => {
  const downloadFilename = `${campaignName}_${kpi}_prediction_from_${formatDateString(new Date())}_to_5 days`;
  const series = [
    {
      name: 'Actual',
      type: 'line',
      data: actual
    },
    {
      name: 'predicted',
      type: 'line',
      data: prediction
    },
    {
      name: 'Lower Interval',
      type: 'line',
      data: intervals['lower']
    },
    {
      name: 'Upper Interval',
      type: 'line',
      data: intervals['upper']
    },

  ];
  const options: ApexOptions = {
    chart: {
      type: 'line',
      height: 380,
      width: '100%',
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: getIcons(ZoomInIcon, 'Zoomin'),
          zoomout: getIcons(ZoomOutIcon, 'Zoomout'),
          pan: false,
          zoom: getIcons(SelectAllIcon, 'Selection view'),
          reset: getIcons(SettingsBackupRestoreIcon, 'Reset Original view'),
        },
        export: {
          png: {
            filename: downloadFilename,
          },
          svg: {
            filename: downloadFilename,
          },
        },
        autoSelected: 'zoom'
      },
    },

    dataLabels: {
      enabled: false,
      enabledOnSeries: [1, 2, 3, 4]
    },
    markers: {
      size: 4,
      colors: ['#58A0DA']
    },
    stroke: {
      width: [3, 5, 3, 3],
      dashArray: [0, 3, 0, 0]
    },
    legend: {
      show: true,
      customLegendItems: ['Actual', 'Predicted', 'Confidence Intervals'],
      horizontalAlign: 'center',
      position: 'top'
    },
    colors: ['#4E55C3', '#58A0DA', '#BF384C', '#BF384C'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',

        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [100, 100, 100, 100]
      },
    },
    annotations: {
      yaxis: [
        {
          y: avergarePerfomance,
          borderColor: '#597',
          label: {
            text: 'Average Line',
            borderWidth: 0,
            borderRadius: 0,
            textAnchor: 'start',
            position: 'left',
            style: {
              color: '#fff',
              background: '#775DD0'
            }
          }
        }
      ],
      xaxis: [
        {
          x: forecastFrom.getTime(),
          borderColor: '#999',
          label: {
            text: 'Forecast',
            style: {
              color: '#fff',
              background: '#775DD0'
            }
          }
        }
      ]
    },


    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value: string, timestamp: number, opts): string => {
          return opts.dateFormatter(new Date(timestamp), 'dd MMM');
        }
      }
    },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: kpi,
      },
      labels: {
        formatter: (val: number, index: number): string => {
          return formatAxis(kpi, val);
        }
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    title: {
      text: `${kpi} predictions for 5 days from today [80% Confidence]`,
      align: 'left',
      offsetX: 14
    },

  };

  return (

    <ReactApexChart options={options}
      series={series as ApexOptions['series']} type='area' height={400}
    />


  );
};

export default ForecastPlot;

