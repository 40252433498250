import React from 'react';

import ReportingDashboard from '@adludio/reporting-dashboard';

import { RouteComponentProps } from '@reach/router';
import { ConfigProps } from '../';

const ReportingDashboardContainer = ({
  config
}: RouteComponentProps & ConfigProps) => {
  return <ReportingDashboard config={{ env: config.env }} />;
};

export default ReportingDashboardContainer;
