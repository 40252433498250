import React, { Dispatch, SetStateAction } from 'react';
import { CreativeBriefOverviewRead } from './CreativeBriefOverviewRead';
import { RouteComponentProps } from '@reach/router';
import { useGetCampaignQuery } from '../../../generated/graphql';
// import { AuthContext } from '@adludio/react-sso';
import { Box, Card, Grid } from '@adludio/components';
export interface CreativeBriefOverviewProps extends RouteComponentProps {
  campaignId?: string;
  setEditCreative: Dispatch<SetStateAction<boolean>>;
}

export function CreativeBriefOverview(
  props: CreativeBriefOverviewProps
): JSX.Element {
  // const handleClick = () => props.setEditCreative(true);

  const { data } = useGetCampaignQuery({
    variables: { id: props.campaignId },
    fetchPolicy: 'no-cache',
  });

  // const { auth } = React.useContext(AuthContext);
  // const isInternalUser =
  //   auth.user?.role !== 'guest' && auth.user?.role !== 'client';
  return (
    <Grid container xs={12} justifyContent='center'>
      <Grid xs={11}>
        <Card elevation={2}>
          <Box p='2rem'>
            {/* <Grid direction='row-reverse' container>
              <Grid container item xs={6} justify='flex-end'>
                {(data?.getCampaignById?.title === null || isInternalUser) && (
                  <Button variant='outlined' onClick={handleClick}>
                    Edit brief
                  </Button>
                )}
              </Grid>
            </Grid> */}
            <Grid container justify='center'>
              <CreativeBriefOverviewRead data={data} />
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
