import {
  AdludioTheme,
  Body2,
  Dropzone,
  Grid,
  Icon,
  makeStyles,
} from '@adludio/components';
import React, { useState } from 'react';
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useUploadManaged } from '../../../helpers/useUploadManaged';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  icon: {
    width: '1.5rem',
    height: '2rem',
    paddingRight: '0.8rem',
  },
  waiting: {
    color: theme.palette.warning.dark,
  },
  success: {
    color: theme.palette.success.dark,
  },
  failed: {
    color: theme.palette.error.dark,
  },
}));

interface LiveUploadsProps {
  id: string;
  setLink: (linkData: string) => void;
}

export const LiveUploadsMultiple = ({ id, setLink }: LiveUploadsProps) => {
  const [files, setFiles] = useState<File[]>([]);
  const { uploadDocument, status, isUploading, uploadedUrl } =
    useUploadManaged();
  const [statuses, setStatuses] = useState<boolean[]>([]);
  // const [statuses,]=useState<boolean[]>([])

  const [filesAwaiting, setFilesAwaiting] = useState<File[]>([]);
  const [urls, setUrls] = useState('');

  const { icon, waiting, success, failed } = useStyles();

  const handleUploaded = (droppedFiles: File[] | null) => {
    console.log(droppedFiles);
    if (droppedFiles && droppedFiles.length) {
      setFiles(files.concat(droppedFiles));
      setFilesAwaiting(filesAwaiting.concat(droppedFiles));
    }
  };

  React.useEffect(() => {
    if (filesAwaiting?.length && !isUploading) {
      uploadDocument(filesAwaiting[0], id);
    }
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (!isUploading && status === true && uploadedUrl) {
      const stringToConcat = urls.length
        ? urls.concat(', ', uploadedUrl.split('?')[0])
        : uploadedUrl.split('?')[0];
      setLink(stringToConcat);
      setUrls(stringToConcat);
    }
    if (!isUploading && status !== null) {
      setStatuses(statuses.concat(status));
      setFilesAwaiting(filesAwaiting.slice(1));
    }
  }, [isUploading, status, uploadedUrl]);

  const StatusFileIcon = ({ fileName }: { fileName: string }): JSX.Element => {
    const fileExt = fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />;
  };

  const UploadStatusIcon = ({
    uploadStatus,
  }: {
    uploadStatus: boolean | null;
  }) => {
    console.log('UploadStatus', uploadStatus);
    // eslint-disable-next-line eqeqeq
    if (uploadStatus == null) {
      return (
        <Icon className={waiting} label={''}>
          hourglass_empty
        </Icon>
      );
    }
    return (
      <Icon className={uploadStatus ? success : failed} label={''}>
        {uploadStatus ? 'check_circle' : 'error'}
      </Icon>
    );
  };

  return (
    <Grid item container justifyContent='center' spacing={2}>
      <>
        <Dropzone
          numberOfFiles='multiple'
          setFiles={handleUploaded as any}
          files={files}
          accept={''}
        />
        {files.map((fig) => {
          <p>{fig.name}</p>;
        })}
        {files.map((file, index) => (
          <Grid
            key={file.name}
            item
            container
            xs={8}
            direction='row'
            alignItems='center'
            wrap='nowrap'
          >
            <div className={icon}>
              <StatusFileIcon fileName={file.name} />
            </div>
            <Grid
              item
              container
              xs={11}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Body2>{file.name}</Body2>
            </Grid>
            <UploadStatusIcon uploadStatus={statuses[index]} />
          </Grid>
        ))}
      </>
    </Grid>
  );
};
