import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '@adludio/react-sso';
import { fetchCustomReport } from '../api';
import { BreakdownReport } from '../types';

export interface CustomBreakdownReportContextState {
  customBreakdownReport: BreakdownReport;
  isLoading: boolean;
  fetchCustomBreakdownReport: (
    campaignKey: string,
    breakdownName: string,
    breakdownValues: string[],
    filter: { [breakdownName: string]: string[] }
  ) => void;
}

export const initialReportState: CustomBreakdownReportContextState = {
  customBreakdownReport: {},
  isLoading: false,
  fetchCustomBreakdownReport: (
    campaignKey,
    breakdownName,
    breakdownValues,
    filter
  ) => {
    throw Error('fetchBreakdownReport not implemented');
  },
};

export const CustomBreakdownReportContext =
  React.createContext<CustomBreakdownReportContextState>(initialReportState);

export const useCustomBreakdownReport = () =>
  useContext(CustomBreakdownReportContext);

export interface CustomBreakdownReportContextProviderProps {
  children: React.ReactNode;
}

export const CustomBreakdownReportContextProvider = ({
  children,
}: CustomBreakdownReportContextProviderProps) => {
  const [customBreakdownReport, setCustomBreakdownReport] =
    useState<BreakdownReport>({});
  const [isFetching, setIsFetching] = useState(false);

  const { auth } = React.useContext(AuthContext);

  const loadBreakdownReport = useCallback(
    (
      campaignKey: string,
      breakdownName: string,
      breakdownValues: string[],
      filter: { [breakdownName: string]: string[] }
    ) => {
      setIsFetching(true);
      setCustomBreakdownReport({});
      fetchCustomReport(
        auth.authToken,
        campaignKey,
        breakdownName,
        breakdownValues,
        filter
      )
        .then((result) => {
          setCustomBreakdownReport(result);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
          console.log(err);
        });
    },
    [auth.authToken]
  );

  return (
    <CustomBreakdownReportContext.Provider
      value={{
        customBreakdownReport: customBreakdownReport,
        isLoading: isFetching,
        fetchCustomBreakdownReport: loadBreakdownReport,
      }}
    >
      {children}
    </CustomBreakdownReportContext.Provider>
  );
};
