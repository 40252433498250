import {
  AdludioTheme,
  Box,
  Caption,
  Grid,
  makeStyles,
} from '@adludio/components';

import React from 'react';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  mainDiv: {
    borderRadius: '15px',
    padding: '3px',
  },
  programmatic: {
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  brandDirect: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  managedService: {
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
  },
}));

export const BriefTypeBadge = (props: { campaignType: string }) => {
  const { programmatic, brandDirect, mainDiv, managedService } = useStyles();

  const getBriefType = (campaignType: string) => {
    if (campaignType === 'PMP') return programmatic;
    else if (campaignType === 'BD') return brandDirect;
    else return managedService;
  };

  return (
    <Box className={`${mainDiv} ${getBriefType(props.campaignType)}`}>
      <Grid container direction='row' alignItems='center'>
        <Box mx='0.3rem'>
          <Caption>{props.campaignType}</Caption>
        </Box>
      </Grid>
    </Box>
  );
};
