
export const getCampaignLink = (startDate: Date, endDate: Date, campaignStatus: string, adunitsLength: number) => {
  const today = new Date();
  const isPendingLaunch = today < startDate;
  const isServing = (startDate < today) && (today < endDate) ;

  if (campaignStatus === 'PRODUCTION' && adunitsLength === 0 && isPendingLaunch) return 'production';
  else if (isPendingLaunch){
    return 'creativeProduction';
  }
  else if (isServing){
    return 'reporting' ;
  }
  else {
    return 'reporting';
  }
};
