import {
  AdludioTheme,
  Body2,
  Box,
  CircularProgress,
  Grid,
  Subtitle1,
  makeStyles,
  useMediaQuery,
} from '@adludio/components';
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable complexity */
import React, { useContext, useEffect, useRef, useState } from 'react';

import { AuthContext } from '@adludio/react-sso/dist';
import CampaignBrief from '../../components/CampaignBrief';
import { CampaignHeader } from '../../components/CampaignHeader/CampaignHeader';
import { CampaignPlanPage } from '../CampaignPlanPage';
import EmptyPage from '../../components/EmptyPage';
import { ErrorPage } from './../ErrorPage';
import FilesPage from '../FilesPage';
import { PreviewProvider } from '@adludio/components/dist/Preview';
import Report from '../../repo-board';
import { RouteComponentProps } from '@reach/router';
import { SideNav } from '../../components/CampaignNav';
import UploadAdunit, { AdunitData } from '../../components/UploadAdunit';
import CreativeUpload from '../../components/CreativeUpload';
import { getBriefLink } from '../../helpers/getBriefLink';
import { getCampaignLink } from '../../helpers/getCampaignLink';
import { useCreateAdunitMutation } from '../../generated/graphql';
import { useGetCampaignQuery } from '../../generated/graphql';
import { ResponsiveAdunitWrapper } from '../../components/ResponsiveAdunitWrapper';
import { Theme } from '@material-ui/core';

export interface ViewSingleBriefProps extends RouteComponentProps {
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignId?: string;
  state?: string;
}
interface User {
  name: string;
  email: string;
}
const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    backgroundColor: theme.palette.secondary.contrastText,
    zIndex: -3,
    padding: '0px',
    width: '100%',
    minHeight: '100vh',
    paddingBottom: '3.5em',
    transition: ({
      expandedNav,
      animate,
    }: {
      expandedNav: boolean;
      animate: boolean;
    }) => (animate === true ? 'all 0.3s ease-out' : 'unset'),
    [theme.breakpoints.up('md')]: {
      paddingBottom: '0px',
      zIndex: -2,
      width: ({
        expandedNav,
        animate,
      }: {
        expandedNav: boolean;
        animate: boolean;
      }) => (expandedNav ? 'calc(100% - 220px)' : 'calc(100% - 65px)'),
      marginLeft: ({
        expandedNav,
        animate,
      }: {
        expandedNav: boolean;
        animate: boolean;
      }) => (expandedNav ? '220px' : '65px'),
    },
  },
  loadingContainer: {
    height: '100vh',
  },
  reporting: {
    margin: '0px 1em',
    width: '98%',
  },
  dotsDiv: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '6rem',
  },
  pageContent: {
    height: '100%',
    overflow: 'hidden',
    marginBottom: '3.75rem',
  },
}));

export function BriefPage(props: ViewSingleBriefProps): JSX.Element {
  // GraphQL hooks
  const { data, loading, error } = useGetCampaignQuery({
    variables: { id: props.campaignId! },
    fetchPolicy: 'network-only',
  });
  const { auth } = useContext(AuthContext);
  const [createAdunit, { data: newAdunit }] = useCreateAdunitMutation();
  const [creativeBriefSubmitted, setCreativeBriefSubmitted] = useState(false);
  const [briefSubmitted, setBriefSubmitted] = useState(false);

  // STATES
  const [team, setTeam] = useState<User[]>([]);
  const [adunits, setAdunits] = useState<string[]>([]);
  const [adunitData, setAdunitData] = useState<AdunitData[]>([]);
  const [selectedItem, setSelectedItem] = useState(props.state);
  const [expandedNav, setExpandedNav] = useState(false);
  const [adunitVersion, setAdunitVersion] = useState(new Map());
  const adunitVersionRef = useRef(adunitVersion);
  const [animate, setAnimate] = useState(false);
  const animationStarted = useRef<boolean | null>(null);
  // STYLES
  const { root, loadingContainer, reporting, pageContent } = useStyles({
    expandedNav,
    animate,
  });

  // OTHER VARS

  const isReseller = data?.getCampaignById?.resellerBrief !== null;
  const handleType = (data: any) => {
    if (isReseller) {
      return data?.getCampaignById?.resellerBrief;
    } else {
      return data?.getCampaignById?.brief;
    }
  };
  const isAllowed = auth.user?.role === 'admin' || auth.user?.role === 'cm';
  const campaignName =
    handleType(data)?.title ?? (handleType(data)?.name as string);
  const manualCampaign =
    (handleType(data)?.name === null || handleType(data)?.brief === null) &&
    handleType(data)?.title !== null;
  const isNotOnProduction = handleType(data)?.title === null;
  // This logic is disabled, change the value to true to view creativeUpload!
  const hasCreativeUpload = false;
  // handleType(data)?.brief?.DSP === 'TTD' &&
  // data?.getCampaignById?.brief?.isManaged === !!null;
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const getEmailStatus = () => {
    if (data?.getCampaignById?.status) {
      if (isReseller) {
        return getCampaignLink(
          data.getCampaignById.resellerBrief?.startDate,
          data.getCampaignById.resellerBrief?.endDate,
          data.getCampaignById.status,
          data.getCampaignById.adunits?.length ?? 0
        );
      } else {
        return getCampaignLink(
          data.getCampaignById.brief?.startDate,
          data.getCampaignById.brief?.endDate,
          data.getCampaignById.status,
          data.getCampaignById.adunits?.length ?? 0
        );
      }
    } else {
      if (isReseller) {
        return getBriefLink(
          data?.getCampaignById?.resellerBrief?.isSubmitted,
          data?.getCampaignById?.creativeBrief?.isSubmitted ?? false,
          data?.getCampaignById?.resellerBrief?.isManaged ?? false
        );
      } else {
        return getBriefLink(
          data?.getCampaignById?.brief?.isSubmitted,
          data?.getCampaignById?.creativeBrief?.isSubmitted ?? false,
          data?.getCampaignById?.brief?.isManaged ?? false
        );
      }
    }
  };

  const getVersion = (adunit: string) =>
    fetch(`https://wat.adludio.com/games/${adunit}/active`, {
      method: 'GET',
    })
      .then((response) => response.text().then((res) => res))
      .catch((e) => null);

  const handleExpand = (expand: boolean) => {
    setExpandedNav(expand);
    if (!animationStarted.current) {
      setAnimate(true);
      animationStarted.current = true;
    }
  };

  // useEffects
  useEffect(() => {
    if (data?.getCampaignById?.adunits && adunits.length < 1) {
      const gameNames: string[] = [];
      const gameData: {
        title: string;
        createdAt: Date;
        id: string;
        width: number;
        height: number;
      }[] = [];
      data?.getCampaignById?.adunits.map((a: any) => {
        a?.adunitIterations?.map((i: any) => {
          gameNames.push(i?.creativeChanges?.gameName!);
          gameData.push({
            title: a?.title!,
            createdAt: a?.createdAt!,
            id: a.id!,
            width: a?.width!,
            height: a?.height!,
          });
        });
      });
      setAdunitData(gameData);
      setAdunits(gameNames);
    }

    if (newAdunit?.createAdunit?.adunitIterations) {
      let newGameName = '';
      newAdunit.createAdunit.adunitIterations?.map(
        (a) => (newGameName = a?.creativeChanges?.gameName!)
      );
      setAdunits([...adunits, newGameName]);
      setAdunitData([
        ...adunitData,
        {
          title: newAdunit.createAdunit.title!,
          createdAt: new Date(),
          id: newAdunit.createAdunit.id!,
          width: newAdunit.createAdunit.width!,
          height: newAdunit.createAdunit.height!,
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, newAdunit]);

  useEffect(() => {
    if (data) {
      setTeam(
        (data.getCampaignById?.team?.map((user) => user) as User[]) ??
          ([] as User[])
      );
      setBriefSubmitted(!!handleType(data)?.isSubmitted);
      setCreativeBriefSubmitted(
        !!data?.getCampaignById?.creativeBrief?.isSubmitted
      );
    }
  }, [data]);

  useEffect(() => {
    setSelectedItem(props.state);
    if (animate) setAnimate(false);
    if (props.state === 'reporting') {
      setExpandedNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.state]);

  useEffect(() => {
    if (!expandedNav && props.state !== 'reporting') setAnimate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedNav]);

  useEffect(() => {
    for (const key of adunits) {
      getVersion(key)
        .then((res) => {
          const newVersions = new Map(adunitVersionRef.current).set(key, res);
          adunitVersionRef.current = newVersions;
          setAdunitVersion(newVersions);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adunits]);

  return error ? (
    <ErrorPage />
  ) : (
    <PreviewProvider>
      {loading ? (
        <Grid
          className={loadingContainer}
          container
          item
          xs
          justify='center'
          alignItems='center'
        >
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction='column'
          xs={12}
          className={root}
          justifyContent='space-between'
        >
          <SideNav
            status={data?.getCampaignById?.status}
            briefSubmitted={briefSubmitted}
            manualCampaign={manualCampaign}
            creativeSubmitted={creativeBriefSubmitted}
            selectedItem={selectedItem ?? 'brief'}
            setSelectedItem={setSelectedItem}
            campaignId={props.campaignId}
            expandedNav={expandedNav}
            setExpandedNav={handleExpand}
            startDate={
              handleType(data)?.startDate
                ? new Date(handleType(data)?.startDate)
                : null
            }
            managedCampaign={handleType(data)?.isManaged ?? false}
          />
          {/* Campaign info container */}
          <Grid
            container
            direction='column'
            xs={12}
            style={{ minHeight: '100vh' }}
          >
            {selectedItem !== 'reporting' && (
              <Box py='20px' mt='1rem'>
                <CampaignHeader
                  endDate={handleType(data)?.endDate}
                  startDate={handleType(data)?.startDate}
                  team={team}
                  campaignId={handleType(data)?.id || ''}
                  campaignStatus={getEmailStatus()}
                  env={props.env}
                  campaignName={campaignName}
                />
              </Box>
            )}
            <Grid item container xs={12} style={{ zIndex: 2 }}>
              {data && (
                <Grid container item direction='row' justify='center'>
                  <>
                    {selectedItem === 'brief' &&
                      (!manualCampaign ? (
                        <CampaignBrief
                          campaignId={props.campaignId}
                          briefSubmitted={briefSubmitted}
                          isReseller={isReseller}
                          setBriefSubmitted={setBriefSubmitted}
                          creativeSubmitted={creativeBriefSubmitted}
                          setCreativeBriefSubmitted={setCreativeBriefSubmitted}
                          campaignStatus={getEmailStatus()}
                        />
                      ) : (
                        <ErrorPage />
                      ))}
                    {selectedItem === 'campaignPlan' && (
                      <CampaignPlanPage
                        campaignId={props.campaignId!}
                        isManaged={handleType(data)?.isManaged}
                      />
                    )}
                    {selectedItem === 'files' && (
                      <FilesPage campaignId={props.campaignId!} />
                    )}
                  </>
                  {!isNotOnProduction || isAllowed ? (
                    <>
                      {selectedItem === 'reporting' && (
                        <Grid
                          container
                          item
                          xs={12}
                          direction='row'
                          justify='center'
                          className={reporting}
                        >
                          <Report
                            campaignId={props.campaignId}
                            team={team}
                            campaignStatus={getEmailStatus()}
                            env={props.env}
                            campaignName={campaignName}
                          />
                        </Grid>
                      )}
                      {selectedItem === 'creativeProduction' && (
                        <Grid
                          container
                          item
                          xs={10}
                          direction='row'
                          justify='center'
                          spacing={3}
                          className={pageContent}
                        >
                          <Grid container direction={'row'} spacing={2}>
                            {auth.user?.role === 'designer' || isAllowed ? (
                              hasCreativeUpload ? (
                                <CreativeUpload
                                  campaignId={props.campaignId!}
                                />
                              ) : (
                                <UploadAdunit
                                  startDate={handleType(data)?.startDate}
                                  campaignId={props.campaignId}
                                  createAdunit={createAdunit}
                                  adunits={adunits}
                                  adunitVersion={adunitVersion}
                                  setAdunits={setAdunits}
                                  adunitData={adunitData}
                                  setAdunitData={setAdunitData}
                                />
                              )
                            ) : adunits.length >= 1 ? (
                              adunits.map((adunit: string, key) => {
                                const adunitVer = adunitVersion.get(adunit);
                                return (
                                  adunitVer && (
                                    <Grid
                                      container
                                      item
                                      key={adunit}
                                      alignContent='flex-start'
                                      xs={12}
                                      md={6}
                                      lg={4}
                                      xl={3}
                                    >
                                      <Box
                                        boxSizing='border-box'
                                        width={isSmallScreen ? '100%' : '300px'}
                                        mt='2rem'
                                        mb='1rem'
                                      >
                                        <ResponsiveAdunitWrapper
                                          src={`https://wat.adludio.com/games/${adunit}/${adunitVer}/index.html`}
                                          height={adunitData[key].height}
                                          width={adunitData[key].width}
                                        />
                                      </Box>
                                      <Box
                                        width={isSmallScreen ? '100%' : '300px'}
                                      >
                                        <Grid
                                          container
                                          alignContent='flex-end'
                                          justifyContent='space-between'
                                          xs={12}
                                        >
                                          <Grid>
                                            <Subtitle1
                                              style={{ fontWeight: 500 }}
                                            >
                                              {adunitData[key].title}
                                            </Subtitle1>
                                          </Grid>
                                          <Grid>
                                            <Body2>
                                              {new Date(
                                                adunitData[key].createdAt
                                              ).toLocaleDateString()}
                                            </Body2>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  )
                                );
                              })
                            ) : (
                              <EmptyPage isCreative />
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  ) : (
                    selectedItem === 'reporting' && <ErrorPage />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </PreviewProvider>
  );
}
