import React from 'react';

import Wrapper from './Wrapper';

import { ConfigProps } from '../Pages';

const Navigation = ({ children, config }: React.PropsWithChildren<{}> & ConfigProps) => {
  return <Wrapper config={config}>{children}</Wrapper>;
};

export default Navigation;
