import React from 'react';
import UpdatePassword from './UpdatePassword';
import UserProfile from './UserProfile/index';
import { Box, Grid } from '@adludio/components';
import { CookieSettingsContainer } from './CookieSettings/CookieSettingContainer';

export default function UserProfilePage() {
  return (
    <Grid container direction='row' spacing={4}>
      <Grid container item direction='column' xs={5}>
        <Grid item>
          <Box pb='5%'>
            <UserProfile />
          </Box>
        </Grid>
        <Grid item>
          <UpdatePassword />
        </Grid>
      </Grid>
      <Grid container item xs={7} direction='column'>
        <Grid item xs>
          <CookieSettingsContainer />
        </Grid>
      </Grid>
    </Grid>
  );
}
