import {
  Body1,
  Body2,
  Grid,
  H6,
  Subtitle1,
  Box,
  makeStyles,
  AdludioTheme,
  useMediaQuery,
  Link,
} from '@adludio/components';
import { CreativeBriefOverviewRead } from '../CreativeBrief/CreativeBriefOverviewRead';
/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { Theme } from '@material-ui/core';
import {
  FileType,
  GetCampaignQuery,
  Operation,
  useGetMagicLinkLazyQuery,
} from '../../../generated/graphql';

const styles = makeStyles((theme: AdludioTheme) => ({
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
  },
  dividerMobile: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginTop: '1.5rem',
  },

  link: {
    color: '#A100FF',
    fontWeight: 500,
  },
}));

interface CampaignBriefOverviewRead {
  data?: GetCampaignQuery;
}
export function CampaignBriefOverviewRead(
  props: CampaignBriefOverviewRead
): JSX.Element {
  const [links, setLinks] = useState<string[]>([]);
  const [_, { refetch: genDownloadLink }] = useGetMagicLinkLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { purple, divider, dividerMobile, link } = styles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const formattedDate = (date: any) => {
    if (typeof date === 'object') {
      const day = date ? date.toISOString().split('T')[0] : '-';
      return day;
    }
    const day = date ? date.split('T') : '-';
    return day[0];
  };

  const isReseller = props.data?.getCampaignById?.resellerBrief !== null;
  const handleType = (props: any) => {
    if (isReseller) {
      return props.data?.getCampaignById?.resellerBrief;
    } else {
      return props.data?.getCampaignById?.brief;
    }
  };
  const briefSitelistUrl = props.data?.getCampaignById?.brief?.sitelistUrl;
  const resellerSiteListUrl =
    props.data?.getCampaignById?.resellerBrief?.sitelistUrl;
  const briefSeatId = props.data?.getCampaignById?.brief?.seatId;
  const resellerBriefSeatId =
    props.data?.getCampaignById?.resellerBrief?.seatID;

  const sitelistUrl = isReseller ? resellerSiteListUrl : briefSitelistUrl;
  const seatId = isReseller ? resellerBriefSeatId : briefSeatId;

  useEffect(() => {
    if (sitelistUrl) {
      const promises = sitelistUrl.split(',').map((sitelist) =>
        genDownloadLink({
          campaignId: props.data?.getCampaignById?.id!,
          fileId: sitelist.trim(),
          type: FileType.SiteList,
          operation: Operation.Get,
        })
      );
      Promise.all(promises).then((results) =>
        setLinks(results.map((res) => res.data?.getMagicLink ?? ''))
      );
    }
  }, [sitelistUrl]);

  return (
    <Grid
      container
      direction='row'
      justifyContent='space-around'
      alignItems='flex-start'
    >
      <Grid container xs={12} md={5} spacing={4}>
        <Grid item xs={12}>
          <H6 color='secondary'>Campaign Information</H6>
        </Grid>
        <Grid item xs={12}>
          <Subtitle1 className={purple}>Brand</Subtitle1>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>Brand name</Body2>
          <Body1>{handleType(props)?.brandName || 'Not specified'}</Body1>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>Campaign name</Body2>
          <Body1>{handleType(props)?.name || 'Not specified'}</Body1>
        </Grid>
        <Grid item xs={12}>
          <Subtitle1 className={purple}>Budget</Subtitle1>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={6}>
            <Body2 color='textSecondary'>Currency</Body2>
            <Body1>{handleType(props)?.currency || 'Not specified'}</Body1>
          </Grid>
          <Grid item xs={6}>
            <Body2 color='textSecondary'>Budget</Body2>
            <Body1>{handleType(props)?.budget || 'Not specified'}</Body1>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Subtitle1 className={purple}>Campaign Information</Subtitle1>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>Description</Body2>
          <Body1>{handleType(props)?.description || 'Not specified'}</Body1>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>Industry</Body2>
          <Body1>{handleType(props)?.industry || 'Not specified'}</Body1>
        </Grid>
        <Grid item xs={12} container direction='row' spacing={4}>
          <Grid item xs={12}>
            <Body2 color='textSecondary'>Start date</Body2>
            <Body1>{formattedDate(handleType(props)?.startDate)}</Body1>
          </Grid>
          <Grid item xs={12}>
            <Body2 color='textSecondary'>End date</Body2>
            <Body1>{formattedDate(handleType(props)?.endDate)}</Body1>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>Objective</Body2>
          <Body1>{handleType(props)?.objective || 'Not specified'}</Body1>
        </Grid>
        <Grid item xs={12}>
          <Body2 color='textSecondary'>KPI</Body2>
          <Body1>{handleType(props)?.KPI || 'Not specified'}</Body1>
        </Grid>
      </Grid>
      <Grid
        className={isSmallScreen ? dividerMobile : divider}
        container
        xs={12}
        md={6}
        justifyContent='flex-end'
        alignItems='flex-start'
      >
        <Grid
          container
          direction='column'
          xs={12}
          md={10}
          spacing={isSmallScreen ? 2 : 4}
          justifyContent='flex-start'
        >
          <Grid item xs>
            <Box mt={isSmallScreen ? '1.5rem' : 0}>
              <H6 color='secondary'>Serving & Targeting</H6>
            </Box>
          </Grid>
          <Grid item xs>
            <Subtitle1 className={purple}>Audience</Subtitle1>
          </Grid>
          <Grid item xs>
            <Body2 color='textSecondary'>Audience</Body2>
            <Body1>{handleType(props)?.audience || 'Not specified'}</Body1>
          </Grid>
          <Grid item xs>
            <Subtitle1 className={purple}>Serving Information</Subtitle1>
          </Grid>
          <Grid item xs>
            <Body2 color='textSecondary'>Serving Locations</Body2>
            {handleType(props)
              ?.geographies?.split(',')
              .map((geo: any) => <Body1 key={geo}>{geo}</Body1>) || (
              <Body1>Not specified</Body1>
            )}
          </Grid>
          {handleType(props)?.isManaged ? (
            <>
              <Grid item xs>
                <CreativeBriefOverviewRead data={props.data} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs>
                <Body2 color='textSecondary'>DSP</Body2>
                <Body1>{handleType(props)?.DSP || 'Not specified'}</Body1>
              </Grid>
              <Grid item xs>
                <Body2 color='textSecondary'>Seat ID</Body2>
                <Body1>{seatId || 'Not specified'}</Body1>
              </Grid>
            </>
          )}
          <Grid item xs>
            <Subtitle1 className={purple}>Site list</Subtitle1>
            {sitelistUrl ? (
              sitelistUrl.split(',').map((a, idx) => (
                <Link href={links[idx]} target='_blank'>
                  <Body1 key={a} className={link}>
                    {a}
                  </Body1>
                </Link>
              ))
            ) : (
              <Body1>Not specified</Body1>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
