import React from 'react';
import Grid from '@adludio/components/dist/Layout/Grid';
import { CircularProgress } from '@adludio/components';

const Spinner = () => {
  return (
    <Grid style={{ width: '100vw', height: '100vh' }} container justify='center' alignItems='center'>
      <CircularProgress size={100} />
    </Grid>
  );
};

export default Spinner;
