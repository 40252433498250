import * as React from 'react';

import {
  Operation,
  useGetMagicLinkTermsContitionsLazyQuery,
} from '../generated/graphql';

import { CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarKey } from 'notistack';
import { useSnackbar } from '@adludio/components';

export function useDownloadTermsAndConditions() {
  const [getDownloadMagicLink, { data: downloadData }] =
    useGetMagicLinkTermsContitionsLazyQuery();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snackbarKey = React.useRef<number | null | string>();

  const downloadDocument = () => {
    snackbarKey.current = enqueueSnackbar('Download Started', {
      persist: true,
      preventDuplicate: true,
      variant: 'info',
      // eslint-disable-next-line react/display-name
      action: (key: SnackbarKey) => {
        return (
          <>
            <CircularProgress size={20} />{' '}
            <CloseIcon fontSize='small' onClick={() => closeSnackbar()} />
          </>
        );
      },
    });
    getDownloadMagicLink();
    setIsDownloading(true);
  };

  /* download useffect */
  React.useEffect(() => {
    if (downloadData?.getTermsAndConditionsMagicLink && isDownloading) {
      /* any doubts? 👉https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js */
      fetch(downloadData.getTermsAndConditionsMagicLink, {
        method: Operation.Get,
      })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            closeSnackbar();
            enqueueSnackbar('Download Failed', {
              variant: 'error',
            });
            return Promise.reject();
          }
        })
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'PlatformT&Cs.pdf';
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();
          closeSnackbar();
          enqueueSnackbar('Download Completed', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.log(error);
          closeSnackbar();
          enqueueSnackbar('Download Failed', {
            variant: 'error',
          });
        })
        .finally(() => setIsDownloading(false));
    }
  }, [closeSnackbar, downloadData, enqueueSnackbar, isDownloading]);

  return { downloadDocument, isDownloading };
}
