import React from 'react';

import { Grid, H2 } from '@adludio/components';

type PermissionsError = { message: string }

const PermissionsError = ({ message }: PermissionsError) => (
  <Grid container justify='center'>
    <H2 className='permission-error'style= {{ paddingTop: '1rem' }}>{message}</H2>
  </Grid>
);

export default PermissionsError;
