import { ApexOptions } from 'apexcharts';
import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatAxis } from '../../utils/ChartAxis';

export interface ShareChartProps {
  kpi: string;
  breakdown: string;
  series: number[];
  labels: string[];
}
const ShareFractionChart = ({ kpi, breakdown, series, labels }: ShareChartProps) => {
  const sumAll = series.reduce((v, i) => (v + i));
  const options: ApexOptions = {
    series: series,
    chart: {
      width: 380,
      dropShadow: {
        enabled: true,
        color: '#111',
        top: -1,
        left: 3,
        blur: 3,
        opacity: 0.2
      }
    },
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          size: '65%',
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              formatter: ():string => formatAxis(kpi, sumAll, true)
            },
          }
        }
      }
    },
    labels: labels,
    dataLabels: {
      dropShadow: {
        blur: 3,
        opacity: 0.8
      }
    },
    tooltip: {
      y: {
        formatter: (value: number): string => formatAxis(kpi, value)
      }
    },
    legend: { position: 'bottom', horizontalAlign: 'center' },
    colors: ['#4E55C3', '#7DC3AA', '#F7C1A1', '#58A0DA', '#495373', '#CDC7E5'],
    theme: {
      palette: 'palette2'
    },
    title: {
      text: `${kpi} share by ${breakdown}`,
      align: 'center'
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  };
  return (
    <ReactApexChart options={options}
      series={options.series} type='donut' width={300} height={270}
    />
  );
};
export default ShareFractionChart;
