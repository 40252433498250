/* eslint-disable complexity */
import {
  AdludioTheme,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Body1,
  makeStyles,
  useSnackbar,
  Box,
} from '@adludio/components';

import React, { Dispatch, SetStateAction } from 'react';
import { useDeleteCreativeUploadMutation } from '../../../generated/graphql';
import { CreativeUploadValues } from '../types';

export interface DeleteAdUnitProps {
  isOpen: boolean;
  onClose: () => void;
  idx: number;
  creativeUploadId: string;
  forms: CreativeUploadValues[];
  setForms: Dispatch<SetStateAction<CreativeUploadValues[]>>;
  setCreativeSelected: Dispatch<SetStateAction<number>>;
}

const styles = makeStyles((theme: AdludioTheme) => ({
  content: {
    margin: '10px 80px',
  },
  buttonsSection: {
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
  },
  padding: {
    paddingBottom: '0',
  },
}));

export const DeleteAdUnitModal = ({
  isOpen,
  onClose,
  setForms,
  idx,
  forms,
  setCreativeSelected,
}: DeleteAdUnitProps) => {
  const { content, padding, buttonsSection } = styles();
  const [deleteCreative] = useDeleteCreativeUploadMutation();
  const { enqueueSnackbar } = useSnackbar();
  const handleDelete = () => {
    if (forms.length >= 0) {
      deleteCreative({ variables: { id: forms[idx].id } }).catch((e) =>
        console.log(e)
      );
      setForms(forms.filter((f, i) => i !== idx));
      enqueueSnackbar('Item deleted', { variant: 'success' });
    } else {
      enqueueSnackbar('Cannot delete item', { variant: 'error' });
    }
    setCreativeSelected(0);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} hasCloseButton>
      <Box pt='2rem'>
        <DialogTitle className={padding}>
          <Grid container direction='row' justifyContent='center'>
            <Body1 align='right'>
              Are you sure you want to delete the adunit?
            </Body1>
          </Grid>
        </DialogTitle>
      </Box>
      <DialogContent className={content}>
        <Grid container justify='center'>
          <Grid
            item
            container
            direction='row'
            xs={8}
            className={buttonsSection}
          >
            <Grid
              container
              xs={12}
              direction='row'
              justifyContent='space-between'
            >
              <Button variant='contained' onClick={handleDelete}>
                Delete
              </Button>
              <Button variant='outlined' onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
