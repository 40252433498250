import {
  AdludioTheme,
  Box,
  Button,
  Card,
  Grid,
  H5,
  makeStyles,
  useSnackbar,
} from '@adludio/components';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { CampaignInformationScreen } from '../Components/CampaignInformationForm';
import { CreativeForm } from '../Components/CreativeForm';
import { TargetingForm } from '../Components/TargetingForm';
import {
  useGetCampaignLazyQuery,
  useSaveMediaCampaignBriefMutation,
  useSubmitCampaignBriefMutation,
} from '../../../generated/graphql';

import {
  parseQueryToFormValuesMedia,
  MANAGED_FORM_VALUES,
  validateManaged,
} from '../const';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    marginTop: '15px',
    paddingTop: '20px',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
    '@media only screen and (max-width: 425px)': {
      display: 'none',
    },
  },
}));

interface MediaCampaignFormProps extends RouteComponentProps {
  id?: string;
  onCancel?: () => void;
}

export function MediaCampaignForm(props: MediaCampaignFormProps): JSX.Element {
  const { container, purple } = styles();
  const [save, { loading: isSaving }] = useSaveMediaCampaignBriefMutation();
  const [fetch, { data }] = useGetCampaignLazyQuery();
  const [isSaved, setIsSaved] = useState(false);

  const [submit] = useSubmitCampaignBriefMutation();
  const { enqueueSnackbar } = useSnackbar();

  const isEditing = !!props.id;
  const campaignId = useMemo(() => props.id ?? uuidv4(), [props.id]);
  useEffect(() => {
    if (isEditing) {
      fetch({ variables: { id: props.id! } });
    }
  }, [fetch, isEditing, props.id]);

  const initialValues = useMemo(
    () => (isEditing ? parseQueryToFormValuesMedia(data) : MANAGED_FORM_VALUES),
    [data, isEditing]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues ?? MANAGED_FORM_VALUES}
      validateOnBlur={false}
      validateOnChange={false}
      validate={(values) =>
        validateManaged(values, save, campaignId, enqueueSnackbar)
      }
      onSubmit={async (values) => {
        try {
          await save({
            variables: {
              id: campaignId,
              fieldsToSave: {
                ...values,
                name: values.name ? values.name : null,
                isSubmitted: true,
              },
            },
          });
          await submit({ variables: { campaignId } });
          enqueueSnackbar('Successfully submitted Brief.', {
            variant: 'success',
          });
          await navigate(`/campaigns/campaign/${campaignId}/brief`);
        } catch (e) {
          enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
        }
      }}
    >
      {(formikProps) => (
        <>
          {!isEditing ? (
            <Grid container xs={8} justify='center'>
              <Box mb='1.5rem'>
                <H5 align='center' className={purple}>
                  We’re looking forward to delivering your mobile campaign, but
                  let’s get some details first
                </H5>
              </Box>
            </Grid>
          ) : (
            <Box mt='1.5rem' />
          )}
          <>
            <Grid container justify='center' item xs={11} md={8} spacing={2}>
              <Card className={container} elevation={2}>
                <CampaignInformationScreen
                  wasSaved={isSaved}
                  formikProps={formikProps}
                />
                <TargetingForm
                  wasSaved={isSaved}
                  campaignId={campaignId}
                  formikProps={formikProps}
                  isManaged
                />
                <CreativeForm
                  wasSaved={isSaved}
                  campaignId={campaignId!}
                  formikProps={formikProps}
                  isManaged
                />
                <Box mb='3rem' mt='1rem'>
                  <Grid container item justify='center'>
                    <Grid container xs={10} md={8} direction='row'>
                      <Box pr='1rem'>
                        <Button
                          variant='contained'
                          onClick={(e) => formikProps.handleSubmit(e as any)}
                        >
                          Submit
                        </Button>
                      </Box>
                      {!data?.getCampaignById?.brief?.isSubmitted ? (
                        <Button
                          variant='outlined'
                          disabled={isSaving}
                          isLoading={isSaving}
                          onClick={() => {
                            const { values, setFieldError } = formikProps;
                            if (values.name) {
                              save({
                                variables: {
                                  id: campaignId,
                                  fieldsToSave: {
                                    ...values,
                                    name: values.name ? values.name : null,
                                  },
                                },
                              })
                                .then(() => {
                                  setIsSaved(true);
                                  enqueueSnackbar('Successfully saved draft.', {
                                    variant: 'success',
                                  });
                                  // eslint-disable-next-line no-undefined
                                  setFieldError('name', undefined);
                                })
                                .catch(() =>
                                  setFieldError(
                                    'name',
                                    'A campaign with that name already exists.'
                                  )
                                );
                            } else {
                              setFieldError(
                                'name',
                                'Please set a Campaign Name before saving.'
                              );
                            }
                          }}
                        >
                          Save as draft
                        </Button>
                      ) : (
                        <Button
                          variant='outlined'
                          isLoading={formikProps.isSubmitting}
                          onClick={(e) => props.onCancel && props.onCancel()}
                        >
                          Cancel
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>
          </>
        </>
      )}
    </Formik>
  );
}
