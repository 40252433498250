import React, { useContext, useState } from 'react';
import {
  AdludioTheme,
  Body2,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  H6,
  Link,
  makeStyles,
  useSnackbar,
} from '@adludio/components';
import CookieSettingsPanel from './CookieSettingsPanel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import { setConsent } from '../../../API/consent';
import { AuthContext } from '@adludio/react-sso';
import { initialConfig } from '../../..';

const styles = makeStyles((theme: AdludioTheme) => ({
  accordion: {
    margin: '0px !important',
  },
  accordionDetails: {
    background: theme.palette.grey[100],
  },
  dialogContent: {
    padding: '0rem 2rem',
    overflow: 'visible',
  },
  dialogActions: {
    margin: '0.5rem 0.5rem 1rem 2rem',
    justifyContent: 'space-between',
  },
  backButton: {
    padding: '0px 0px 10px 0px',
    marginLeft: '-0.5rem',
    color: 'black',
  },
  link: {
    color: theme.palette.grey[700],
    textDecoration: 'underline',
  },
}));

export const CookieConsentDialog = ({
  dialogOpen,
  closeDialog,
}: {
  dialogOpen: boolean;
  closeDialog: () => void;
}) => {
  const style = styles();
  const [performanceConsent, setPerformanceConsent] = useState(
    localStorage.getItem('performanceCookies') === 'true'
  );
  const [detailView, setDetailView] = useState(false);
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setPerformanceConsent(checked);
  };
  const saveSettings = (body: { consentKey: string; consentValue: string }) => {
    setConsent(initialConfig.ssoBEURL, authContext.auth.authToken, body)
      .then(() => {
        enqueueSnackbar('Successfully set preferences', { variant: 'success' });
        window.localStorage.setItem(body.consentKey, body.consentValue);
        window.dispatchEvent(new Event('storage'));
        closeDialog();
      })
      .catch(() => {
        enqueueSnackbar("Couldn't set preferences, please try again", {
          variant: 'success',
        });
      });
  };
  return (
    <Dialog open={dialogOpen}>
      {detailView ? (
        <>
          <DialogTitle>
            <IconButton
              className={style.backButton}
              onClick={() => setDetailView(false)}
            >
              <ArrowBackIosIcon fontSize='small' color='inherit' />
            </IconButton>
            <Grid item xs container alignItems='center'>
              <img
                style={{ height: '25px', paddingRight: '10px' }}
                src='/cookie.png'
                alt='Cookie'
              />
              <H6 color='primary'>Manage cookie consent preferences</H6>
            </Grid>
          </DialogTitle>
          <DialogContent className={style.dialogContent}>
            <CookieSettingsPanel
              onChange={handleChange}
              performanceConsent={performanceConsent}
            />
          </DialogContent>
          <DialogActions className={style.dialogActions}>
            <Grid container item spacing={2} xs={12} justifyContent='flex-end'>
              <Grid item>
                <Button
                  variant='text'
                  color='primary'
                  onClick={() =>
                    saveSettings({
                      consentKey: 'performance',
                      consentValue: 'false',
                    })
                  }
                >
                  Reject All
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    saveSettings({
                      consentKey: 'performance',
                      consentValue: performanceConsent.toString(),
                    })
                  }
                >
                  Save & Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <Grid item xs container alignItems='center'>
              <img
                style={{ height: '25px', paddingRight: '10px' }}
                src='/cookie.png'
                alt='Cookie'
              />
              <H6 color='primary'>Cookie Consent</H6>
            </Grid>
            <Divider />
          </DialogTitle>
          <DialogContent className={style.dialogContent}>
            <Body2>
              Our platform uses cookies to improve your experience. By clicking
              “Accept All” you consent to the use of all cookies. However, you
              can always update your consent preferences in your “Cookie
              Consent” panel on the platform.
            </Body2>
          </DialogContent>
          <DialogActions className={style.dialogActions}>
            <Link
              href='https://help.hotjar.com/hc/en-us/articles/6952777582999-Cookies-Set-by-the-Hotjar-Tracking-Code'
              variant='caption'
              target='_blank'
              className={style.link}
            >
              Hotjar analytics cookies
            </Link>
            <Grid container item spacing={2} xs={8} justifyContent='flex-end'>
              <Grid item>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => setDetailView(true)}
                >
                  Customize
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    saveSettings({
                      consentKey: 'performance',
                      consentValue: 'true',
                    })
                  }
                >
                  Accept All
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
