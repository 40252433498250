import numeral from 'numeral';


export function formatAxis(labelName: any, val: any, group:boolean = false) {
  const volumeColsIndx: string[] = ['Impression', 'Engagement', 'Click', 'Views', 'Viewable'];
  const rateColsIndx: string[] = ['ER', 'CTR', 'eCTR', 'VTR', 'Viewability'];
  const spendColsIndx: string = 'Spend';
  if (volumeColsIndx.includes(labelName)) {
    if (group){
      return numeral(val).format('0.0a');
    } else {
      return numeral(val).format('0,0');
    }

  }
  else if (rateColsIndx.includes(labelName)) {
    return `${val.toFixed(1)}%`;
  }
  else if (spendColsIndx === labelName) {
    if (group){
      return numeral(val).format('$0.0a');
    } else {
      return numeral(val).format('$0,0');
    }
  }
  return val;
}
