import React, { useEffect, useState, useCallback } from 'react';
import Spinner from '../../Components/Spinner';
import { AuthContext } from '@adludio/react-sso';
import { ConfigProps } from '../';
import { navigate, RouteComponentProps } from '@reach/router';
import PermissionsError from '../Error/PermissionsError';
import usePortalPermissions from '../../util/usePortalPermissions';
import Landing from '../Landing';
type ProtectedRoutesProps = React.PropsWithChildren<
  ConfigProps & RouteComponentProps
>;

const ProtectedRoutes = (props: ProtectedRoutesProps) => {
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { auth } = React.useContext(AuthContext);
  const currentPath = props.location?.pathname;
  const navigateToDefaultPage = useCallback(
    () => {
      if (currentPath === '/') {
        navigate('campaigns').catch(console.log);
      }
    },
    [currentPath]
  );

  const { isPathValid, isToolPermitted } = usePortalPermissions(
    currentPath,
    auth.user
  );

  useEffect(() => {
    if (!auth.user) return;
    if (isPathValid && !auth.user.permissions.length) {
      setError('No Portal Permissions');
    } else if (isPathValid && !isToolPermitted) {
      setError('You don\'t have permissions for this tool');
    } else {
      setError('');
    }

    navigateToDefaultPage();
    setLoading(false);
  }, [auth, isToolPermitted, isPathValid, navigateToDefaultPage]);

  useEffect(() => {
    if (auth.user && currentPath === '/admin' && auth.user.role !== 'admin') {
      navigate('campaigns').catch(console.log);
    }
  }, [auth.user, currentPath]);

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!auth.authStatus) return <Spinner />;
  if (auth.authStatus === 'fail') {
    return <Landing />;
  }
  if (isLoading) return <Spinner />;
  if (error) {
    return <PermissionsError message={error} />;
  }
  return <>{props.children}</>;
};

export { ProtectedRoutes };
