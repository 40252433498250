import React from 'react';
import { Router } from '@reach/router';
import {
  ThemeProvider,
  SnackbarProvider,
  makeStyles,
} from '@adludio/components';

import Navigation from '../Navigation';
import { ErrorPage } from './NotFoundPage';
import ReportingDashboard from './ReportingDashboard';
import Landing from './Landing';
import AdludioAdmin from './AdludioAdmin';
import Gatekeeper from './Gatekeeper';
import EmailPreferences from './EmailPreferences';
import TermsAndConditions from './SettingsPage/TC';
import PrivacyPolicy from './SettingsPage/PrivacyPolicy';
import { ProtectedRoutes } from './Protected';
import { PortalConfig } from '../';
import { AuthProvider } from '@adludio/react-sso';

import Projects from './Projects';

import ScrollToTop from '../util/ScrollToTop';
import SettingsPage from './SettingsPage';
import { SessionTimeoutModal } from '../Components/Modals/SessionTimeout';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Consent } from './SettingsPage/CookieSettings/Consent';

const useStyles = makeStyles({
  snackbars: {
    paddingLeft: '3rem',
  },
});
/*
 * This is the main file for routed components/containers
 */

export type ConfigProps = {
  config: PortalConfig;
};
const globalStyles = makeStyles({
  '@global': {
    body: {
      margin: 0,
      minHeight: '100vh',
      height: '100vh',
      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    code: {
      fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace`,
    },
  },
});

function SSOThemeProvider({ children }: React.PropsWithChildren<{}>) {
  globalStyles();
  return <ThemeProvider>{children}</ThemeProvider>;
}

const Pages = (props: ConfigProps) => {
  const styles = useStyles();
  const clientId: string = process.env.REACT_APP_GOOGLE_LOGIN_API as string;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <SSOThemeProvider>
        <ThemeProvider>
          <SnackbarProvider className={styles.snackbars}>
            <AuthProvider ssoBEURL={props.config.ssoBEURL}>
              <Navigation config={props.config}>
                <SessionTimeoutModal ssoBEURL={props.config.ssoBEURL} />
                <Router primary={false}>
                  <ScrollToTop path='/'>
                    <EmailPreferences
                      config={props.config}
                      path='/email-preferences/*'
                    />
                    <ProtectedRoutes config={props.config} path='/'>
                      <ReportingDashboard
                        config={props.config}
                        path='/reporting-dashboard/*'
                      />
                      <Landing path='/login' />
                      <Projects config={props.config} path='/campaigns/*' />
                      <AdludioAdmin config={props.config} path='/admin/*' />
                      <Gatekeeper config={props.config} path='/gatekeeper' />
                      <ErrorPage default />
                      <TermsAndConditions path='/terms-and-conditions' />
                      <PrivacyPolicy path='/privacy-policy' />
                      <SettingsPage path='/user-profile' index={0} />
                      <SettingsPage path='/company-profile' index={1} />
                    </ProtectedRoutes>
                  </ScrollToTop>
                </Router>
              </Navigation>
              <Consent />
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </SSOThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default Pages;
