import {
  Box,
  Button,
  CircularProgress,
  Icon,
  List,
  ListItem,
  Paper,
  withStyles,
} from '@material-ui/core';
import {
  BreakdownName,
  BreakdownReport,
  BreakdownReportValues,
} from '../../types';
import { BreakdownOpts, ReportBreakdown } from '../../types';
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { getKey, getNames, getOptions } from '../../utils/breakdownsConfig';
import getKpiName, { getFullNotation, mapKpis } from '../../utils/setKpiCols';

import BootstrapTooltip from '../ui/bootstrapTooltip';
import { BreakdownComputation } from '../../utils/breakdownCompute';
import BreakdownFilterOptions from './breakdownDropdownOptions';
import BreakdownNumericReport from './breakdownTabular';
import BreakdownOptions from './breakdownDropdowns';
import DailyBreakdownPlot from '../charts/dailyArea';
import { Grid } from '@adludio/components';
import ShareFractionChart from '../charts/fractionSharePlots';
import SummaryBarPlot from '../charts/summaryBars';
import { TabHeader } from '../ui/tabHeader';
import { responsiveStyle } from '../../utils/Styles';
import { useCustomBreakdownReport } from '../../contexts/CustomBreakdownReportContext';

const StyledToggleButton = withStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    '&$selected': {
      backgroundColor: '#4E55C3',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4E55C3',
        color: '#ffffff',
      },
    },
  },
  selected: {},
})(ToggleButton);

interface BreakdownProps {
  breakdownReport: BreakdownReport;
  breakdownComputation: BreakdownComputation;
  campaignName: string;
  reportBreakdown: ReportBreakdown[];
  campaignKey: string;
  kpiCols: string[];
  dates: { min: string; max: string };
}

const Breakdown = ({
  breakdownReport,
  breakdownComputation,
  campaignName,
  reportBreakdown,
  campaignKey,
  kpiCols,
  dates,
}: BreakdownProps) => {
  const classes = responsiveStyle();
  const { isLoading, fetchCustomBreakdownReport, customBreakdownReport } =
    useCustomBreakdownReport();

  const breakdownNames = Object.keys(breakdownReport).sort();
  const selectedBreakdown: BreakdownName = breakdownNames.includes('Country')
    ? 'Country'
    : 'AdFormat';

  const [kpi, setKpi] = useState<string>('er');

  const [dailyTrend, setDailyTrend] = useState<
    { name: string; data: number[] }[]
  >(
    breakdownComputation.getSelectedKpiTrend(
      breakdownComputation.getSelectedBreakdown(selectedBreakdown),
      kpi
    )
  );

  const [selectedBreakdownReport, setSelectedBreakdownReport] =
    useState<BreakdownReportValues>(
      breakdownComputation.getSelectedBreakdown(selectedBreakdown)
    );

  /*
   * Selected sub breakdowns are breakdowns with in the selected breakdown
   * Eg. if selected breakdown is AdFormat possible sub breakdowns are
   *      MPU & FS.
   */
  const [selectedSubBreakdowns, setSelectedSubBreakdowns] =
    useState<BreakdownOpts>([]);

  /*
   * selectedFilterBreakdowns are breakdowns other than the selected one.
   * thet are used to further filter the selected breakdown's data
   */
  const [selectedFilterBreakdowns, setSelectedFilterBreakdowns] = useState<{
    [breakdownName: string]: BreakdownOpts;
  }>({});

  useEffect(() => {
    setDailyTrend(
      breakdownComputation.getSelectedKpiTrend(selectedBreakdownReport, kpi)
    );
  }, [kpi, selectedBreakdownReport, breakdownComputation]);

  useEffect(() => {
    if (Object.keys(customBreakdownReport).length !== 0) {
      const selectedCustomBreakdownReport = customBreakdownReport[
        selectedBreakdown
      ] as BreakdownReportValues;

      setSelectedBreakdownReport(selectedCustomBreakdownReport);

      setKpi('ER');
      setDailyTrend(
        breakdownComputation.getSelectedKpiTrend(
          selectedCustomBreakdownReport,
          'ER'
        )
      );
      setSelectedBreakdownReport(selectedCustomBreakdownReport);
    }
  }, [customBreakdownReport, selectedBreakdown, breakdownComputation]);

  const handleKpiChange = (
    event: React.MouseEvent<HTMLElement>,
    newKpi: string | null
  ) => {
    if (newKpi === null) {
      return;
    }

    setKpi(newKpi);
  };

  const onSubBreakdownsChange = (
    breakdown: string,
    subBreakdowns: BreakdownOpts
  ) => {
    if (breakdown === selectedBreakdown) {
      setSelectedSubBreakdowns(subBreakdowns);
    } else {
      setSelectedFilterBreakdowns({
        ...selectedFilterBreakdowns,
        [breakdown]: subBreakdowns,
      });
    }
  };

  const allBreakdownValues = () => getNames(selBreakdownReport.breakdownValues);

  const allFilters = () => {
    const _allFilters = {};

    breakdownNames
      .filter((item) => item !== selectedBreakdown)
      .map((breakdownName) => {
        const currentBreakdown = findBreakdown(breakdownName);
        _allFilters[currentBreakdown.breakdownName] = getNames(
          currentBreakdown.breakdownValues
        );
      });

    return _allFilters;
  };

  const onApplyClicked = () => {
    // BREAKDOWN VALUES
    let breakdownValues = Array.from(
      new Set(
        selectedSubBreakdowns.map((subBreakdown) => getKey(subBreakdown.label))
      )
    );

    // FILTER
    let filters = {};
    for (let key in selectedFilterBreakdowns) {
      filters[key] = Array.from(
        new Set(
          selectedFilterBreakdowns[key].map((filterBreakdown) =>
            getKey(filterBreakdown.label)
          )
        )
      );
    }

    // if no breakdown values are selected, select all by default
    if (breakdownValues.length === 0 || breakdownValues.includes('All')) {
      breakdownValues = allBreakdownValues();
    }

    // if no filters are selected, select all by default
    if (
      Object.keys(filters).length === 0 ||
      Object.keys(filters).includes('All')
    ) {
      filters = allFilters();
    }

    fetchCustomBreakdownReport(
      campaignKey,
      selectedBreakdown,
      breakdownValues,
      filters
    );
  };

  const onApplyClear = () => {
    setSelectedSubBreakdowns([]);
    setSelectedFilterBreakdowns({});

    fetchCustomBreakdownReport(
      campaignKey,
      selectedBreakdown,
      allBreakdownValues(),
      allFilters()
    );
  };

  const showClearBtn =
    selectedSubBreakdowns.length !== 0 ||
    Object.values(selectedFilterBreakdowns).join('') !== '';

  const findBreakdown = (breakdown: string) => {
    let singleBreakdown = reportBreakdown.find(
      (bk) => bk.breakdownName === breakdown
    );

    return (
      singleBreakdown ?? {
        breakdownName: '',
        breakdownValues: [],
        breakdownLevel: '',
      }
    );
  };

  const selBreakdownReport = findBreakdown(selectedBreakdown);
  const ComparissionButtons = () => {
    return (
      <List className={classes.autocomplete}>
        <ListItem key={selectedBreakdown} disableGutters>
          <BreakdownOptions
            options={getOptions(
              selBreakdownReport.breakdownValues,
              selectedSubBreakdowns
            )}
            label={`${selBreakdownReport.breakdownName} Insights`}
            placeholder={`${selBreakdownReport.breakdownName} Insights`}
            breakdown={selBreakdownReport.breakdownName}
            value={selectedSubBreakdowns}
            onSubBreakdownsChange={onSubBreakdownsChange}
          />
        </ListItem>

        {breakdownNames
          .filter((item) => item !== selectedBreakdown)
          .map((breakdownName) => {
            const selBreakdownName = findBreakdown(breakdownName);
            return (
              <ListItem
                key={breakdownName}
                className={classes.horizontalPadding25}
                disableGutters
              >
                <BreakdownFilterOptions
                  options={getOptions(
                    selBreakdownName.breakdownValues,
                    selectedSubBreakdowns
                  )}
                  label={`${selBreakdownName.breakdownName} Insights`}
                  breakdown={selBreakdownName.breakdownName}
                  value={
                    selectedFilterBreakdowns[selBreakdownName.breakdownName]
                  }
                  placeholder={`${selBreakdownName.breakdownName} Insights`}
                  onSubBreakdownsChange={onSubBreakdownsChange}
                />
              </ListItem>
            );
          })}

        <ListItem>
          <Button color='primary' variant='contained' onClick={onApplyClicked}>
            {isLoading && (
              <Box pt={0.5}>
                <CircularProgress size={16} thickness={4} color='secondary' />
              </Box>
            )}
            Apply
          </Button>
        </ListItem>
        {showClearBtn && (
          <ListItem>
            <Button
              color='primary'
              style={{ width: '150px' }}
              variant='text'
              onClick={onApplyClear}
              startIcon={<Icon>clear</Icon>}
            >
              {' '}
              Clear filters
            </Button>
          </ListItem>
        )}
      </List>
    );
  };
  const kpilist = mapKpis(kpiCols);
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <TabHeader
              dates={dates}
              header={'Performance by Breakdown Dashboard'}
            />
          </List>
        </Grid>
        <Grid item lg={12} md={12} xs={12} className={classes.autocomplete}>
          <ComparissionButtons />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid container item xs={12} direction='column'>
          <Paper className={classes.spacedGrid} elevation={2}>
            <Grid item lg={12} md={12} xs={12}>
              <ToggleButtonGroup
                value={kpi}
                size='small'
                color='primary'
                exclusive
                onChange={handleKpiChange}
                className={classes.toggleButtons}
              >
                {mapKpis(
                  breakdownComputation.getSelectedBreakdownKpis(
                    selectedBreakdownReport
                  ),
                  true,
                  true
                ).map((element) => (
                  <StyledToggleButton
                    key={element.value}
                    value={element.value}
                    color='primary'
                    style={{ textTransform: 'none' }}
                  >
                    <BootstrapTooltip
                      title={getFullNotation(element.value)}
                      placement='bottom'
                      key={element.label}
                    >
                      <span>{element.label}</span>
                    </BootstrapTooltip>
                  </StyledToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <DailyBreakdownPlot
                dates={breakdownComputation.getDates(selectedBreakdownReport)}
                series={dailyTrend}
                campaignName={campaignName}
                breakdown={selectedBreakdown}
                kpi={getKpiName(kpi, kpilist)}
              />
            </Grid>
          </Paper>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item lg={8} md={12} xs={12}>
            <Paper className={classes.spacedGrid} elevation={2}>
              <List className={classes.flexContainer}>
                <ListItem className={classes.portionDonut}>
                  <ShareFractionChart
                    kpi={'Spend'}
                    breakdown={selectedBreakdown}
                    series={breakdownComputation.getSelectedBreakdownSpend(
                      selectedBreakdownReport
                    )}
                    labels={breakdownComputation.getSelectedBreakdownLabel(
                      selectedBreakdownReport
                    )}
                  />
                </ListItem>
                <ListItem className={classes.portionDonut}>
                  <ShareFractionChart
                    kpi={'Impression'}
                    breakdown={selectedBreakdown}
                    series={breakdownComputation.getSelectedBreakdownImp(
                      selectedBreakdownReport
                    )}
                    labels={breakdownComputation.getSelectedBreakdownLabel(
                      selectedBreakdownReport
                    )}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
          <Grid item lg={4} md={12} xs={12}>
            <Paper elevation={2}>
              <SummaryBarPlot
                campaignName={campaignName}
                breakdown={selectedBreakdown}
                data={breakdownComputation.getSelectedBreakdownKpiSummary(
                  selectedBreakdownReport
                )}
                categories={breakdownComputation.getSelectedBreakdownKpis(
                  selectedBreakdownReport
                )}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2}>
            <BreakdownNumericReport
              breakdownReport={
                Object.keys(customBreakdownReport).length === 0
                  ? breakdownReport
                  : customBreakdownReport
              }
              kpiCols={kpiCols}
              campaignName={campaignName}
              selectedBreakdown={selectedBreakdown}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default Breakdown;
