import { DataMapType } from '..';
import { TagRecord } from '../store/tagReducer';

export default function (tag: TagRecord, data: DataMapType) {
  let [width, height] = (tag.dimensions || '').split('x');
  if (!width || !height) {
    return data;
  }

  return data.set('width', width).set('height', height);
}
