import * as React from 'react';

import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from '@material-ui/core';
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';

import DropdownOptions from './siteDropdown';
import GetAppIcon from '@material-ui/icons/GetApp';
import { H6 } from '@adludio/components';
import { Site } from '../../types';
import { formatDateString } from '../../utils/date';
import { removeURLPrefix } from '../../utils/stringFilter';
import { responsiveStyle } from '../../utils/Styles';

const getColor = (value: string) =>
  value === 'neutral'
    ? '#F8B560'
    : value === 'positive'
      ? '#7DC3AA'
      : '#E86464';

const StyledDataGrid = withStyles({
  root: {
    border: 'none',
    '& .paxton-table--header': {
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
      // set header font styling
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        textTransform: 'uppercase',
        fontSize: '16px',
      },
    },
    // This is to fix the rows being cut off due to adding padding
    '& .MuiDataGrid-viewport': {
      maxHeight: 'unset !important',
      minHeight: '100px',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important', // <= manually setting the max height, but this is not responsive
      padding: 0,
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
      width: 'auto',
    },
  },
})(DataGrid);

const columns: GridColDef[] = [
  {
    field: 'site',
    headerName: 'Site',
    width: 250,
    // eslint-disable-next-line react/display-name
    renderCell: (cellValues) => {
      return (
        <List>
          <ListItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'body2',
              }}
              primary={cellValues.row.site.title}
              secondaryTypographyProps={{
                align: 'right',
              }}
              secondary={cellValues.row.site.url}
            />
          </ListItem>
        </List>
      );
    },
  },
  {
    field: 'url',
    hide: true,
  },

  {
    field: 'vertical',
    headerName: 'Vertical',
    headerAlign: 'center',
    flex: 1,
    // eslint-disable-next-line react/display-name
    renderCell: (cellValues) => {
      return (
        <Button
          size='small'
          variant='outlined'
          style={{ borderRadius: 5 }}
          color='secondary'
        >
          {cellValues.row.vertical}
        </Button>
      );
    },
  },
  {
    field: 'sentiment',
    headerName: 'Sentiment',
    headerAlign: 'center',
    flex: 1,
    // eslint-disable-next-line react/display-name
    renderCell: (cellValues) => {
      return (
        <Button
          variant='outlined'
          size='small'
          style={{
            borderRadius: 5,
            color: getColor(cellValues.row.sentiment.toLowerCase()),
            borderColor: getColor(cellValues.row.sentiment.toLowerCase()),
          }}
        >
          {cellValues.row.sentiment}
        </Button>
      );
    },
  },
];

const getSiteVerticals = (_sites: Site[]) =>
  Object.assign(
    {},
    ..._sites.map((site) => {
      let _verticals = site.verticals.map((vertical, index) => ({
        id: index,
        site: {
          title: vertical.content,
          url: vertical.site,
        },
        url: vertical.site,
        vertical: vertical.vertical,
        sentiment: vertical.sentiment,
      }));

      return {
        [removeURLPrefix(site.site)]: _verticals,
      };
    })
  );

interface kpiOptions {
  dropdownOptions: string[];
  kpi: string;
  sites: Site[];
  campaignName: string;
}
const SentimentTableViews = ({
  dropdownOptions,
  kpi,
  sites,
  campaignName,
}: kpiOptions) => {
  const siteVerticals = getSiteVerticals(sites);
  const [selectedSite, setSelectedSite] = React.useState<string>(
    dropdownOptions[0]
  );
  const [siteVertical, setSiteVertical] = React.useState(
    siteVerticals[dropdownOptions[0]]
  );

  React.useEffect(() => {
    setSelectedSite(dropdownOptions[0]);
    setSiteVertical(siteVerticals[dropdownOptions[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOptions]);

  const handleSiteSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue: string = e.target.value;
    setSelectedSite(selectedValue);
    setSiteVertical(siteVerticals[selectedValue]);
  };

  const CustomToolbar = () => {
    const date = new Date();
    const classes = responsiveStyle();
    return (
      <GridToolbarContainer>
        <Grid container className={classes.toolbar}>
          <Grid item xs={12} md={12} lg={12}>
            <H6 align='left' className={classes.h6Tpyos}>
              Top targeted verticals by {kpi} performance
            </H6>
          </Grid>
          <Grid item xs={12} md={12} lg={10}>
            <DropdownOptions
              idKey={'toList'}
              handleSelectOption={handleSiteSelect}
              selectedValue={selectedSite}
              dropdownOptions={dropdownOptions}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={2}>
            <GridToolbarExport
              startIcon={<GetAppIcon />}
              csvOptions={{
                fileName: `${campaignName}_${kpi}_verticals_${formatDateString(
                  date
                )}`,
                delimiter: ';',
                utf8WithBom: true,
                fields: ['url', 'vertical', 'sentiment'],
              }}
            >
              {null}
            </GridToolbarExport>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  };
  return (
    <StyledDataGrid
      pageSize={10}
      columns={columns}
      rows={siteVertical}
      components={{
        Toolbar: CustomToolbar,
      }}
    />
  );
};
export default SentimentTableViews;
