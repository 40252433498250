import { BreakdownOpts } from '../types';

export function defineCols(item: string) {
  let label = '';

  const colMapper = {
    site: 'Site',
    category: 'Breakdown Value',
    date: 'Date',
    curatorRevenue: 'Spend',
    ectr: 'eCTR(%)',
    videoEnd: 'Views',
    videoStart: 'Video-Start',
    viewedImps: 'Viewable',
    engagement: 'Engagement',
    impression: 'Impression',
    click: 'Click',
    er: 'ER(%)',
    ctr: 'CTR(%)',
    vtr: 'VTR(%)',
    viewability: 'Viewability(%)',
  };

  label = colMapper[item];

  return label;
}
export let kpiMapper = [
  {
    value: 'curatorRevenue',
    label: 'Spend',
  },
  {
    value: 'imps',
    label: 'Impression',
  },
  {
    value: 'impression',
    label: 'Impression',
  },
  {
    value: 'engagement',
    label: 'Engagement',
  },

  {
    value: 'er',
    label: 'ER',
  },
  {
    value: 'click',
    label: 'Click',
  },

  {
    value: 'ctr',
    label: 'CTR',
  },
  {
    value: 'ectr',
    label: 'eCTR',
  },
  {
    value: 'videoEnd',
    label: 'Views',
  },
  {
    value: 'vtr',
    label: 'VTR',
  },
  {
    value: 'viewedImps',
    label: 'Viewable',
  },
  {
    value: 'viewability',
    label: 'Viewability',
  },
];

export function mapKpis(
  kpiCols: string[],
  noSpend: boolean = false,
  noVolume: boolean = false,
  noImp: boolean = false,
  noVtr: boolean = false
) {
  let finalList: BreakdownOpts = [];
  kpiMapper.forEach((kpi) => {
    if (kpiCols.includes(kpi.value)) {
      const kpiActions = [
        'engagement',
        'click',
        'viewedImps',
        'videoStart',
        'videoEnd',
      ];
      const skip =
        (noVolume && kpiActions.includes(kpi.value)) ||
        (noSpend && kpi.value === 'curatorRevenue') ||
        (noImp && kpi.value === 'impression') ||
        (noVtr && kpi.value === 'vtr');

      if (!skip) {
        finalList.push(kpi);
      }
    }
  });

  return finalList;
}

const getKpiName = (kpiId: string, kpilist: BreakdownOpts = kpiMapper) => {
  let kpiName: string = '';
  kpilist.map((kpi) => {
    if (kpi.value === kpiId) kpiName = kpi.label;
  });
  return kpiName;
};
export default getKpiName;

export const getFullNotation = (kpi: string): string => {
  const kpiRates = {
    'er': 'Engagement Rate',
    'ctr': 'Clickthrough Rate',
    'ectr': 'Expected Clickthrough Rate',
    'vtr': 'View Through Rate'
  };
  return kpiRates[kpi];
};
