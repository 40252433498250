import React from 'react';

export interface SummaryMetricProps {
  disable: boolean;
  hasValue: boolean;
  children?: React.ReactChild;
}

const SummaryMetric = ({
  disable,
  hasValue,
  children,
}: SummaryMetricProps) => {
  return <>{disable && hasValue && children}</>;
};
export default SummaryMetric;
