export const getCampaignStatus = (startDate: Date, endDate: Date, campaignStatus: string, adunitsLength: number) => {
  const today = new Date();
  const isPendingLaunch = today < startDate;
  const isServing = (startDate < today) && (today < endDate) ;
  if (campaignStatus === 'PRODUCTION' && adunitsLength === 0 && isPendingLaunch) return 'production';
  else if (isPendingLaunch){
    return 'pendingLaunch';
  }
  else if (isServing){
    return 'serving';
  }
  else {
    return 'completed';
  }
};
