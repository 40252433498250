/* eslint-disable complexity */
import { Grid, Subtitle1, TextInput, Chip } from '@adludio/components';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { useMemo, useEffect } from 'react';
import { FormikProps } from 'formik';
import countryList from 'react-select-country-list';
import { mockDSPs } from '../../../mock-data/mockDSPs';
import { ManagedFormValues, ProgrammaticFormValues } from '../types';
import { targetingStyles as styles } from '../styles';
import { SiteListUpload } from './SiteListUpload';
import { preventBriefExit } from '../../../helpers/preventBriefExit';

export interface FormValues {
  audience: string;
  geographies: string;
  DSP: string;
  seatId: string;
  siteListUrl: string;
}

export const TargetingForm = ({
  campaignId,
  formikProps,
  isManaged,
  wasSaved,
}: {
  formikProps:
    | FormikProps<ProgrammaticFormValues>
    | FormikProps<ManagedFormValues>;
  campaignId: string;
  isManaged?: boolean;
  wasSaved: boolean;
}) => {
  const { errors, values, handleChange, setFieldValue, setFieldError } =
    formikProps;
  const { column, purple, menuPaper, dropdown } = styles();

  const countries = useMemo(() => countryList().getData(), []);
  const getValue = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((label, i) => {
      const countryItem = countries.find((country) => country.label === label);
      countryItem?.value && countriesList.push(countryItem.value);
    });
    return countriesList.filter((e) => e);
  };
  const getCountries = (c: string[]) => {
    const countriesList: string[] = [''];
    c.map((value, i) => {
      const countryItem = countries.find((country) => country.value === value);
      countryItem?.label && countriesList.push(countryItem.label);
    });
    return countriesList.filter((e) => e);
  };

  const blockExit = preventBriefExit(wasSaved, formikProps.values);

  useEffect(() => {
    blockExit;
  }, [formikProps.values, wasSaved]);

  const handleChangeWrapper = (e: any) => {
    setFieldError(e.target.name, undefined);
    handleChange(e);
  };

  return (
    <>
      <Grid className={column} container justify='center' xs={12}>
        <Grid item xs={10} md={8}>
          <Subtitle1 className={purple}>Audience</Subtitle1>
        </Grid>
        <Grid container xs={10} md={8}>
          <TextInput
            id='audience'
            fullWidth
            name='audience'
            variant='outlined'
            label='Audience'
            value={values.audience}
            onChange={handleChangeWrapper}
            required
            rows={4}
            multiline
            error={!!errors.audience}
            helperText={errors.audience ?? 'Add target audience groups here.'}
          />
        </Grid>
        <Grid container xs={10} md={8} direction='column'>
          <Box mb='1rem' mt='1.5rem'>
            <Subtitle1 className={purple}>Serving Information</Subtitle1>
          </Box>
          <Autocomplete
            value={
              typeof values.geographies === 'string'
                ? getCountries(values.geographies!.split(','))
                : []
            }
            onChange={(event: React.ChangeEvent<{}>, newValue: string[]) => {
              setFieldError('geographies', undefined);
              const countriesValues = getValue(newValue);
              setFieldValue('geographies', countriesValues.join(',') as string);
            }}
            multiple
            id='geographies'
            options={countries.map((option) => option.label)}
            freeSolo
            renderTags={(
              value: string[],
              getTagProps: AutocompleteGetTagProps
            ) =>
              value.map((option: string, index: number) => (
                <Chip key={option} label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                name='geographies'
                required
                variant='outlined'
                label='Serving Locations'
                placeholder='Search'
                inputProps={{
                  ...params.inputProps,
                  required: values.geographies?.length === 0,
                }}
                error={!!errors.geographies}
                helperText={errors.geographies}
              />
            )}
          />
        </Grid>
        {!isManaged && (
          <>
            <Grid item xs={10} md={8}>
              <FormControl
                variant='outlined'
                required
                className={dropdown}
                error={!!errors.DSP}
              >
                <InputLabel id='DSP'>DSP</InputLabel>
                <Select
                  fullWidth
                  required
                  MenuProps={{ classes: { paper: menuPaper } }}
                  value={values.DSP}
                  onChange={handleChangeWrapper}
                  name='DSP'
                  labelId='DSP'
                  label='DSP'
                >
                  {mockDSPs.map((c) => (
                    <MenuItem value={c.value} key={c.key}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.KPI && <FormHelperText>{errors.KPI}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={10} md={8}>
              <TextField
                fullWidth
                required
                name='seatId'
                variant='outlined'
                value={values.seatId}
                label='Seat ID'
                onChange={handleChangeWrapper}
                error={!!errors.seatId}
                helperText={errors.seatId}
              />
            </Grid>
          </>
        )}
        <Grid item xs={10} md={8}>
          <Box mb={2} mt='1.5rem'>
            <FormControl style={{ width: '100%' }}>
              <SiteListUpload
                sitelistUrl={values.sitelistUrl}
                error={errors.sitelistUrl}
                isManaged={!!isManaged}
                formikProps={formikProps}
                campaignId={campaignId}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
