import { FileType } from '../generated/graphql';

export enum FileStatus{
    accepted = 'ACCEPTED',
    pending = 'AWAITING',
    archived = 'ARCHIVED'
}

export interface CampaignDocument {
    campaignId: { id: string };
    id: string;
    fileName: string;
    fileStatus: FileStatus;
    fileType: FileType;
    uploadedAt: Date,
    creatorId?: { id: number, name: string };
    deletedAt?: Date;
}
