import { CampaignDocument, FileStatus } from '../../models/document';
import { useEffect, useState, useContext } from 'react';
import { FileType } from '../../generated/graphql';
import { AuthContext } from '@adludio/react-sso';

export function useCanApproveDocument(
  doc: CampaignDocument,
  isManaged: boolean
) {
  const {
    auth: { user },
  } = useContext(AuthContext);
  const [canApprove, setCanApprove] = useState<boolean>(false);

  useEffect(() => {
    if (user?.role === 'client') {
      if (
        (isManaged && doc.fileType === FileType.Storyboard) ||
        doc.fileType === FileType.SiteList
      ) {
        setCanApprove(false);
        return;
      }
      switch (doc.fileStatus) {
        case FileStatus.accepted:
        case FileStatus.archived:
          setCanApprove(false);
          break;
        case FileStatus.pending:
          setCanApprove(true);
          break;
      }
    }
  }, [doc, setCanApprove, user]);

  return canApprove;
}
