import {
  AdludioTheme,
  Box,
  List,
  ListItem,
  makeStyles,
} from '@adludio/components';
/* eslint-disable complexity */
import { AppBar, Divider, Grid, Icon } from '@material-ui/core';
import {
  InviteTeamWrapper,
  InviteTeamWrapperHandle,
} from '../../../components/InviteTeam/InviteTeamWrapper';
import { Report, SiteTabSections } from '../../types';
import { Tab, Tabs } from '@adludio/components';

import Avatars from '../../../components/Avatars';
import Breakdown from '../../components/breakdown/breakdownBody';
import { BreakdownComputation } from '../../utils/breakdownCompute';
import BreakdownSkeleton from '../Skeletons/BreakdownSkeleton';
import { H5 } from '@adludio/components';
import KPITrend from '../../components/dashboard/livePerformance';
import PredictionBody from '../../components/prediction/PredictionLayout';
import PredictionNoData from '../../components/prediction/PredictionNoData';
import PredictionSkeleton from '../../components/prediction/PredictionSkeleton';
import React from 'react';
import SiteBody from '../../components/site/siteBody';
import SiteBodySkeleton from '../../components/site/siteBodySkeleton';
import SummarySection from '../../components/dashboard/SummarySection';
// import { TabPanel } from '../../components/ui/tabPanel';
import { TabPanel } from './TabPanel';
import { User } from '../../../generated/graphql';
import { responsiveStyle } from '../../utils/Styles';
import { useBreakdownReport } from '../../contexts/BreakdownReportContext';
import { usePrediction } from '../../contexts/PredictionContext';
import { useSiteList } from '../../contexts/SiteListContext';
import validate from '../../utils/validateNonZero';

interface DashboardWithTabsProps {
  campaignKey: string;
  report: Report;
  team: User[];
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignName: string;
  campaignStatus: string;
}

function indexTabs(index: any) {
  return {
    id: `tab-${index}`,
    'aria-controls': `panel-${index}`,
  };
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  disableVertPadding: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  title: {
    fontSize: '1.25rem',
  },
  tabs: {
    paddingTop: '1rem',
    paddingBottom: '0px',
  },
  inviteButton: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
}));

function DashboardWithTabs({
  campaignKey,
  report,
  team,
  env,
  campaignName,
  campaignStatus,
}: DashboardWithTabsProps) {
  const { isLoading, breakdownReport } = useBreakdownReport();
  const { isSiteLoading, topSiteList, bottomSiteList } = useSiteList();
  const { isPredictionLoading, prediction } = usePrediction();
  const overRideClasses = useStyles();

  const [value, setValue] = React.useState(0);
  const [teamInitials, setTeamInitials] = React.useState<string[]>([]);
  const kpiCols = validate(report.overview);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const classes = responsiveStyle();

  const inviteTeamRef = React.useRef<InviteTeamWrapperHandle>(null);

  const handleHoverLarge = (open: boolean) => {
    inviteTeamRef.current?.setModalOpen(open);
    inviteTeamRef.current?.setTeamOpen(open);
  };

  let dates = { min: report.startDate, max: report.endDate };

  let tabIndex = 0;
  let tabPanelIndex = 0;

  const tabConfig = [
    {
      label: 'Live',
      icon: <Icon>trending_up</Icon>,
      show: report.layout.tabs.live.show,
      section: report.layout.tabs.live.sections,
    },
    {
      label: 'Breakdown',
      icon: <Icon>vertical_split</Icon>,
      show: report.layout.tabs.breakdown.show,
      section: report.layout.tabs.breakdown.sections,
    },
    {
      label: 'Site',
      icon: <Icon>leaderboard</Icon>,
      show: report.layout.tabs.site.show,
      section: report.layout.tabs.site.sections,
    },
    {
      label: 'Prediction',
      icon: <Icon>insights</Icon>,
      show: report.layout.tabs.prediction.show,
      section: report.layout.tabs.prediction.sections,
    },
  ];

  function getUserInitials(user: User): string {
    return (
      user.name
        ?.split(' ')
        .map((n: string) => n[0])
        .join('') || ''
    );
  }

  React.useEffect(() => {
    setTeamInitials(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      team
        .filter((user: User) => user.name !== null)
        .map((user: User) => getUserInitials(user)) || []
    );
  }, [team]);

  return (
    <Box mt='2rem' className={classes.container}>
      <AppBar position='static' className={classes.appBarColor} elevation={2}>
        <List classes={{ padding: overRideClasses.disableVertPadding }}>
          <ListItem classes={{ gutters: overRideClasses.disableVertPadding }}>
            <Grid container direction='row'>
              <Grid container item xs={6} md={8} alignItems='center'>
                <H5 className={overRideClasses.title}>
                  {campaignName || report.campaignName}
                </H5>
              </Grid>
              <Grid
                container
                direction='row'
                xs={6}
                md={4}
                justify='flex-end'
                wrap='nowrap'
              >
                <Grid container xs={6} sm={8} justifyContent='flex-end'>
                  <Avatars
                    team={team}
                    teamInitials={teamInitials}
                    openModal={handleHoverLarge}
                  />
                </Grid>
                <Grid
                  container
                  className={overRideClasses.inviteButton}
                  xs={6}
                  sm={4}
                  alignItems='center'
                >
                  <InviteTeamWrapper
                    ref={inviteTeamRef}
                    env={env}
                    campaignId={campaignKey}
                    team={team}
                    campaignName={campaignName}
                    campaignStatus={campaignStatus}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          <ListItem classes={{ gutters: overRideClasses.disableVertPadding }}>
            <SummarySection
              SummaryData={report.overview}
              layout={report.layout}
              kpiCols={kpiCols}
            />
          </ListItem>
          <Divider />
          <ListItem
            classes={{ gutters: overRideClasses.tabs }}
            className={classes.centeredItems}
          >
            <Tabs
              classes={{
                root: classes.centeredItems,
                scroller: classes.scroller,
              }}
              value={value}
              onChange={handleChange}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
            >
              {tabConfig.map((tabC) => {
                if (tabC.show) {
                  let _tab = (
                    <Tab
                      label={tabC.label}
                      icon={tabC.icon}
                      {...indexTabs(tabIndex)}
                    />
                  );
                  tabIndex = tabIndex + 1;
                  return _tab;
                }
                return;
              })}
            </Tabs>
          </ListItem>
        </List>
      </AppBar>
      {tabConfig.map((tabC) => {
        let _tab = <></>;
        if (tabC.show) {
          switch (tabC.label) {
            case 'Live':
              _tab = (
                <TabPanel value={value} index={tabPanelIndex}>
                  <KPITrend
                    report={report}
                    dates={dates}
                    campaignName={report.campaignName.toString()}
                    kpiCols={kpiCols}
                  />
                </TabPanel>
              );
              tabPanelIndex = tabPanelIndex + 1;
              break;
            case 'Breakdown':
              _tab = (
                <TabPanel value={value} index={tabPanelIndex}>
                  {isLoading ? (
                    <BreakdownSkeleton />
                  ) : (
                    <Breakdown
                      breakdownReport={breakdownReport}
                      breakdownComputation={
                        new BreakdownComputation(breakdownReport)
                      }
                      campaignName={report.campaignName}
                      reportBreakdown={report.breakdown ?? []}
                      campaignKey={campaignKey}
                      kpiCols={kpiCols}
                      dates={dates}
                    />
                  )}
                </TabPanel>
              );
              tabPanelIndex = tabPanelIndex + 1;
              break;
            case 'Site':
              _tab = (
                <TabPanel value={value} index={tabPanelIndex}>
                  {isSiteLoading ? (
                    <SiteBodySkeleton />
                  ) : (
                    <SiteBody
                      campaignName={report.campaignName}
                      topSiteList={topSiteList}
                      bottomSiteList={bottomSiteList}
                      kpiCols={kpiCols}
                      dates={dates}
                      sections={tabC.section as SiteTabSections}
                    />
                  )}
                </TabPanel>
              );
              tabPanelIndex = tabPanelIndex + 1;
              break;
            case 'Prediction':
              _tab = (
                <TabPanel value={value} index={3}>
                  {isPredictionLoading ? (
                    <PredictionSkeleton />
                  ) : prediction.length === 0 ? (
                    <PredictionNoData dates={dates} />
                  ) : (
                    <PredictionBody
                      kpiCols={kpiCols}
                      campaignName={report.campaignName.toString()}
                      predictions={prediction}
                      report={report}
                      dates={dates}
                    />
                  )}
                </TabPanel>
              );
              tabPanelIndex = tabPanelIndex + 1;
              break;
            default:
              break;
          }
        }
        return _tab;
      })}
    </Box>
  );
}

export default DashboardWithTabs;
