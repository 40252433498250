import { Body2, Dropzone, Grid, IconButton, Box } from '@adludio/components';
import axios from 'axios';
import { FormikProps } from 'formik';
import React, { useEffect, useRef } from 'react';
import { joinWithoutEmpty } from './CreativeForm';
import DeleteIcon from '@material-ui/icons/Delete';
import { assetsUploadStyles as styles } from '../styles';
import {
  useSaveCreativeBriefMutation,
  useSaveMediaCampaignBriefMutation,
} from '../../../generated/graphql';
import { Tooltip } from '@material-ui/core';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { acceptedFiletypes } from '../../../helpers/acceptedFiletypes';
import {
  CreativeFormValues,
  LiveFormValues,
  ManagedFormValues,
} from '../types';
import { UploadStatus } from '../../UploadStatus';
import { useUpload } from '../../../helpers/useUpload';

interface Props {
  formikProps:
    | FormikProps<CreativeFormValues>
    | FormikProps<ManagedFormValues>
    | FormikProps<LiveFormValues>;
  campaignId: string;
  isManaged: boolean;
}

export const AssetUpload: React.FC<Props> = ({
  formikProps,
  campaignId,
  isManaged,
}) => {
  const {
    values: { assetFiles },
    setFieldValue,
  } = formikProps;
  const { assetTitle, tooltip } = styles();
  const [saveMedia] = useSaveMediaCampaignBriefMutation();
  const [saveProgrammatic] = useSaveCreativeBriefMutation();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [files, setFiles] = React.useState<File[]>([]);
  const filesRef = useRef(files);
  const [status, setStatus] = React.useState<boolean[]>([]);
  const [filesAwaiting, setFilesAwaiting] = React.useState<File[]>([]);
  const filesAwaitingRef = useRef(filesAwaiting);
  const [progress, setProgress] = React.useState<number[]>([]);
  const progressRef = useRef(progress);
  const cancelTokenSource = useRef(axios.CancelToken.source());
  const intervalRef = useRef<number | null>();

  const {
    uploadDocument,
    isUploading,
    status: fileStatus,
    uploadProgress,
  } = useUpload({
    reFetch: () => console.log(''),
    cancelToken: cancelTokenSource.current.token,
  });

  const handleFiles = async (droppedFiles: File[]) => {
    if (isManaged) {
      await saveMedia({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...formikProps.values,
          },
        },
      });
    } else {
      await saveProgrammatic({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...formikProps.values,
          },
        },
      });
    }
    const sorted = [...droppedFiles].sort((a, b) => a.size - b.size);
    if (filesAwaiting.length) {
      setFiles(files.concat(sorted));
      setProgress(
        progressRef.current.concat(Array(droppedFiles.length).fill(0))
      );
    } else {
      setFiles(sorted);
      setStatus([]);
      setProgress(Array(droppedFiles.length).fill(0));
    }
    setFilesAwaiting(filesAwaiting.concat(sorted));
  };

  const removeUpload = (index: number) => {
    const fileToBeRemoved = files[index];
    const newFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(newFiles);
    const newUploads = [
      ...progress.slice(0, index),
      ...progress.slice(index + 1),
    ];
    setProgress(newUploads);
    if (selectedFile === fileToBeRemoved) {
      cancelTokenSource.current.cancel();
    } else {
      setFilesAwaiting(
        filesAwaiting.filter((awaitingFile) => awaitingFile !== fileToBeRemoved)
      );
    }
  };

  const fakeUploadProgress = async () => {
    if (filesAwaitingRef.current.length > 1) {
      const newUploadProgress = [...progressRef.current];
      filesAwaitingRef.current.forEach((file, i) => {
        if (i === 0) return;
        const fileIndex = filesRef.current.indexOf(file);
        const fakeProgress =
          progressRef.current[fileIndex] + Math.random() * 0.5;
        if (fakeProgress > 5) return;
        newUploadProgress.splice(fileIndex, 1, fakeProgress);
      });
      setProgress(newUploadProgress);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
  };

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    filesAwaitingRef.current = filesAwaiting;
    if (filesAwaiting.length > 1) {
      intervalRef.current = window.setInterval(function () {
        fakeUploadProgress().catch(console.log);
      }, 4000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (
      !isUploading &&
      filesAwaiting.length &&
      selectedFile !== filesAwaiting[0]
    ) {
      cancelTokenSource.current = axios.CancelToken.source();
      setSelectedFile(filesAwaiting[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAwaiting]);

  React.useEffect(() => {
    if (!selectedFile || isUploading) return;
    uploadDocument(selectedFile, campaignId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  React.useEffect(() => {
    const selectedFileIndex = filesRef.current.indexOf(selectedFile!);
    if (uploadProgress > progressRef.current[selectedFileIndex]) {
      const newUploadProgress = [...progressRef.current];
      newUploadProgress.splice(selectedFileIndex, 1, uploadProgress);
      setProgress(newUploadProgress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress]);

  React.useEffect(() => {
    if (!isUploading && selectedFile) {
      setFilesAwaiting(filesAwaitingRef.current.slice(1));
      const assetFile = assetFiles
        ? assetFiles.concat(`, ${selectedFile!.name}`)
        : selectedFile!.name;
      setFieldValue('assetFiles', assetFile);
      if (fileStatus !== null) {
        setStatus(status.concat(fileStatus));
        if (fileStatus) {
          const selectedFileIndex = filesRef.current.indexOf(selectedFile!);
          const newUploadProgress = [...progressRef.current];
          newUploadProgress.splice(selectedFileIndex, 1, 100);
          setProgress(newUploadProgress);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploading]);

  const CardFileIcon = (fileName: string) => {
    const fileExt = fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return (
      <Box width='2rem'>
        <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />
      </Box>
    );
  };

  const assetUploadItem = (asset: string, index: number) => {
    return (
      <Box width={'100%'}>
        <Grid
          container
          item
          xs={12}
          key={asset}
          direction='row'
          alignItems='center'
        >
          <Grid xs={2} sm={1} md={1}>
            {CardFileIcon(asset)}
          </Grid>
          <Tooltip title={asset} classes={{ tooltip: tooltip }}>
            <Grid xs={9} md={10}>
              <Body2 className={assetTitle}>{asset}</Body2>
            </Grid>
          </Tooltip>
          {asset && (
            <Grid xs={1}>
              <IconButton
                size='small'
                label='delete'
                onClick={() => {
                  const newValue = assetFiles?.split(',');
                  newValue.splice(index, 1);
                  setFieldValue('assetFiles', joinWithoutEmpty(newValue));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>{' '}
      </Box>
    );
  };

  return (
    <>
      <Grid container item xs={12}>
        <Box
          width='100%'
          paddingBottom='1rem'
          minHeight='8rem'
          textAlign='center'
        >
          <Dropzone
            numberOfFiles='multiple'
            setFiles={handleFiles as any} // Dropzone component expects a setState type but we're wrapping it
            files={files}
            accept={acceptedFiletypes}
          />
          <UploadStatus
            files={files}
            status={status}
            progress={progress}
            currentProgress={uploadProgress}
            removeUpload={removeUpload}
            currentIndex={selectedFile ? files.indexOf(selectedFile) : 0}
          />
        </Box>
      </Grid>
      <Grid container xs={12} justifyContent='center'>
        {assetFiles
          ?.split(',')
          .map((asset, index) => assetUploadItem(asset, index))}
      </Grid>
    </>
  );
};
