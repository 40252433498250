import * as React from 'react';

import { CircularProgress, useSnackbar } from '@adludio/components';
import { Operation, useGetMagicLinkLazyQuery } from '../generated/graphql';

import { CampaignDocument } from '../models/document';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarKey } from 'notistack';
import { useRef } from 'react';

export function useDownload() {
  const [getDownloadMagicLink, { data: downloadData }] =
    useGetMagicLinkLazyQuery({ fetchPolicy: 'no-cache' });
  const [fileName, setFileName] = React.useState('');
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [prevLink, setPrevLink] = React.useState('');
  const snackbarKey = useRef<number | null | string>();

  const downloadDocument = (document: CampaignDocument) => {
    setIsDownloading(true);
    snackbarKey.current = enqueueSnackbar('Download Started', {
      persist: true,
      preventDuplicate: true,
      variant: 'info',
      // eslint-disable-next-line react/display-name
      action: (key: SnackbarKey) => {
        return (
          <>
            <CircularProgress size={20} />
            <CloseIcon fontSize='small' onClick={() => closeSnackbar()} />
          </>
        );
      },
    });
    getDownloadMagicLink({
      variables: {
        fileId: document.id,
        type: document.fileType,
        campaignId: document.campaignId.id,
        operation: Operation.Get,
      },
    });
    setFileName(document.fileName);
  };

  /* download useffect */
  React.useEffect(() => {
    if (
      downloadData?.getMagicLink &&
      isDownloading &&
      downloadData.getMagicLink !== prevLink
    ) {
      /* any doubts? 👉https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js */
      setPrevLink(downloadData.getMagicLink);
      fetch(downloadData.getMagicLink, { method: Operation.Get })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            closeSnackbar();
            enqueueSnackbar('Download Failed', {
              variant: 'error',
            });
            return Promise.reject();
          }
        })
        .then((blob: Blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          // Append to html link element page
          document.body.appendChild(link);
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode!.removeChild(link);
          setIsDownloading(false);
          closeSnackbar();
          enqueueSnackbar('Download Completed', {
            variant: 'success',
          });
        })
        .catch((error) => {
          console.log(error);
          setIsDownloading(false);
          closeSnackbar();
          enqueueSnackbar('Download Failed', {
            variant: 'error',
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData, isDownloading]);

  return { downloadDocument, isDownloading };
}
