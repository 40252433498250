import React from 'react';

import { Link, LinkGetProps, LinkProps } from '@reach/router';
import { useCallback } from 'react';
import {
  makeStyles,
  AdludioTheme
} from '@adludio/components';
type NavLinkProps = Omit<LinkProps<{}>, 'getProps' | 'ref'>

const useStyles = makeStyles((theme: AdludioTheme) => ({
  active: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    svg: {
      '& path': {
        fill: theme.palette.primary.contrastText,
      },
    }
  }
}));

const NavLink = ({ className, ...props }: NavLinkProps) => {
  const styles = useStyles();
  const getActiveClassname = useCallback(
    ({ isCurrent }: LinkGetProps) => ({
      className: `${className} ${ isCurrent ? styles.active : '' }`
    }),
    [className, styles.active]
  );

  return (
    <Link {...props} getProps={getActiveClassname} />
  );
};

export default NavLink;
