export const mockCostCentre = [
  { label: 'UK', value: 'UK', key: 'UK' },
  { label: 'INTL', value: 'INTL', key: 'INTL' },
  { label: 'SGP', value: 'SGP', key: 'SGP' },
  { label: 'FR', value: 'FR', key: 'FR' },
  { label: 'USA', value: 'USA', key: 'USA' },
  { label: 'HK', value: 'HK', key: 'HK' },
  { label: 'TW', value: 'TW', key: 'TW' },
  { label: 'BRA', value: 'BRA', key: 'BRA' },
];
