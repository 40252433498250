import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { defineCols } from './setKpiCols';

const exportSite = (header: string[], rows: string[][], campaignName: string, title:string = 'Worst Performing Sites') => {

  const head = [header.map((col) => defineCols(col))];
  const data = rows;

  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape

  const marginLeft = 40;
  const doc = new JsPDF(orientation, unit, size);

  // campaign name
  doc.setFont('helvetica');
  doc.setFontSize(27);
  doc.text(`${campaignName} ${title}`, marginLeft, 40);

  autoTable(doc, {
    head: head,
    body: data,
    startY: 50
  });
  let today = new Date().toISOString().slice(0, 10);
  doc.save(`${campaignName}_${today}_worst_sites.pdf`);
};
export default exportSite;
