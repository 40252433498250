import React from 'react';

interface TabPanelProps{
    value:any,
    index:number,
    children: React.ReactNode
}

export function TabPanel({ value, index, children }:TabPanelProps){
  if (value === index) return <>{children}</>;
  else return <></>;
}

