import * as React from 'react';
import { BreakdownReport } from '../../types';
import NumericReport from '../dashboard/numericReport';
import { populateData } from '../../utils/compute';

export interface BreakdownProps {
  breakdownReport: BreakdownReport;
  kpiCols: string[];
  campaignName: string;
  selectedBreakdown: string;
}

export interface DownloadReportProps { }

export default function BreakdownNumericReport({
  breakdownReport,
  kpiCols,
  campaignName,
  selectedBreakdown,
}: BreakdownProps) {

  let tableRows: any[] = [];

  // get selected breakdown
  let selectedBreakdownValue = breakdownReport[selectedBreakdown];
  let uniqueKeys = new Set<string>();
  Object.keys(selectedBreakdownValue).map((key, index) => {
    uniqueKeys.add(key);
    let subRows: any[] = [];
    subRows = populateData(selectedBreakdownValue[key]['daily'], kpiCols, key);
    tableRows = tableRows.concat(subRows);
  });

  return (

    <NumericReport
      report={selectedBreakdownValue}
      tableRows={tableRows}
      campaignName={campaignName}
      kpiCols={kpiCols}
      category={Array.from(uniqueKeys.values())}
      titleText={'Breakdown Daily Performance'}
    />
  );
}
