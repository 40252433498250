import React, { useState, Dispatch, SetStateAction } from 'react';
import {
  Body1,
  Body2,
  IconButton,
  Box,
  Grid,
  TextInput,
} from '@adludio/components';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

import { useUpdateAdunitTitleMutation } from '../generated/graphql';
import { AdunitData } from './UploadAdunit';
import { DeleteAdunitModal } from './Modals/DeleteAdunit';

interface AdunitInfoProps {
  setAdunits: Dispatch<SetStateAction<string[]>>;
  adunits: string[];
  adunitData: AdunitData;
  adunitsData: AdunitData[];
  setAdunitData: Dispatch<SetStateAction<AdunitData[]>>;
}

export const AdunitInfo = ({
  setAdunits,
  adunitData,
  setAdunitData,
  adunits,
  adunitsData,
}: AdunitInfoProps) => {
  const [editAdunit, setEditAdunit] = useState(false);
  const [adunitTitleInput, setAdunitTitleInput] = useState(adunitData.title);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [updateAdunitTitleMutation] = useUpdateAdunitTitleMutation({
    variables: {
      id: adunitData.id,
      title: adunitTitleInput!,
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    return setAdunitTitleInput(e.target.value);
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setEditAdunit(false);
      updateAdunitTitleMutation()
        .then(() => {
          const updatedAdunitData = { ...adunitData };
          updatedAdunitData.title = adunitTitleInput!;
          setAdunitData((prevState) =>
            prevState.map((obj) =>
              updatedAdunitData.id === obj.id ? updatedAdunitData : obj
            )
          );
        })
        .catch(console.log);
    }
  };

  return (
    <>
      <Grid
        container
        xs={12}
        md={10}
        justifyContent='space-between'
        alignContent='space-between'
      >
        {editAdunit ? (
          <Grid xs={12}>
            <Box mb='1rem'>
              <TextInput
                defaultValue={adunitTitleInput}
                onKeyDown={keyPress}
                onChange={handleChange}
              />
            </Box>
          </Grid>
        ) : (
          <Grid container direction='row' justifyContent='space-between'>
            <Grid xs={7}>
              <Body1 style={{ maxWidth: '17rem', overflowWrap: 'break-word' }}>
                {adunitData.title}
              </Body1>
            </Grid>
            <Grid container xs={5} direction='row' justifyContent='flex-end'>
              <Grid>
                <Box ml='0.5rem' mb='0.5rem'>
                  <IconButton label='edit' onClick={() => setEditAdunit(true)}>
                    <Edit />
                  </IconButton>
                </Box>
              </Grid>
              <Grid>
                <Box ml='0.5rem' mb='0.5rem'>
                  <IconButton
                    label='edit'
                    onClick={() => setDeleteModalOpen(true)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Body2>{new Date(adunitData.createdAt).toLocaleDateString()}</Body2>
      </Grid>
      <DeleteAdunitModal
        isOpen={deleteModalOpen}
        adunitsData={adunitsData}
        onClose={() => setDeleteModalOpen(false)}
        adunits={adunits}
        setAdunitData={setAdunitData}
        adunitId={adunitData.id}
        setAdunits={setAdunits}
        adunitData={adunitData}
      />
    </>
  );
};
