/* eslint-disable complexity */
import {
  AdludioTheme,
  Subtitle1,
  Grid,
  H6,
  makeStyles,
  Card,
} from '@adludio/components';
import React from 'react';
import { RouteComponentProps } from '@reach/router';

const styles = makeStyles((theme: AdludioTheme) => ({
  info: {
    fontWeight: 500,
  },
  purple: {
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  card: {
    borderRadius: '4px',
    width: '100%',
    marginTop: '3rem',
    paddingBottom: '2rem',
    paddingTop: '2rem',
  },
}));
interface CreativeBriefOverviewReadProps extends RouteComponentProps {
  data?: any;
}

export function LiveBriefOverviewRead(
  props: CreativeBriefOverviewReadProps
): JSX.Element {
  const { card, purple, info } = styles();

  return (
    <Card elevation={5} className={card}>
      <Grid container xs={12} justifyContent='center' spacing={4}>
        <Grid container item xs={10}>
          <H6 color='secondary'>Managed Campaign Information</H6>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Brand Name</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.brandName || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Campaign Name*</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.name || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid container item xs={10}>
          <H6 color='secondary'>Creative Details</H6>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Description</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.description || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Objectives</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.objective || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>KPI's</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.KPI || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Web Links</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.websiteUrl || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Assets</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.assetFiles || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid container item xs={10}>
          <H6 color='secondary'>Client Contact Details</H6>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Who booked this?</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.bookedBy || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Company Address</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.companyAddress || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Finance Contact email</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.contactEmail || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid container item xs={10}>
          <H6 color='secondary'>Campaign Details</H6>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Placement</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.placement || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Campaign Dates</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.startDate || 'Not specified'}
          </Subtitle1>
          -
          <Subtitle1 className={info}>
            {props.data.endDate || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Target Audience</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.audience || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Serving Locations</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.geographies || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>IO</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.IO || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Audience List</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.audienceList || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Cost Centre</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.costCentre || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Currency</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.currency || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Budget</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.budget || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Buy Rate (CPE)</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.CPE || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Volume agreed</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.volume || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Agency Fee</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.percentage || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Net Cost</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.newCost || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid container item xs={10}>
          <H6 color='secondary'>Extra information</H6>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Tracking tags</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.trackingTags || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Standard Campaign</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.standardCampaign || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Type of Client</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.clientType || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>
            Does this brief qualify for a non-standard build?
          </Subtitle1>
          <Subtitle1 className={info}>
            {props.data.standardBuild || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Adludio Sales Representative</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.salesEmail || 'Not specified'}
          </Subtitle1>
        </Grid>
        <Grid item xs={10}>
          <Subtitle1 className={purple}>Additional information</Subtitle1>
          <Subtitle1 className={info}>
            {props.data.additionalInfo || 'Not specified'}
          </Subtitle1>
        </Grid>
      </Grid>
    </Card>
  );
}
