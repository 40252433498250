import React from 'react';
import {
  AdludioTheme,
  Body1,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@adludio/components';
import placeholderThumbnail from '../static-assets/campaignCard.png';
import { Link } from '@reach/router';
import { StatusBadge } from './StatusBadge';
import { ManagedService } from '../generated/graphql';
import { AuthContext } from '@adludio/react-sso';
import { BriefTypeBadge } from './BriefTypeBadge';
export interface Props {
  campaigns?: ManagedService[] | undefined;
}
const styles = makeStyles((theme: AdludioTheme) => ({
  rootCard: {
    height: '21rem',
    width: '14.5rem',
    [theme.breakpoints.only('xs')]: {
      height: '21rem',
      width: '16.5rem',
    },
    [theme.breakpoints.only('sm')]: {
      height: '21rem',
      width: '16.5rem',
    },
    boxSizing: 'border-box',
    '&:hover': {
      boxShadow: '0px 1px 5px rgba(0, 0, 2, 0.2)',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  thumbnail: {
    height: '14rem',
    width: '14.5rem',
    [theme.breakpoints.only('sm')]: {
      width: '16.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      width: '16.5rem',
      height: '15rem',
    },
    backgroundRepeat: 'no-repeat',
    background: `url(${placeholderThumbnail})`,
    backgroundSize: '100% 100%',
    ObjectFit: 'cover',
  },
  contentCard: {
    height: '4rem',
  },
  title: {
    maxHeight: '1.3rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  root: {
    marginBottom: '3rem',
  },
}));

export function LiveCampaignGrid(props: Props): JSX.Element {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const { rootCard, thumbnail, contentCard, title, root } = styles();

  const { auth } = React.useContext(AuthContext);
  const isInternalUser =
    auth.user?.role !== 'guest' && auth.user?.role !== 'client';

  return (
    <Grid item xs container spacing={3} className={root}>
      {props.campaigns &&
        props.campaigns.map(
          (campaign) =>
            campaign.name !== null && (
              <Grid
                item
                key={campaign.name}
                container
                justifyContent={isDesktop ? 'flex-start' : 'center'}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
              >
                <Card className={rootCard} elevation={2}>
                  <Link
                    to={`/campaigns/reporting/${campaign.id}/brief`}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                  >
                    <CardMedia className={thumbnail} />
                    <CardContent className={contentCard}>
                      <Grid
                        container
                        direction='column'
                        wrap='nowrap'
                        xs={12}
                        className={contentCard}
                      >
                        {campaign.name!.length > 20 ? (
                          <Tooltip title={campaign.name!} placement='top-start'>
                            <Grid item xs>
                              <Body1 className={title}>{campaign.name}</Body1>
                            </Grid>
                          </Tooltip>
                        ) : (
                          <Grid item xs>
                            <Body1>{campaign.name}</Body1>
                          </Grid>
                        )}
                        <Grid item xs>
                          <Box pt='1.75rem'>
                            <Grid
                              container
                              xs
                              justify='flex-end'
                              alignContent='flex-end'
                            >
                              {isInternalUser && (
                                <BriefTypeBadge campaignType={'MS'} />
                              )}
                              <StatusBadge status='serving' />
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            )
        )}
    </Grid>
  );
}
