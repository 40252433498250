import React from 'react';
import { responsiveStyle } from '../../utils/Styles';
import { BreakdownOpts } from '../../types';
import { MenuItem, TextField } from '@material-ui/core';

export interface SettingProps {
  kpilist: BreakdownOpts;
  kpi1: string;
  kpi2: string;
  dates: { min: string; max: string };
  handleKPI1Select: (e: any) => void;
  handleKPI2Select: (e: any) => void;
}
export interface KPIOptionsProps {
  idKey: string,
  handleKPISelectOption: any,
  kpiValue: string
}
const SettingsContainer = ({
  kpilist,
  kpi1,
  kpi2,
  handleKPI1Select,
  handleKPI2Select,
}: SettingProps) => {
  const classes = responsiveStyle();
  const KPIOptions = ({ idKey, handleKPISelectOption, kpiValue }: KPIOptionsProps) => {
    return (
      <TextField
        className={classes.optionsStyle}
        size='small'
        variant='outlined'
        id={idKey}
        select
        label=''
        value={kpiValue}
        onChange={handleKPISelectOption}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }
        }}
      >
        {kpilist.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  return (
    <div className={classes.root} >
      <KPIOptions idKey={'KpiDropdown1'}
        handleKPISelectOption={handleKPI1Select}
        kpiValue={kpi1}
      />
      <KPIOptions idKey={'KpiDropdown2'}
        handleKPISelectOption={handleKPI2Select}
        kpiValue={kpi2}
      />
    </div>
  );
};
export default SettingsContainer;
