import { BreakdownReportContextProvider } from './contexts/BreakdownReportContext';
import { CustomBreakdownReportContextProvider } from './contexts/CustomBreakdownReportContext';
import Dashboard from './pages/Dashboard/Dashboard';
import { PredictionContextProvider } from './contexts/PredictionContext';
import React from 'react';
import { ReportContextProvider } from './contexts/ReportContext';
import { RouteComponentProps } from '@reach/router';
import { SiteListContextProvider } from './contexts/SiteListContext';
import { User } from '../generated/graphql';

interface ReportProps extends RouteComponentProps {
  campaignId?: string;
  team: User[];
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignName: string;
  campaignStatus: string;
}

const Report = ({ campaignId = '', team, env, campaignName, campaignStatus }: ReportProps) => {
  return (
    <ReportContextProvider>
      <BreakdownReportContextProvider>
        <SiteListContextProvider>
          <CustomBreakdownReportContextProvider>
            <PredictionContextProvider>
              <Dashboard
                campaignKey={campaignId}
                team={team}
                env={env}
                campaignName={campaignName}
                campaignStatus={campaignStatus}
              />
            </PredictionContextProvider>
          </CustomBreakdownReportContextProvider>
        </SiteListContextProvider>
      </BreakdownReportContextProvider>
    </ReportContextProvider>
  );
};

export default Report;
