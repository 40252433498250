import {
  AdludioTheme,
  Box,
  Button,
  Card,
  Grid,
  makeStyles,
  useSnackbar,
} from '@adludio/components';
import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { CreativeForm } from '../Components/CreativeForm';
import {
  useGetCampaignLazyQuery,
  useSaveCreativeBriefMutation,
  useSubmitCreativeBriefMutation,
} from '../../../generated/graphql';

import { parseQueryToFormValuesCreative, CREATIVE_FORM_VALUES } from '../const';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';

const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    marginTop: '3rem',
    marginBottom: '3rem',
    paddingTop: '20px',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

interface CreativeFormProps extends RouteComponentProps {
  id?: string;
  onCancel?: () => void;
}

export function CreativeCampaignForm(props: CreativeFormProps): JSX.Element {
  const { container } = styles();
  const [isSaved, setIsSaved] = useState(false);
  const [save, { loading: isSaving }] = useSaveCreativeBriefMutation();
  const [fetch, { data }] = useGetCampaignLazyQuery();

  const [submit] = useSubmitCreativeBriefMutation();
  const { enqueueSnackbar } = useSnackbar();

  const isEditing = !!props.id;
  const campaignId = useMemo(() => props.id ?? uuidv4(), [props.id]);
  useEffect(() => {
    if (isEditing) {
      fetch({ variables: { id: props.id! } });
    }
  }, [fetch, isEditing, props.id]);

  const initialValues = useMemo(
    () =>
      isEditing ? parseQueryToFormValuesCreative(data) : CREATIVE_FORM_VALUES,
    [data, isEditing]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues ?? CREATIVE_FORM_VALUES}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values) => {
        try {
          await save({
            variables: {
              id: campaignId,
              fieldsToSave: {
                ...values,
              },
            },
          });
          await submit({ variables: { campaignId } });
          enqueueSnackbar('Successfully submitted Brief.', {
            variant: 'success',
          });
          await navigate(`/campaigns/campaign/${campaignId}/brief`);
        } catch (e) {
          enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
        }
      }}
    >
      {(formikProps) => (
        <>
          <Grid
            container
            justify='center'
            item
            xs={11}
            md={8}
            spacing={2}
            direction='column'
          >
            <Card className={container} elevation={2}>
              <Box mt='1rem'>
                <CreativeForm
                  wasSaved={isSaved}
                  campaignId={campaignId!}
                  formikProps={formikProps}
                  isManaged={false}
                />
              </Box>

              <Grid container item direction='row' justify='center'>
                <Grid
                  xs={10}
                  md={8}
                  container
                  item
                  direction='row'
                  justifyContent='flex-start'
                >
                  <Box m='1rem 1rem 5rem 0rem'>
                    <Button
                      variant='contained'
                      isLoading={formikProps.isSubmitting}
                      onClick={(e) => formikProps.handleSubmit(e as any)}
                    >
                      Submit
                    </Button>
                  </Box>
                  {!data?.getCampaignById?.creativeBrief?.isSubmitted ? (
                    <Box m='1rem 1rem 1rem 0rem'>
                      <Button
                        variant='outlined'
                        disabled={isSaving}
                        isLoading={isSaving}
                        onClick={() => {
                          const { values } = formikProps;
                          save({
                            variables: {
                              id: campaignId,
                              fieldsToSave: {
                                ...values,
                              },
                            },
                          })
                            .then(() => {
                              setIsSaved(true);
                              enqueueSnackbar('Successfully saved draft.', {
                                variant: 'success',
                              });
                            })
                            .catch((e) => console.log('e : ', e));
                        }}
                      >
                        Save as draft
                      </Button>
                    </Box>
                  ) : (
                    <Box m='1rem 1rem 1rem 0rem'>
                      <Button
                        variant='outlined'
                        isLoading={formikProps.isSubmitting}
                        onClick={(e) => props.onCancel && props.onCancel()}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </>
      )}
    </Formik>
  );
}
