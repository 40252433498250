export const mockSales = [
  {
    label: 'alex.dane@adludio.com',
    value: 'alex.dane@adludio.com',
    key: 'alex.dane@adludio.com',
  },
  {
    label: 'amaury.leconte@adludio.com',
    value: 'amaury.leconte@adludio.com',
    key: 'INTL',
  },
  {
    label: 'anna.gregory@adludio.com',
    value: 'anna.gregory@adludio.com',
    key: 'anna.gregory@adludio.com',
  },
  {
    label: 'andy.munro-lott@adludio.co',
    value: 'andy.munro-lott@adludio.co',
    key: 'andy.munro-lott@adludio.co',
  },
  {
    label: 'anubhav.mathur@adludio.com',
    value: 'anubhav.mathur@adludio.com',
    key: 'anubhav.mathur@adludio.com',
  },
  {
    label: 'ben.peers@adludio.com',
    value: 'ben.peers@adludio.com',
    key: 'ben.peers@adludio.com',
  },
  {
    label: 'benjamin.pavanetto@adludio.com',
    value: 'benjamin.pavanetto@adludio.com',
    key: 'benjamin.pavanetto@adludio.com',
  },
  {
    label: 'bono.chan@adludio.com',
    value: 'bono.chan@adludio.com',
    key: 'bono.chan@adludio.com',
  },
  {
    label: 'clayton.chao@adludio.com',
    value: 'clayton.chao@adludio.com',
    key: 'clayton.chao@adludio.com',
  },
  {
    label: 'dan.graham@adludio.com',
    value: 'dan.graham@adludio.com',
    key: 'dan.graham@adludio.com',
  },
  {
    label: 'david.mozier@adludio.com',
    value: 'david.mozier@adludio.com',
    key: 'david.mozier@adludio.com',
  },
  {
    label: 'jordan.douglas@adludio.com',
    value: 'jordan.douglas@adludio.com',
    key: 'jordan.douglas@adludio.com',
  },
  {
    label: 'kinvara.bentley@adludio.com',
    value: 'kinvara.bentley@adludio.com',
    key: 'kinvara.bentley@adludio.com',
  },
  {
    label: 'luke.hills@adludio.com',
    value: 'luke.hills@adludio.com',
    key: 'luke.hills@adludio.com',
  },
  {
    label: 'laura.holmes@adludio.com',
    value: 'laura.holmes@adludio.com',
    key: 'laura.holmes@adludio.com',
  },
  {
    label: 'margarida.galafassi@adludio.com',
    value: 'margarida.galafassi@adludio.com',
    key: 'margarida.galafassi@adludio.com',
  },
  {
    label: 'nadia.oon@adludio.com',
    value: 'nadia.oon@adludio.com',
    key: 'nadia.oon@adludio.com',
  },
  {
    label: 'octavian.pop@adludio.com',
    value: 'octavian.pop@adludio.com',
    key: 'octavian.pop@adludio.com',
  },
  {
    label: 'talulla.rendall@adludio.com',
    value: 'talulla.rendall@adludio.com',
    key: 'talulla.rendall@adludio.com',
  },
  {
    label: 'tom.skoff@adludio.com',
    value: 'tom.skoff@adludio.com',
    key: 'tom.skoff@adludio.com',
  },
  {
    label: 'will.harwood@adludio.com',
    value: 'will.harwood@adludio.com',
    key: 'will.harwood@adludio.com',
  },
  {
    label: 'other - state in description',
    value: 'other - state in description',
    key: 'other - state in description',
  },
];
