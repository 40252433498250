import React from 'react';
import {
  Box,
  Grid,
  Button,
  TextInput,
  useMediaQuery,
  Subtitle2,
  useTheme,
  makeStyles,
  Link,
} from '@adludio/components';
import { GoogleCard } from './GoogleCard';
import MainCard from './MainCard';
import { login } from '../../util/login';
import isEmailValid from '../../util/isEmailValid';
import { useSnackbar } from 'notistack';
import { initialConfig } from '../..';
import { encodePayload } from '../../util/payloadActions';
import { getEnvPrefix } from '../../util/getEnvPrefix';
import { navigate, useLocation } from '@reach/router';

const apiSSOUrl = `//${getEnvPrefix()}api.sso.adludio.com`;

interface Error {
  status?: number;
  message?: string;
  label?: string;
}

interface StateProps {
  message: string;
  isLoading: boolean;
  error: { [key: string]: Error | null };
  email: string;
  name: string;
  password: string;
  emailIsValid: boolean;
}

const initialState: StateProps = {
  message: '',
  emailIsValid: true,
  isLoading: false,
  error: {},
  email: '',
  name: '',
  password: '',
};

const useStyles = makeStyles(() => ({
  forgotPassword: {
    fontSize: 12,
  },
}));

const Login = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [state, setState] = React.useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  const setStateFromServerResponse = (loginData: any) => {
    setState({ ...state, isLoading: true });
    const serverError = loginData.error;
    const serverSuccess = loginData.data;
    if (serverSuccess) {
      const { accessToken } = serverSuccess;
      window.localStorage.setItem('authToken', accessToken);
      if (location.pathname === '/') {
        navigate('/campaigns')
          .then(() => window.location.reload())
          .catch(console.log);
      } else {
        window.location.reload();
      }
    } else {
      enqueueSnackbar(serverError.message);
    }
    setState({ ...state, isLoading: false });
  };

  async function handleSubmit(
    e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    if (!isEmailValid(state.email)) {
      setState({ ...state, emailIsValid: false, isLoading: false });
    } else {
      setState({ ...state, emailIsValid: true, isLoading: true });
      const { email, password } = state;
      const loginResponse = await login(email, password);
      const loginData = await loginResponse.json();
      setStateFromServerResponse(loginData);
    }
  }

  const responseGoogle = async ({
    email,
    accessToken,
  }: {
    email: string;
    accessToken: string;
  }) => {
    const loginResponse = await fetch(`${apiSSOUrl}/auth/google-user/login`, {
      method: 'POST',
      body: JSON.stringify({
        email: email,
        googleAccessToken: accessToken,
      }),
      headers: {
        'content-type': 'application/json',
      },
    });
    const loginData = await loginResponse.json();
    setStateFromServerResponse(loginData);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const failGoogle = () => {
    enqueueSnackbar('Sign up with google failed - please sign up manually', {
      variant: 'error',
    });
    setState({
      ...state,
      error: {
        google: {
          message: 'Login with google failed - please sign up normally',
        },
      },
    });
  };

  const payload = encodePayload({ url: window.location.href, payload: {} });
  const { email, password } = state;
  const { forgotPassword } = useStyles();
  return (
    <Box width='100%'>
      <Grid container direction='column' justify='center' alignItems='center'>
        <MainCard secondary>
          <Grid
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <Grid item container direction='column' justify='center'>
              <Box py='5%'>
                <Subtitle2>Log into your Adludio Account</Subtitle2>
              </Box>
            </Grid>
            <Grid
              xs={10}
              sm={8}
              md={10}
              container
              item
              direction='column'
              justify='center'
            >
              <Grid item xs>
                <Box pb='15%'>
                  <TextInput
                    label='Email'
                    required
                    variant='outlined'
                    value={email}
                    disabled={state.isLoading}
                    onChange={handleChange}
                    helperText={
                      !state.emailIsValid
                        ? 'Please enter a valid email address'
                        : ''
                    }
                    name='email'
                    error={!state.emailIsValid}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <TextInput
                  disabled={state.isLoading}
                  label='Password'
                  required
                  variant='outlined'
                  password
                  value={password}
                  onChange={handleChange}
                  name='password'
                />
              </Grid>
              <Grid container justify='flex-end'>
                <Box>
                  <Grid item>
                    <Link
                      className={forgotPassword}
                      href={`${initialConfig.ssoFEURL}/forgot-password?payload=${payload}`}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Box>
              </Grid>
              <Grid>
                <Box pt='5%' pb='50%'>
                  <Button
                    fullWidth
                    disabled={!state.email || !state.password}
                    isLoading={state.isLoading}
                    variant='contained'
                    size='large'
                    onClick={handleSubmit}
                  >
                    {state.isLoading ? 'Loading...' : 'Log in'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {!isDesktop && (
            <Box width='100%'>
              <GoogleCard
                onFailure={failGoogle}
                onSuccess={responseGoogle}
                isLogin
              />
            </Box>
          )}
        </MainCard>
        {isDesktop && (
          <>
            <Box padding={1} />
            <GoogleCard
              isLarge
              onFailure={failGoogle}
              onSuccess={responseGoogle}
              isLogin
            />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Login;
