import React, { useState, useContext, useCallback } from 'react';
import { AuthContext } from '@adludio/react-sso';
import { Prediction } from '../types';
import { fetchPrediction } from '../api';

export interface PredictionContextState {
  prediction: Prediction;
  isPredictionLoading: boolean;
  fetchPrediction: (campaignKey: string) => void;
}

export const initialPredictionState: PredictionContextState = {
  prediction: [],
  isPredictionLoading: false,
  fetchPrediction: (campaignKey) => {
    throw Error('fetchForecast not implemented');
  },
};

export const PredictionContext = React.createContext<PredictionContextState>(
  initialPredictionState
);

export const usePrediction = () => useContext(PredictionContext);

export interface PredictionContextProviderProps {
  children: React.ReactNode;
}

export const PredictionContextProvider = ({ children }: PredictionContextProviderProps) => {
  const [prediction, setPrediction] = useState<Prediction>([]);
  const [isFetching, setIsFetching] = useState(true);

  const { auth } = React.useContext(AuthContext);

  const loadPrediction = useCallback(
    (campaignKey: string) => {
      setIsFetching(true);
      setPrediction([]);
      fetchPrediction(auth.authToken, campaignKey)
        .then((result) => {
          setPrediction(result);
          setIsFetching(false);
        })
        .catch((err) => console.log(err));
    },
    [auth.authToken]
  );

  return (
    <PredictionContext.Provider
      value={{
        prediction: prediction,
        isPredictionLoading: isFetching,
        fetchPrediction: loadPrediction,
      }}
    >
      {children}
    </PredictionContext.Provider>
  );
};
