import {
  AdludioTheme,
  Grid,
  makeStyles,
  Divider,
  Card,
  Box,
  Button,
  PreviewProvider,
  useSnackbar,
} from '@adludio/components';
import React, { useState, useMemo, useEffect } from 'react';
import SidePanel, { CreativeUploadError } from './sidePanel';
import { CreativeUploadForm } from '../Forms/Components/CreativeUploadForm';
import { Formik, FormikProps } from 'formik';
import { validateCreativeUpload } from '../Forms/const';

import { Preview } from './Preview';
import {
  useGetCreativeUploadsQuery,
  useSaveCreativeUploadMutation,
  useCreateTtdCreativeMutation,
} from '../../generated/graphql';
import { RouteComponentProps } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import { CreativeUploadValues } from '../Forms/types';
import useGenerateScript from '../../pages/Creative Form/GenerateTagScript/scriptGenerator/useGenerateScript';
import { findOffset } from '../../mock-data/mockTimeZone';
import { getMraidVersion } from '../../pages/Creative Form/GenerateTagScript/scriptGenerator/setters/setEnvironment';

const styles = makeStyles((theme: AdludioTheme) => ({
  root: {
    padding: '3rem 1rem',
  },
  dividerBox: {
    padding: '0 1rem 0 2rem',
  },
  divider: {
    height: '100%',
  },
  formContainer: {
    maxHeight: '100vh',
    overflow: 'scroll',
  },
}));

interface CreativeUploadProps extends RouteComponentProps {
  id?: string;
  campaignId?: string;
  onCancel?: () => void;
  advertiserId?: string | null;
  dsp?: string | null;
  campaignName?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

const CreativeUpload = (props: CreativeUploadProps) => {
  const { root, dividerBox, divider, formContainer } = styles();
  const [preview, setPreview] = useState(false);
  const [advertiserID, setAdvertiserID] = useState(props.advertiserId ?? '');
  const [dsp, setDsp] = useState(props.dsp ?? '');
  const [save] = useSaveCreativeUploadMutation();
  const { enqueueSnackbar } = useSnackbar();
  const campaignId = useMemo(
    () => props.campaignId ?? uuidv4(),
    [props.campaignId]
  );
  const { data } = useGetCreativeUploadsQuery({ variables: { campaignId } });
  const [creativeSelected, setCreativeSelected] = useState(0);
  const [advertiserError, setAdvertiserError] = useState(false);
  const [dspError, setDspError] = useState(false);

  const ref = React.createRef<FormikProps<CreativeUploadValues>>();

  const [forms, setForms] = useState<CreativeUploadValues[]>([]);
  const [currentTag, setCurrentTag] = useState<string>('');
  const [creativeUploadId, setCreativeUploadId] = useState('');

  const [submitCreative] = useCreateTtdCreativeMutation();

  const [isLoading, setLoading] = useState(false);
  const formatData = () => {
    data?.getCreativeUploads?.map((creative, idx) =>
      setForms((prevState) => [
        ...prevState,
        getForm(creative?.creativeUploadId),
      ])
    );
  };

  useEffect(() => {
    data?.getCreativeUploads && formatData();
  }, [data]);

  const getForm = (creative: any) => {
    const form = {
      id: creative.id?.length > 1 ? creative.id : uuidv4(),
      gameKey: creative.gameKey ?? '',
      name: creative.name ?? '',
      enviroment: creative.enviroment ?? '',
      region: creative.region ?? '',
      redirectUrl: creative.redirectUrl ?? '',
      landingPageUrl: creative.landingPageUrl ?? '',
      width: creative.width ?? 320,
      height: creative.height ?? 480,
      startDate: creative.startDate ?? null,
      endDate: creative.endDate ?? null,
      timeZone: creative.timeZone ?? '',
      time: creative.time ?? null,
      adludioIAS: creative.adludioIAS ?? false,
      thirdPartyIAS: creative.thirdPartyIAS ?? false,
      trackingTag: creative.trackingTag ?? '',
      IASImpression: creative.IASImpression ?? '',
      DVImpression: creative.DVImpression ?? '',
      MOATImpression: creative.MOATImpression ?? '',
      otherImpression: creative.otherImpression ?? '',
      thirdPartyEng: creative.thirdPartyEng ?? '',
    };
    return form;
  };

  const copyCurrentTag = () => {
    console.log('currentTag : ', currentTag);
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(currentTag);
    } else {
      document.execCommand('copy', true, currentTag);
    }

    enqueueSnackbar(`Copied tag for ${ref.current?.values.name}`, {
      variant: 'success',
    });
  };

  const combineDateAndTime = function (
    date: Date,
    time: string,
    timeZone: string
  ) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    const [hour, minute] = time.split(':');
    var combined = new Date(
      Date.UTC(year, month, day, parseInt(hour), parseInt(minute))
    );
    var offset = findOffset(timeZone);
    combined.setUTCHours(combined.getUTCHours() - offset);
    return combined;
  };

  const submitCreaitves = async () => {
    var error: CreativeUploadError[] = [];
    setLoading(true);
    for (var form of forms) {
      if (dsp !== 'TTD') {
        continue;
      }
      const { generatedScript } = useGenerateScript(
        form,
        form.gameKey,
        dsp || 'TTD',
        props.campaignName || 'Not Provided'
      );
      const variables = {
        creativeName: form.name,
        description: '',
        adTag: generatedScript,
        advertiserId: advertiserID,
        width: form.width!,
        height: form.height!,
        landingPages: [form.landingPageUrl],
        mraidVersion: getMraidVersion(form.enviroment),
        startTime: form.startDate
          ? combineDateAndTime(
              new Date(form.startDate),
              form.time ?? '00:00',
              form.timeZone
            )
              .toISOString()
              .replace(/[Z]/gm, '')
          : '',
        endTime: form.endDate
          ? combineDateAndTime(
              new Date(form.endDate),
              form.time ?? '00:00',
              form.timeZone
            )
              .toISOString()
              .replace(/[Z]/gm, '')
          : '',
      };
      if (form.trackingTag !== '') {
        try {
          const values = JSON.parse(form.trackingTag);
          const types = Object.keys(values);
          if (types.indexOf('JS') !== -1)
            variables['trackingJS'] = values['JS'];
          if (types.indexOf('1x1') !== -1)
            variables['trackingPixel'] = values['1x1'];
        } catch (e) {
          variables['trackingJS'] = form.trackingTag;
        }
      }
      const response = await submitCreative({
        variables: variables,
      });

      if (!response?.data?.createTTDCreative) {
        error.push({ name: form.name, error: true });
      } else {
        const responseObject = JSON.parse(response.data.createTTDCreative);
        if (responseObject['CreatedAtUTC']) {
          error.push({ name: form.name, error: false });
        } else {
          error.push({ name: form.name, error: true });
        }
      }
    }
    const erroredAdunits = error?.filter((x) => x.error === true);
    const adunitNames = erroredAdunits
      ?.map(({ name }) => name)
      .flat()
      .join(', ');
    if (erroredAdunits?.length) {
      enqueueSnackbar(
        `The following ${adunitNames} ad-units failed to upload`,
        {
          variant: 'error',
        }
      );
    } else {
      enqueueSnackbar('Ad Units Successfully Uploaded to TTD', {
        variant: 'success',
      });
    }
    setLoading(false);
    return true;
  };

  useEffect(() => {
    setCreativeUploadId(
      forms[creativeSelected]?.id.length > 1
        ? forms[creativeSelected]?.id
        : uuidv4()
    );
    forms[creativeSelected] && ref.current?.setValues(forms[creativeSelected]);
  }, [forms, creativeSelected]);
  return (
    <>
      <Grid
        container
        xs={10}
        justifyContent='center'
        direction='row'
        className={root}
      >
        <Grid container item xs={4} direction='column'>
          <SidePanel
            setDsp={setDsp}
            setAdvertiserID={setAdvertiserID}
            dsp={dsp}
            isDraft={false}
            setForms={setForms}
            forms={forms}
            advertiserError={advertiserError}
            dspError={dspError}
            setCreativeSelected={setCreativeSelected}
            creativeSelected={creativeSelected}
            creativeUploads={data}
            getForm={getForm}
            creativeUploadId={creativeUploadId}
            advertiserID={advertiserID}
            copyTag={copyCurrentTag}
            uploadCreatives={submitCreaitves}
            isLoading={isLoading}
          />
        </Grid>
        <Grid container item xs={8} direction='row' justify='flex-end'>
          <Grid item xs className={dividerBox}>
            <Divider className={divider} orientation='vertical' />
          </Grid>
          {forms.length >= 1 ? (
            <Grid item xs={11}>
              <Card elevation={2} className={formContainer}>
                <Formik
                  innerRef={ref}
                  validateOnBlur={false}
                  validateOnChange={false}
                  initialValues={getForm({})}
                  validate={(values) => {
                    setAdvertiserError(advertiserID === '');
                    setDspError(dsp === '');
                    return validateCreativeUpload(
                      values,
                      creativeUploadId,
                      campaignId,
                      dsp,
                      advertiserID
                    );
                  }}
                  onSubmit={async (values) => {
                    try {
                      await save({
                        variables: {
                          id: creativeUploadId,
                          advertiserID: advertiserID,
                          dsp: dsp,
                          campaignId: campaignId,
                          fieldsToSave: {
                            ...values,
                          },
                        },
                      });
                      await enqueueSnackbar(
                        'Successfully saved Creative Upload.',
                        {
                          variant: 'success',
                        }
                      );
                      const updatedForms = [...forms];
                      updatedForms[creativeSelected] = values;
                      setForms(updatedForms);
                    } catch (e) {
                      enqueueSnackbar(`Error: ${e}`, { variant: 'error' });
                    }
                  }}
                >
                  {(formikProps) => (
                    <>
                      {preview ? (
                        <PreviewProvider>
                          <Preview
                            close={() => setPreview(false)}
                            formikProps={formikProps}
                          />
                        </PreviewProvider>
                      ) : (
                        <Box p='4rem' ml='1rem'>
                          <Grid>
                            <Box mb='2rem'>
                              <CreativeUploadForm
                                setCurrentTag={setCurrentTag}
                                formikProps={formikProps}
                                dsp={dsp}
                                campaignName={props.campaignName}
                                preview={() => setPreview(true)}
                              />
                            </Box>
                            <Button
                              type='submit'
                              variant='contained'
                              onClick={(e) => {
                                formikProps.handleSubmit(e as any);
                              }}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Box>
                      )}
                    </>
                  )}
                </Formik>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default CreativeUpload;
