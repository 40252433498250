/* eslint react/prop-types: 0 */
import * as React from 'react';
import {
  Body2,
  Box,
  Button,
  CheckboxButton,
  Grid,
  Subtitle2,
  makeStyles,
  AdludioTheme,
  Divider,
  Tooltip,
} from '@adludio/components';
import EmailIcon from '@material-ui/icons/Email';
import StarIcon from '@material-ui/icons/Stars';

const styles = makeStyles((theme: AdludioTheme) => ({
  emailsGrid: {
    width: '100%',
    paddingTop: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  starIcon: {
    fontSize: '1.8rem',
    color: theme.palette.grey[400],
  },
  checkbox: {
    padding: '0',
  },
  emailText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '14rem',
  },
}));

export interface NoUsersOnCompanyWarningProps {
  emails: string[];
  submitCallback: (data: { emails: string[] }) => Promise<any>;
}

export const NoUsersOnCompanyWarning: React.FC<
  NoUsersOnCompanyWarningProps
> = ({ emails, submitCallback }) => {
  const [selectedEmails, setSelectedEmails] = React.useState<string[]>([]);

  const submitEmails = async () => {
    submitCallback({ emails: selectedEmails }).catch(console.error);
  };

  const handleSelection = (event: any, email: string, i: number) => {
    if (event.target.value) {
      setSelectedEmails([...selectedEmails, email]);
      return;
    }
    setSelectedEmails(selectedEmails.splice(i, 1));
  };
  const { emailsGrid, starIcon, checkbox, emailText } = styles();

  return (
    <Grid justifyContent='center' container>
      <Grid>
        <Box pb='0.5rem'>
          <Subtitle2>This person(s) is not in your organisation</Subtitle2>
        </Box>
        <Body2>· Check the tick box to invite to organisation</Body2>
        <Body2>· Leave unchecked to only invite to campaign</Body2>
        <Box pt='1rem'>
          <Divider variant='fullWidth' />
        </Box>
      </Grid>
      <Box pt='2rem' width='100%'>
        {emails.map((email: string, i: number) => (
          <Grid
            key={email}
            item
            container
            direction='row'
            className={emailsGrid}
          >
            <Grid item container xs={2} justifyContent='center'>
              <StarIcon className={starIcon} />
            </Grid>
            <Grid item xs={9} justifyContent='flex-start' alignItems='center'>
              <Tooltip title={email} placement='top'>
                <div>
                  <Body2 className={emailText}>{email}</Body2>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={1}>
              <CheckboxButton
                data-testId={`invite-select-${email}`}
                key={email}
                onClick={(event: any) => handleSelection(event, email, i)}
                value={email}
                className={checkbox}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
      <Box pt='2rem'>
        <Grid item xs>
          <Button
            data-testId={'submit-emails-with-company-invite'}
            variant='contained'
            endIcon={<EmailIcon />}
            onClick={submitEmails}
          >
            Send Invite
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};
