import { Grid, Box, List, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { TabHeader } from '../ui/tabHeader';
import { responsiveStyle } from '../../utils/Styles';

import BarGraphSVG from '../../assets/BarGraph.svg';

export interface PredictionProps {
  dates: { min: string; max: string };
}
const PredictionBody = ({ dates }:PredictionProps) => {
  const classes = responsiveStyle();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <TabHeader dates={dates} header={'Performance Prediction Dashboard'} />
          </List>
        </Grid>
        <Grid xs={12} style={{ textAlign: 'center' }}>
          <Paper elevation={0} className={classes.paper}>
            <Box sx={{ p: 3 }}>
              <Typography className={classes.listItemText}>
                Chart not available
              </Typography>
              <Box sx={{ p: 1 }} />
              <Typography>Not enough data to render chart</Typography>
              <img src={BarGraphSVG} />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
export default PredictionBody;
