export const getEnvPrefix = () => {
  const env = process.env.REACT_APP_BUILD_ENV ?? 'development';
  let envPrefix = 'dev.';
  if (env === 'production') {
    envPrefix = '';
  } else if (env === 'staging' || env === 'testing') {
    envPrefix = `${env}.`;
  }
  return envPrefix;
};
