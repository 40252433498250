import React, { useState } from 'react';
import { initialConfig } from '../../..';
import { CookieConsentDialog } from './CookieConsentDialog';
import { useFetchConsent } from './useFetchConsent';
import { useInjectConsentRequiredScripts } from './useInjectConsentRequiredScripts';

export const Consent = () => {
  const [cookieConsentOpen, setCookieConsentOpen] = useState(false);
  useFetchConsent({
    openConsent: (open: boolean) => setCookieConsentOpen(open),
  });

  const idEnv = {
    production: '2930732',
    staging: '3145682',
    testing: '3145679',
    development: '3145684',
  };

  useInjectConsentRequiredScripts(
    `https://static.hotjar.com/c/hotjar-${idEnv[initialConfig.env]}.js?sv=6`,
    'performance',
    'hotjar',
    () => {
      (window as any)._hjSettings = {
        hjid: parseInt(idEnv[initialConfig.env]),
        hjsv: 6,
      };
      (window as any).hj =
        (window as any).hj ||
        function () {
          ((window as any).hj.q = (window as any).hj.q || []).push(arguments);
        };
    }
  );

  return (
    <CookieConsentDialog
      dialogOpen={cookieConsentOpen}
      closeDialog={() => setCookieConsentOpen(false)}
    />
  );
};
