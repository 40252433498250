import {
  AdludioTheme,
  Grid,
  makeStyles,
  Card,
  Body1,
  Box,
} from '@adludio/components';
import React, { Dispatch, SetStateAction } from 'react';
import FileCopyRounded from '@material-ui/icons/FileCopyRounded';
import { CreativeUploadValues } from '../Forms/types';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { v4 as uuidv4 } from 'uuid';
import { DeleteAdUnitModal } from '../Forms/Components/DeleteAdUnit';

const styles = makeStyles((theme: AdludioTheme) => ({
  numberBox: {
    paddingLeft: '1rem',
    cursor: 'pointer',
    flexWrap: 'nowrap',
  },
  title: {
    overflowWrap: 'anywhere',
  },
  number: {
    backgroundColor: theme.palette.secondary.contrastText,
    padding: '0 0.6rem 0 0.6rem',
    justify: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    height: 'fit-content',
    marginRight: '0.5rem',
    borderRadius: 50,
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.grey[600],
    cursor: 'pointer',
  },
}));

const DraftCard = (props: {
  name: string;
  idx: number;
  creativeUploadId: string;
  forms: CreativeUploadValues[];
  getForm: (creative: any) => CreativeUploadValues;
  setForms: Dispatch<SetStateAction<CreativeUploadValues[]>>;
  setCreativeSelected: Dispatch<SetStateAction<number>>;
  creativeSelected: boolean;
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { icon, numberBox, number, title } = styles();
  const copyForm = () => {
    props.setCreativeSelected(props.forms.length);
    return props.getForm({
      id: uuidv4(),
      name: props.name + ' ' + 'copy ',
      enviroment: props.forms[props.idx]?.enviroment ?? '',
      region: props.forms[props.idx]?.region ?? '',
      startDate: props.forms[props.idx]?.startDate ?? '',
      endDate: props.forms[props.idx]?.endDate ?? '',
      timeZone: props.forms[props.idx]?.timeZone ?? '',
      time: props.forms[props.idx]?.time ?? '',
      width: props.forms[props.idx]?.width ?? '',
      height: props.forms[props.idx]?.height ?? '',
    });
  };
  const handleCloseDelete = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box mb='0.5rem'>
        <Grid item xs>
          <Card
            variant={props.creativeSelected ? 'elevation' : 'outlined'}
            elevation={2}
          >
            <Box p='0.5rem'>
              <Grid
                container
                item
                xs
                direction='row'
                alignItems='center'
                justify='space-between'
                wrap='nowrap'
              >
                <Grid
                  container
                  item
                  xs
                  alignItems='center'
                  className={numberBox}
                  onClick={() => {
                    props.setCreativeSelected(props.idx);
                  }}
                >
                  <Body1 className={number}>{props.idx + 1}</Body1>
                  <Body1 className={title}>{props.name}</Body1>
                </Grid>
                {props.creativeUploadId.length > 2 && (
                  <Box mx='0.5rem'>
                    <DeleteRounded
                      color='error'
                      onClick={() => setIsOpen(true)}
                      className={icon}
                    ></DeleteRounded>
                  </Box>
                )}
                <DeleteAdUnitModal
                  isOpen={isOpen}
                  onClose={handleCloseDelete}
                  setForms={props.setForms}
                  idx={props.idx}
                  forms={props.forms}
                  setCreativeSelected={props.setCreativeSelected}
                  creativeUploadId={props.creativeUploadId}
                />
                <FileCopyRounded
                  className={icon}
                  onClick={() => {
                    props.setForms((prevState) => [...prevState, copyForm()]);
                    props.setCreativeSelected(props.forms.length);
                  }}
                />
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Box>
    </>
  );
};

export default DraftCard;
