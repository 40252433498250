import {
  Body2,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Subtitle1,
} from '@adludio/components';
import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import {
  FileType,
  Operation,
  useDeleteFileLazyQuery,
  useGetMagicLinkLazyQuery,
  useSaveCampaignBriefMutation,
  useSaveMediaCampaignBriefMutation,
} from '../../../generated/graphql';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import DeleteIcon from '@material-ui/icons/Delete';
import { SiteListStyles as styles } from '../styles';
import { FormikProps } from 'formik';
import { ManagedFormValues, ProgrammaticFormValues } from '../types';
import { useUpload } from '../../../helpers/useUpload';

interface SiteListProps {
  sitelistUrl?: string;
  formikProps:
    | FormikProps<ProgrammaticFormValues>
    | FormikProps<ManagedFormValues>;
  campaignId: string;
  isManaged: boolean;
  error?: string;
}

export const SiteListUpload: React.FC<SiteListProps> = ({
  formikProps,
  sitelistUrl,
  isManaged,
  campaignId,
}) => {
  const { setFieldValue } = formikProps;
  const { purple, siteList, siteListGrid, siteListTitle, tooltip } = styles();
  const [deleteFile] = useDeleteFileLazyQuery();
  const [links, setLinks] = useState<string[]>([]);
  const [file, setFile] = useState<File>();
  const [saveMedia] = useSaveMediaCampaignBriefMutation();
  const [saveProgrammatic] = useSaveCampaignBriefMutation();

  const [_, { refetch: genDownloadLink }] = useGetMagicLinkLazyQuery({
    fetchPolicy: 'network-only',
  });
  const { uploadDocument, isUploading } = useUpload({
    reFetch: () => console.log('refetch'),
    cancelToken: '' as any,
  });

  useEffect(() => {
    if (sitelistUrl) {
      const promises = sitelistUrl.split(',').map((sitelist) =>
        genDownloadLink({
          campaignId,
          fileId: sitelist.trim(),
          type: FileType.SiteList,
          operation: Operation.Get,
        })
      );
      Promise.all(promises).then((results) =>
        setLinks(results.map((res) => res.data?.getMagicLink ?? ''))
      );
    }
  }, [sitelistUrl]);

  React.useEffect(() => {
    if (!file || isUploading) return;
    uploadDocument(file, campaignId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  React.useEffect(() => {
    if (file && !isUploading) {
      const sitesUrl = sitelistUrl
        ? sitelistUrl.concat(`, ${file!.name}`)
        : file!.name;
      setFieldValue('sitelistUrl', sitesUrl);
      if (isManaged) {
        saveMedia({
          variables: {
            id: campaignId,
            fieldsToSave: {
              ...formikProps.values,
              sitelistUrl: sitesUrl,
            },
          },
        });
      } else {
        saveProgrammatic({
          variables: {
            id: campaignId,
            fieldsToSave: {
              ...formikProps.values,
              sitelistUrl: sitesUrl,
            },
          },
        });
      }
    }
  }, [isUploading]);

  const handleFiles = async (fileSelected: File) => {
    if (!fileSelected) return;
    if (isManaged && formikProps) {
      await saveMedia({
        variables: {
          id: campaignId,
          fieldsToSave: {
            ...formikProps.values,
          },
        },
      });
    }
    setFile(fileSelected);
  };

  return (
    <Grid container item direction='column'>
      <Grid container item xs={12}>
        <Subtitle1 className={purple}>Site List</Subtitle1>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Button
            variant='outlined'
            className={siteList}
            color='default'
            style={{
              background: isUploading ? '#307F1C' : 'inherit',
              color: isUploading ? 'white' : '#307F1C',
            }}
            component='label'
            isLoading={isUploading}
          >
            Upload site list
            <Box ml='0.5rem' mt='0.3rem'>
              {!isUploading && <PresentToAllIcon />}
            </Box>
            <input
              type='file'
              hidden
              onChange={(e) => {
                handleFiles(e.target.files?.[0] as File);
              }}
            />
          </Button>
        </Grid>
        <Grid className={siteListGrid}>
          {sitelistUrl?.split(',').map((sitelist, idx) => (
            <Grid
              container
              xs={12}
              key={sitelist}
              direction='row'
              alignItems='center'
            >
              <Tooltip title={sitelist} classes={{ tooltip: tooltip }}>
                <Grid xs={6} md={9}>
                  <Link href={links[idx]} target='_blank'>
                    <Body2 className={siteListTitle}>{sitelist}</Body2>
                  </Link>
                </Grid>
              </Tooltip>
              {sitelist && (
                <IconButton
                  size='small'
                  label='delete'
                  onClick={() => {
                    deleteFile({
                      variables: {
                        fileName: sitelist,
                        type: FileType.SiteList,
                        campaignId: campaignId!,
                      },
                    });
                    const newSiteList = sitelistUrl!
                      .split(',')
                      .filter((sitelistURL) => sitelistURL !== sitelist);
                    setFieldValue(
                      'sitelistUrl',
                      newSiteList.length === 1
                        ? newSiteList[0]
                        : (newSiteList.join() as string)
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
