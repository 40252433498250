import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { formatAxis } from '../../utils/ChartAxis';
import { formatDateString } from '../../utils/date';
import GetAppIcon from '@material-ui/icons/GetApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import getIcons from './chartIcons';
import { responsiveStyle } from '../../utils/Styles';
export interface DataProps {
  dates: string[];
  series: { name: string; data: number[] }[];
  campaignName: string;
  breakdown: string;
  kpi: string;
}

const DailyBreakdownPlot = ({
  dates,
  series,
  campaignName,
  breakdown,
  kpi,
}: DataProps) => {
  // go through all object and round up all values of `data` to 1st decimal place
  series = series.map((s) => ({
    ...s,
    data: s.data.map((d) => Math.round(d * 10) / 10),
  }));

  const downloadFilename = `${campaignName}_${formatDateString(
    new Date()
  )}_${breakdown}_daily_${kpi}`;
  const classes = responsiveStyle();
  const options: ApexOptions = {
    series: series,
    chart: {
      height: 350,
      stacked: false,
      type: 'line',
      toolbar: {
        show: true,
        tools: {
          download: getIcons(GetAppIcon, 'Download Chart'),
          zoomin: getIcons(ZoomInIcon, 'Zoomin'),
          zoomout: getIcons(ZoomOutIcon, 'Zoomout'),
          pan: false,
          zoom: getIcons(SelectAllIcon, 'Selection view'),
          reset: getIcons(SettingsBackupRestoreIcon, 'Reset Original view'),
        },
        export: {
          png: {
            filename: downloadFilename,
          },
          svg: {
            filename: downloadFilename,
          },
          csv: {
            filename: downloadFilename,
          },
        },
      },
    },
    colors: ['#4E55C3', '#7DC3AA', '#F7C1A1', '#58A0DA', '#495373', '#CDC7E5'],
    dataLabels: {
      enabled: false,
    },
    stroke: { width: 3, curve: 'smooth' },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    markers: { size: 2, colors: ['#2B3243'] },
    yaxis: {
      decimalsInFloat: 0,
      title: {
        text: kpi,
      },
      labels: {
        formatter: (val: number): string => formatAxis(kpi, val),
      },
    },
    xaxis: {
      categories: dates,
      type: 'datetime',
    },
    title: {
      text: `${breakdown} ${kpi} Trends `,
      style: { fontSize: '14px' },
      align: 'left',
    },
  };

  return (
    <div className={classes.chartsPadding}>
      <ReactApexChart options={options} series={options.series} height={400} />
    </div>
  );
};

export default DailyBreakdownPlot;
