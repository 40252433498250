export const mockKPIs = [
  { label: 'VTR', value: 'VTR', key: 'VTR' },
  { label: 'CTR', value: 'CTR', key: 'CTR' },
  { label: 'Completion Rate', value: 'Completion Rate', key: 'CompletionRate' },
  { label: 'Conversion Rate', value: 'Conversion Rate', key: 'ConversionRate' },
  { label: 'Engagement Rate', value: 'Engagement Rate', key: 'EngagementRate' },
  { label: 'Viewability', value: 'Viewability', key: 'Viewability' },
  { label: 'CPA', value: 'CPA', key: 'CPA' },
  { label: 'CPL', value: 'CPL', key: 'CPL' },
  { label: 'Reach', value: 'Reach', key: 'Reach' },
];
