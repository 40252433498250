import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AdludioTheme,
  Adunit,
  Box,
  Button,
  Grid,
  makeStyles,
  PreviewContext,
} from '@adludio/components';
import { FormikProps } from 'formik';
import { CreativeUploadValues } from '../Forms/types';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

type region =
  | ''
  | 'eu-west-1'
  | 'eu-east-1'
  | 'us-west-1'
  | 'us-east-1'
  | 'ap-southeast-1'
  | 'ap-east-1'
  | undefined;
const styles = makeStyles((theme: AdludioTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    verticalAlign: 'center',
    alignItems: 'center',
    width: '100%',
    alignSelf: 'center',
    height: '100%',
  },
  preview: {
    margin: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  button: {
    fontSize: '0.8rem',
  },
}));
export const Preview = ({
  formikProps,
  close,
}: {
  close: () => void;
  formikProps: FormikProps<CreativeUploadValues>;
}) => {
  const [gameId, version] = formikProps.values.gameKey.split('/');
  const style = styles();
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const div = useRef<HTMLDivElement>(null);
  const context = useContext(PreviewContext);
  const handleResize = () => {
    const aspectRatio = calculateAspectRatio(
      `${formikProps.values.width}`,
      `${formikProps.values.height}`
    );
    // eslint-disable-next-line no-undefined
    if (div.current !== null && div.current !== undefined) {
      setHeight(div.current.getBoundingClientRect().width / aspectRatio);
      setWidth(div.current.getBoundingClientRect().width);
    }
  };

  const calculateAspectRatio = (
    setHeight: string | null,
    setWidth: string | null
  ) => {
    return setWidth && setHeight ? parseInt(setWidth) / parseInt(setHeight) : 1;
  };

  const handleChange = () => {
    handleResize();
    context.toggleReload();
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleChange);
    return () => window.removeEventListener('resize', handleChange);
  });
  return (
    <Box p='1rem'>
      <Button color='default' onClick={() => close()} className={style.button}>
        <ArrowBackIosIcon fontSize='inherit' />
        Back to Ad Unit set up
      </Button>
      <div ref={div} className={style.container}>
        <Grid
          container
          item
          xs={10}
          justifyContent='center'
          alignContent='center'
        >
          {width ? (
            <div
              style={{ height: `${height}px`, width: `${width}px` }}
              className={style.preview}
            >
              <Adunit
                gameId={gameId}
                version={version}
                region={formikProps.values.region.split(' ')[0] as region}
              />
            </div>
          ) : (
            <Adunit
              gameId={gameId}
              version={version}
              region={formikProps.values.region as region}
            />
          )}
        </Grid>
      </div>
    </Box>
  );
};
