import React from 'react';
import ReactWordcloud, { CallbacksProp, OptionsProp } from 'react-wordcloud';
import { responsiveStyle } from '../../utils/Styles';

interface WordsProps {
    words: { text: string, value: number }[]
}
function getWordColor(value: number) {
  if (value >= 50) {
    return '#4E55C3';
  } else if (value >= 30) {
    return '#545cc4';
  } else if (value >= 10) {
    return '#676ecb';
  } else {
    return '#7a80d1';
  }
}
const WordCloud = ({ words }: WordsProps) => {
  words.sort((prev, curr) => curr.value - prev.value);
  words = words.slice(0, 40);
  const options: OptionsProp = {
    enableTooltip: false,
    deterministic: false,
    fontFamily: 'impact',
    fontSizes: [15, 40],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 0,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 0
  };

  const callbacks:CallbacksProp = {
    getWordColor: (word: { value: number; }) => getWordColor(word.value),
  };
  const classes = responsiveStyle();

  return (
    <div className={classes.wordCloud}>
      <ReactWordcloud callbacks={callbacks} options={options} words={words} />
    </div>);
};
export default WordCloud;
