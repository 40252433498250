import React from 'react';
import { AdludioTheme, Grid, Caption, makeStyles } from '@adludio/components';

const useStyles = makeStyles((theme: AdludioTheme) => ({
  iconNumber: {
    color: theme.palette.primary.dark,
    border: '1px solid #757575',
    padding: '1rem',
    marginLeft: '0.5rem',
    borderRadius: '50%',
    maxHeight: '1rem',
    maxWidth: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
}));

export const IconResult = (props: { value: number }) => {
  const { iconNumber } = useStyles();

  return (
    <>
      {props.value < 1 ? (
        <></>
      ) : (
        <Grid container className={iconNumber}>
          <Caption>{props.value}</Caption>
        </Grid>
      )}
    </>
  );
};
