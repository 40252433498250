import { useGetManagedServiceSignedUrlLazyQuery } from '../generated/graphql';
import axios from 'axios';

import React from 'react';
import { useSnackbar } from '@adludio/components';

export function useUploadManaged() {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const [getMagicLink, { data: linkData }] =
    useGetManagedServiceSignedUrlLazyQuery({
      fetchPolicy: 'network-only',
    });

  const { enqueueSnackbar } = useSnackbar();
  const uploadedUrl = linkData?.getManagedServiceSignedUrl;
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<boolean | null>(null);
  const uploadDocument = (e: File, id: string) => {
    setSelectedFile(e);
    setStatus(null);
    getMagicLink({
      variables: {
        fileId: e.name,
        id,
      },
    })
      .then((data) => {
        if (data.error) {
          enqueueSnackbar(
            "Error occurred couldn't upload file, please try again.",
            { variant: 'error' }
          );
        }
      })
      .catch(console.log);
  };

  React.useEffect(() => {
    if (selectedFile) {
      setIsUploading(true);
      if (linkData) {
        axios
          .put(linkData.getManagedServiceSignedUrl!, selectedFile, {
            maxBodyLength: Infinity,
          })
          .then(() => {
            setStatus(true);
            setIsUploading(false);
          })
          .catch((error: any) => {
            enqueueSnackbar(
              "Error occurred couldn't upload file, please try again.",
              { variant: 'error' }
            );
            console.log(error);
            setStatus(false);
            setIsUploading(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkData]);

  return {
    uploadDocument,
    isUploading,
    status,
    uploadedUrl,
  };
}
