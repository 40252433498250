import { List, Record } from 'immutable';
import generateScript, { TagState } from '.';
import { CreativeUploadValues } from '../../../../components/Forms/types';
import { EnvironmentTypes } from './store/static/envChoices';

const useGenerateScript = (
  creativeValues: CreativeUploadValues,
  gameKey: string,
  dsp: string,
  campaignName: string
) => {
  const ListimpressionLevelImagePixelUrls = [
    creativeValues.IASImpression && creativeValues.IASImpression,
    creativeValues.DVImpression && creativeValues.DVImpression,
    creativeValues.MOATImpression && creativeValues.MOATImpression,
    creativeValues.otherImpression && creativeValues.otherImpression,
  ].filter((val) => val !== '');

  // Creating variables with Inmutable library, else it won't work
  const thirdPartyList = List([
    creativeValues.thirdPartyEng ? creativeValues.thirdPartyEng : '',
  ]);
  const mainCampaignGameKeys: List<string> = List(gameKey);
  const impressionLevelJsUrlsList = [];
  if (creativeValues.trackingTag !== '' && dsp !== 'TTD') {
    try {
      const values = JSON.parse(creativeValues.trackingTag);
      const types = Object.keys(values);
      if (types.indexOf('JS') !== -1)
        impressionLevelJsUrlsList.push(values['JS']);
      if (types.indexOf('1x1') !== -1)
        ListimpressionLevelImagePixelUrls.push(values['1x1']);
    } catch (e) {
      impressionLevelJsUrlsList.push(creativeValues.trackingTag);
    }
  }
  const impressionLevelImagePixelUrls: List<string> = List(
    ListimpressionLevelImagePixelUrls
  );
  const impressionLevelJsUrls: List<string> = List(impressionLevelJsUrlsList);
  const tagState = Record<TagState>({
    dimensions: `${creativeValues.width}x${creativeValues.height}` as any,
    dsp: dsp as any,
    adludio_brief_ref: campaignName,
    thirdParty: thirdPartyList,
    environment: creativeValues.enviroment as EnvironmentTypes,
    gameKey: creativeValues.gameKey,
    ias: creativeValues.adludioIAS,
    impressionLevelImagePixelUrls: impressionLevelImagePixelUrls,
    impressionLevelJsUrls: impressionLevelJsUrls,
    mainCampaignGameKeys: mainCampaignGameKeys,
    redirect: creativeValues.redirectUrl,
    region: creativeValues.region as any,
    serviceLevel: 'managed',
    unitType: 'display',
  });
  const dataRecord = new tagState();
  const generatedScript = generateScript(dataRecord);
  return { generatedScript };
};

export default useGenerateScript;
