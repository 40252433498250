import React from 'react';
import { Link } from '@reach/router';
import adludioLogo from '../../shared/img/adludio_logo.svg';

const AdludioLogo = () => {
  return (
    <Link to='/campaigns'>
      <img
        className='adludioLogo'
        src={adludioLogo}
        alt='adludio-logo'
      />
    </Link>
  );
};

export default AdludioLogo;
