export const mockObjectives = [
  { label: 'Brand Awareness', value: 'Brand Awareness', key: 'Brand Awareness' },
  { label: 'Product Awareness', value: 'Product Awareness', key: 'Product Awareness' },
  { label: 'Message Recall', value: 'Message Recall', key: 'Message Recall' },
  { label: 'Sentiment / Favourability', value: 'Sentiment / Favourability', key: 'Sentiment / Favourability' },
  { label: 'Consideration / Purchase Intent', value: 'Consideration / Purchase Intent', key: 'Consideration / Purchase Intent' },
  { label: 'Drive Traffic (to site)', value: 'Drive Traffic (to site)', key: 'Drive Traffic (to site)' },
  { label: 'Audience Engagement', value: 'Audience Engagement', key: 'Audience Engagement' },
  { label: 'Reach', value: 'Reach', key: 'Reach' },
  { label: 'Video Views', value: 'Video Views', key: 'Video Views' },
  { label: 'Conversion (sales, downloads)', value: 'Conversion (sales, downloads)', key: 'Conversion (sales, downloads)' }
];
