import {
  AdludioTheme,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Caption,
  Body2,
} from '@adludio/components';
import { FileIcon, defaultStyles } from 'react-file-icon';

import CloseIcon from '@material-ui/icons/Close';
import Icon from '@material-ui/core/Icon';
import React from 'react';

interface UploadStatusProps {
  files: File[];
  status: boolean[];
  progress: number[];
  currentProgress: number;
  currentIndex: number;
  removeUpload: (index: number) => void;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  filesUpload: {
    marginTop: '1em',
    maxHeight: '120px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#e6e6e5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#c5c4c4',
    },
  },
  waiting: {
    color: theme.palette.warning.dark,
  },
  success: {
    color: theme.palette.success.dark,
  },
  failed: {
    color: theme.palette.error.dark,
  },
  text: {
    overflowWrap: 'anywhere',
  },
  icon: {
    width: '1.5rem',
    height: '2rem',
    paddingRight: '0.8rem',
  },
  remove: {
    fontSize: theme.typography.body1.fontSize,
  },
  rows: {
    maxHeight: '60px',
  },
}));

export const UploadStatus = ({
  files,
  status,
  progress,
  currentProgress,
  currentIndex,
  removeUpload,
}: UploadStatusProps) => {
  const { filesUpload, waiting, success, failed, text, icon, remove, rows } =
    useStyles();
  const UploadStatusIcon = ({
    uploadStatus,
  }: {
    uploadStatus: boolean | null;
  }) => {
    // eslint-disable-next-line eqeqeq
    if (uploadStatus == null) {
      return <Icon className={waiting}>hourglass_empty</Icon>;
    }
    return (
      <Icon className={uploadStatus ? success : failed}>
        {uploadStatus ? 'check_circle' : 'error'}
      </Icon>
    );
  };
  const StatusFileIcon = ({ fileName }: { fileName: string }): JSX.Element => {
    const fileExt = fileName.split('.').pop();
    if (!fileExt) {
      return <></>;
    }
    return <FileIcon extension={fileExt} {...defaultStyles[fileExt]} />;
  };
  const getProgressSize = (size: number, uploadProgress: number): string => {
    const fileSizeInKB = size / 1024;
    const fileSizeInMB = fileSizeInKB / 1024;

    function generateProgressMsg(
      fileSize: number,
      measurementUnit: string
    ): string {
      const progressSize = (fileSize * (uploadProgress / 100)).toFixed(2);
      const totalSize = fileSize.toFixed(2);
      return `${progressSize}${measurementUnit} of ${totalSize}${measurementUnit}`;
    }

    return fileSizeInMB < 0.09
      ? generateProgressMsg(fileSizeInKB, 'KB')
      : generateProgressMsg(fileSizeInMB, 'MB');
  };

  return (
    <Grid container direction='row' spacing={2} className={filesUpload}>
      {files.map((file, index) => {
        const elKey = `${file.name}-${index}`;
        return status[index] || progress[index] === 100 ? (
          <></>
        ) : (
          <Grid
            key={elKey}
            item
            container
            xs={12}
            className={rows}
            direction='row'
            alignItems='center'
            wrap='nowrap'
          >
            <div className={icon}>
              <StatusFileIcon fileName={file.name} />
            </div>
            <Grid
              key={elKey}
              item
              container
              xs={11}
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Body2 className={text}>{file.name}</Body2>
              {status[index] !== false ? (
                <>
                  <Grid item container xs={12}>
                    <Box sx={{ width: '100%', mr: 1, mt: 0.3 }}>
                      <LinearProgress
                        variant='determinate'
                        value={progress[index]}
                      />
                    </Box>
                  </Grid>
                  {index === 0 && !progress[index] ? (
                    <Grid item container justifyContent='flex-end' xs={12}>
                      <Caption align='right' color='primary'>
                        Initializing upload...
                      </Caption>
                    </Grid>
                  ) : (
                    <Grid item container justifyContent='space-between' xs={12}>
                      <Caption align='right' color='primary'>
                        {getProgressSize(file.size, progress[index])}
                      </Caption>
                      <Caption align='right' color='primary'>
                        {`Uploading...${Math.round(progress[index])}%`}
                      </Caption>
                    </Grid>
                  )}
                </>
              ) : (
                <UploadStatusIcon uploadStatus={status[index]} />
              )}
            </Grid>
            <IconButton
              label='Remove'
              className={remove}
              aria-label='remove'
              onClick={() => removeUpload(index)}
            >
              <CloseIcon fontSize='inherit' color='primary' />
            </IconButton>
          </Grid>
        );
      })}
    </Grid>
  );
};
