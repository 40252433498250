import React, { useState } from 'react';
import { SingleSiteList, Site } from '../../types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import getKpiName, { getFullNotation } from '../../utils/setKpiCols';

import BootstrapTooltip from '../ui/bootstrapTooltip';
import ClickableBarCharts from '../charts/clickableBarChart';
import DonutPlot from '../charts/donutProportionPlot';
import { Grid } from '@adludio/components';
import { Paper, withStyles } from '@material-ui/core';
import WorstSiteReport from './worstPerformance';
import { responsiveStyle } from '../../utils/Styles';

const StyledToggleButton = withStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    '&$selected': {
      backgroundColor: '#4E55C3',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#4E55C3',
        color: '#ffffff',
      },
    },
  },
  selected: {},
})(ToggleButton);

interface SiteOverviewProps {
  campaignName: string;
  topSiteList: SingleSiteList;
  bottomSiteList: SingleSiteList;
  kpiCols: string[];
  dates: { min: string; max: string };
}
const OverviewTab = ({
  campaignName,
  topSiteList,
  bottomSiteList,
  kpiCols,
  dates,
}: SiteOverviewProps) => {
  const [selectedTopSiteList, setSelectedTopSiteList] = useState<Site[]>(
    topSiteList['er']
  );

  const [selectedBottomSiteList, setSelectedBottomSiteList] = useState<Site[]>(
    bottomSiteList['er']
  );

  const [kpi, setKpi] = useState<string>('er');

  const handleKpiChange = (
    event: React.MouseEvent<HTMLElement>,
    newKpi: string | null
  ) => {
    if (newKpi === null) {
      return;
    }

    setKpi(newKpi);
    setSelectedTopSiteList(topSiteList[newKpi]);
    setSelectedBottomSiteList(bottomSiteList[newKpi]);
  };

  const kpilist = [
    {
      value: 'er',
      label: 'ER',
    },
    {
      value: 'ctr',
      label: 'CTR',
    },
    {
      value: 'ectr',
      label: 'eCTR',
    },
  ];
  const kpiName = getKpiName(kpi, kpilist);
  const classes = responsiveStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <ToggleButtonGroup
          color='primary'
          value={kpi}
          exclusive
          onChange={handleKpiChange}
        >
          {kpilist.map((element) => (
            <StyledToggleButton
              key={element.value}
              value={element.value}
              size='small'
              style={{ textTransform: 'none' }}
              color='primary'
            >
              <BootstrapTooltip
                title={getFullNotation(element.value)}
                placement='bottom'
                key={element.label}
              >
                <span>{element.label}</span>
              </BootstrapTooltip>
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Paper className={classes.spacedGrid} elevation={2}>
          <ClickableBarCharts
            kpi={kpi}
            kpiLabel={kpiName}
            campaignName={campaignName}
            siteList={selectedTopSiteList}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Paper
          className={`${classes.spacedGrid} ${classes.sharePie}`}
          elevation={2}
        >
          <DonutPlot topN={5} kpi={kpiName} siteList={selectedTopSiteList} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Paper className={classes.paper} elevation={2}>
          <WorstSiteReport
            selectedBottomSiteList={selectedBottomSiteList}
            campaignName={campaignName}
            kpi={kpiName}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default OverviewTab;
