import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Adunit = {
  __typename?: 'Adunit';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  client?: Maybe<Company>;
  campaign?: Maybe<Campaign>;
  adunitIterations?: Maybe<Array<Maybe<AdunitIteration>>>;
  approvedAdunitIterations?: Maybe<Array<Maybe<AdunitIteration>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type AdunitIteration = {
  __typename?: 'AdunitIteration';
  id?: Maybe<Scalars['ID']>;
  approved?: Maybe<Scalars['Boolean']>;
  creator?: Maybe<User>;
  adunit?: Maybe<Adunit>;
  creativeChanges?: Maybe<CreativeChanges>;
  targetingChanges?: Maybe<TargetingChanges>;
  iterationIndex?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AdunitIterationComment = {
  __typename?: 'AdunitIterationComment';
  id?: Maybe<Scalars['ID']>;
  creator?: Maybe<User>;
  adunitIteration?: Maybe<AdunitIteration>;
  parent?: Maybe<AdunitIterationComment>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type AssetAnalysisScore = {
  __typename?: 'AssetAnalysisScore';
  url?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  client?: Maybe<Company>;
  adunits?: Maybe<Array<Maybe<Adunit>>>;
  brief?: Maybe<CampaignBrief>;
  resellerBrief?: Maybe<ResellerBrief>;
  creativeBrief?: Maybe<CreativeBrief>;
  campaignPlan?: Maybe<CampaignPlan>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  team?: Maybe<Array<Maybe<User>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CampaignBrief = {
  __typename?: 'CampaignBrief';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isSubmitted: Scalars['Boolean'];
  objective?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  sitelistUrl?: Maybe<Scalars['String']>;
  geographies?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
  seatId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  client?: Maybe<Company>;
  campaign?: Maybe<Campaign>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CampaignPlan = {
  __typename?: 'CampaignPlan';
  id?: Maybe<Scalars['ID']>;
  fileUrl?: Maybe<Scalars['String']>;
};

export type CampaignSpecifics = {
  __typename?: 'CampaignSpecifics';
  campaign_objective?: Maybe<Scalars['String']>;
  region?: Maybe<Array<Maybe<Scalars['String']>>>;
  season?: Maybe<Array<Maybe<Scalars['String']>>>;
  vertical?: Maybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CreateAdunitIterationInput = {
  creatorId: Scalars['Int'];
  iterationIndex: Scalars['Int'];
  adunitId: Scalars['String'];
};

export type CreateCampaignInput = {
  title: Scalars['String'];
  slug: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientId: Scalars['Float'];
  creatorId: Scalars['Float'];
};

export type CreateIterationCommentInput = {
  creatorId: Scalars['Float'];
  iterationId?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
};

export type CreativeBrief = {
  __typename?: 'CreativeBrief';
  id?: Maybe<Scalars['ID']>;
  websiteUrl?: Maybe<Scalars['String']>;
  assetsUrl?: Maybe<Scalars['String']>;
  assetFiles?: Maybe<Scalars['String']>;
  focus?: Maybe<Scalars['String']>;
  promoMessage?: Maybe<Scalars['String']>;
  isSeasonal?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  device?: Maybe<Scalars['String']>;
  adunitType?: Maybe<Scalars['String']>;
  adunitDimensions?: Maybe<Scalars['String']>;
  adunitCopy?: Maybe<Scalars['String']>;
  clicktroughUrl?: Maybe<Scalars['String']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CreativeChanges = {
  __typename?: 'CreativeChanges';
  id?: Maybe<Scalars['ID']>;
  assets?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  userJourney?: Maybe<Scalars['String']>;
  interactionType?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  gameVersion?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  creativeRegion?: Maybe<CreativeRegion>;
};

export type CreativeChangesInput = {
  creatorId: Scalars['Int'];
  gameName: Scalars['String'];
  gameVersion: Scalars['String'];
  deployedRegionId: Scalars['Int'];
  assets?: Maybe<Scalars['String']>;
  copy?: Maybe<Scalars['String']>;
  cta?: Maybe<Scalars['String']>;
  userJourney?: Maybe<Scalars['String']>;
  interactionType?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type CreativePlan = {
  __typename?: 'CreativePlan';
  top_games?: Maybe<Array<Maybe<Scalars['String']>>>;
  text_suggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  recommendations?: Maybe<LabelColorRecommendations>;
  top_eng_types?: Maybe<TopEngagementTypes>;
  asset_analysis?: Maybe<Array<Maybe<AssetAnalysisScore>>>;
  campaign_specifics?: Maybe<CampaignSpecifics>;
};

export type CreativeRegion = {
  __typename?: 'CreativeRegion';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CreativeTtdInput = {
  creativeName: Scalars['String'];
  advertiserId: Scalars['String'];
  description: Scalars['String'];
  adTag: Scalars['String'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  landingPages?: Maybe<Array<Maybe<Scalars['String']>>>;
  mraidVersion: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  trackingJS?: Maybe<Scalars['String']>;
  trackingPixel?: Maybe<Scalars['String']>;
};

export type CreativeUpload = {
  __typename?: 'CreativeUpload';
  id?: Maybe<Scalars['ID']>;
  gameKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  enviroment?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  timeZone?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  adludioIAS?: Maybe<Scalars['Boolean']>;
  thirdPartyIAS?: Maybe<Scalars['Boolean']>;
  trackingTag?: Maybe<Scalars['String']>;
  IASImpression?: Maybe<Scalars['String']>;
  DVImpression?: Maybe<Scalars['String']>;
  MOATImpression?: Maybe<Scalars['String']>;
  otherImpression?: Maybe<Scalars['String']>;
  thirdPartyEng?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type CreativeUploadCampaign = {
  __typename?: 'CreativeUploadCampaign';
  id?: Maybe<Scalars['ID']>;
  managedServiceId?: Maybe<ManagedService>;
  creativeUploadId?: Maybe<CreativeUpload>;
};

export type Document = {
  __typename?: 'Document';
  id?: Maybe<Scalars['ID']>;
  fileName?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  fileStatus?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Campaign>;
  uploaded?: Maybe<Scalars['Boolean']>;
  creatorId?: Maybe<User>;
  uploadedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export enum FileType {
  Assets = 'Assets',
  SiteList = 'SiteList',
  CampaignPlan = 'CampaignPlan',
  Storyboard = 'Storyboard',
  SpendFloorCpm = 'SpendFloorCPM',
}

export type LabelColorRecommendations = {
  __typename?: 'LabelColorRecommendations';
  best_colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  worst_colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  best_labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  worst_labels?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ManagedService = {
  __typename?: 'ManagedService';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  advertiserID?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
  s3URL?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAdunitIteration?: Maybe<AdunitIteration>;
  approveAdunitIteration?: Maybe<AdunitIteration>;
  createAdunit?: Maybe<Adunit>;
  updateAdunitTitle?: Maybe<Adunit>;
  deleteAdunit?: Maybe<Scalars['Boolean']>;
  submitManagedService?: Maybe<ManagedService>;
  saveCampaignBrief?: Maybe<CampaignBrief>;
  saveResellerBrief?: Maybe<ResellerBrief>;
  saveResellerMediaCampaignBrief?: Maybe<ResellerBrief>;
  saveMediaCampaignBrief?: Maybe<CampaignBrief>;
  saveCreativeBrief?: Maybe<CampaignBrief>;
  saveCampaignPlan?: Maybe<CampaignBrief>;
  submitCampaignBrief?: Maybe<Scalars['Boolean']>;
  submitCreativeBrief?: Maybe<Scalars['Boolean']>;
  deleteCampaignBrief?: Maybe<Scalars['Boolean']>;
  inviteToTeam?: Maybe<Array<Maybe<User>>>;
  createCampaign?: Maybe<Campaign>;
  inviteDesigner?: Maybe<Array<Maybe<User>>>;
  goLive?: Maybe<Scalars['Boolean']>;
  createTTDCreative?: Maybe<Scalars['String']>;
  saveCreativeUpload?: Maybe<CreativeUpload>;
  deleteCreativeUpload?: Maybe<Scalars['Boolean']>;
  createDocument?: Maybe<Document>;
  updateDocumentStatus?: Maybe<Document>;
  updateUploadedStatus?: Maybe<Document>;
  deleteDocument?: Maybe<Scalars['Boolean']>;
  archiveDocuments?: Maybe<Scalars['Boolean']>;
  createIterationComment?: Maybe<AdunitIterationComment>;
};

export type MutationCreateAdunitIterationArgs = {
  targetingChangesData: TargetingChangesInput;
  creativeChangesData: CreativeChangesInput;
  iterationData: CreateAdunitIterationInput;
};

export type MutationApproveAdunitIterationArgs = {
  id: Scalars['String'];
};

export type MutationCreateAdunitArgs = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  gameKey: Scalars['String'];
  campaignId: Scalars['String'];
};

export type MutationUpdateAdunitTitleArgs = {
  title: Scalars['String'];
  id: Scalars['String'];
};

export type MutationDeleteAdunitArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationSubmitManagedServiceArgs = {
  payload: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
};

export type MutationSaveCampaignBriefArgs = {
  fieldsToSave: SaveCampaignBriefInput;
  id: Scalars['String'];
};

export type MutationSaveResellerBriefArgs = {
  fieldsToSave: SaveResellerInput;
  id: Scalars['String'];
};

export type MutationSaveResellerMediaCampaignBriefArgs = {
  fieldsToSave: SaveMediaInput;
  id: Scalars['String'];
};

export type MutationSaveMediaCampaignBriefArgs = {
  fieldsToSave: SaveMediaInput;
  id: Scalars['String'];
};

export type MutationSaveCreativeBriefArgs = {
  fieldsToSave: SaveCreativeBriefInput;
  id: Scalars['String'];
};

export type MutationSaveCampaignPlanArgs = {
  fileName: Scalars['String'];
  campaignId: Scalars['String'];
};

export type MutationSubmitCampaignBriefArgs = {
  campaignId: Scalars['String'];
};

export type MutationSubmitCreativeBriefArgs = {
  campaignId: Scalars['String'];
};

export type MutationDeleteCampaignBriefArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationInviteToTeamArgs = {
  note?: Maybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  campaignId: Scalars['String'];
};

export type MutationCreateCampaignArgs = {
  data?: Maybe<CreateCampaignInput>;
};

export type MutationInviteDesignerArgs = {
  emails: Array<Scalars['String']>;
  campaignId: Scalars['String'];
};

export type MutationGoLiveArgs = {
  campaignId: Scalars['String'];
};

export type MutationCreateTtdCreativeArgs = {
  CreativeInputData: CreativeTtdInput;
};

export type MutationSaveCreativeUploadArgs = {
  dsp: Scalars['String'];
  advertiserID: Scalars['String'];
  campaignId: Scalars['String'];
  fieldsToSave: SaveCreativeUploadInput;
  id: Scalars['String'];
};

export type MutationDeleteCreativeUploadArgs = {
  id: Scalars['String'];
};

export type MutationCreateDocumentArgs = {
  fileType?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
  campaignId: Scalars['String'];
};

export type MutationUpdateDocumentStatusArgs = {
  status: Scalars['String'];
  id: Scalars['String'];
};

export type MutationUpdateUploadedStatusArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationDeleteDocumentArgs = {
  id?: Maybe<Scalars['String']>;
};

export type MutationArchiveDocumentsArgs = {
  fileType?: Maybe<Scalars['String']>;
  campaignId: Scalars['String'];
};

export type MutationCreateIterationCommentArgs = {
  data: CreateIterationCommentInput;
};

export enum Operation {
  Get = 'GET',
  Put = 'PUT',
}

export type Query = {
  __typename?: 'Query';
  getAdunitIterations: Array<AdunitIteration>;
  getAdunitBySlug?: Maybe<Adunit>;
  getCampaignBrief?: Maybe<CampaignBrief>;
  getManagedServiceSignedUrl?: Maybe<Scalars['String']>;
  getCreativePlan?: Maybe<CreativePlan>;
  getMagicLink?: Maybe<Scalars['String']>;
  getLiveCampaign?: Maybe<Scalars['String']>;
  getTermsAndConditionsMagicLink?: Maybe<Scalars['String']>;
  deleteFile?: Maybe<Scalars['Boolean']>;
  acceptCampaignPlan?: Maybe<Scalars['Boolean']>;
  getCampaigns: Array<Campaign>;
  getManagedServices: Array<ManagedService>;
  getManagedServiceById?: Maybe<ManagedService>;
  getCampaignBySlug?: Maybe<Campaign>;
  getCampaignById?: Maybe<Campaign>;
  getCreativeUploads?: Maybe<Array<Maybe<CreativeUploadCampaign>>>;
  getAssets?: Maybe<Array<Maybe<Document>>>;
  getCampaignPlanDocuments?: Maybe<Array<Maybe<Document>>>;
  sendEmails?: Maybe<Scalars['Boolean']>;
  getIterationComments?: Maybe<Array<Maybe<AdunitIterationComment>>>;
};

export type QueryGetAdunitIterationsArgs = {
  adunitId: Scalars['String'];
};

export type QueryGetAdunitBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryGetCampaignBriefArgs = {
  campaignId: Scalars['String'];
};

export type QueryGetManagedServiceSignedUrlArgs = {
  fileId: Scalars['String'];
  id: Scalars['String'];
};

export type QueryGetCreativePlanArgs = {
  campaignId: Scalars['String'];
};

export type QueryGetMagicLinkArgs = {
  operation?: Maybe<Operation>;
  campaignId: Scalars['String'];
  fileId: Scalars['String'];
  type: FileType;
};

export type QueryGetLiveCampaignArgs = {
  operation?: Maybe<Operation>;
  campaignId: Scalars['String'];
};

export type QueryDeleteFileArgs = {
  campaignId: Scalars['String'];
  fileName: Scalars['String'];
  type: FileType;
};

export type QueryAcceptCampaignPlanArgs = {
  campaignId: Scalars['String'];
};

export type QueryGetManagedServiceByIdArgs = {
  id?: Maybe<Scalars['String']>;
};

export type QueryGetCampaignBySlugArgs = {
  slug?: Maybe<Scalars['String']>;
};

export type QueryGetCampaignByIdArgs = {
  id?: Maybe<Scalars['String']>;
};

export type QueryGetCreativeUploadsArgs = {
  campaignId: Scalars['String'];
};

export type QueryGetAssetsArgs = {
  campaignId: Scalars['String'];
};

export type QueryGetCampaignPlanDocumentsArgs = {
  campaignId: Scalars['String'];
};

export type QuerySendEmailsArgs = {
  templateName: Scalars['String'];
  campaignId: Scalars['String'];
  userType: Array<UserType>;
  otherFields?: Maybe<Scalars['String']>;
};

export type QueryGetIterationCommentsArgs = {
  id: Scalars['String'];
};

export type ResellerBrief = {
  __typename?: 'ResellerBrief';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isSubmitted: Scalars['Boolean'];
  objective?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  sitelistUrl?: Maybe<Scalars['String']>;
  geographies?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
  seatID?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
  client?: Maybe<Company>;
  campaign?: Maybe<Campaign>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
};

export type SaveCampaignBriefInput = {
  name?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  objective?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  sitelistUrl?: Maybe<Scalars['String']>;
  geographies?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
  seatId?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
};

export type SaveCreativeBriefInput = {
  websiteUrl?: Maybe<Scalars['String']>;
  assetsUrl?: Maybe<Scalars['String']>;
  assetFiles?: Maybe<Scalars['String']>;
  focus?: Maybe<Scalars['String']>;
  promoMessage?: Maybe<Scalars['String']>;
  isSeasonal?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  device?: Maybe<Scalars['String']>;
  adunitType?: Maybe<Scalars['String']>;
  adunitDimensions?: Maybe<Scalars['String']>;
  adunitCopy?: Maybe<Scalars['String']>;
  clicktroughUrl?: Maybe<Scalars['String']>;
};

export type SaveCreativeUploadInput = {
  id?: Maybe<Scalars['String']>;
  gameKey?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  enviroment?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  landingPageUrl?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  timeZone?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  adludioIAS?: Maybe<Scalars['Boolean']>;
  thirdPartyIAS?: Maybe<Scalars['Boolean']>;
  trackingTag?: Maybe<Scalars['String']>;
  IASImpression?: Maybe<Scalars['String']>;
  DVImpression?: Maybe<Scalars['String']>;
  MOATImpression?: Maybe<Scalars['String']>;
  otherImpression?: Maybe<Scalars['String']>;
  thirdPartyEng?: Maybe<Scalars['String']>;
};

export type SaveMediaInput = {
  name?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  objective?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  sitelistUrl?: Maybe<Scalars['String']>;
  geographies?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  assetsUrl?: Maybe<Scalars['String']>;
  assetFiles?: Maybe<Scalars['String']>;
  focus?: Maybe<Scalars['String']>;
  promoMessage?: Maybe<Scalars['String']>;
  isSeasonal?: Maybe<Scalars['Boolean']>;
  isNewProduct?: Maybe<Scalars['Boolean']>;
  seatId?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  adunitType?: Maybe<Scalars['String']>;
  adunitDimensions?: Maybe<Scalars['String']>;
  adunitCopy?: Maybe<Scalars['String']>;
  clicktroughUrl?: Maybe<Scalars['String']>;
};

export type SaveResellerInput = {
  name?: Maybe<Scalars['String']>;
  budget?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  isManaged?: Maybe<Scalars['Boolean']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  objective?: Maybe<Scalars['String']>;
  KPI?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  sitelistUrl?: Maybe<Scalars['String']>;
  geographies?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  DSP?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  campaignBriefUpdates?: Maybe<CampaignBrief>;
  creativeBriefUpdates?: Maybe<CreativeBrief>;
};

export type SubscriptionCampaignBriefUpdatesArgs = {
  id?: Maybe<Scalars['String']>;
};

export type SubscriptionCreativeBriefUpdatesArgs = {
  id?: Maybe<Scalars['String']>;
};

export type TargetingChanges = {
  __typename?: 'TargetingChanges';
  id?: Maybe<Scalars['ID']>;
  demographic?: Maybe<Scalars['String']>;
  incExclList?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  creator?: Maybe<User>;
};

export type TargetingChangesInput = {
  creatorId: Scalars['Int'];
  demographic?: Maybe<Scalars['String']>;
  incExclList?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type TopEngagementTypes = {
  __typename?: 'TopEngagementTypes';
  top_engs?: Maybe<Array<Maybe<Scalars['String']>>>;
  best_swipe_dirs?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  googleSignup?: Maybe<Scalars['Boolean']>;
  verified?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

/** Types of users that will receive an email, internal corresponds to the predefined ones in internalEmailRecipients inside adludio while external refers to others in a campaign team */
export enum UserType {
  Internal = 'internal',
  External = 'external',
}

export type CreateAdunitMutationVariables = Exact<{
  title: Scalars['String'];
  gameKey: Scalars['String'];
  campaignId: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
}>;

export type CreateAdunitMutation = { __typename?: 'Mutation' } & {
  createAdunit?: Maybe<
    { __typename?: 'Adunit' } & Pick<
      Adunit,
      'id' | 'slug' | 'width' | 'height' | 'title'
    > & {
        adunitIterations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'AdunitIteration' } & Pick<
                AdunitIteration,
                'id'
              > & {
                  creativeChanges?: Maybe<
                    { __typename?: 'CreativeChanges' } & Pick<
                      CreativeChanges,
                      'gameName' | 'gameVersion'
                    > & {
                        creativeRegion?: Maybe<
                          { __typename?: 'CreativeRegion' } & Pick<
                            CreativeRegion,
                            'name'
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type CreateDocumentMutationVariables = Exact<{
  fileType: Scalars['String'];
  fileName: Scalars['String'];
  campaignId: Scalars['String'];
}>;

export type CreateDocumentMutation = { __typename?: 'Mutation' } & {
  createDocument?: Maybe<
    { __typename?: 'Document' } & Pick<
      Document,
      'id' | 'fileName' | 'fileType' | 'fileStatus' | 'uploadedAt'
    > & {
        campaignId?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
        creatorId?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>;
      }
  >;
};

export type CreateTtdCreativeMutationVariables = Exact<{
  creativeName: Scalars['String'];
  description: Scalars['String'];
  adTag: Scalars['String'];
  advertiserId: Scalars['String'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  landingPages?: Maybe<
    Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>
  >;
  mraidVersion: Scalars['String'];
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  trackingPixel?: Maybe<Scalars['String']>;
  trackingJS?: Maybe<Scalars['String']>;
}>;

export type CreateTtdCreativeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'createTTDCreative'
>;

export type DeleteAdunitMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteAdunitMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteAdunit'
>;

export type DeleteCampaignBriefMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteCampaignBriefMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCampaignBrief'
>;

export type DeleteCreativeUploadMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteCreativeUploadMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteCreativeUpload'
>;

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteDocumentMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'deleteDocument'
>;

export type GoLiveMutationVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GoLiveMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'goLive'
>;

export type InviteDesignerMutationVariables = Exact<{
  campaignId: Scalars['String'];
  emails: Array<Scalars['String']> | Scalars['String'];
}>;

export type InviteDesignerMutation = { __typename?: 'Mutation' } & {
  inviteDesigner?: Maybe<
    Array<Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'email'>>>
  >;
};

export type InviteToTeamMutationVariables = Exact<{
  campaignId: Scalars['String'];
  emails: Array<Scalars['String']> | Scalars['String'];
  note?: Maybe<Scalars['String']>;
}>;

export type InviteToTeamMutation = { __typename?: 'Mutation' } & {
  inviteToTeam?: Maybe<
    Array<Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'email'>>>
  >;
};

export type SaveCampaignBriefMutationVariables = Exact<{
  fieldsToSave: SaveCampaignBriefInput;
  id: Scalars['String'];
}>;

export type SaveCampaignBriefMutation = { __typename?: 'Mutation' } & {
  saveCampaignBrief?: Maybe<
    { __typename?: 'CampaignBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SaveCampaignPlanMutationVariables = Exact<{
  fileName: Scalars['String'];
  campaignId: Scalars['String'];
}>;

export type SaveCampaignPlanMutation = { __typename?: 'Mutation' } & {
  saveCampaignPlan?: Maybe<
    { __typename?: 'CampaignBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SaveCreativeBriefMutationVariables = Exact<{
  fieldsToSave: SaveCreativeBriefInput;
  id: Scalars['String'];
}>;

export type SaveCreativeBriefMutation = { __typename?: 'Mutation' } & {
  saveCreativeBrief?: Maybe<
    { __typename?: 'CampaignBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SaveCreativeUploadMutationVariables = Exact<{
  fieldsToSave: SaveCreativeUploadInput;
  id: Scalars['String'];
  campaignId: Scalars['String'];
  dsp: Scalars['String'];
  advertiserID: Scalars['String'];
}>;

export type SaveCreativeUploadMutation = { __typename?: 'Mutation' } & {
  saveCreativeUpload?: Maybe<
    { __typename?: 'CreativeUpload' } & Pick<CreativeUpload, 'id'>
  >;
};

export type SaveMediaCampaignBriefMutationVariables = Exact<{
  fieldsToSave: SaveMediaInput;
  id: Scalars['String'];
}>;

export type SaveMediaCampaignBriefMutation = { __typename?: 'Mutation' } & {
  saveMediaCampaignBrief?: Maybe<
    { __typename?: 'CampaignBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SaveResellerBriefMutationVariables = Exact<{
  fieldsToSave: SaveResellerInput;
  id: Scalars['String'];
}>;

export type SaveResellerBriefMutation = { __typename?: 'Mutation' } & {
  saveResellerBrief?: Maybe<
    { __typename?: 'ResellerBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SaveResellerMediaCampaignBriefMutationVariables = Exact<{
  fieldsToSave: SaveMediaInput;
  id: Scalars['String'];
}>;

export type SaveResellerMediaCampaignBriefMutation = {
  __typename?: 'Mutation';
} & {
  saveResellerMediaCampaignBrief?: Maybe<
    { __typename?: 'ResellerBrief' } & {
      campaign?: Maybe<{ __typename?: 'Campaign' } & Pick<Campaign, 'id'>>;
    }
  >;
};

export type SubmitCampaignBriefMutationVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type SubmitCampaignBriefMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'submitCampaignBrief'
>;

export type SubmitCreativeBriefMutationVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type SubmitCreativeBriefMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'submitCreativeBrief'
>;

export type SubmitManagedServiceMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  payload: Scalars['String'];
}>;

export type SubmitManagedServiceMutation = { __typename?: 'Mutation' } & {
  submitManagedService?: Maybe<
    { __typename?: 'ManagedService' } & Pick<ManagedService, 'id'>
  >;
};

export type UpdateAdunitTitleMutationVariables = Exact<{
  id: Scalars['String'];
  title: Scalars['String'];
}>;

export type UpdateAdunitTitleMutation = { __typename?: 'Mutation' } & {
  updateAdunitTitle?: Maybe<
    { __typename?: 'Adunit' } & Pick<Adunit, 'id' | 'title'>
  >;
};

export type UpdateDocumentStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status: Scalars['String'];
}>;

export type UpdateDocumentStatusMutation = { __typename?: 'Mutation' } & {
  updateDocumentStatus?: Maybe<
    { __typename?: 'Document' } & Pick<Document, 'id' | 'fileStatus'>
  >;
};

export type UpdateUploadedStatusMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type UpdateUploadedStatusMutation = { __typename?: 'Mutation' } & {
  updateUploadedStatus?: Maybe<
    { __typename?: 'Document' } & Pick<
      Document,
      'id' | 'fileName' | 'fileStatus' | 'uploaded'
    >
  >;
};

export type AcceptCampaignPlanQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type AcceptCampaignPlanQuery = { __typename?: 'Query' } & Pick<
  Query,
  'acceptCampaignPlan'
>;

export type DeleteFileQueryVariables = Exact<{
  type: FileType;
  fileName: Scalars['String'];
  campaignId: Scalars['String'];
}>;

export type DeleteFileQuery = { __typename?: 'Query' } & Pick<
  Query,
  'deleteFile'
>;

export type GetAdunitQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type GetAdunitQuery = { __typename?: 'Query' } & {
  getAdunitBySlug?: Maybe<
    { __typename?: 'Adunit' } & Pick<
      Adunit,
      'id' | 'title' | 'slug' | 'description' | 'width' | 'height'
    > & {
        approvedAdunitIterations?: Maybe<
          Array<
            Maybe<
              { __typename?: 'AdunitIteration' } & Pick<
                AdunitIteration,
                'startDate' | 'endDate' | 'iterationIndex'
              >
            >
          >
        >;
      }
  >;
};

export type GetAssetsQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetAssetsQuery = { __typename?: 'Query' } & {
  getAssets?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Document' } & Pick<
          Document,
          | 'id'
          | 'fileName'
          | 'fileType'
          | 'fileStatus'
          | 'uploadedAt'
          | 'deletedAt'
        > & {
            creatorId?: Maybe<
              { __typename?: 'User' } & Pick<User, 'name' | 'id'>
            >;
            campaignId?: Maybe<
              { __typename?: 'Campaign' } & Pick<Campaign, 'id'>
            >;
          }
      >
    >
  >;
};

export type GetCampaignQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;

export type GetCampaignQuery = { __typename?: 'Query' } & {
  getCampaignById?: Maybe<
    { __typename?: 'Campaign' } & Pick<
      Campaign,
      'id' | 'title' | 'slug' | 'status' | 'brandName'
    > & {
        adunits?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Adunit' } & Pick<
                Adunit,
                'id' | 'title' | 'createdAt' | 'width' | 'height'
              > & {
                  adunitIterations?: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'AdunitIteration' } & {
                          creativeChanges?: Maybe<
                            { __typename?: 'CreativeChanges' } & Pick<
                              CreativeChanges,
                              'gameName' | 'gameVersion'
                            > & {
                                creativeRegion?: Maybe<
                                  { __typename?: 'CreativeRegion' } & Pick<
                                    CreativeRegion,
                                    'name'
                                  >
                                >;
                              }
                          >;
                        }
                      >
                    >
                  >;
                }
            >
          >
        >;
        team?: Maybe<
          Array<Maybe<{ __typename?: 'User' } & Pick<User, 'name' | 'email'>>>
        >;
        brief?: Maybe<
          { __typename?: 'CampaignBrief' } & Pick<
            CampaignBrief,
            | 'id'
            | 'name'
            | 'isManaged'
            | 'isSubmitted'
            | 'startDate'
            | 'endDate'
            | 'geographies'
            | 'brandName'
            | 'currency'
            | 'budget'
            | 'KPI'
            | 'description'
            | 'industry'
            | 'audience'
            | 'objective'
            | 'sitelistUrl'
            | 'DSP'
            | 'seatId'
          >
        >;
        resellerBrief?: Maybe<
          { __typename?: 'ResellerBrief' } & Pick<
            ResellerBrief,
            | 'name'
            | 'isManaged'
            | 'isSubmitted'
            | 'startDate'
            | 'endDate'
            | 'geographies'
            | 'brandName'
            | 'currency'
            | 'budget'
            | 'KPI'
            | 'description'
            | 'industry'
            | 'audience'
            | 'objective'
            | 'sitelistUrl'
            | 'DSP'
            | 'seatID'
          >
        >;
        campaignPlan?: Maybe<
          { __typename?: 'CampaignPlan' } & Pick<CampaignPlan, 'fileUrl'>
        >;
        creativeBrief?: Maybe<
          { __typename?: 'CreativeBrief' } & Pick<
            CreativeBrief,
            | 'id'
            | 'websiteUrl'
            | 'assetsUrl'
            | 'assetFiles'
            | 'focus'
            | 'promoMessage'
            | 'isSeasonal'
            | 'isNewProduct'
            | 'isSubmitted'
            | 'device'
            | 'adunitType'
            | 'adunitDimensions'
            | 'adunitCopy'
            | 'clicktroughUrl'
          >
        >;
      }
  >;
};

export type GetCampaignBriefQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetCampaignBriefQuery = { __typename?: 'Query' } & {
  getCampaignBrief?: Maybe<
    { __typename?: 'CampaignBrief' } & Pick<
      CampaignBrief,
      | 'id'
      | 'name'
      | 'budget'
      | 'currency'
      | 'brandName'
      | 'description'
      | 'industry'
      | 'startDate'
      | 'endDate'
      | 'isManaged'
      | 'isSubmitted'
      | 'objective'
      | 'KPI'
      | 'audience'
      | 'sitelistUrl'
      | 'geographies'
      | 'DSP'
      | 'seatId'
    > & {
        creator?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        client?: Maybe<
          { __typename?: 'Company' } & Pick<Company, 'id' | 'name'>
        >;
      }
  >;
};

export type GetCampaignBySlugQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
}>;

export type GetCampaignBySlugQuery = { __typename?: 'Query' } & {
  getCampaignBySlug?: Maybe<
    { __typename?: 'Campaign' } & Pick<Campaign, 'id' | 'title' | 'slug'> & {
        brief?: Maybe<
          { __typename?: 'CampaignBrief' } & Pick<
            CampaignBrief,
            'name' | 'isSubmitted' | 'startDate' | 'endDate'
          >
        >;
        creativeBrief?: Maybe<
          { __typename?: 'CreativeBrief' } & Pick<
            CreativeBrief,
            | 'id'
            | 'websiteUrl'
            | 'assetsUrl'
            | 'assetFiles'
            | 'focus'
            | 'promoMessage'
            | 'isSeasonal'
            | 'isNewProduct'
            | 'isSubmitted'
          >
        >;
      }
  >;
};

export type GetCampaignPlanDocumentsQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetCampaignPlanDocumentsQuery = { __typename?: 'Query' } & {
  getCampaignPlanDocuments?: Maybe<
    Array<
      Maybe<
        { __typename?: 'Document' } & Pick<
          Document,
          | 'id'
          | 'fileName'
          | 'fileType'
          | 'fileStatus'
          | 'uploadedAt'
          | 'deletedAt'
        > & {
            creatorId?: Maybe<
              { __typename?: 'User' } & Pick<User, 'name' | 'id'>
            >;
            campaignId?: Maybe<
              { __typename?: 'Campaign' } & Pick<Campaign, 'id'>
            >;
          }
      >
    >
  >;
};

export type GetCampaignTypeQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetCampaignTypeQuery = { __typename?: 'Query' } & {
  getCampaignBrief?: Maybe<
    { __typename?: 'CampaignBrief' } & Pick<
      CampaignBrief,
      'isManaged' | 'isSubmitted'
    >
  >;
};

export type GetCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCampaignsQuery = { __typename?: 'Query' } & {
  getCampaigns: Array<
    { __typename?: 'Campaign' } & Pick<
      Campaign,
      'id' | 'thumbnailUrl' | 'title' | 'slug' | 'status'
    > & {
        brief?: Maybe<
          { __typename?: 'CampaignBrief' } & Pick<
            CampaignBrief,
            | 'id'
            | 'isManaged'
            | 'name'
            | 'budget'
            | 'startDate'
            | 'endDate'
            | 'isSubmitted'
            | 'createdAt'
          >
        >;
        resellerBrief?: Maybe<
          { __typename?: 'ResellerBrief' } & Pick<
            ResellerBrief,
            | 'id'
            | 'isManaged'
            | 'name'
            | 'budget'
            | 'startDate'
            | 'endDate'
            | 'isSubmitted'
            | 'createdAt'
          >
        >;
        creativeBrief?: Maybe<
          { __typename?: 'CreativeBrief' } & Pick<
            CreativeBrief,
            'id' | 'isSubmitted'
          >
        >;
        adunits?: Maybe<
          Array<Maybe<{ __typename?: 'Adunit' } & Pick<Adunit, 'id'>>>
        >;
      }
  >;
};

export type GetCreativePlanQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetCreativePlanQuery = { __typename?: 'Query' } & {
  getCreativePlan?: Maybe<
    { __typename?: 'CreativePlan' } & Pick<
      CreativePlan,
      'top_games' | 'text_suggestions'
    > & {
        top_eng_types?: Maybe<
          { __typename?: 'TopEngagementTypes' } & Pick<
            TopEngagementTypes,
            'top_engs' | 'best_swipe_dirs'
          >
        >;
        recommendations?: Maybe<
          { __typename?: 'LabelColorRecommendations' } & Pick<
            LabelColorRecommendations,
            'best_colors' | 'worst_colors' | 'best_labels' | 'worst_labels'
          >
        >;
        asset_analysis?: Maybe<
          Array<
            Maybe<
              { __typename?: 'AssetAnalysisScore' } & Pick<
                AssetAnalysisScore,
                'url' | 'score'
              >
            >
          >
        >;
        campaign_specifics?: Maybe<
          { __typename?: 'CampaignSpecifics' } & Pick<
            CampaignSpecifics,
            'campaign_objective' | 'region' | 'season' | 'vertical'
          >
        >;
      }
  >;
};

export type GetCreativeUploadsQueryVariables = Exact<{
  campaignId: Scalars['String'];
}>;

export type GetCreativeUploadsQuery = { __typename?: 'Query' } & {
  getCreativeUploads?: Maybe<
    Array<
      Maybe<
        { __typename?: 'CreativeUploadCampaign' } & Pick<
          CreativeUploadCampaign,
          'id'
        > & {
            creativeUploadId?: Maybe<
              { __typename?: 'CreativeUpload' } & Pick<
                CreativeUpload,
                | 'id'
                | 'gameKey'
                | 'name'
                | 'enviroment'
                | 'redirectUrl'
                | 'landingPageUrl'
                | 'width'
                | 'height'
                | 'startDate'
                | 'endDate'
                | 'timeZone'
                | 'time'
                | 'adludioIAS'
                | 'thirdPartyIAS'
                | 'trackingTag'
                | 'IASImpression'
                | 'DVImpression'
                | 'MOATImpression'
                | 'otherImpression'
                | 'thirdPartyEng'
                | 'createdAt'
                | 'region'
                | 'modifiedAt'
              >
            >;
          }
      >
    >
  >;
};

export type GetLiveCampaignQueryVariables = Exact<{
  campaignId: Scalars['String'];
  operation: Operation;
}>;

export type GetLiveCampaignQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getLiveCampaign'
>;

export type GetMagicLinkQueryVariables = Exact<{
  fileId: Scalars['String'];
  type: FileType;
  campaignId: Scalars['String'];
  operation: Operation;
}>;

export type GetMagicLinkQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getMagicLink'
>;

export type GetMagicLinkTermsContitionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMagicLinkTermsContitionsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getTermsAndConditionsMagicLink'
>;

export type GetManagedServiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type GetManagedServiceByIdQuery = { __typename?: 'Query' } & {
  getManagedServiceById?: Maybe<
    { __typename?: 'ManagedService' } & Pick<
      ManagedService,
      'id' | 'name' | 'advertiserID' | 'DSP'
    > & { creator?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>> }
  >;
};

export type GetManagedServiceSignedUrlQueryVariables = Exact<{
  id: Scalars['String'];
  fileId: Scalars['String'];
}>;

export type GetManagedServiceSignedUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'getManagedServiceSignedUrl'
>;

export type GetManagedServicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManagedServicesQuery = { __typename?: 'Query' } & {
  getManagedServices: Array<
    { __typename?: 'ManagedService' } & Pick<ManagedService, 'name' | 'id'> & {
        creator?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
      }
  >;
};

export type EmailResolverQueryVariables = Exact<{
  templateName: Scalars['String'];
  campaignId: Scalars['String'];
  userType: Array<UserType> | UserType;
  otherFields?: Maybe<Scalars['String']>;
}>;

export type EmailResolverQuery = { __typename?: 'Query' } & Pick<
  Query,
  'sendEmails'
>;

export type CampaignBriefUpdatesSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;

export type CampaignBriefUpdatesSubscription = {
  __typename?: 'Subscription';
} & {
  campaignBriefUpdates?: Maybe<
    { __typename?: 'CampaignBrief' } & Pick<
      CampaignBrief,
      | 'id'
      | 'name'
      | 'budget'
      | 'currency'
      | 'description'
      | 'industry'
      | 'startDate'
      | 'endDate'
      | 'isManaged'
      | 'isSubmitted'
      | 'objective'
      | 'KPI'
      | 'audience'
      | 'sitelistUrl'
      | 'geographies'
      | 'DSP'
      | 'seatId'
    > & {
        creator?: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>;
        client?: Maybe<
          { __typename?: 'Company' } & Pick<Company, 'id' | 'name'>
        >;
      }
  >;
};

export type CreativeBriefUpdatesSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;

export type CreativeBriefUpdatesSubscription = {
  __typename?: 'Subscription';
} & {
  creativeBriefUpdates?: Maybe<
    { __typename?: 'CreativeBrief' } & Pick<
      CreativeBrief,
      | 'id'
      | 'websiteUrl'
      | 'assetsUrl'
      | 'assetFiles'
      | 'focus'
      | 'promoMessage'
      | 'isSeasonal'
      | 'isNewProduct'
    >
  >;
};

export const CreateAdunitDocument = gql`
  mutation createAdunit(
    $title: String!
    $gameKey: String!
    $campaignId: String!
    $width: Int!
    $height: Int!
  ) {
    createAdunit(
      title: $title
      gameKey: $gameKey
      campaignId: $campaignId
      width: $width
      height: $height
    ) {
      id
      slug
      width
      height
      title
      adunitIterations {
        id
        creativeChanges {
          creativeRegion {
            name
          }
          gameName
          gameVersion
        }
      }
    }
  }
`;
export type CreateAdunitMutationFn = Apollo.MutationFunction<
  CreateAdunitMutation,
  CreateAdunitMutationVariables
>;

/**
 * __useCreateAdunitMutation__
 *
 * To run a mutation, you first call `useCreateAdunitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdunitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdunitMutation, { data, loading, error }] = useCreateAdunitMutation({
 *   variables: {
 *      title: // value for 'title'
 *      gameKey: // value for 'gameKey'
 *      campaignId: // value for 'campaignId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useCreateAdunitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdunitMutation,
    CreateAdunitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAdunitMutation,
    CreateAdunitMutationVariables
  >(CreateAdunitDocument, options);
}
export type CreateAdunitMutationHookResult = ReturnType<
  typeof useCreateAdunitMutation
>;
export type CreateAdunitMutationResult =
  Apollo.MutationResult<CreateAdunitMutation>;
export type CreateAdunitMutationOptions = Apollo.BaseMutationOptions<
  CreateAdunitMutation,
  CreateAdunitMutationVariables
>;
export const CreateDocumentDocument = gql`
  mutation createDocument(
    $fileType: String!
    $fileName: String!
    $campaignId: String!
  ) {
    createDocument(
      fileName: $fileName
      fileType: $fileType
      campaignId: $campaignId
    ) {
      id
      fileName
      fileType
      fileStatus
      uploadedAt
      campaignId {
        id
      }
      creatorId {
        id
        name
      }
    }
  }
`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      fileName: // value for 'fileName'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCreateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDocumentMutation,
    CreateDocumentMutationVariables
  >(CreateDocumentDocument, options);
}
export type CreateDocumentMutationHookResult = ReturnType<
  typeof useCreateDocumentMutation
>;
export type CreateDocumentMutationResult =
  Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;
export const CreateTtdCreativeDocument = gql`
  mutation createTTDCreative(
    $creativeName: String!
    $description: String!
    $adTag: String!
    $advertiserId: String!
    $width: Float!
    $height: Float!
    $landingPages: [String]
    $mraidVersion: String!
    $startTime: String
    $endTime: String
    $trackingPixel: String
    $trackingJS: String
  ) {
    createTTDCreative(
      CreativeInputData: {
        creativeName: $creativeName
        description: $description
        advertiserId: $advertiserId
        adTag: $adTag
        landingPages: $landingPages
        width: $width
        height: $height
        mraidVersion: $mraidVersion
        startTime: $startTime
        endTime: $endTime
        trackingPixel: $trackingPixel
        trackingJS: $trackingJS
      }
    )
  }
`;
export type CreateTtdCreativeMutationFn = Apollo.MutationFunction<
  CreateTtdCreativeMutation,
  CreateTtdCreativeMutationVariables
>;

/**
 * __useCreateTtdCreativeMutation__
 *
 * To run a mutation, you first call `useCreateTtdCreativeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTtdCreativeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTtdCreativeMutation, { data, loading, error }] = useCreateTtdCreativeMutation({
 *   variables: {
 *      creativeName: // value for 'creativeName'
 *      description: // value for 'description'
 *      adTag: // value for 'adTag'
 *      advertiserId: // value for 'advertiserId'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      landingPages: // value for 'landingPages'
 *      mraidVersion: // value for 'mraidVersion'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *      trackingPixel: // value for 'trackingPixel'
 *      trackingJS: // value for 'trackingJS'
 *   },
 * });
 */
export function useCreateTtdCreativeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTtdCreativeMutation,
    CreateTtdCreativeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTtdCreativeMutation,
    CreateTtdCreativeMutationVariables
  >(CreateTtdCreativeDocument, options);
}
export type CreateTtdCreativeMutationHookResult = ReturnType<
  typeof useCreateTtdCreativeMutation
>;
export type CreateTtdCreativeMutationResult =
  Apollo.MutationResult<CreateTtdCreativeMutation>;
export type CreateTtdCreativeMutationOptions = Apollo.BaseMutationOptions<
  CreateTtdCreativeMutation,
  CreateTtdCreativeMutationVariables
>;
export const DeleteAdunitDocument = gql`
  mutation deleteAdunit($id: String!) {
    deleteAdunit(id: $id)
  }
`;
export type DeleteAdunitMutationFn = Apollo.MutationFunction<
  DeleteAdunitMutation,
  DeleteAdunitMutationVariables
>;

/**
 * __useDeleteAdunitMutation__
 *
 * To run a mutation, you first call `useDeleteAdunitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdunitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdunitMutation, { data, loading, error }] = useDeleteAdunitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdunitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdunitMutation,
    DeleteAdunitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAdunitMutation,
    DeleteAdunitMutationVariables
  >(DeleteAdunitDocument, options);
}
export type DeleteAdunitMutationHookResult = ReturnType<
  typeof useDeleteAdunitMutation
>;
export type DeleteAdunitMutationResult =
  Apollo.MutationResult<DeleteAdunitMutation>;
export type DeleteAdunitMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdunitMutation,
  DeleteAdunitMutationVariables
>;
export const DeleteCampaignBriefDocument = gql`
  mutation deleteCampaignBrief($id: String!) {
    deleteCampaignBrief(id: $id)
  }
`;
export type DeleteCampaignBriefMutationFn = Apollo.MutationFunction<
  DeleteCampaignBriefMutation,
  DeleteCampaignBriefMutationVariables
>;

/**
 * __useDeleteCampaignBriefMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignBriefMutation, { data, loading, error }] = useDeleteCampaignBriefMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCampaignBriefMutation,
    DeleteCampaignBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCampaignBriefMutation,
    DeleteCampaignBriefMutationVariables
  >(DeleteCampaignBriefDocument, options);
}
export type DeleteCampaignBriefMutationHookResult = ReturnType<
  typeof useDeleteCampaignBriefMutation
>;
export type DeleteCampaignBriefMutationResult =
  Apollo.MutationResult<DeleteCampaignBriefMutation>;
export type DeleteCampaignBriefMutationOptions = Apollo.BaseMutationOptions<
  DeleteCampaignBriefMutation,
  DeleteCampaignBriefMutationVariables
>;
export const DeleteCreativeUploadDocument = gql`
  mutation deleteCreativeUpload($id: String!) {
    deleteCreativeUpload(id: $id)
  }
`;
export type DeleteCreativeUploadMutationFn = Apollo.MutationFunction<
  DeleteCreativeUploadMutation,
  DeleteCreativeUploadMutationVariables
>;

/**
 * __useDeleteCreativeUploadMutation__
 *
 * To run a mutation, you first call `useDeleteCreativeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreativeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreativeUploadMutation, { data, loading, error }] = useDeleteCreativeUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCreativeUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCreativeUploadMutation,
    DeleteCreativeUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCreativeUploadMutation,
    DeleteCreativeUploadMutationVariables
  >(DeleteCreativeUploadDocument, options);
}
export type DeleteCreativeUploadMutationHookResult = ReturnType<
  typeof useDeleteCreativeUploadMutation
>;
export type DeleteCreativeUploadMutationResult =
  Apollo.MutationResult<DeleteCreativeUploadMutation>;
export type DeleteCreativeUploadMutationOptions = Apollo.BaseMutationOptions<
  DeleteCreativeUploadMutation,
  DeleteCreativeUploadMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: String!) {
    deleteDocument(id: $id)
  }
`;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteDocumentMutation,
    DeleteDocumentMutationVariables
  >(DeleteDocumentDocument, options);
}
export type DeleteDocumentMutationHookResult = ReturnType<
  typeof useDeleteDocumentMutation
>;
export type DeleteDocumentMutationResult =
  Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const GoLiveDocument = gql`
  mutation goLive($campaignId: String!) {
    goLive(campaignId: $campaignId)
  }
`;
export type GoLiveMutationFn = Apollo.MutationFunction<
  GoLiveMutation,
  GoLiveMutationVariables
>;

/**
 * __useGoLiveMutation__
 *
 * To run a mutation, you first call `useGoLiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoLiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goLiveMutation, { data, loading, error }] = useGoLiveMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGoLiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GoLiveMutation,
    GoLiveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GoLiveMutation, GoLiveMutationVariables>(
    GoLiveDocument,
    options
  );
}
export type GoLiveMutationHookResult = ReturnType<typeof useGoLiveMutation>;
export type GoLiveMutationResult = Apollo.MutationResult<GoLiveMutation>;
export type GoLiveMutationOptions = Apollo.BaseMutationOptions<
  GoLiveMutation,
  GoLiveMutationVariables
>;
export const InviteDesignerDocument = gql`
  mutation inviteDesigner($campaignId: String!, $emails: [String!]!) {
    inviteDesigner(campaignId: $campaignId, emails: $emails) {
      name
      email
    }
  }
`;
export type InviteDesignerMutationFn = Apollo.MutationFunction<
  InviteDesignerMutation,
  InviteDesignerMutationVariables
>;

/**
 * __useInviteDesignerMutation__
 *
 * To run a mutation, you first call `useInviteDesignerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteDesignerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteDesignerMutation, { data, loading, error }] = useInviteDesignerMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      emails: // value for 'emails'
 *   },
 * });
 */
export function useInviteDesignerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteDesignerMutation,
    InviteDesignerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteDesignerMutation,
    InviteDesignerMutationVariables
  >(InviteDesignerDocument, options);
}
export type InviteDesignerMutationHookResult = ReturnType<
  typeof useInviteDesignerMutation
>;
export type InviteDesignerMutationResult =
  Apollo.MutationResult<InviteDesignerMutation>;
export type InviteDesignerMutationOptions = Apollo.BaseMutationOptions<
  InviteDesignerMutation,
  InviteDesignerMutationVariables
>;
export const InviteToTeamDocument = gql`
  mutation inviteToTeam(
    $campaignId: String!
    $emails: [String!]!
    $note: String
  ) {
    inviteToTeam(campaignId: $campaignId, emails: $emails, note: $note) {
      name
      email
    }
  }
`;
export type InviteToTeamMutationFn = Apollo.MutationFunction<
  InviteToTeamMutation,
  InviteToTeamMutationVariables
>;

/**
 * __useInviteToTeamMutation__
 *
 * To run a mutation, you first call `useInviteToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteToTeamMutation, { data, loading, error }] = useInviteToTeamMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      emails: // value for 'emails'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useInviteToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >(InviteToTeamDocument, options);
}
export type InviteToTeamMutationHookResult = ReturnType<
  typeof useInviteToTeamMutation
>;
export type InviteToTeamMutationResult =
  Apollo.MutationResult<InviteToTeamMutation>;
export type InviteToTeamMutationOptions = Apollo.BaseMutationOptions<
  InviteToTeamMutation,
  InviteToTeamMutationVariables
>;
export const SaveCampaignBriefDocument = gql`
  mutation saveCampaignBrief(
    $fieldsToSave: SaveCampaignBriefInput!
    $id: String!
  ) {
    saveCampaignBrief(fieldsToSave: $fieldsToSave, id: $id) {
      campaign {
        id
      }
    }
  }
`;
export type SaveCampaignBriefMutationFn = Apollo.MutationFunction<
  SaveCampaignBriefMutation,
  SaveCampaignBriefMutationVariables
>;

/**
 * __useSaveCampaignBriefMutation__
 *
 * To run a mutation, you first call `useSaveCampaignBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCampaignBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCampaignBriefMutation, { data, loading, error }] = useSaveCampaignBriefMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveCampaignBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCampaignBriefMutation,
    SaveCampaignBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCampaignBriefMutation,
    SaveCampaignBriefMutationVariables
  >(SaveCampaignBriefDocument, options);
}
export type SaveCampaignBriefMutationHookResult = ReturnType<
  typeof useSaveCampaignBriefMutation
>;
export type SaveCampaignBriefMutationResult =
  Apollo.MutationResult<SaveCampaignBriefMutation>;
export type SaveCampaignBriefMutationOptions = Apollo.BaseMutationOptions<
  SaveCampaignBriefMutation,
  SaveCampaignBriefMutationVariables
>;
export const SaveCampaignPlanDocument = gql`
  mutation saveCampaignPlan($fileName: String!, $campaignId: String!) {
    saveCampaignPlan(fileName: $fileName, campaignId: $campaignId) {
      campaign {
        id
      }
    }
  }
`;
export type SaveCampaignPlanMutationFn = Apollo.MutationFunction<
  SaveCampaignPlanMutation,
  SaveCampaignPlanMutationVariables
>;

/**
 * __useSaveCampaignPlanMutation__
 *
 * To run a mutation, you first call `useSaveCampaignPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCampaignPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCampaignPlanMutation, { data, loading, error }] = useSaveCampaignPlanMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSaveCampaignPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCampaignPlanMutation,
    SaveCampaignPlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCampaignPlanMutation,
    SaveCampaignPlanMutationVariables
  >(SaveCampaignPlanDocument, options);
}
export type SaveCampaignPlanMutationHookResult = ReturnType<
  typeof useSaveCampaignPlanMutation
>;
export type SaveCampaignPlanMutationResult =
  Apollo.MutationResult<SaveCampaignPlanMutation>;
export type SaveCampaignPlanMutationOptions = Apollo.BaseMutationOptions<
  SaveCampaignPlanMutation,
  SaveCampaignPlanMutationVariables
>;
export const SaveCreativeBriefDocument = gql`
  mutation saveCreativeBrief(
    $fieldsToSave: SaveCreativeBriefInput!
    $id: String!
  ) {
    saveCreativeBrief(fieldsToSave: $fieldsToSave, id: $id) {
      campaign {
        id
      }
    }
  }
`;
export type SaveCreativeBriefMutationFn = Apollo.MutationFunction<
  SaveCreativeBriefMutation,
  SaveCreativeBriefMutationVariables
>;

/**
 * __useSaveCreativeBriefMutation__
 *
 * To run a mutation, you first call `useSaveCreativeBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCreativeBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCreativeBriefMutation, { data, loading, error }] = useSaveCreativeBriefMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveCreativeBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCreativeBriefMutation,
    SaveCreativeBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCreativeBriefMutation,
    SaveCreativeBriefMutationVariables
  >(SaveCreativeBriefDocument, options);
}
export type SaveCreativeBriefMutationHookResult = ReturnType<
  typeof useSaveCreativeBriefMutation
>;
export type SaveCreativeBriefMutationResult =
  Apollo.MutationResult<SaveCreativeBriefMutation>;
export type SaveCreativeBriefMutationOptions = Apollo.BaseMutationOptions<
  SaveCreativeBriefMutation,
  SaveCreativeBriefMutationVariables
>;
export const SaveCreativeUploadDocument = gql`
  mutation saveCreativeUpload(
    $fieldsToSave: SaveCreativeUploadInput!
    $id: String!
    $campaignId: String!
    $dsp: String!
    $advertiserID: String!
  ) {
    saveCreativeUpload(
      fieldsToSave: $fieldsToSave
      id: $id
      campaignId: $campaignId
      dsp: $dsp
      advertiserID: $advertiserID
    ) {
      id
    }
  }
`;
export type SaveCreativeUploadMutationFn = Apollo.MutationFunction<
  SaveCreativeUploadMutation,
  SaveCreativeUploadMutationVariables
>;

/**
 * __useSaveCreativeUploadMutation__
 *
 * To run a mutation, you first call `useSaveCreativeUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCreativeUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCreativeUploadMutation, { data, loading, error }] = useSaveCreativeUploadMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *      campaignId: // value for 'campaignId'
 *      dsp: // value for 'dsp'
 *      advertiserID: // value for 'advertiserID'
 *   },
 * });
 */
export function useSaveCreativeUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCreativeUploadMutation,
    SaveCreativeUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCreativeUploadMutation,
    SaveCreativeUploadMutationVariables
  >(SaveCreativeUploadDocument, options);
}
export type SaveCreativeUploadMutationHookResult = ReturnType<
  typeof useSaveCreativeUploadMutation
>;
export type SaveCreativeUploadMutationResult =
  Apollo.MutationResult<SaveCreativeUploadMutation>;
export type SaveCreativeUploadMutationOptions = Apollo.BaseMutationOptions<
  SaveCreativeUploadMutation,
  SaveCreativeUploadMutationVariables
>;
export const SaveMediaCampaignBriefDocument = gql`
  mutation saveMediaCampaignBrief(
    $fieldsToSave: SaveMediaInput!
    $id: String!
  ) {
    saveMediaCampaignBrief(fieldsToSave: $fieldsToSave, id: $id) {
      campaign {
        id
      }
    }
  }
`;
export type SaveMediaCampaignBriefMutationFn = Apollo.MutationFunction<
  SaveMediaCampaignBriefMutation,
  SaveMediaCampaignBriefMutationVariables
>;

/**
 * __useSaveMediaCampaignBriefMutation__
 *
 * To run a mutation, you first call `useSaveMediaCampaignBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMediaCampaignBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMediaCampaignBriefMutation, { data, loading, error }] = useSaveMediaCampaignBriefMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveMediaCampaignBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMediaCampaignBriefMutation,
    SaveMediaCampaignBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMediaCampaignBriefMutation,
    SaveMediaCampaignBriefMutationVariables
  >(SaveMediaCampaignBriefDocument, options);
}
export type SaveMediaCampaignBriefMutationHookResult = ReturnType<
  typeof useSaveMediaCampaignBriefMutation
>;
export type SaveMediaCampaignBriefMutationResult =
  Apollo.MutationResult<SaveMediaCampaignBriefMutation>;
export type SaveMediaCampaignBriefMutationOptions = Apollo.BaseMutationOptions<
  SaveMediaCampaignBriefMutation,
  SaveMediaCampaignBriefMutationVariables
>;
export const SaveResellerBriefDocument = gql`
  mutation saveResellerBrief($fieldsToSave: SaveResellerInput!, $id: String!) {
    saveResellerBrief(fieldsToSave: $fieldsToSave, id: $id) {
      campaign {
        id
      }
    }
  }
`;
export type SaveResellerBriefMutationFn = Apollo.MutationFunction<
  SaveResellerBriefMutation,
  SaveResellerBriefMutationVariables
>;

/**
 * __useSaveResellerBriefMutation__
 *
 * To run a mutation, you first call `useSaveResellerBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResellerBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResellerBriefMutation, { data, loading, error }] = useSaveResellerBriefMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveResellerBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveResellerBriefMutation,
    SaveResellerBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveResellerBriefMutation,
    SaveResellerBriefMutationVariables
  >(SaveResellerBriefDocument, options);
}
export type SaveResellerBriefMutationHookResult = ReturnType<
  typeof useSaveResellerBriefMutation
>;
export type SaveResellerBriefMutationResult =
  Apollo.MutationResult<SaveResellerBriefMutation>;
export type SaveResellerBriefMutationOptions = Apollo.BaseMutationOptions<
  SaveResellerBriefMutation,
  SaveResellerBriefMutationVariables
>;
export const SaveResellerMediaCampaignBriefDocument = gql`
  mutation saveResellerMediaCampaignBrief(
    $fieldsToSave: SaveMediaInput!
    $id: String!
  ) {
    saveResellerMediaCampaignBrief(fieldsToSave: $fieldsToSave, id: $id) {
      campaign {
        id
      }
    }
  }
`;
export type SaveResellerMediaCampaignBriefMutationFn = Apollo.MutationFunction<
  SaveResellerMediaCampaignBriefMutation,
  SaveResellerMediaCampaignBriefMutationVariables
>;

/**
 * __useSaveResellerMediaCampaignBriefMutation__
 *
 * To run a mutation, you first call `useSaveResellerMediaCampaignBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveResellerMediaCampaignBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveResellerMediaCampaignBriefMutation, { data, loading, error }] = useSaveResellerMediaCampaignBriefMutation({
 *   variables: {
 *      fieldsToSave: // value for 'fieldsToSave'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSaveResellerMediaCampaignBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveResellerMediaCampaignBriefMutation,
    SaveResellerMediaCampaignBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveResellerMediaCampaignBriefMutation,
    SaveResellerMediaCampaignBriefMutationVariables
  >(SaveResellerMediaCampaignBriefDocument, options);
}
export type SaveResellerMediaCampaignBriefMutationHookResult = ReturnType<
  typeof useSaveResellerMediaCampaignBriefMutation
>;
export type SaveResellerMediaCampaignBriefMutationResult =
  Apollo.MutationResult<SaveResellerMediaCampaignBriefMutation>;
export type SaveResellerMediaCampaignBriefMutationOptions =
  Apollo.BaseMutationOptions<
    SaveResellerMediaCampaignBriefMutation,
    SaveResellerMediaCampaignBriefMutationVariables
  >;
export const SubmitCampaignBriefDocument = gql`
  mutation submitCampaignBrief($campaignId: String!) {
    submitCampaignBrief(campaignId: $campaignId)
  }
`;
export type SubmitCampaignBriefMutationFn = Apollo.MutationFunction<
  SubmitCampaignBriefMutation,
  SubmitCampaignBriefMutationVariables
>;

/**
 * __useSubmitCampaignBriefMutation__
 *
 * To run a mutation, you first call `useSubmitCampaignBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCampaignBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCampaignBriefMutation, { data, loading, error }] = useSubmitCampaignBriefMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSubmitCampaignBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitCampaignBriefMutation,
    SubmitCampaignBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitCampaignBriefMutation,
    SubmitCampaignBriefMutationVariables
  >(SubmitCampaignBriefDocument, options);
}
export type SubmitCampaignBriefMutationHookResult = ReturnType<
  typeof useSubmitCampaignBriefMutation
>;
export type SubmitCampaignBriefMutationResult =
  Apollo.MutationResult<SubmitCampaignBriefMutation>;
export type SubmitCampaignBriefMutationOptions = Apollo.BaseMutationOptions<
  SubmitCampaignBriefMutation,
  SubmitCampaignBriefMutationVariables
>;
export const SubmitCreativeBriefDocument = gql`
  mutation submitCreativeBrief($campaignId: String!) {
    submitCreativeBrief(campaignId: $campaignId)
  }
`;
export type SubmitCreativeBriefMutationFn = Apollo.MutationFunction<
  SubmitCreativeBriefMutation,
  SubmitCreativeBriefMutationVariables
>;

/**
 * __useSubmitCreativeBriefMutation__
 *
 * To run a mutation, you first call `useSubmitCreativeBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCreativeBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCreativeBriefMutation, { data, loading, error }] = useSubmitCreativeBriefMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useSubmitCreativeBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitCreativeBriefMutation,
    SubmitCreativeBriefMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitCreativeBriefMutation,
    SubmitCreativeBriefMutationVariables
  >(SubmitCreativeBriefDocument, options);
}
export type SubmitCreativeBriefMutationHookResult = ReturnType<
  typeof useSubmitCreativeBriefMutation
>;
export type SubmitCreativeBriefMutationResult =
  Apollo.MutationResult<SubmitCreativeBriefMutation>;
export type SubmitCreativeBriefMutationOptions = Apollo.BaseMutationOptions<
  SubmitCreativeBriefMutation,
  SubmitCreativeBriefMutationVariables
>;
export const SubmitManagedServiceDocument = gql`
  mutation submitManagedService(
    $id: String!
    $name: String!
    $payload: String!
  ) {
    submitManagedService(id: $id, name: $name, payload: $payload) {
      id
    }
  }
`;
export type SubmitManagedServiceMutationFn = Apollo.MutationFunction<
  SubmitManagedServiceMutation,
  SubmitManagedServiceMutationVariables
>;

/**
 * __useSubmitManagedServiceMutation__
 *
 * To run a mutation, you first call `useSubmitManagedServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitManagedServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitManagedServiceMutation, { data, loading, error }] = useSubmitManagedServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSubmitManagedServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitManagedServiceMutation,
    SubmitManagedServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitManagedServiceMutation,
    SubmitManagedServiceMutationVariables
  >(SubmitManagedServiceDocument, options);
}
export type SubmitManagedServiceMutationHookResult = ReturnType<
  typeof useSubmitManagedServiceMutation
>;
export type SubmitManagedServiceMutationResult =
  Apollo.MutationResult<SubmitManagedServiceMutation>;
export type SubmitManagedServiceMutationOptions = Apollo.BaseMutationOptions<
  SubmitManagedServiceMutation,
  SubmitManagedServiceMutationVariables
>;
export const UpdateAdunitTitleDocument = gql`
  mutation updateAdunitTitle($id: String!, $title: String!) {
    updateAdunitTitle(id: $id, title: $title) {
      id
      title
    }
  }
`;
export type UpdateAdunitTitleMutationFn = Apollo.MutationFunction<
  UpdateAdunitTitleMutation,
  UpdateAdunitTitleMutationVariables
>;

/**
 * __useUpdateAdunitTitleMutation__
 *
 * To run a mutation, you first call `useUpdateAdunitTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdunitTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdunitTitleMutation, { data, loading, error }] = useUpdateAdunitTitleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateAdunitTitleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAdunitTitleMutation,
    UpdateAdunitTitleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAdunitTitleMutation,
    UpdateAdunitTitleMutationVariables
  >(UpdateAdunitTitleDocument, options);
}
export type UpdateAdunitTitleMutationHookResult = ReturnType<
  typeof useUpdateAdunitTitleMutation
>;
export type UpdateAdunitTitleMutationResult =
  Apollo.MutationResult<UpdateAdunitTitleMutation>;
export type UpdateAdunitTitleMutationOptions = Apollo.BaseMutationOptions<
  UpdateAdunitTitleMutation,
  UpdateAdunitTitleMutationVariables
>;
export const UpdateDocumentStatusDocument = gql`
  mutation updateDocumentStatus($id: String!, $status: String!) {
    updateDocumentStatus(id: $id, status: $status) {
      id
      fileStatus
    }
  }
`;
export type UpdateDocumentStatusMutationFn = Apollo.MutationFunction<
  UpdateDocumentStatusMutation,
  UpdateDocumentStatusMutationVariables
>;

/**
 * __useUpdateDocumentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentStatusMutation, { data, loading, error }] = useUpdateDocumentStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateDocumentStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDocumentStatusMutation,
    UpdateDocumentStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDocumentStatusMutation,
    UpdateDocumentStatusMutationVariables
  >(UpdateDocumentStatusDocument, options);
}
export type UpdateDocumentStatusMutationHookResult = ReturnType<
  typeof useUpdateDocumentStatusMutation
>;
export type UpdateDocumentStatusMutationResult =
  Apollo.MutationResult<UpdateDocumentStatusMutation>;
export type UpdateDocumentStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentStatusMutation,
  UpdateDocumentStatusMutationVariables
>;
export const UpdateUploadedStatusDocument = gql`
  mutation updateUploadedStatus($id: String!) {
    updateUploadedStatus(id: $id) {
      id
      fileName
      fileStatus
      uploaded
    }
  }
`;
export type UpdateUploadedStatusMutationFn = Apollo.MutationFunction<
  UpdateUploadedStatusMutation,
  UpdateUploadedStatusMutationVariables
>;

/**
 * __useUpdateUploadedStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUploadedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUploadedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUploadedStatusMutation, { data, loading, error }] = useUpdateUploadedStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUploadedStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUploadedStatusMutation,
    UpdateUploadedStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUploadedStatusMutation,
    UpdateUploadedStatusMutationVariables
  >(UpdateUploadedStatusDocument, options);
}
export type UpdateUploadedStatusMutationHookResult = ReturnType<
  typeof useUpdateUploadedStatusMutation
>;
export type UpdateUploadedStatusMutationResult =
  Apollo.MutationResult<UpdateUploadedStatusMutation>;
export type UpdateUploadedStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateUploadedStatusMutation,
  UpdateUploadedStatusMutationVariables
>;
export const AcceptCampaignPlanDocument = gql`
  query acceptCampaignPlan($campaignId: String!) {
    acceptCampaignPlan(campaignId: $campaignId)
  }
`;

/**
 * __useAcceptCampaignPlanQuery__
 *
 * To run a query within a React component, call `useAcceptCampaignPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcceptCampaignPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcceptCampaignPlanQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useAcceptCampaignPlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    AcceptCampaignPlanQuery,
    AcceptCampaignPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AcceptCampaignPlanQuery,
    AcceptCampaignPlanQueryVariables
  >(AcceptCampaignPlanDocument, options);
}
export function useAcceptCampaignPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AcceptCampaignPlanQuery,
    AcceptCampaignPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AcceptCampaignPlanQuery,
    AcceptCampaignPlanQueryVariables
  >(AcceptCampaignPlanDocument, options);
}
export type AcceptCampaignPlanQueryHookResult = ReturnType<
  typeof useAcceptCampaignPlanQuery
>;
export type AcceptCampaignPlanLazyQueryHookResult = ReturnType<
  typeof useAcceptCampaignPlanLazyQuery
>;
export type AcceptCampaignPlanQueryResult = Apollo.QueryResult<
  AcceptCampaignPlanQuery,
  AcceptCampaignPlanQueryVariables
>;
export const DeleteFileDocument = gql`
  query deleteFile($type: FileType!, $fileName: String!, $campaignId: String!) {
    deleteFile(type: $type, fileName: $fileName, campaignId: $campaignId)
  }
`;

/**
 * __useDeleteFileQuery__
 *
 * To run a query within a React component, call `useDeleteFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteFileQuery({
 *   variables: {
 *      type: // value for 'type'
 *      fileName: // value for 'fileName'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDeleteFileQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeleteFileQuery,
    DeleteFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeleteFileQuery, DeleteFileQueryVariables>(
    DeleteFileDocument,
    options
  );
}
export function useDeleteFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeleteFileQuery,
    DeleteFileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeleteFileQuery, DeleteFileQueryVariables>(
    DeleteFileDocument,
    options
  );
}
export type DeleteFileQueryHookResult = ReturnType<typeof useDeleteFileQuery>;
export type DeleteFileLazyQueryHookResult = ReturnType<
  typeof useDeleteFileLazyQuery
>;
export type DeleteFileQueryResult = Apollo.QueryResult<
  DeleteFileQuery,
  DeleteFileQueryVariables
>;
export const GetAdunitDocument = gql`
  query getAdunit($slug: String!) {
    getAdunitBySlug(slug: $slug) {
      id
      title
      slug
      description
      width
      height
      approvedAdunitIterations {
        startDate
        endDate
        iterationIndex
      }
    }
  }
`;

/**
 * __useGetAdunitQuery__
 *
 * To run a query within a React component, call `useGetAdunitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdunitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdunitQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAdunitQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdunitQuery, GetAdunitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdunitQuery, GetAdunitQueryVariables>(
    GetAdunitDocument,
    options
  );
}
export function useGetAdunitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdunitQuery,
    GetAdunitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdunitQuery, GetAdunitQueryVariables>(
    GetAdunitDocument,
    options
  );
}
export type GetAdunitQueryHookResult = ReturnType<typeof useGetAdunitQuery>;
export type GetAdunitLazyQueryHookResult = ReturnType<
  typeof useGetAdunitLazyQuery
>;
export type GetAdunitQueryResult = Apollo.QueryResult<
  GetAdunitQuery,
  GetAdunitQueryVariables
>;
export const GetAssetsDocument = gql`
  query getAssets($campaignId: String!) {
    getAssets(campaignId: $campaignId) {
      id
      fileName
      fileType
      fileStatus
      uploadedAt
      deletedAt
      creatorId {
        name
        id
      }
      campaignId {
        id
      }
    }
  }
`;

/**
 * __useGetAssetsQuery__
 *
 * To run a query within a React component, call `useGetAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetsQuery, GetAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export function useGetAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssetsQuery,
    GetAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssetsQuery, GetAssetsQueryVariables>(
    GetAssetsDocument,
    options
  );
}
export type GetAssetsQueryHookResult = ReturnType<typeof useGetAssetsQuery>;
export type GetAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAssetsLazyQuery
>;
export type GetAssetsQueryResult = Apollo.QueryResult<
  GetAssetsQuery,
  GetAssetsQueryVariables
>;
export const GetCampaignDocument = gql`
  query getCampaign($id: String) {
    getCampaignById(id: $id) {
      id
      title
      slug
      status
      adunits {
        id
        title
        createdAt
        width
        height
        adunitIterations {
          creativeChanges {
            gameName
            gameVersion
            creativeRegion {
              name
            }
          }
        }
      }
      team {
        name
        email
      }
      brief {
        id
        name
        isManaged
        isSubmitted
        startDate
        endDate
        geographies
        brandName
        currency
        budget
        KPI
        description
        industry
        audience
        objective
        sitelistUrl
        DSP
        seatId
      }
      resellerBrief {
        name
        isManaged
        isSubmitted
        startDate
        endDate
        geographies
        brandName
        currency
        budget
        KPI
        description
        industry
        audience
        objective
        sitelistUrl
        DSP
        seatID
      }
      campaignPlan {
        fileUrl
      }
      creativeBrief {
        id
        websiteUrl
        assetsUrl
        assetFiles
        focus
        promoMessage
        isSeasonal
        isNewProduct
        isSubmitted
        device
        adunitType
        adunitDimensions
        adunitCopy
        clicktroughUrl
      }
      brandName
    }
  }
`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignQuery,
    GetCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options
  );
}
export function useGetCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignQuery,
    GetCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(
    GetCampaignDocument,
    options
  );
}
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<
  typeof useGetCampaignLazyQuery
>;
export type GetCampaignQueryResult = Apollo.QueryResult<
  GetCampaignQuery,
  GetCampaignQueryVariables
>;
export const GetCampaignBriefDocument = gql`
  query getCampaignBrief($campaignId: String!) {
    getCampaignBrief(campaignId: $campaignId) {
      id
      name
      budget
      currency
      brandName
      description
      industry
      startDate
      endDate
      isManaged
      isSubmitted
      objective
      KPI
      id
      audience
      sitelistUrl
      geographies
      DSP
      seatId
      creator {
        id
      }
      client {
        id
        name
      }
    }
  }
`;

/**
 * __useGetCampaignBriefQuery__
 *
 * To run a query within a React component, call `useGetCampaignBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignBriefQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignBriefQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignBriefQuery,
    GetCampaignBriefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignBriefQuery, GetCampaignBriefQueryVariables>(
    GetCampaignBriefDocument,
    options
  );
}
export function useGetCampaignBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignBriefQuery,
    GetCampaignBriefQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignBriefQuery,
    GetCampaignBriefQueryVariables
  >(GetCampaignBriefDocument, options);
}
export type GetCampaignBriefQueryHookResult = ReturnType<
  typeof useGetCampaignBriefQuery
>;
export type GetCampaignBriefLazyQueryHookResult = ReturnType<
  typeof useGetCampaignBriefLazyQuery
>;
export type GetCampaignBriefQueryResult = Apollo.QueryResult<
  GetCampaignBriefQuery,
  GetCampaignBriefQueryVariables
>;
export const GetCampaignBySlugDocument = gql`
  query getCampaignBySlug($slug: String) {
    getCampaignBySlug(slug: $slug) {
      id
      title
      slug
      brief {
        name
        isSubmitted
        startDate
        endDate
      }
      creativeBrief {
        id
        websiteUrl
        assetsUrl
        assetFiles
        focus
        promoMessage
        isSeasonal
        isNewProduct
        isSubmitted
      }
    }
  }
`;

/**
 * __useGetCampaignBySlugQuery__
 *
 * To run a query within a React component, call `useGetCampaignBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetCampaignBySlugQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignBySlugQuery,
    GetCampaignBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignBySlugQuery,
    GetCampaignBySlugQueryVariables
  >(GetCampaignBySlugDocument, options);
}
export function useGetCampaignBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignBySlugQuery,
    GetCampaignBySlugQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignBySlugQuery,
    GetCampaignBySlugQueryVariables
  >(GetCampaignBySlugDocument, options);
}
export type GetCampaignBySlugQueryHookResult = ReturnType<
  typeof useGetCampaignBySlugQuery
>;
export type GetCampaignBySlugLazyQueryHookResult = ReturnType<
  typeof useGetCampaignBySlugLazyQuery
>;
export type GetCampaignBySlugQueryResult = Apollo.QueryResult<
  GetCampaignBySlugQuery,
  GetCampaignBySlugQueryVariables
>;
export const GetCampaignPlanDocumentsDocument = gql`
  query getCampaignPlanDocuments($campaignId: String!) {
    getCampaignPlanDocuments(campaignId: $campaignId) {
      id
      fileName
      fileType
      fileStatus
      uploadedAt
      deletedAt
      creatorId {
        name
        id
      }
      campaignId {
        id
      }
    }
  }
`;

/**
 * __useGetCampaignPlanDocumentsQuery__
 *
 * To run a query within a React component, call `useGetCampaignPlanDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignPlanDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignPlanDocumentsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignPlanDocumentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignPlanDocumentsQuery,
    GetCampaignPlanDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCampaignPlanDocumentsQuery,
    GetCampaignPlanDocumentsQueryVariables
  >(GetCampaignPlanDocumentsDocument, options);
}
export function useGetCampaignPlanDocumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignPlanDocumentsQuery,
    GetCampaignPlanDocumentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignPlanDocumentsQuery,
    GetCampaignPlanDocumentsQueryVariables
  >(GetCampaignPlanDocumentsDocument, options);
}
export type GetCampaignPlanDocumentsQueryHookResult = ReturnType<
  typeof useGetCampaignPlanDocumentsQuery
>;
export type GetCampaignPlanDocumentsLazyQueryHookResult = ReturnType<
  typeof useGetCampaignPlanDocumentsLazyQuery
>;
export type GetCampaignPlanDocumentsQueryResult = Apollo.QueryResult<
  GetCampaignPlanDocumentsQuery,
  GetCampaignPlanDocumentsQueryVariables
>;
export const GetCampaignTypeDocument = gql`
  query getCampaignType($campaignId: String!) {
    getCampaignBrief(campaignId: $campaignId) {
      isManaged
      isSubmitted
    }
  }
`;

/**
 * __useGetCampaignTypeQuery__
 *
 * To run a query within a React component, call `useGetCampaignTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignTypeQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCampaignTypeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCampaignTypeQuery,
    GetCampaignTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignTypeQuery, GetCampaignTypeQueryVariables>(
    GetCampaignTypeDocument,
    options
  );
}
export function useGetCampaignTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignTypeQuery,
    GetCampaignTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCampaignTypeQuery,
    GetCampaignTypeQueryVariables
  >(GetCampaignTypeDocument, options);
}
export type GetCampaignTypeQueryHookResult = ReturnType<
  typeof useGetCampaignTypeQuery
>;
export type GetCampaignTypeLazyQueryHookResult = ReturnType<
  typeof useGetCampaignTypeLazyQuery
>;
export type GetCampaignTypeQueryResult = Apollo.QueryResult<
  GetCampaignTypeQuery,
  GetCampaignTypeQueryVariables
>;
export const GetCampaignsDocument = gql`
  query getCampaigns {
    getCampaigns {
      id
      thumbnailUrl
      title
      slug
      status
      brief {
        id
        isManaged
        name
        budget
        startDate
        endDate
        isSubmitted
        createdAt
      }
      resellerBrief {
        id
        isManaged
        name
        budget
        startDate
        endDate
        isSubmitted
        createdAt
      }
      creativeBrief {
        id
        isSubmitted
      }
      adunits {
        id
      }
    }
  }
`;

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCampaignsQuery,
    GetCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCampaignsQuery,
    GetCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(
    GetCampaignsDocument,
    options
  );
}
export type GetCampaignsQueryHookResult = ReturnType<
  typeof useGetCampaignsQuery
>;
export type GetCampaignsLazyQueryHookResult = ReturnType<
  typeof useGetCampaignsLazyQuery
>;
export type GetCampaignsQueryResult = Apollo.QueryResult<
  GetCampaignsQuery,
  GetCampaignsQueryVariables
>;
export const GetCreativePlanDocument = gql`
  query getCreativePlan($campaignId: String!) {
    getCreativePlan(campaignId: $campaignId) {
      top_games
      top_eng_types {
        top_engs
        best_swipe_dirs
      }
      text_suggestions
      recommendations {
        best_colors
        worst_colors
        best_labels
        worst_labels
      }
      asset_analysis {
        url
        score
      }
      campaign_specifics {
        campaign_objective
        region
        season
        vertical
      }
    }
  }
`;

/**
 * __useGetCreativePlanQuery__
 *
 * To run a query within a React component, call `useGetCreativePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativePlanQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCreativePlanQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreativePlanQuery,
    GetCreativePlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCreativePlanQuery, GetCreativePlanQueryVariables>(
    GetCreativePlanDocument,
    options
  );
}
export function useGetCreativePlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreativePlanQuery,
    GetCreativePlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreativePlanQuery,
    GetCreativePlanQueryVariables
  >(GetCreativePlanDocument, options);
}
export type GetCreativePlanQueryHookResult = ReturnType<
  typeof useGetCreativePlanQuery
>;
export type GetCreativePlanLazyQueryHookResult = ReturnType<
  typeof useGetCreativePlanLazyQuery
>;
export type GetCreativePlanQueryResult = Apollo.QueryResult<
  GetCreativePlanQuery,
  GetCreativePlanQueryVariables
>;
export const GetCreativeUploadsDocument = gql`
  query getCreativeUploads($campaignId: String!) {
    getCreativeUploads(campaignId: $campaignId) {
      id
      creativeUploadId {
        id
        gameKey
        name
        enviroment
        redirectUrl
        landingPageUrl
        width
        height
        startDate
        endDate
        timeZone
        time
        adludioIAS
        thirdPartyIAS
        trackingTag
        IASImpression
        DVImpression
        MOATImpression
        otherImpression
        thirdPartyEng
        createdAt
        region
        modifiedAt
      }
    }
  }
`;

/**
 * __useGetCreativeUploadsQuery__
 *
 * To run a query within a React component, call `useGetCreativeUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreativeUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreativeUploadsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useGetCreativeUploadsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCreativeUploadsQuery,
    GetCreativeUploadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCreativeUploadsQuery,
    GetCreativeUploadsQueryVariables
  >(GetCreativeUploadsDocument, options);
}
export function useGetCreativeUploadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCreativeUploadsQuery,
    GetCreativeUploadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCreativeUploadsQuery,
    GetCreativeUploadsQueryVariables
  >(GetCreativeUploadsDocument, options);
}
export type GetCreativeUploadsQueryHookResult = ReturnType<
  typeof useGetCreativeUploadsQuery
>;
export type GetCreativeUploadsLazyQueryHookResult = ReturnType<
  typeof useGetCreativeUploadsLazyQuery
>;
export type GetCreativeUploadsQueryResult = Apollo.QueryResult<
  GetCreativeUploadsQuery,
  GetCreativeUploadsQueryVariables
>;
export const GetLiveCampaignDocument = gql`
  query getLiveCampaign($campaignId: String!, $operation: Operation!) {
    getLiveCampaign(campaignId: $campaignId, operation: $operation)
  }
`;

/**
 * __useGetLiveCampaignQuery__
 *
 * To run a query within a React component, call `useGetLiveCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiveCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiveCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useGetLiveCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLiveCampaignQuery,
    GetLiveCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLiveCampaignQuery, GetLiveCampaignQueryVariables>(
    GetLiveCampaignDocument,
    options
  );
}
export function useGetLiveCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLiveCampaignQuery,
    GetLiveCampaignQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLiveCampaignQuery,
    GetLiveCampaignQueryVariables
  >(GetLiveCampaignDocument, options);
}
export type GetLiveCampaignQueryHookResult = ReturnType<
  typeof useGetLiveCampaignQuery
>;
export type GetLiveCampaignLazyQueryHookResult = ReturnType<
  typeof useGetLiveCampaignLazyQuery
>;
export type GetLiveCampaignQueryResult = Apollo.QueryResult<
  GetLiveCampaignQuery,
  GetLiveCampaignQueryVariables
>;
export const GetMagicLinkDocument = gql`
  query getMagicLink(
    $fileId: String!
    $type: FileType!
    $campaignId: String!
    $operation: Operation!
  ) {
    getMagicLink(
      fileId: $fileId
      type: $type
      campaignId: $campaignId
      operation: $operation
    )
  }
`;

/**
 * __useGetMagicLinkQuery__
 *
 * To run a query within a React component, call `useGetMagicLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagicLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagicLinkQuery({
 *   variables: {
 *      fileId: // value for 'fileId'
 *      type: // value for 'type'
 *      campaignId: // value for 'campaignId'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useGetMagicLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMagicLinkQuery,
    GetMagicLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMagicLinkQuery, GetMagicLinkQueryVariables>(
    GetMagicLinkDocument,
    options
  );
}
export function useGetMagicLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMagicLinkQuery,
    GetMagicLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMagicLinkQuery, GetMagicLinkQueryVariables>(
    GetMagicLinkDocument,
    options
  );
}
export type GetMagicLinkQueryHookResult = ReturnType<
  typeof useGetMagicLinkQuery
>;
export type GetMagicLinkLazyQueryHookResult = ReturnType<
  typeof useGetMagicLinkLazyQuery
>;
export type GetMagicLinkQueryResult = Apollo.QueryResult<
  GetMagicLinkQuery,
  GetMagicLinkQueryVariables
>;
export const GetMagicLinkTermsContitionsDocument = gql`
  query getMagicLinkTermsContitions {
    getTermsAndConditionsMagicLink
  }
`;

/**
 * __useGetMagicLinkTermsContitionsQuery__
 *
 * To run a query within a React component, call `useGetMagicLinkTermsContitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMagicLinkTermsContitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMagicLinkTermsContitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMagicLinkTermsContitionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMagicLinkTermsContitionsQuery,
    GetMagicLinkTermsContitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMagicLinkTermsContitionsQuery,
    GetMagicLinkTermsContitionsQueryVariables
  >(GetMagicLinkTermsContitionsDocument, options);
}
export function useGetMagicLinkTermsContitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMagicLinkTermsContitionsQuery,
    GetMagicLinkTermsContitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMagicLinkTermsContitionsQuery,
    GetMagicLinkTermsContitionsQueryVariables
  >(GetMagicLinkTermsContitionsDocument, options);
}
export type GetMagicLinkTermsContitionsQueryHookResult = ReturnType<
  typeof useGetMagicLinkTermsContitionsQuery
>;
export type GetMagicLinkTermsContitionsLazyQueryHookResult = ReturnType<
  typeof useGetMagicLinkTermsContitionsLazyQuery
>;
export type GetMagicLinkTermsContitionsQueryResult = Apollo.QueryResult<
  GetMagicLinkTermsContitionsQuery,
  GetMagicLinkTermsContitionsQueryVariables
>;
export const GetManagedServiceByIdDocument = gql`
  query getManagedServiceById($id: String!) {
    getManagedServiceById(id: $id) {
      id
      name
      advertiserID
      DSP
      creator {
        id
      }
    }
  }
`;

/**
 * __useGetManagedServiceByIdQuery__
 *
 * To run a query within a React component, call `useGetManagedServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedServiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetManagedServiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagedServiceByIdQuery,
    GetManagedServiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagedServiceByIdQuery,
    GetManagedServiceByIdQueryVariables
  >(GetManagedServiceByIdDocument, options);
}
export function useGetManagedServiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagedServiceByIdQuery,
    GetManagedServiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagedServiceByIdQuery,
    GetManagedServiceByIdQueryVariables
  >(GetManagedServiceByIdDocument, options);
}
export type GetManagedServiceByIdQueryHookResult = ReturnType<
  typeof useGetManagedServiceByIdQuery
>;
export type GetManagedServiceByIdLazyQueryHookResult = ReturnType<
  typeof useGetManagedServiceByIdLazyQuery
>;
export type GetManagedServiceByIdQueryResult = Apollo.QueryResult<
  GetManagedServiceByIdQuery,
  GetManagedServiceByIdQueryVariables
>;
export const GetManagedServiceSignedUrlDocument = gql`
  query getManagedServiceSignedUrl($id: String!, $fileId: String!) {
    getManagedServiceSignedUrl(id: $id, fileId: $fileId)
  }
`;

/**
 * __useGetManagedServiceSignedUrlQuery__
 *
 * To run a query within a React component, call `useGetManagedServiceSignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedServiceSignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedServiceSignedUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetManagedServiceSignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetManagedServiceSignedUrlQuery,
    GetManagedServiceSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagedServiceSignedUrlQuery,
    GetManagedServiceSignedUrlQueryVariables
  >(GetManagedServiceSignedUrlDocument, options);
}
export function useGetManagedServiceSignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagedServiceSignedUrlQuery,
    GetManagedServiceSignedUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagedServiceSignedUrlQuery,
    GetManagedServiceSignedUrlQueryVariables
  >(GetManagedServiceSignedUrlDocument, options);
}
export type GetManagedServiceSignedUrlQueryHookResult = ReturnType<
  typeof useGetManagedServiceSignedUrlQuery
>;
export type GetManagedServiceSignedUrlLazyQueryHookResult = ReturnType<
  typeof useGetManagedServiceSignedUrlLazyQuery
>;
export type GetManagedServiceSignedUrlQueryResult = Apollo.QueryResult<
  GetManagedServiceSignedUrlQuery,
  GetManagedServiceSignedUrlQueryVariables
>;
export const GetManagedServicesDocument = gql`
  query getManagedServices {
    getManagedServices {
      name
      id
      creator {
        id
      }
    }
  }
`;

/**
 * __useGetManagedServicesQuery__
 *
 * To run a query within a React component, call `useGetManagedServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagedServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagedServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManagedServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManagedServicesQuery,
    GetManagedServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetManagedServicesQuery,
    GetManagedServicesQueryVariables
  >(GetManagedServicesDocument, options);
}
export function useGetManagedServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManagedServicesQuery,
    GetManagedServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManagedServicesQuery,
    GetManagedServicesQueryVariables
  >(GetManagedServicesDocument, options);
}
export type GetManagedServicesQueryHookResult = ReturnType<
  typeof useGetManagedServicesQuery
>;
export type GetManagedServicesLazyQueryHookResult = ReturnType<
  typeof useGetManagedServicesLazyQuery
>;
export type GetManagedServicesQueryResult = Apollo.QueryResult<
  GetManagedServicesQuery,
  GetManagedServicesQueryVariables
>;
export const EmailResolverDocument = gql`
  query EmailResolver(
    $templateName: String!
    $campaignId: String!
    $userType: [UserType!]!
    $otherFields: String
  ) {
    sendEmails(
      campaignId: $campaignId
      templateName: $templateName
      userType: $userType
      otherFields: $otherFields
    )
  }
`;

/**
 * __useEmailResolverQuery__
 *
 * To run a query within a React component, call `useEmailResolverQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailResolverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailResolverQuery({
 *   variables: {
 *      templateName: // value for 'templateName'
 *      campaignId: // value for 'campaignId'
 *      userType: // value for 'userType'
 *      otherFields: // value for 'otherFields'
 *   },
 * });
 */
export function useEmailResolverQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmailResolverQuery,
    EmailResolverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmailResolverQuery, EmailResolverQueryVariables>(
    EmailResolverDocument,
    options
  );
}
export function useEmailResolverLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmailResolverQuery,
    EmailResolverQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EmailResolverQuery, EmailResolverQueryVariables>(
    EmailResolverDocument,
    options
  );
}
export type EmailResolverQueryHookResult = ReturnType<
  typeof useEmailResolverQuery
>;
export type EmailResolverLazyQueryHookResult = ReturnType<
  typeof useEmailResolverLazyQuery
>;
export type EmailResolverQueryResult = Apollo.QueryResult<
  EmailResolverQuery,
  EmailResolverQueryVariables
>;
export const CampaignBriefUpdatesDocument = gql`
  subscription campaignBriefUpdates($id: String!) {
    campaignBriefUpdates(id: $id) {
      id
      name
      budget
      currency
      description
      industry
      startDate
      endDate
      isManaged
      isSubmitted
      objective
      KPI
      id
      audience
      sitelistUrl
      geographies
      DSP
      seatId
      creator {
        id
      }
      client {
        id
        name
      }
    }
  }
`;

/**
 * __useCampaignBriefUpdatesSubscription__
 *
 * To run a query within a React component, call `useCampaignBriefUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCampaignBriefUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignBriefUpdatesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignBriefUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CampaignBriefUpdatesSubscription,
    CampaignBriefUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CampaignBriefUpdatesSubscription,
    CampaignBriefUpdatesSubscriptionVariables
  >(CampaignBriefUpdatesDocument, options);
}
export type CampaignBriefUpdatesSubscriptionHookResult = ReturnType<
  typeof useCampaignBriefUpdatesSubscription
>;
export type CampaignBriefUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<CampaignBriefUpdatesSubscription>;
export const CreativeBriefUpdatesDocument = gql`
  subscription creativeBriefUpdates($id: String!) {
    creativeBriefUpdates(id: $id) {
      id
      websiteUrl
      assetsUrl
      assetFiles
      focus
      promoMessage
      isSeasonal
      isNewProduct
    }
  }
`;

/**
 * __useCreativeBriefUpdatesSubscription__
 *
 * To run a query within a React component, call `useCreativeBriefUpdatesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCreativeBriefUpdatesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreativeBriefUpdatesSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreativeBriefUpdatesSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    CreativeBriefUpdatesSubscription,
    CreativeBriefUpdatesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    CreativeBriefUpdatesSubscription,
    CreativeBriefUpdatesSubscriptionVariables
  >(CreativeBriefUpdatesDocument, options);
}
export type CreativeBriefUpdatesSubscriptionHookResult = ReturnType<
  typeof useCreativeBriefUpdatesSubscription
>;
export type CreativeBriefUpdatesSubscriptionResult =
  Apollo.SubscriptionResult<CreativeBriefUpdatesSubscription>;
