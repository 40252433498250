import React from 'react';

import { Grid, H6, Body1, Box } from '@adludio/components';

import { UserProfileProps } from './UserProfileEdit';
import ProfileView from '../ProfileView';
import { useAuth } from '@adludio/react-sso';
import { initialConfig } from '../../../index';

const UserProfileView = (props: UserProfileProps) => {
  const auth = useAuth(initialConfig.ssoBEURL);
  if (!auth.user) return null;

  const avatar = {
    text: (auth.user.name ? auth.user.name : '')
      .split(' ')
      .map((n: string) => n[0])
      .join(''),
    bgColor: '#212121',
    fontColor: '#fff',
  };

  return (
    <ProfileView switchView={props.switchView} avatar={avatar}>
      <Grid container item direction='column' justify='center'>
        <Grid item xs>
          <Box pb='10%'>
            <H6>{auth.user.name}</H6>
            <Body1>{auth.user.jobTitle}</Body1>
          </Box>
        </Grid>
        <Grid item xs>
          <Body1>{auth.user.email}</Body1>
        </Grid>
      </Grid>
    </ProfileView>
  );
};

export default UserProfileView;
