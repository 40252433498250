import {
  AdludioTheme,
  CircularProgress,
  Grid,
  makeStyles,
} from '@adludio/components';
import React, { useEffect, useState } from 'react';

import { ErrorPage } from './ErrorPage';
import { PreviewProvider } from '@adludio/components/dist/Preview';
import Report from '../repo-board';
import { RouteComponentProps } from '@reach/router';
import {
  Operation,
  useGetLiveCampaignLazyQuery,
  useGetManagedServiceByIdQuery,
} from '../generated/graphql';
import { LiveBriefOverviewRead } from '../components/SingleBriefOverview/LiveBrief';
import { LiveSideNav } from '../components/LiveCampaignNav';
import CreativeUpload from '../components/CreativeUpload';
import Spinner from '../../../../Components/Spinner';

export interface ViewSingleBriefProps extends RouteComponentProps {
  env: 'development' | 'staging' | 'production' | 'testing';
  campaignId?: string;
  state?: string;
}

const useStyles = makeStyles((theme: AdludioTheme) => ({
  root: {
    backgroundColor: theme.palette.secondary.contrastText,
    zIndex: -3,
    padding: '0px',
    width: '100%',
    minHeight: '100vh',
    paddingBottom: '3.5em',
  },
  loadingContainer: {
    height: '100vh',
  },
  reporting: {
    margin: '0px 1em',
    width: '98%',
  },
}));

export function LiveCampaignPage(props: ViewSingleBriefProps): JSX.Element {
  // GraphQL hooks
  const { data, loading, error } = useGetManagedServiceByIdQuery({
    variables: { id: props.campaignId! },
    fetchPolicy: 'network-only',
  });

  const { root, loadingContainer, reporting } = useStyles();

  const [_, { refetch: getDownloadLink }] = useGetLiveCampaignLazyQuery({
    variables: { campaignId: props.campaignId!, operation: Operation.Get },
    fetchPolicy: 'network-only',
  });
  const [liveCampaignData, setLiveCampaignData] = useState({ startDate: '' });
  const [selectedItem, setSelectedItem] = useState(props.state);

  useEffect(() => {
    const campaignId = props.campaignId;

    if (campaignId !== undefined) {
      const promise = getDownloadLink({
        campaignId: props.campaignId!,
        operation: Operation.Get,
      });
      campaignId &&
        Promise.all([promise])
          .then((results) => {
            const data = results[0].data.getLiveCampaign;
            setLiveCampaignData(JSON.parse(data!));
          })
          .catch((err) => console.log(err));
    }
  }, [props.campaignId]);

  return error ? (
    <ErrorPage />
  ) : (
    <PreviewProvider>
      {loading ? (
        <Grid
          className={loadingContainer}
          container
          item
          xs
          justify='center'
          alignItems='center'
        >
          <Grid item xs={1}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction='column'
          xs={12}
          className={root}
          justifyContent='space-between'
        >
          <Grid
            container
            direction='column'
            xs={12}
            style={{ minHeight: '100vh' }}
          >
            <LiveSideNav
              selectedItem={selectedItem ?? 'reporting'}
              setSelectedItem={setSelectedItem}
              campaignId={props.campaignId}
            />
            <Grid item container xs={12} style={{ zIndex: 2 }}>
              {data && (
                <Grid container item direction='row' justify='center'>
                  <Grid
                    container
                    item
                    xs={12}
                    direction='row'
                    justify='center'
                    className={reporting}
                  >
                    {selectedItem === 'reporting' && (
                      <Report
                        campaignId={props.campaignId}
                        team={[data.getManagedServiceById?.creator!]}
                        campaignStatus='serving'
                        env={props.env}
                        campaignName={data.getManagedServiceById?.name!}
                      />
                    )}
                    {selectedItem === 'brief' && liveCampaignData && (
                      <Grid xs={10}>
                        {!!liveCampaignData.startDate ? (
                          <LiveBriefOverviewRead data={liveCampaignData} />
                        ) : (
                          <Spinner />
                        )}
                      </Grid>
                    )}
                    {selectedItem === 'creativeProduction' && (
                      <CreativeUpload
                        campaignId={props.campaignId!}
                        dsp={data.getManagedServiceById?.DSP}
                        advertiserId={data.getManagedServiceById?.advertiserID}
                        campaignName={data.getManagedServiceById?.name}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </PreviewProvider>
  );
}
